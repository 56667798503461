import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    height: '50px',
    padding: '5px',
    borderRadius: '4px',
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      backgroundColor: alpha(theme.palette.oldPrimary.main, 0.2),

      '& p': {
        textTransform: 'none',
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.oldPrimary.main, 0.4),
      },
    },
  },
  writtenData: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '80%',
    gap: '5px',
    padding: '0 4px',
    '& img': {
      width: '18px',
    },
    '& p': {
      margin: 10,
      fontWeight: 400,
      size: '14px',
      color: (props) =>
        props.active ? theme.palette.oldPrimary.main : theme.palette.accent.main,
    },
  },
  coloredLine: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    height: '5px',
    backgroundColor: (props: { color: string; active: boolean }) =>
      props.active ? props.color : theme.palette.accent.main,
    borderRadius: '4px',
    alignSelf: 'center',
  },
}));

export default useStyles;
