import { Button } from 'antd';
import SortIcon from 'src/assets/Icons/sharedIcons/sortDropdown-icon.svg';
import { SortByButtonProps } from 'src/shared/data/types/searchBarType';
import useStyles from './useStyles';

function SortByButton(props: SortByButtonProps): JSX.Element {
  const { onClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.sortByContainer}>
      <Button className={classes.sortByBtn} onClick={onClick}>
        <img src={SortIcon} alt="" />
      </Button>
    </div>
  );
}
export default SortByButton;
