import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { FarmDetails } from 'src/shared/data/types/farmTypes';
import { NewFarm } from '../../shared/data/types/growerFarmsTypes';

class FarmAdapter {
  static readonly endpoint = `/farms`;

  static readonly report_endpoint = `/reports`;

  async getFarmsByGrowerInternalId(
    id: number,
  ): Promise<AxiosResponse<FarmDetails[]>> {
    return HttpClientService.get<FarmDetails[]>(
      `${FarmAdapter.endpoint}/find-by-grower-id/${id}`,
      {
        headers: {},
      },
    );
  }

  async getFarmsByManagerId(
    ownerAccountId: string,
  ): Promise<AxiosResponse<FarmDetails[]>> {
    return HttpClientService.get<FarmDetails[]>(
      `${FarmAdapter.endpoint}/find-by-user`,
      {
        headers: { Authorization: `Bearer ${ownerAccountId}` },
      },
    );
  }

  // Oren's
  async getFarmsByUserToken(
    token: string,
  ): Promise<AxiosResponse<FarmDetails[]>> {
    // TODO - change endpoint in server to farm-by-user-token - this will work with regular growers
    return HttpClientService.get<FarmDetails[]>(
      `${FarmAdapter.endpoint}/find-by-grower`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  async getFarmsByGrowerCognitoId(
    growerCognitoId: string,
  ): Promise<AxiosResponse<FarmDetails[]>> {
    return HttpClientService.get<FarmDetails[]>(
      `${FarmAdapter.endpoint}/find-by-grower-cognito-id/${growerCognitoId}`,
    );
  }

  async postNewFarm(newFarm: NewFarm): Promise<AxiosResponse<FarmDetails>> {
    return HttpClientService.post<FarmDetails>(
      `${FarmAdapter.endpoint}`,
      newFarm,
    );
  }

  async getReport(body: {
    farmId: number;
    startDate?: string;
    endDate?: string;
    dataType?: string[];
    fileType: string;
    mSys: string;
  }): Promise<AxiosResponse<any>> {
    return HttpClientService.post<any>(
      `${FarmAdapter.report_endpoint}/excel`,
      body,
      {
        responseType: 'arraybuffer',
      },
    );
  }
}

const farmAdapter = new FarmAdapter();
export default farmAdapter;
