import ToggleWidget from './toggle_widget';
import { measurementSystemOptions } from './constants';
import { MeasurementSys } from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import {setMeasurementSys} from "../../../../../reduxToolkit/slices/UserSlice/user.slice";
import {getMeasurementSystem} from "../modals/utils";
import {useAppDispatch} from "../../../../../reduxToolkit/store/hooks";

interface MeasurementSystemToggleProps {
    measurement_system: MeasurementSys;
}

const MeasurementSystemToggle: React.FC<MeasurementSystemToggleProps> = ({
         measurement_system,
     }) => {
    const dispatch = useAppDispatch();
    const set_measurement_system = (system: MeasurementSys): void => {
        dispatch(setMeasurementSys({ measurementSystem: getMeasurementSystem(system)}));
    };
    return (
        <ToggleWidget
            options={measurementSystemOptions}
            selected_option={measurement_system}
            set_selected_option={(optionId: MeasurementSys) => set_measurement_system(optionId)}
        />
    );
};

export default MeasurementSystemToggle;
