import { useCallback } from 'react';
import { useAppDispatch } from 'src/reduxToolkit/store/hooks';
import { updateBlockField } from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { SelectFormFieldProps } from 'src/shared/data/types/formsTypes';
import Select from 'src/shared/components/Select/Select';
import useStyles from './useStyles';

function SelectFormField(props: SelectFormFieldProps): JSX.Element {
  const {
    title,
    fieldName,
    placeholder,
    options,
    isMultiSelect = false,
    filterOption,
    handleBlur,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleChangeSelection = useCallback(
    (value: string) => {
      dispatch(updateBlockField({ field: fieldName, value }));
    },
    [fieldName, dispatch],
  );

  return (
    <span className={classes.root}>
      <span>{title}</span>
      <Select
        options={options}
        placeholder={placeholder}
        handleChangeSelection={handleChangeSelection}
        isMultiSelect={isMultiSelect}
        handleBlur={handleBlur}
        filterOption={filterOption}
      />
    </span>
  );
}

export default SelectFormField;
