import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { Button } from 'antd';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import {
  setOpenConfirmationModal,
  setDeletedBlock,
  setSavedBlock,
} from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import BlocksAdapter from 'src/reduxToolkit/adapters/BlocksAdapter';
import useStyles from './useStyles';
import messageTypes from '../../../consts/messages';
import notifyService from '../../../shared/notification/notification.service';
import { setCurrentBlock } from '../../../reduxToolkit/slices/BlockSlice/block.slice';
import {
  closeModal,
  removeBlock,
} from '../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';
import { useNavigate } from 'react-router-dom';
import { MiniGrower } from '../../../shared/data/types/growerFarmsTypes';

function DeleteBlockForm(props: any): JSX.Element {
  const { block } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { deletedBlock } = useAppSelector(
    (state) => state.rootReducer.addOrEditBlockState,
  );
  const grower: MiniGrower | null = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState.currentGrower;
  });
  const { blocks } = useAppSelector((state) => state.rootReducer.blockState);
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSaveForm = useCallback(async (): Promise<void> => {
    if (deletedBlock && !submitted) {
      const farmId = block.farmId;
      setSubmitted(true);
      await BlocksAdapter.deleteBlockInfo(deletedBlock);
      dispatch(removeBlock(deletedBlock.id));
      setSubmitted(false);
      dispatch(setOpenConfirmationModal(false));
      dispatch(setDeletedBlock(undefined));
      dispatch(setSavedBlock(true));
      const filteredBlocksWithoutCurrent = blocks.filter(
        (item) => item.id !== deletedBlock.id,
      );
      dispatch(setCurrentBlock(filteredBlocksWithoutCurrent[0]));
      dispatch(closeModal());
      notifyService('Block deleted successfully', messageTypes.success);
      navToFarm(farmId);
    }
  }, [deletedBlock, submitted, blocks, dispatch, block.farmId]);

  function navToFarm(farmId: number) {
    if (!grower) return;

    navigate(`/growers/${grower?.id}?farmId=${farmId}`);
  }

  return (
    <div className={`flex-column align-center justify-center ${classes.root}`}>
      <Typography className="delete-block-modal-title">
        {`${t('deleteModal.title')}`} {block.title}?
      </Typography>
      <Typography variant="titleMedium">{`${t(
        'deleteModal.warning',
      )}`}</Typography>
      <div className={classes.btnContainer}>
        <Button
          id="confirm-delete-block"
          type="primary"
          className={classes.confirmationBtn}
          disabled={submitted}
          onClick={() => {
            void (async () => {
              await onSaveForm();
            })();
          }}>
          {`${t('deleteModal.delete')}`} &amp; {`${t('deleteModal.exit')}`}
        </Button>
        <Button
          type="primary"
          className={classes.cancelBtn}
          onClick={() => {
            dispatch(setDeletedBlock(undefined));
            dispatch(setOpenConfirmationModal(false));
          }}>
          {`${t('deleteModal.cancel')}`}
        </Button>
      </div>
    </div>
  );
}

export default DeleteBlockForm;
