import React from 'react';
import { IconFile } from "../../../../assets/Icons/blockData";

interface IconProps {
    name: keyof typeof IconFile; // Ensure the key matches IconFile keys
    size?: number; // Optional size prop for controlling the icon size
    onClick?: () => void; // Optional onClick prop for handling clicks
}

const GenericIcon: React.FC<IconProps> = ({ name, size = 24, onClick }) => {
    const iconSrc = IconFile[name];

    if (!iconSrc) {
        return null; // Handle the case if the icon is not found
    }

    return (
        <img
            src={iconSrc}
            width={size}
            height={size}
            style={{ display: 'inline-block', cursor: onClick ? 'pointer' : 'default' }} // Add cursor pointer if clickable
            onClick={onClick} // Attach onClick handler if provided
        />
    );
};

export default GenericIcon;
