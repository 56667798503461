function Download(data: { color: string }): JSX.Element {
  const { color } = data;
  return (
    <svg
      id="uuid-2e18a754-0e61-40d8-bcf6-233644142abb"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.4 17.78">
      <g id="uuid-5c8d5590-161a-4dc9-9a02-12c2799782bb">
        <path
          d="m4.37,17.78c-1.16,0-2.26-.46-3.08-1.28C.47,15.69,0,14.58,0,13.42v-2.91c0-.23.09-.46.26-.62.16-.17.39-.26.62-.26.23,0,.45.1.61.26.16.16.26.38.26.61v2.91c0,.69.28,1.36.77,1.85.49.49,1.15.77,1.85.77h11.65c.34,0,.68-.07,1-.2.32-.13.61-.33.85-.57.24-.24.43-.53.57-.85.13-.32.2-.66.2-1v-2.91c0-.12.01-.24.05-.35.04-.11.11-.21.19-.3.08-.09.18-.15.29-.2.11-.04.23-.07.35-.06.12,0,.23.01.34.06.11.04.21.1.29.19.08.08.15.18.19.28.04.11.06.22.06.34v2.91c0,.58-.11,1.15-.33,1.68-.22.53-.54,1.02-.95,1.42-.41.41-.89.73-1.42.95-.53.22-1.1.33-1.68.33H4.37v.03Z"
          style={{
            fill: color,
          }}
        />
        <path
          d="m10.82.26c.16.17.26.39.26.62v10.49c0,.23-.1.45-.26.61-.16.16-.38.25-.61.25-.12,0-.23-.02-.34-.06-.11-.04-.2-.11-.29-.19-.08-.08-.15-.18-.19-.29-.04-.11-.06-.22-.06-.34V.87c0-.23.1-.45.26-.61.16-.16.38-.26.61-.26.23,0,.46.1.62.26Z"
          style={{
            fill: color,
          }}
        />
        <path
          d="m5.57,7.05c.11.05.21.11.29.2l4.34,4.34,4.31-4.31c.16-.16.39-.26.62-.26s.45.09.62.26c.16.16.26.39.26.62s-.09.45-.26.62l-4.95,4.93c-.17.16-.39.25-.62.25s-.46-.09-.62-.25l-4.93-4.97c-.12-.12-.2-.28-.23-.45-.03-.17-.01-.34.05-.5.07-.16.17-.29.31-.39.14-.1.31-.15.48-.16.12,0,.23.02.34.07Z"
          style={{
            fill: color,
          }}
        />
      </g>
    </svg>
  );
}
export default Download;
