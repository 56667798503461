import { Theme, darken } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  graphTitleContainer: {
    flex: 1,
  },
  switchContainer: {
    zIndex: 50,
    '& .ant-switch': {
      marginRight: 'auto',
      backgroundColor: theme.palette.accent.main,
      colorPrimaryHover: darken(theme.palette.accent.main, 0.2),
      colorPrimaryBorder: 'none !important',
      outline: 'none',
      minWidth: '4.75rem !important',
      '@media (max-width: 767px)': {
        minWidth: '3rem !important',
      },
      '&.ant-switch-checked': {
        marginLeft: 'auto',
        backgroundColor: theme.palette.accent.main,
        '&:hover': {
          backgroundColor: darken(theme.palette.accent.main, 0.2),
        },
        '&.ant-switch-unchecked': {
          marginLeft: 'auto',
          backgroundColor: theme.palette.accent.main,
          '&:hover': {
            backgroundColor: darken(theme.palette.accent.main, 0.2),
          },
        },
      },
      '&:hover': {
        backgroundColor: darken(theme.palette.accent.main, 0.2),
        '&::not(.ant-switch-disabled)': {
          backgroundColor: darken(theme.palette.accent.main, 0.2),
        },
      },
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: '#fff',
      pointerEvents: 'none',
    },
  },
  graphTitleReports: {
    width: '100%',
  },
  farmReportsIcons: {
    gap: '0.8rem',
  },
  reportsButton: {
    border: 'none',
    outline: 'none',
    pointerEvents: 'auto',
    cursor: 'pointer',
    background: 'none',
    height: 'unset !important',
    padding: '0 !important',
    lineHeight: '1 !important',
  },
  iconLarge: {
    display: 'block !important',
  },
  growerReportsModalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  reportsModalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
}));

export default useStyles;
