import { combineReducers } from '@reduxjs/toolkit';
import { blockReducer } from 'src/reduxToolkit/slices/BlockSlice/block.slice';
import { searchAndSortReducer } from 'src/reduxToolkit/slices/SearchAndSortSlice/searchAndSort.slice';
import { farmReducer } from 'src/reduxToolkit/slices/FarmSlice/farm.slice';
import { mapReducer } from 'src/reduxToolkit/slices/MapSlice/map.slice';
import { weatherReducer } from 'src/reduxToolkit/slices/WeatherSlice/weather.slice';
import { addOrEditBlockReducer } from '../slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { RootState } from './root-state';
import { userReducer } from '../slices/UserSlice/user.slice';
import { sensorsReducer } from '../slices/SensorsSlice/sensors.slice';
import { tokenReducer } from '../slices/TokenSlice/token.slice';
import { accountReducer } from '../slices/AccountSlice/account.slice';
import { agentReducer } from '../slices/AgentSlice/agent.slice';
import { growerReducer } from '../slices/GrowerSlice/grower.slice';
import { languageReducer } from '../slices/LanguageSlice/language.slice';
import { GrowerReportsSliceReducer } from '../slices/GrowerReportsSlice/growerReports.slice';
import { demoUserReducer } from '../slices/demoUserSlice/demoUser.slice';
import growerFarmsReducer from '../slices/GrowerFarmsSlice/GrowerFarms.slice';
import { blockDataReducer } from '../slices/BlockDataSlice/blockData.slice';
import { flowDataReducer } from '../slices/FlowData/flowData.slice';
const RootReducer = combineReducers<RootState>({
  blockState: blockReducer,
  searchAndSortState: searchAndSortReducer,
  farmState: farmReducer,
  mapState: mapReducer,
  growerState: growerReducer,
  growerReportsState: GrowerReportsSliceReducer,
  accountState: accountReducer,
  agentState: agentReducer,
  weatherState: weatherReducer,
  addOrEditBlockState: addOrEditBlockReducer,
  sensorsState: sensorsReducer,
  userState: userReducer,
  tokenState: tokenReducer,
  languageState: languageReducer,
  demoUserState: demoUserReducer,
  GrowerFarmsState: growerFarmsReducer,
  blockDataState: blockDataReducer,
  flowDataState: flowDataReducer
});
export default RootReducer;
