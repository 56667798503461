import { createAsyncThunk } from '@reduxjs/toolkit';
import { PolygonType } from 'src/shared/data/types/polygonTypes';
import polygonAdapter from '../adapters/PolygonsAdapter';

const getPolygonsByFarm = createAsyncThunk<
  PolygonType[],
  number,
  { rejectValue: Error }
>(
  'polygons',
  async (farmId, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await polygonAdapter.getPolygonsByFarm(farmId);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export default getPolygonsByFarm;
