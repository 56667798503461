export const codeDomainExt = [
  'mobi',
  'supply',
  'site',
  'com',
  'net',
  'org',
  'io',
  'co',
  'ai',
  'co.uk',
  'ca',
  'dev',
  'me',
  'de',
  'app',
  'in',
  'is',
  'eu',
  'gg',
  'to',
  'ph',
  'nl',
  'id',
  'inc',
  'website',
  'xyz',
  'club',
  'online',
  'info',
  'store',
  'best',
  'live',
  'us',
  'tech',
  'pw',
  'pro',
  'uk',
  'tv',
  'cx',
  'mx',
  'fm',
  'br',
  'au',
  'ru',
  'jp',
  'world',
  'space',
  'vip',
  'life',
  'shop',
  'host',
  'fun',
  'biz',
  'icu',
  'design',
  'edu',
  'gov',
  'mil',
  'net',
  'art',
  'ac', // Ascension Island
  'ad', // Andorra
  'ae', // United Arab Emirates
  'af', // Afghanistan
  'ag', // Antigua and Barbuda
  'ai', // Anguilla
  'al', // Albania
  'am', // Armenia
  'an', // Netherlands Antilles (obsolete, but previously used)
  'ao', // Angola
  'aq', // Antarctica
  'ar', // Argentina
  'as', // American Samoa
  'at', // Austria
  'au', // Australia
  'aw', // Aruba
  'ax', // Åland Islands
  'az', // Azerbaijan
  'ba', // Bosnia and Herzegovina
  'bb', // Barbados
  'bd', // Bangladesh
  'be', // Belgium
  'bf', // Burkina Faso
  'bg', // Bulgaria
  'bh', // Bahrain
  'bi', // Burundi
  'bj', // Benin
  'bl', // Saint Barthélemy
  'bm', // Bermuda
  'bn', // Brunei Darussalam
  'bo', // Bolivia
  'bq', // Bonaire, Sint Eustatius and Saba
  'br', // Brazil
  'bs', // Bahamas
  'bt', // Bhutan
  'bv', // Bouvet Island
  'bw', // Botswana
  'by', // Belarus
  'bz', // Belize
  'ca', // Canada
  'cc', // Cocos (Keeling) Islands
  'cd', // Democratic Republic of the Congo
  'cf', // Central African Republic
  'cg', // Republic of the Congo
  'ch', // Switzerland
  'ci', // Côte d'Ivoire
  'ck', // Cook Islands
  'cl', // Chile
  'cm', // Cameroon
  'cn', // People's Republic of China
  'co', // Colombia
  'cr', // Costa Rica
  'cu', // Cuba
  'cv', // Cape Verde
  'cw', // Curaçao
  'cx', // Christmas Island
  'cy', // Cyprus
  'cz', // Czech Republic
  'de', // Germany
  'dj', // Djibouti
  'dk', // Denmark
  'dm', // Dominica
  'do', // Dominican Republic
  'dz', // Algeria
  'ec', // Ecuador
  'ee', // Estonia
  'eg', // Egypt
  'eh', // Western Sahara
  'er', // Eritrea
  'es', // Spain
  'et', // Ethiopia
  'eu', // European Union
  'fi', // Finland
  'fj', // Fiji
  'fk', // Falkland Islands
  'fm', // Federated States of Micronesia
  'fo', // Faroe Islands
  'fr', // France
  'ga', // Gabon
  'gb', // United Kingdom (not actively used as .uk is used instead)
  'gd', // Grenada
  'ge', // Georgia
  'gf', // French Guiana
  'gg', // Guernsey
  'gh', // Ghana
  'gi', // Gibraltar
  'gl', // Greenland
  'gm', // The Gambia
  'gn', // Guinea
  'gp', // Guadeloupe
  'gq', // Equatorial Guinea
  'gr', // Greece
  'gs', // South Georgia and the South Sandwich Islands
  'gt', // Guatemala
  'gu', // Guam
  'gw', // Guinea-Bissau
  'gy', // Guyana
  'hk', // Hong Kong
  'hm', // Heard Island and McDonald Islands
  'hn', // Honduras
  'hr', // Croatia
  'ht', // Haiti
  'hu', // Hungary
  'id', // Indonesia
  'ie', // Ireland
  'il', // Israel
  'im', // Isle of Man
  'in', // India
  'io', // British Indian Ocean Territory
  'iq', // Iraq
  'ir', // Iran
  'is', // Iceland
  'it', // Italy
  'je', // Jersey
  'jm', // Jamaica
  'jo', // Jordan
  'jp', // Japan
  'ke', // Kenya
  'kg', // Kyrgyzstan
  'kh', // Cambodia
  'ki', // Kiribati
  'km', // Comoros
  'kn', // Saint Kitts and Nevis
  'kp', // North Korea
  'kr', // South Korea
  'kw', // Kuwait
  'ky', // Cayman Islands
  'kz', // Kazakhstan
  'la', // Laos
  'lb', // Lebanon
  'lc', // Saint Lucia
  'li', // Liechtenstein
  'lk', // Sri Lanka
  'lr', // Liberia
  'ls', // Lesotho
  'lt', // Lithuania
  'lu', // Luxembourg
  'lv', // Latvia
  'ly', // Libya
  'ma', // Morocco
  'mc', // Monaco
  'md', // Moldova
  'me', // Montenegro
  'mg', // Madagascar
  'mh', // Marshall Islands
  'mk', // North Macedonia
  'ml', // Mali
  'mm', // Myanmar
  'mn', // Mongolia
  'mo', // Macao
  'mp', // Northern Mariana Islands
  'mq', // Martinique
  'mr', // Mauritania
  'ms', // Montserrat
  'mt', // Malta
  'mu', // Mauritius
  'mv', // Maldives
  'mw', // Malawi
  'mx', // Mexico
  'my', // Malaysia
  'mz', // Mozambique
  'na', // Namibia
  'nc', // New Caledonia
  'ne', // Niger
  'nf', // Norfolk Island
  'ng', // Nigeria
  'ni', // Nicaragua
  'nl', // Netherlands
  'no', // Norway
  'np', // Nepal
  'nr', // Nauru
  'nu', // Niue
  'nz', // New Zealand
  'om', // Oman
  'pa', // Panama
  'pe', // Peru
  'pf', // French Polynesia
  'pg', // Papua New Guinea
  'ph', // Philippines
  'pk', // Pakistan
  'pl', // Poland
  'pm', // Saint Pierre and Miquelon
  'pn', // Pitcairn Group of Islands
  'pr', // Puerto Rico
  'ps', // State of Palestine
  'pt', // Portugal
  'pw', // Palau
  'py', // Paraguay
  'qa', // Qatar
  're', // Réunion
  'ro', // Romania
  'rs', // Serbia
  'ru', // Russia
  'rw', // Rwanda
  'sa', // Saudi Arabia
  'sb', // Solomon Islands
  'sc', // Seychelles
  'sd', // Sudan
  'se', // Sweden
  'sg', // Singapore
  'sh', // Saint Helena, Ascension and Tristan da Cunha
  'si', // Slovenia
  'sj', // Svalbard og Jan Mayen (not in use)
  'sk', // Slovakia
  'sl', // Sierra Leone
  'sm', // San Marino
  'sn', // Senegal
  'so', // Somalia
  'sr', // Suriname
  'ss', // South Sudan
  'st', // São Tomé and Príncipe
  'su', // Soviet Union (still in use)
  'sv', // El Salvador
  'sx', // Sint Maarten
  'sy', // Syria
  'sz', // Eswatini
  'tc', // Turks and Caicos Islands
  'td', // Chad
  'tf', // French Southern and Antarctic Lands
  'tg', // Togo
  'th', // Thailand
  'tj', // Tajikistan
  'tk', // Tokelau
  'tl', // East Timor (using its Portuguese name)
  'tm', // Turkmenistan
  'tn', // Tunisia
  'to', // Tonga
  'tp', // East Timor (old code, now using .tl)
  'tr', // turkey
  'ye', // Yemen
  'yt', // Mayotte
  'za', // South Africa
  'zm', // Zambia
  'zw', // Zimbabwe
];
