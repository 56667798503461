import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Tree } from 'antd';
import { useDispatch } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import ProgressBar from '../../shared/components/ProgressBar';
import useStyles from './useStyles';
import toroPlus from '../../assets/Icons/toroThemeIcons/toroPlus.svg';
import greenPlus from '../../assets/Icons/mainThemeIcons/greenPlus.svg';
import Modal from '../../shared/components/Modal/Modal';
import AddAgentModal from './AgentModal/AddAgentModal';
import { useAppSelector } from '../../reduxToolkit/store/hooks';
import agentsAdapter from '../../reduxToolkit/adapters/AgentsAdapter';
import { getAllAccounts } from '../../reduxToolkit/thunks/account_thunks';
import AgentPreview from './AgentPreview';
import { DeleteModal } from 'src/shared/components/GenericModal';
import ItemSelect from "./ItemSelect";
import notifyService from '../../shared/notification/notification.service';
import messageTypes from '../../consts/messages';
import { UserRole } from '../../shared/constants/enums';
import EditAgentModal from './AgentModal/EditAgentModal/EditAgentModal';
import deleteIcon from '../../assets/Icons/sharedIcons/deleteIcon.svg';
import editIcon from '../../assets/Icons/sharedIcons/editIcon.svg';

const updateTreeData = (list: any[], key: React.Key, children: any[]): any[] =>
  list.map((node) => {
    if (node.sub === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

function AgentsManagement(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [accounts, setAccounts] = useState<any>({
    accounts: [],
    total_accounts: 0,
  });
  const [agents, setAgents] = useState<any>({ agents: [], total_agents: 0 });
  const [selectedAgent, setSelectedAgent] = useState<any>(undefined);
  const [account, setAccount] = useState<any>(undefined);
  const [agentData, setAgentData] = useState<any[]>([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.agentState.isToroAccount,
  );
  const userData = useAppSelector((state) => state.rootReducer.userState);
  const { t } = useTranslation();

  const handleCopy = async (e: any, item: any): Promise<any> => {
    const copiedText = await navigator.clipboard.writeText(item);
    notifyService(
      Number.isInteger(item)
        ? `${selectedAgent.title} ${t(
            'agentPage.notifyCopyAgentNumberSuccess',
          )}`
        : `${selectedAgent.title} ${t(
            'agentPage.notifyCopyAgentEmailSuccess',
          )}`,
      messageTypes.success,
    );
    return copiedText;
  };

  useEffect(() => {
    if (agents) {
      setAgentData(
        agents.agents.map((ag: any) => ({
          ...ag,
          key: ag.sub,
          title: `${ag.firstName} ${ag.lastName}`,
        })),
      );
    }
  }, [agents]);

  useEffect(() => {
    if (userData) {
      if (userData.role === UserRole.ADMIN) {
        setLoading(true);
        getAllAccounts().then((mAccounts: any) => {
          setAccounts({
            accounts: mAccounts.data,
            total_accounts: mAccounts.data.length,
          });
          setSelectedAgent(mAccounts.data[0]);
          setAccount(mAccounts.data[0]);
          setLoading(false);
        });
      } else if (
        [
          UserRole.ACCOUNT_ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.AGENT_ADMIN,
          UserRole.AGENT_VIEWER,
        ].includes(userData.role ?? UserRole.ADMIN)
      ) {
        const item: any = {
          ...userData,
          accountCognitoId: userData.sub,
        };
        setSelectedAgent(item);
        setAccount(item);
      }
    }
  }, [dispatch, userData]);

  const loadAccountAgents = (ownerAccountId: any): void => {
    if (ownerAccountId) {
      setLoadingAgent(true);
      agentsAdapter
        .getAllAgents(ownerAccountId)
        .then((mAgents: any) => {
          setAgents({
            agents: mAgents.data,
            total_agents: mAgents.data.length,
          });
        })
        .finally(() => {
          setLoadingAgent(false);
        });
    }
  };
  //
  const loadAccountChildAgents = (
    ownerAccountId: any,
    selectedAgentSub: any,
    callback?: any,
  ): void => {
    if (ownerAccountId) {
      agentsAdapter
        .getAgentsChildren(ownerAccountId, selectedAgentSub)
        .then((mAgents: any) => {
          setAgents({
            agents: updateTreeData(agents.agents, selectedAgentSub, [
              ...mAgents.data.map((item: any) => ({
                ...item,
                key: item.sub,
                title: `${item.firstName} ${item.lastName}`,
              })),
            ]),
          });
          if (ownerAccountId === selectedAgent.sub) {
            setSelectedAgent({
              ...selectedAgent,
              children: [
                ...mAgents.data.map((item: any) => ({
                  ...item,
                  key: item.sub,
                  title: `${item.firstName} ${item.lastName}`,
                })),
              ],
            });
          }
          if (callback) {
            callback();
          }
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    if (account) {
      setSelectedAgent(account);
      setAgents({ agents: [], total_agents: 0 });
      setAgentData([]);
      loadAccountAgents(account.accountCognitoId);
    }
  }, [account]);

  const onOpenAgentTree = async (item: any): Promise<void> =>
    new Promise<void>((resolve) => {
      if (item.children) {
        resolve();
        return;
      }
      loadAccountChildAgents(item.ownerAccountId, item.sub, () => {
        resolve();
      });
    });

  const handleCloseModal = (): any => {
    setToggleModal(false);
    setToggleDeleteModal(false);
    setToggleEditModal(false);
  };

  const handleOnEdit = async (record: any): Promise<any> => {
    setToggleEditModal(false);
    loadAccountAgents(account);
    setSelectedAgent(account);
    notifyService(
      `${selectedAgent.title} ${t('agentPage.notifyUpdateAgentSuccess')}`,
      messageTypes.success,
    );
  };

  const handleOnDelete = async (record: any): Promise<any> => {
    if (record !== undefined) {
      setLoadingAction(true);
      await agentsAdapter
        .deleteSpecificAgentById(selectedAgent?.key, record.sub)
        .then(() => {
          loadAccountAgents(account);
          setToggleDeleteModal(false);
          setSelectedAgent(account);
          notifyService(
            `${selectedAgent.title} ${t(
              'agentPage.notifyDeletedAgentSuccess',
            )}`,
            messageTypes.success,
          );
        })
        .finally(() => {
          setLoadingAction(false);
        });
    }
  };

  const handleOnSave = (item: any): any => {
    if (!selectedAgent) {
      setAgents({
        agents: [
          ...agents.agents,
          {
            ...item,
            key: item.sub,
            title: `${item.firstName} ${item.lastName}`,
          },
        ],
        total_agents: agents.agents.length + 1,
      });
    } else if (selectedAgent.children) {
      setAgents({
        agents: updateTreeData(agents.agents, selectedAgent.sub, [
          ...selectedAgent.children,
          {
            ...item,
            key: item.sub,
            title: `${item.firstName} ${item.lastName}`,
          },
        ]),
      });
    } else if (
      agents.agents.length === 0 ||
      account.sub === selectedAgent.sub
    ) {
      setAgents({
        agents: [
          ...(agents.agents || []),
          {
            ...item,
            key: item.sub,
            title: `${item.firstName} ${item.lastName}`,
          },
        ],
      });
    } else if (!selectedAgent.children) {
      setAgents({
        agents: updateTreeData(agents.agents, selectedAgent.sub, [
          {
            ...item,
            key: item.sub,
            title: `${item.firstName} ${item.lastName}`,
          },
        ]),
      });
    }
    setToggleModal(false);
  };

  const selectAgent = (item: any, event: any): any => {
    if (item.length > 0) {
      setSelectedAgent(event.node);
    } else {
      setSelectedAgent(account);
    }
  };

  return (
    <section
      className={`flex-column container-inline ${classes.tableContainer}`}>
      <h2 className={`flex align-items justify-center ${classes.header}`}>
        {t('agentPage.title')}
      </h2>
      {!loading && (
        <>
          <article className={`flex ${classes.btnContainer}`}>
            <div className={classes.btnContainerWrapper}>
              <div className={classes.btnContainerSelect}>
                {accounts.accounts?.length > 0 && (
                  <ItemSelect
                    data={{
                      fieldState: account?.id,
                      title: `${t('agentPage.accountsSelect')}`,
                      options: accounts.accounts.map((item: any) => ({
                        label: item.title,
                        value: item.id,
                      })),
                    }}
                    onChange={(id: string) => {
                      const selectedAccount = accounts.accounts.find(
                        (acc: any) => acc.id === id,
                      );
                      setAccount(selectedAccount);
                    }}
                  />
                )}
              </div>
              <div>
                <Button
                  onClick={() => setToggleModal(true)}
                  className={`ant-btn-custom ${classes.userButton}`}>
                  {isToroAccount ? (
                    <img src={toroPlus} alt="plus" />
                  ) : (
                    <img src={greenPlus} alt="plus" />
                  )}
                  {`${t('agentPage.addAgent')}`}
                </Button>
              </div>
            </div>
          </article>
          {!loadingAgent && (
            <div className={classes.treeContainer}>
              <div className={classes.treeWrapper}>
                <Tree
                  loadData={onOpenAgentTree}
                  onSelect={selectAgent}
                  switcherIcon={<DownOutlined />}
                  showLine
                  treeData={agentData}
                />
              </div>
              <div className={classes.treeItemPreview}>
                {selectedAgent && (
                  <div className={classes.treeItemPreviewContainer}>
                    <AgentPreview agent={selectedAgent} onCopy={handleCopy} />
                    <div className={classes.btnActionContainer}>
                      {selectedAgent?.role && (
                        <div>
                          <Button
                            onClick={() => setToggleEditModal(true)}
                            className={classes.tableBtn}>
                            <img src={`${editIcon}`} alt="edit agent" />
                          </Button>
                        </div>
                      )}
                      {selectedAgent.children?.length === 0 && (
                        <div>
                          <Button
                            onClick={() => setToggleDeleteModal(true)}
                            className={classes.tableBtn}>
                            <img src={`${deleteIcon}`} alt="delete agent" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {(loading || loadingAgent) && <ProgressBar />}
      {toggleModal && (
        <Modal isOpen={toggleModal} onCancel={handleCloseModal}>
          <AddAgentModal
            onClose={handleCloseModal}
            onSave={handleOnSave}
            account={selectedAgent}
          />
        </Modal>
      )}
      {toggleDeleteModal && (
        <Modal isOpen={toggleDeleteModal} onCancel={handleCloseModal}>
          <DeleteModal
            record={selectedAgent}
            loading={loadingAction}
            handleDelete={handleOnDelete}
            onClose={handleCloseModal}
          />
        </Modal>
      )}
      {toggleEditModal && (
        <Modal isOpen={toggleEditModal} onCancel={handleCloseModal}>
          <EditAgentModal
            account={selectedAgent}
            record={selectedAgent}
            onSave={handleOnEdit}
            onClose={handleCloseModal}
          />
        </Modal>
      )}
    </section>
  );
}
export default AgentsManagement;
