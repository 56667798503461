import {
  createTheme,
  PaletteColorOptions,
  PaletteColor,
  TypographyStyle,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: TypographyStyle;
    titleMedium: TypographyStyle;
    titleLarge: TypographyStyle;
    titleXlLarge: TypographyStyle;
    titleRegular: TypographyStyle;
    h1Bold: TypographyStyle;
    h1Modal: TypographyStyle;
    body1Bold: TypographyStyle;
    headline1:TypographyStyle,
    headline2:TypographyStyle,
    title1:TypographyStyle,
    title2:TypographyStyle,
    title3:TypographyStyle,
    bodyRegular:TypographyStyle,
    bodyMedium:TypographyStyle,
    bodySemiBold:TypographyStyle,
    bodySmallRegular:TypographyStyle,
    bodyMediumRegular:TypographyStyle,
    infoMedium:TypographyStyle,
    infoRegular:TypographyStyle,
    footnote:TypographyStyle,
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title: TypographyStyle;
    titleMedium: TypographyStyle;
    titleLarge: TypographyStyle;
    titleXlLarge: TypographyStyle;
    titleRegular: TypographyStyle;
    h1Bold: TypographyStyle;
    body1Bold: TypographyStyle;
    headline1:TypographyStyle,
    headline2:TypographyStyle,
    title1:TypographyStyle,
    title2:TypographyStyle,
    title3:TypographyStyle,
    bodyRegular:TypographyStyle,
    bodyMedium:TypographyStyle,
    bodySemiBold:TypographyStyle,
    bodySmallRegular:TypographyStyle,
    bodyMediumRegular:TypographyStyle,
    infoMedium:TypographyStyle,
    infoRegular:TypographyStyle,
    footnote:TypographyStyle,
  }
  interface Palette {
    oldPrimary: PaletteColor;
    accent: PaletteColor;
    darkGrey: PaletteColor;
    hoverGrey: PaletteColor;
    hoverDarkGrey: PaletteColor;
    backgroundGrey: PaletteColor;
    modalLightBrown: PaletteColor;
    red: PaletteColor;
    lightGreyBackground: PaletteColor;
    lightGrey: PaletteColor;
    mediumGrey: PaletteColor;
    baseGrey: PaletteColor;
    primary: PaletteColor;
    primaryVariant: PaletteColor;
    surface: PaletteColor;
    onBorder: PaletteColor;
    surfaceVariant: PaletteColor;
    onSurface: PaletteColor;
    surfaceEvent: PaletteColor;
    surfaceWhite: PaletteColor;
    surfaceMenu: PaletteColor;
    baseBlack: PaletteColor;
    deepSurface: PaletteColor;
    silver: PaletteColor;
    deepSilver: PaletteColor;
    deepMenu: PaletteColor;
    steel: PaletteColor;
    deepSteel: PaletteColor;
    event: PaletteColor;
    surfaceHigh: PaletteColor;
    high: PaletteColor;
    surfaceModerate: PaletteColor;
    moderate: PaletteColor;
    surfaceLow: PaletteColor;
    low: PaletteColor;
    surfaceOptimal: PaletteColor;
    optimal: PaletteColor;
  }
  interface PaletteOptions {
    oldPrimary: PaletteColorOptions;
    accent: PaletteColorOptions;
    darkGrey: PaletteColorOptions;
    hoverGrey: PaletteColorOptions;
    hoverDarkGrey: PaletteColorOptions;
    backgroundGrey: PaletteColorOptions;
    modalLightBrown: PaletteColorOptions;
    red: PaletteColorOptions;
    lightGrey: PaletteColorOptions;
    lightGreyBackground: PaletteColorOptions;
    mediumGrey: PaletteColorOptions;
    baseGrey: PaletteColorOptions;
    primaryVariant: PaletteColorOptions;
    surface: PaletteColorOptions;
    onBorder: PaletteColorOptions;
    surfaceVariant: PaletteColorOptions;
    onSurface: PaletteColorOptions;
    surfaceEvent: PaletteColorOptions;
    surfaceWhite: PaletteColorOptions;
    surfaceMenu: PaletteColorOptions;
    baseBlack: PaletteColorOptions;
    deepSurface: PaletteColorOptions;
    silver: PaletteColorOptions;
    deepSilver: PaletteColorOptions;
    deepMenu: PaletteColorOptions;
    steel: PaletteColorOptions;
    deepSteel: PaletteColorOptions;
    event: PaletteColorOptions;
    surfaceHigh: PaletteColorOptions;
    high: PaletteColorOptions;
    surfaceModerate: PaletteColorOptions;
    moderate: PaletteColorOptions;
    surfaceLow:PaletteColorOptions;
    low: PaletteColorOptions;
    surfaceOptimal: PaletteColorOptions;
    optimal: PaletteColorOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    titleMedium: true;
    titleLarge: true;
    titleXlLarge: true;
    titleRegular: true;
    h1Bold: true;
    body1Bold: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    paddings: {
      xs: string;
      s: string;
      m: string;
      l: string;
      xl: string;
      xxl: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    paddings?: {
      xs?: string;
      s?: string;
      m?: string;
      l?: string;
      xl?: string;
      xxl?: string;
    };
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    borderRadius: {
      s: string;
      m: string;
      l: string;
      xl: string;
      xxl: string;
      xxxl: string;
    };
  }

  interface ThemeOptions {
    borderRadius?: {
      s?: string;
      m?: string;
      l?: string;
      xl?: string;
      xxl?: string;
      xxxl?: string;
    };
  }
}


const toroTheme = createTheme({
  palette: {
    primary:{
      main: '#CE023A',
    },
    primaryVariant:{
      main: '#EB0B43',
    },
    onBorder:{
      main: '#545454',
    },
    surface:{
      main: '#EDEDED',
    },
    surfaceMenu :{
      main: '#999999',
    },
    surfaceVariant: {
      main: '#F7F7F7',
    },
    onSurface: {
      main: '#363636',
    },
    surfaceEvent: {
      main: '#E8F1FB',
    },
    surfaceWhite: {
      main: '#FFFFFF',
    },
    baseBlack: {
      main: '#000000',
    },
    deepSurface: {main:'#DFDFDF'},
    silver: {main:'#CFCFCF'},
    deepSilver: {main:'#ADADAD'},
    deepMenu: {main:'#888888'},
    steel: {main:'#7B7B7B'},
    deepSteel: {main:'#676767'},
    event: {main:'#79AEDF'},
    surfaceHigh: {main:'#EEC6BE'},
    high: {main:'#C54027'},
    surfaceModerate: {main:'#F6E2C4'},
    moderate: {main:'#E29F3B'},
    surfaceLow:{main:'#FDEDC4'},
    low: {main:'#F8C43A'},
    surfaceOptimal: {main:'#C8E7CD'},
    optimal: {main:'#47B059'},
    oldPrimary: {
      main: '#555555',
    },
    secondary: {
      main: '#6b6b6b',
    },
    accent: {
      main: '#CC0000',
    },
    darkGrey: {
      main: '#333333',
    },
    red: {
      main: '#CD3E3E',
    },
    lightGreyBackground: {
      main: '#f2f2f2',
    },
    lightGrey: {
      main: '#CCCCCC',
    },
    mediumGrey: {
      main: '#999999',
    },
    baseGrey: {
      main: '#666666',
    },
    hoverGrey: {
      main: '#EDF0EC',
    },
    hoverDarkGrey: {
      main: '#474753',
    },
    backgroundGrey: {
      main: '#F8F8F8',
    },
    modalLightBrown: {
      main: '#F5F5F5',
    },
  },
  typography: {
    fontFamily: 'Rubik, serif',
    fontWeightRegular: 'normal',
    headline1:{
      fontWeight: 700,
      lineHeight: 40,
      fontSize: '72px',
      fontFamily: 'Rubik, serif',
    },
    headline2:{
      fontWeight: 500,
      lineHeight: '40px',
      fontSize: '60px',
      fontFamily: 'Rubik, serif',
    },
    title1:{
      fontWeight: 700,
      lineHeight: '32px',
      fontSize: '32px',
      fontFamily: 'Rubik, serif',
    },
    title2:{
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '32px',
      fontFamily: 'Rubik, serif',
    },
    title3:{
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: '18px',
      fontFamily: 'Rubik, serif',
    },
    bodyRegular:{
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: 'Rubik, serif',
    },
    bodyMedium:{
      fontWeight: 700,
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: 'Rubik, serif',
    },
    bodySemiBold:{
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: 'Rubik, serif',
    },
    bodySmallRegular:{
      fontWeight: 400,
      lineHeight: '20px',
      fontSize: '14px',
      fontFamily: 'Rubik, serif',
    },
    bodyMediumRegular:{
      fontWeight: 500,
      lineHeight: '20px',
      fontSize: '14px',
      fontFamily: 'Rubik, serif',
    },
    infoMedium:{
      fontWeight: 500,
      lineHeight: '20px',
      fontSize: '12px',
      fontFamily: 'Rubik, serif',
    },
    infoRegular:{
      fontWeight: 400,
      lineHeight: '20px',
      fontSize: '12px',
      fontFamily: 'Rubik, serif',
    },
    footnote:{
      fontWeight: 400,
      lineHeight: '16px',
      fontSize: '10px',
      fontFamily: 'Rubik, serif',
    },
    titleLarge: {
      lineHeight: 'normal',
      fontSize: 'calc(1rem + 0.45vw)',
      fontWeight: 700,
      fontFamily: 'Rubik, serif',
    },
    titleXlLarge: {
      lineHeight: 'normal',
      fontSize: 'calc(1.7rem + 1vw)',
      fontWeight: 700,
      fontFamily: 'Rubik, serif',
    },
    titleRegular: {
      lineHeight: 'normal',
      fontSize: 'calc(1rem + 0.45vw)',
      fontWeight: 400,
      fontFamily: 'Rubik, serif',
    },
    subtitle1: {
      fontSize: 'calc(0.6rem + 0.4vw)',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    subtitle2: {
      fontSize: 'calc(0.6rem + 0.5vw)',
      fontWeight: 400,
      margin: 0,
    },
    title: {
      fontSize: 'calc(0.6rem + 0.5vw)',
      fontWeight: 700,
      lineHeight: 'normal',
      fontFamily: 'Rubik, serif',
    },
    titleMedium: {
      fontSize: 'calc(0.6rem + 0.5vw)',
      fontWeight: 700,
      fontFamily: 'Rubik, serif',
    },
    body1: {
      fontSize: 'calc(0.8rem + 0.5vw)',
      fontWeight: 400,
    },
    h1Bold: {
      fontWeight: 700,
      fontSize: 'calc(3.5rem + 0.45vw)',
      fontFamily: 'Rubik, serif',
      lineHeight: 'normal',
      '@media (max-width:1300px) and (min-width:568px)': {
        fontSize: '3.5rem',
      },
    },
    h1: {
      fontWeight: 500,
      fontSize: 'calc(3.5rem + 0.45vw)',
      '@media (max-width:1300px) and (min-width:568px)': {
        fontSize: '3.5rem',
      },
    },
    h2: {
      fontWeight: 300,
      fontSize: 'calc(1.5rem + 0.5vw)',
      '@media (max-width:1300px) and (min-width:568px)': {
        fontSize: '1.5rem',
      },
    },
    h4: {
      lineHeight: 'normal',
    },
    h5: {
      fontSize: 'calc(0.3rem + 0.5vw)',
    },
    body1Bold: {
      fontSize: 'calc(0.8rem + 0.5vw)',
      fontWeight: 700,
      lineHeight: 'normal',
      fontFamily: 'Rubik, serif',
    },
  },
  paddings: {
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '20px',
    xxl: '24px',
  },
  borderRadius: {
    s: '4px',
    m: '8px',
    l: '12px',
    xl: '16px',
    xxl: '24px',
    xxxl: '40px',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          color: '#000',
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
          borderRadius: '8px',
        },
        arrow: {
          color: '#fff',
        },
      },
    },
  },
});
export default toroTheme;
