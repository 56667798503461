// MiniMenuDataWidget.tsx

import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { MiniBlock } from '../../../../shared/data/types/growerFarmsTypes';
import useStyles from './useStyles';
import BlockList from '../../../FieldsPage/BlockList';
import FarmList from "./FarmList";
import GenericIcon from "../block_data_widget/generic_icon";
import { useAppSelector } from '../../../../reduxToolkit/store/hooks';
import {useWindowDimensions} from "../../../../shared/hooks/useWindowDimensions";
import { smallMaxWidth } from 'src/shared/constants/consts';

interface MiniMenuDataWidgetProps {
    grower: any;
    currentFarm: any;
    currentBlock: any;
}

function MiniMenuDataWidget(props: MiniMenuDataWidgetProps): JSX.Element | null {
    // Hooks
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed
    const { width } = useWindowDimensions();

    // Debugging Logs
    useEffect(() => {
    }, [isMobile]);

    // Props
    const { grower, currentFarm, currentBlock } = props;

    // State
    const [searchText, setSearchText] = useState('');
    const [filteredBlocks, setFilteredBlocks] = useState<MiniBlock[]>([]);
    const [accordionExpanded, setAccordionExpanded] = useState(false); // Initialize as closed

    // Redux State
    const selectedOption = useAppSelector(
        (state) => state.rootReducer.searchAndSortState.selectedOption,
    );
    const options = useAppSelector(
        (state) => state.rootReducer.searchAndSortState.options,
    );

    // Effects
    useEffect(() => {
    }, [accordionExpanded]);

    useEffect(() => {
        setAccordionExpanded(false);
    }, [currentBlock]);

    useEffect(() => {
        const filtered = filterBlocks();
        setFilteredBlocks(filtered);
    }, [searchText, currentFarm, currentBlock]);

    // Helper Functions
    function filterBlocks() {
        return currentFarm.blocks.filter((block: MiniBlock) => {
            return (
                block?.title.toLowerCase().includes(searchText.toLowerCase()) ||
                block?.cropType?.toLowerCase().includes(searchText.toLowerCase())
            );
        });
    }

    const handleBlockSelect = () => {
        if (isMobile) {
            setAccordionExpanded(false);
        }
    };

    const toggleAccordion = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionExpanded(isExpanded);
    };

    const getAccordionHeader = () => {
        const farmName = currentFarm?.title || 'Select Farm';
        const blockName = currentBlock?.title || 'Select Block';

        if (currentFarm && currentBlock) {
            return `${farmName} - ${blockName}`;
        } else if (currentFarm) {
            return farmName;
        } else {
            return 'Farms & Blocks';
        }
    };

    return (
        <div className={classes.sideListContainer}>
            {width < smallMaxWidth ? (
                <Accordion
                    expanded={accordionExpanded}
                    onChange={toggleAccordion}
                    className={classes.accordion}
                >
                    <AccordionSummary
                        expandIcon={
                            <GenericIcon name={accordionExpanded ? 'chevron_down' : 'chevron_right'} />
                        }
                        aria-controls="farm-block-list-content"
                        id="farm-block-list-header"
                        className={classes.accordionSummary}
                    >
                        <div className={classes.summaryRow}>
                            <Typography className={classes.heading}>
                                {getAccordionHeader()}
                            </Typography>
                        </div>
                    </AccordionSummary>

                    {accordionExpanded && (
                        <AccordionDetails>
                            <div className={classes.accordionContent}>
                                <FarmList
                                    grower={grower}
                                    currentFarm={currentFarm}
                                    currentBlock={currentBlock}
                                    options={options}
                                    selectedOption={selectedOption}
                                    setSearchText={setSearchText}
                                />
                                <div className={classes.separator} />
                                <BlockList
                                    blocks={filteredBlocks}
                                    currentFarm={currentFarm}
                                    currentBlock={currentBlock}
                                    selectedOption={selectedOption}
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    onBlockSelect={handleBlockSelect} // Pass the callback
                                />
                            </div>
                        </AccordionDetails>
                    )}
                </Accordion>
            ) : (
                <>
                    <div className={classes.farmList}>
                        <FarmList
                            grower={grower}
                            currentFarm={currentFarm}
                            currentBlock={currentBlock}
                            options={options}
                            selectedOption={selectedOption}
                            setSearchText={setSearchText}
                        />
                    </div>
                    <div className={classes.separator} />
                    <div className={classes.blockListContainer}>
                        <BlockList
                            blocks={filteredBlocks}
                            currentFarm={currentFarm}
                            currentBlock={currentBlock}
                            selectedOption={selectedOption}
                            searchText={searchText}
                            setSearchText={setSearchText}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default MiniMenuDataWidget;
