import TableCell from '@material-ui/core/TableCell';
import '../../GrowersReportsManagement.scss';
import useStyles from '../../useStyles';
import {MeasurementSystem} from "../../../../../../shared/data/types/UserTypes";
import {useAppSelector} from "../../../../../../reduxToolkit/store/hooks";

export default function GrowersReportTableBlockDates(props: any): JSX.Element {
  const { row } = props;
  const classes = useStyles();
  const uniqueBlockDates: any = [];
  const duplicatedDates: any = [];
  const measurement_system = useAppSelector(
      (state) => state.rootReducer.userState.measurementSystem,
  );
  // Find the first block with non-null data
  let selectedBlock: any = null;
  for (const block of row.farms[0].blocks) {
    if (block.data != null) {
      selectedBlock = block;
      break;
    }
  }

  const formatDateWithoutYear = (
      date: string | Date,
      measurementSystem?: MeasurementSystem,
  ): string => {
    const d = new Date(date);

    if (isNaN(d.getTime())) { // Check for invalid dates
      return 'Invalid Date';
    }

    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based

    // If measurementSystem is provided and Imperial, format as 'mm/dd'
    if (measurementSystem === MeasurementSystem.Imperial) {
      return `${month}/${day}`; // mm/dd
    }

    // Default to 'dd/mm'
    return `${day}/${month}`; // dd/mm
  };

  if (selectedBlock) {
    selectedBlock.data?.forEach((data: any) => {
      // Check if the dateTitle has not been added
      if (!duplicatedDates.includes(data.dateTitle)) {
        // Mark the dateTitle as added
        duplicatedDates.push(data.dateTitle);
        // Add the TableCell to the uniqueBlockDates array. Only the first occurrence will be used.
        uniqueBlockDates.push(
            <TableCell key={data.dateTitle} align="center" className={classes.expendedTableTitle}>
              {formatDateWithoutYear(data.dateTitle, measurement_system)}
            </TableCell>,
        );
      }
    });
  }

  return uniqueBlockDates;
}
