import React from 'react';
import navigateIcon from 'src/assets/Icons/sharedIcons/navigate.svg';

interface IconProps {
    onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLImageElement>) => void;
    tabIndex?: number;
}

function NavigateIcon({ onClick, onKeyDown, tabIndex = 0 }: IconProps): JSX.Element {
    return (
        <img
            src={navigateIcon}
            alt="Navigate"
            style={{ paddingRight: '6px', cursor: 'pointer'}}
            height="20px"
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={tabIndex}
        />
    );
}

export default NavigateIcon;
