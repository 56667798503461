import { ReactElement } from 'react';
import { mediumWidth, smallMaxWidth } from 'src/shared/constants/consts';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import useStyles from './useStyles';

export default function WeatherListCellTemperature({
  icon,
  secondRowData,
  iconSize = null,
}: {
  icon: string;
  secondRowData: ReactElement;
  iconSize?: number | null;
}): JSX.Element {
  const { width } = useWindowDimensions();
  const classes = useStyles();

  return width > mediumWidth ? (
    <div className={`flex-column ${classes.Root}`}>
      <div className={`flex align-center justify-center ${classes.FirstRow}`}>
        <img
          style={{ width: iconSize ? `${iconSize}px` : '' }}
          src={icon}
          alt=""
        />
      </div>
      <div className={classes.SecondRow}>{secondRowData}</div>
    </div>
  ) : width > smallMaxWidth && width <= mediumWidth ? (
    <div className={`flex ${classes.Root}`}>
      <div className={`flex align-center ${classes.FirstRow}`}>
        <img
          style={{ width: iconSize ? `${iconSize}%` : '' }}
          src={icon}
          alt=""
        />
      </div>
      <div className={classes.SecondRow}>{secondRowData}</div>
    </div>
  ) : (
    <span />
  );
}
