import React, {useMemo} from 'react';
import {GrowerModalType, PRECISION} from '../constants';
import {convertAndRound, convertedLabel} from "../../../../../shared/measurements-converter/converter";
import {
  Measurement,
  MeasurementSys
} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import {useAppDispatch, useAppSelector} from "../../../../../reduxToolkit/store/hooks";
import {openModal} from "../../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice";
import {GrowerModal} from "../../Grower";
import DesignedCard from './designed_card';
import { useTranslation } from 'react-i18next';
import {calculateTotal, formatNumberWithLabel} from "../modals/utils";

interface ActualConsumptionDataProps {
    measurement_system: MeasurementSys;
    measurement: Measurement;
    actual_consumption: number;
    userCountry: string;
}

const ActualConsumptionDataCard: React.FC<ActualConsumptionDataProps> = ({
     measurement_system,
     measurement,
     actual_consumption,
     userCountry
 }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

    const value: string = formatNumberWithLabel(
        actual_consumption,
        userCountry,
        measurement_system,
        measurement,
        2,
        currentBlock?.area
    );

    return (
        <div onClick={() => dispatch(openModal(GrowerModal.actualConsumption))}>
            <DesignedCard
                value={value}
                title={t('blockDataWidget.actual_consumption')}
                span={t('blockDataWidget.yesterday')}
                leftIconName={"tree"}
            />
        </div>
    );
};

export default ActualConsumptionDataCard;
