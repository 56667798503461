import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& .ant-dropdown': {
      '& .ant-dropdown-menu': {
        backgroundColor: 'none !important',
        '& .ant-dropdown-menu-item': {
          backgroundColor: 'none !important',
          '& .ant-dropdown-menu-item-active': {
            backgroundColor: 'none !important',
          },
        },
      },
    },
    '& .MuiIconButton-root': {
      marginLeft: 'unset !important',
      maxWidth: 'fit-content',
      '&:hover': {
        backgroundColor: 'none',
      },
    },
  },
  iconBtn: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  selectContainerItem: {
    width: '100%',
    borderRadius: 0,
    textAlign: 'left',
    fontSize: 'calc(0.6rem + 0.4vw) !important',
    color: `${theme.palette.oldPrimary.main} !important`,
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.22))',
  },
  selectItem: {
    width: '100%',
    border: 'none',
    textAlign: 'left',
    fontSize: 'calc(0.6rem + 0.4vw) !important',
    color: theme.palette.oldPrimary.main,
    backgroundColor: 'transparent !important',
    padding: '0 !important',
    '& span': {
      backgroundColor: 'transparent !important',
    },
    '&:hover:not(:disabled)': {
      color: theme.palette.oldPrimary.main,
      backgroundColor: 'transparent !important',
      borderColor: 'unset !important',
    },
  },
}));

export default useStyles;
