import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BlockState } from 'src/shared/data/types/blockSideListTypes';
import { getBlocksByFarm } from 'src/reduxToolkit/thunks/block_thunks';
import {
  MiniBlock,
  MiniFarm,
} from '../../../shared/data/types/growerFarmsTypes';

const initialState: BlockState = {
  blocks: [],
  daysBack: 0,
  currentBlock: undefined,
  httpErr: undefined,
};

export const BlockSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    setBlockList(state, action: PayloadAction<MiniBlock[]>) {
      state.blocks = action.payload;
    },
    setCurrentBlock(state, action: PayloadAction<MiniBlock | undefined>) {
      state.currentBlock = action.payload;
    },
    setSelectedBlockInit(state, action: PayloadAction<undefined>) {
      state.currentBlock = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBlocksByFarm.fulfilled, (state, action) => {
      state.blocks = action.payload;
    });
    builder.addCase(getBlocksByFarm.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
  },
});

export const { setBlockList, setCurrentBlock, setSelectedBlockInit } =
  BlockSlice.actions;

export const blockReducer = BlockSlice.reducer;
