// useMapButtonsHelper.tsx
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import centerMap from '../../../../assets/Icons/center-map.svg';
import reset from '../../../../assets/Icons/sharedIcons/undoPolygon.svg';
import { setResetPolygonPathAfterEdit } from '../../../../reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import myLocation from '../../../../assets/Icons/sharedIcons/myLocationIcon.svg';
import map from '../../../../assets/Icons/map-icon.svg';
import { MapTypes } from '../../../../shared/constants/enums';
import satellite from '../../../../assets/Icons/satellite-icon.svg';
import mapLabels from '../../../../assets/Icons/sharedIcons/mapLabels.svg';
import { useAppDispatch } from '../../../../reduxToolkit/store/hooks';
import { MapButtonHelper } from '../../../../shared/data/types/mapTypes';

export const useMapButtonsHelper = (props: any): MapButtonHelper => {
  const {
    setMapLayerVisibility,
    farm,
    setCurrentMapCenter,
    fetchCurrentLocation,
    mapLayerVisibility,
  } = props;
  const [mapType, setMapType] = useState<MapTypes>(MapTypes.SATELLITE);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const centerFarmMapBtn = [
    {
      name: t('map.centerTooltip'),
      icon: <img src={centerMap} alt="center" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        setCurrentMapCenter({
          lat: farm?.centerLat ?? 0,
          lng: farm?.centerLng ?? 0,
        });
      },
    },
  ];

  const mapToolsBtn = [
    {
      name: t('map.resetTooltip'),
      icon: <img src={reset} alt="reset" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        dispatch(setResetPolygonPathAfterEdit(true));
      },
    },
    {
      name: t('map.myLocationTooltip'),
      icon: <img src={myLocation} alt="my-Location" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        fetchCurrentLocation();
      },
    },
    {
      name: t('map.centerTooltip'),
      icon: <img src={centerMap} alt="center" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        setCurrentMapCenter({
          lat: farm?.centerLat ?? 0,
          lng: farm?.centerLng ?? 0,
        });
      },
    },
  ];

  const mapLayersOptionBtn = [
    {
      name: t('map.roadMapTooltip'),
      icon: <img src={map} alt="roadMap" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        setMapType(MapTypes.ROADMAP);
      },
    },
    {
      name: t('map.satelliteTooltip'),
      icon: <img src={satellite} alt="satellite" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        setMapType(MapTypes.SATELLITE);
      },
    },
    {
      name: t('map.labelsTooltip'),
      icon: <img src={mapLabels} alt="map-labels" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        setMapType(MapTypes.HYBRID);
      },
    },
  ];

  const showMapLayerOptions = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
    setMapLayerVisibility(!mapLayerVisibility);
  };

  return {
    centerFarmMapBtn,
    mapToolsBtn,
    showMapLayerOptions,
    mapLayersOptionBtn,
    mapType,
  };
};

export default useMapButtonsHelper;
