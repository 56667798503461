export const conversionCalculations = {
  celsiusToFahrenheit: (c: number) => (c * 9) / 5 + 32,
  fahrenheitToCelsius: (f: number) => ((f - 32) * 5) / 9,
  inchesToMillimeter: (inches: number) => inches * 25.4,
  millimeterToInches: (mm: number) => mm * 0.0393701,
  metersToFeet: (m: number) => m * 3.28084,
  feetToMeters: (ft: number) => ft * 0.3048,
  litersToGallons: (lit: number) => lit * 0.264172,
  cubicInchesToCubicMeters: (in3: number) => in3 * 0.0000163871,
  cubicMetersToGallons: (m3: number) => m3 * 264.172,
  cubicCentimetersToGallons: (cm3: number) => cm3 * 0.000264172,
  gallonsToCubicMeters: (gal: number) => gal * 0.00378541,
  meterPerSecondToMilePerHour: (m: number) => m * 2.23694,
  milePerHourToMeterPerSecond: (mph: number) => mph * 0.44704,
  dunamsToSquareMeters: (dun: number) => dun * 1000,
  squareMetersToDunams: (m2: number) => m2 * 0.001,
  squareMetersPerHourPerDunamsToGalPerMinPerAcre: (m2: number) =>
    m2 * 17.8177728,
  squareMetersPerHourPerDunamsToSquareMetersPerHourPerHectare: (m2: number) =>
    m2 * 10,
  hectaresToSquareMeters: (hec: number) => hec * 10000,
  squareMetersToHectares: (m2: number) => m2 * 0.0001,
  acresToSquareMeters: (ac: number) => ac * 4046.86,
  squareMetersToAcres: (m2: number) => m2 * 0.000247105,
};

export default conversionCalculations;
