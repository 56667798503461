import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dropdownBtn: {
    fontSize: 'calc(10px + 0.4vw)',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '4px',
    padding: '3px 9px',
    alignItems: 'center',
    '& span': {
      color: theme.palette.accent.main,
      fontWeight: '400',
      cursor: 'pointer',
      width: '150px', // You can adjust this width as needed
      whiteSpace: 'nowrap', // Prevent text from wrapping
      overflow: 'hidden', // Hide any overflow
      textOverflow: 'ellipsis', // Show ellipsis for overflowed text
      '@media (max-width: 767px)': {
        width: '60px', // You can adjust this width as needed
        whiteSpace: 'break-spaces', // Prevent text from wrapping
      },
    },
    '@media (max-width: 767px)': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      maxWidth: '100%', // Make sure it fits within the parent
      flexDirection: 'row', // Ensure items are in a row
      justifyContent: 'space-between', // Space between text and arrow
      padding: '0px',
    },
  },
  text: {
    '@media (max-width: 767px)': {
      flex: 1, // Allow text to grow and take available space
      overflow: 'hidden', // Hide overflow
      whiteSpace: 'normal', // Allow wrapping
      textOverflow: 'ellipsis', // Optional: show ellipsis if text is too long
    },
  },
  downArrow: {
    paddingLeft: '1rem',
    paddingTop: 'unset !important',
    maxWidth: '30px',
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      paddingLeft: '0.1rem',
      maxWidth: '30px',
    },
  },
  upArrow: {
    paddingLeft: '1rem',
    paddingBottom: 'unset !important',
    maxWidth: '30px',
    width: '30px !important',
    cursor: 'pointer',
  },
  farmDropdownContainer: {
    margin: '6px 8px',
  },
  farmDropdownMenu: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '0',
    display: 'flex',
    alignItems: 'center',
    background: '#DCDEE2',
    borderRadius: '4px',
    width: '100%',
    height: '40px',
    border: 'none',
  },
  farmDropdownSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.palette.oldPrimary.main,
  },
  farmDropdownIcon: {
    alignSelf: 'flex-end',
  },
  farmDropdownBtn: {
    background: '#DCDEE2',
  },
  '@media (max-width: 481px)': {
    farmDropdownMenu: {},
  },
}));

export default useStyles;