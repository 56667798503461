import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrWeather, Weather } from 'src/shared/data/types/weatherTypes';
import weeklyWeatherAdapter from '../adapters/WeeklyWeatherAdapter';
import currentWeatherAdapter from '../adapters/CurrentWeatherAdapter';

export const getWeeklyWeatherData = createAsyncThunk<
  Weather[],
  { farmId: number; lat: number; lng: number; timeZone: string },
  { rejectValue: Error }
>(
  'weather',
  async (
    { farmId, lat, lng, timeZone },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const weatherResponse = await weeklyWeatherAdapter.getWeeklyWeatherData(
        farmId,
        lat,
        lng,
        timeZone,
      );
      const { data } = weatherResponse;

      return fulfillWithValue(data);
    } catch (err) {
      if (err instanceof Error) {
        rejectWithValue(err);
      }
      return [];
    }
  },
);

export const getCurrWeatherData = createAsyncThunk<
  CurrWeather,
  { lat: any; lng: any },
  { rejectValue: Error }
>(
  'curr-weather',
  async ({ lat, lng }, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const weatherResponse = await currentWeatherAdapter.getCurrWeatherData(
        lat,
        lng,
      );
      const { data } = weatherResponse;

      return fulfillWithValue(data);
    } catch (err) {
      if (err instanceof Error) {
        rejectWithValue(err);
      }
      return [];
    }
  },
);
