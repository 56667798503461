import { lighten, darken, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  addPolygons: {
    width: '100vw',
    height: 'calc(100%)',
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0.7)',
    '@media (max-width: 767.9px)': {
    },
  },
  textContainer: {
    transform: 'translate(0, 70vh)',
    width: '80%',
    marginLeft: 'auto',
    '@media (max-width: 767.9px)': {
      transform: 'translate(0, 60vh)',
      width: '100%',
    },
  },
  subtext: {
    color: '#fff',
    marginBlockStart: '1rem',
  },
  text: {
    color: '#fff',
    fontSize: 'clamp(1.7rem, 1.9rem, 2.1rem)',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    marginBlockStart: '-10%',
    maxWidth: '85%',
  },
  addBlockActionBtns: {
    gap: '1.5rem',
  },
  cancelBlock: {
    textAlign: 'center',
    marginBlockStart: '1rem',
  },
  saveBlock: {
    textAlign: 'center',
    marginBlockStart: '1rem',
  },
  toroCancelBlockBtn: {
    '&.MuiButtonBase-root': {
      minWidth: 'fit-content',
      padding: '10px 25px',
      backgroundColor: theme.palette.oldPrimary.main,
      color: '#fff',
      fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
      zIndex: 100,
      '&:hover': {
        backgroundColor: theme.palette.accent.main,
      },
    },
  },
  mainCancelBlockBtn: {
    '&.MuiButtonBase-root': {
      padding: '10px 25px',
      backgroundColor: theme.palette.oldPrimary.main,
      color: '#fff',
      fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
      minWidth: '5rem',
      zIndex: 100,
      '&:hover': {
        backgroundColor: lighten(theme.palette.red.main, 0.3),
      },
    },
  },
  saveBlockBtn: {
    '&.MuiButtonBase-root': {
      padding: '10px 25px',
      backgroundColor: theme.palette.accent.main,
      color: '#fff',
      fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
      minWidth: '5rem',
      zIndex: 100,
      '&:hover': {
        backgroundColor: darken(theme.palette.accent.main, 0.3),
      },
      '&:hover, &:active': {
        '&:not([disabled])': {
          borderRadius: '4px',
          color: '#fff',
          backgroundColor: darken(theme.palette.accent.main, 0.1),
        },
      },
      '&:hover:enabled': {
        borderRadius: '4px',
        color: '#fff',
        backgroundColor: theme.palette.accent.main,
      },
      '&[disabled]': {
        backgroundColor: theme.palette.oldPrimary.main,
        userSelect: 'none',
        cursor: 'not-allowed',
        color: lighten(theme.palette.oldPrimary.main, 0.3),
        pointerEvents: 'none',
      },

    },
  },
}));

export default useStyles;
