import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { SensorsCards } from 'src/shared/data/types/sensorTypes';
import SingleSensorCard from './SingleSensorCard';
import useStyles from './useStyles';
import { lineColors, toroLineColors } from '../sensorsChartHelper';

export default function SensorCards(props: SensorsCards): JSX.Element {
  const data = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );

  const cards = useMemo(
    () =>
      data.sensors.map((sensor, index) => (
        <SingleSensorCard
          sensorId={sensor.sensorId}
          value={33.5}
          color={isToroAccount ? toroLineColors[index] : lineColors[index]}
          isOn={data.sensorsStates}
          clickHandler={() => {
            data.clickHandler(sensor.sensorId);
          }}
          key={sensor.sensorId}
        />
      )),
    [data, isToroAccount],
  );

  return (
    <div className={classes.root}>
      {data.days === 'yesterday' && (
        <p>{`${t('sensorPage.yesterday')} - ${t('sensorPage.sensors')}:`}</p>
      )}
      {data.days === '3 Days' && (
        <p>{`${t('sensorPage.threeDays')} - ${t('sensorPage.sensors')}:`}</p>
      )}
      {data.days === '7 Days' && (
        <p>{`${t('sensorPage.sevenDays')} - ${t('sensorPage.sensors')}:`}</p>
      )}
      {data.days === '30 Days' && (
        <p>{`${t('sensorPage.thirtyDays')} - ${t('sensorPage.sensors')}:`}</p>
      )}
      <div className={classes.cardsContainer}>{cards}</div>
    </div>
  );
}
