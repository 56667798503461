import { useMemo } from 'react';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { TableColumn } from 'src/shared/data/types/tableOptionsType';
import { largeMaxWidth, mediumMinWidth } from 'src/shared/constants/consts';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';

const GrowersTableHelper = (columnsProps: any): any => {
  const { width } = useWindowDimensions();
  const isWideScreen = width > largeMaxWidth; // Devices with max width of 1199px
  const isMediumScreen = width > mediumMinWidth; // Devices with min width of 768px
  const { isAdmin } = useUserAccess();
  const growers = useAppSelector(
    (state) => state.rootReducer.growerState.growers,
  );
  const growersCountryData = growers.map((grower: any) => grower.country);

  const smallScreenColumns: TableColumn[] = useMemo(
    () => [
      columnsProps.businessNameColumn,
      columnsProps.countryColumn,
      columnsProps.emailColumn,
      columnsProps.fullActionColumn,
    ],
    [
      columnsProps.businessNameColumn,
      columnsProps.countryColumn,
      columnsProps.emailColumn,
      columnsProps.fullActionColumn,
    ],
  );

  const mediumScreenColumns: TableColumn[] = useMemo(
    () => [
      columnsProps.businessNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
    [
      columnsProps.businessNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
  );

  const wideScreenColumns: TableColumn[] = useMemo(
    () => [
      columnsProps.businessNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.stateColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
    [
      columnsProps.businessNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.stateColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
  );

  const adminColumns: TableColumn[] = useMemo(
    () => [
      columnsProps.businessNameColumn,
      columnsProps.accountNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.stateColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.growerIdColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
    [
      columnsProps.businessNameColumn,
      columnsProps.accountNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.stateColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.growerIdColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
  );

  const nullStatesColumn: TableColumn[] = useMemo(
    () => [
      columnsProps.businessNameColumn,
      columnsProps.accountNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.growerIdColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
    [
      columnsProps.businessNameColumn,
      columnsProps.accountNameColumn,
      columnsProps.growerNameColumn,
      columnsProps.countryColumn,
      columnsProps.emailColumn,
      columnsProps.phoneColumn,
      columnsProps.growerIdColumn,
      columnsProps.addFarmColumn,
      columnsProps.fullActionColumn,
    ],
  );

  const getScreenColumns = (): any => {
    const isStateColumn = growersCountryData.includes('United States');
    if (isWideScreen) {
      // if some of state === united states or admin show state column
      // if not admin and no united state don't show state
      if (isAdmin) return adminColumns;
      if (!isAdmin && isStateColumn) return wideScreenColumns;
      if (!isAdmin && !isStateColumn) return nullStatesColumn;
    }
    if (isMediumScreen) return mediumScreenColumns;
    return smallScreenColumns;
  };

  const columns = useMemo(getScreenColumns, [
    isWideScreen,
    isMediumScreen,
    wideScreenColumns,
    mediumScreenColumns,
    smallScreenColumns,
    isAdmin,
    adminColumns,
    nullStatesColumn,
    growersCountryData,
  ]);
  return { columns };
};

export default GrowersTableHelper;
