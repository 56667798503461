import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountsDetails } from 'src/shared/data/types/accountOptionsTypes';
import { Table, Button } from 'antd';
import deleteIcon from 'src/assets/Icons/sharedIcons/deleteIcon.svg';
import copyIcon from 'src/assets/Icons/sharedIcons/copyIcon.svg';
import editIcon from 'src/assets/Icons/sharedIcons/editIcon.svg';
import { useDispatch } from 'react-redux';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { smallMaxWidth } from 'src/shared/constants/consts';
import { setAccounts } from 'src/reduxToolkit/slices/AccountSlice/account.slice';
import useStyles from './useStyles';

function AccountsList(props: any): JSX.Element {
  const { accounts, handleDelete, onEdit, onCopy, searchText } = props;
  const [rows, setRows] = useState<AccountsDetails[]>([]);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'delete-popper' : undefined;
  const { t } = useTranslation();
  const classes = useStyles();

  const onEditAccount = useCallback(
    (e: any, record: any): void => {
      e.preventDefault();
      e.stopPropagation();
      onEdit(e, record);
    },
    [onEdit],
  );

  const onDeleteAccount = useCallback(
    (e: any, record: any): void => {
      e.preventDefault();
      e.stopPropagation();
      setAnchorEl(anchorEl ? null : e.currentTarget);
      const filteredRows = rows.filter(
        (account) => account.accountCognitoId !== record,
      );
      setRows(filteredRows);
      handleDelete(e, record);
      dispatch(
        setAccounts({
          total_accounts: filteredRows.length,
          accounts: filteredRows,
        }),
      );
    },
    [dispatch, rows, handleDelete, anchorEl],
  );

  const columns = useMemo(() => {
    return [
      {
        title: t('accountsList.title'),
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        sorter: (a: any, b: any) =>
          a.title > b.title ? 1 : a.title === b.title ? 0 : -1,
      },
      {
        title: t('accountsList.name'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        sorter: (a: any, b: any) =>
          a.name > b.name ? 1 : a.name === b.name ? 0 : -1,
      },
      {
        title: t('accountsList.country'),
        dataIndex: 'country',
        key: 'country',
        ellipsis: true,
        sorter: (a: any, b: any) =>
          a.country > b.country ? 1 : a.country === b.country ? 0 : -1,
      },
      {
        title: t('accountsList.email'),
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
        render: (email: string): any => (
          <a
            href={`mailto:${email}`}
            className={`${classes.actionsText} ${classes.email}`}>
            {email}
          </a>
        ),
      },
      {
        title: t('accountsList.phone'),
        dataIndex: 'phone',
        key: 'phone',
        ellipsis: true,
      },
      {
        title: t('accountsList.accountId'),
        dataIndex: 'accountCognitoId',
        key: 'accountCognitoId',
        render: (accountCognitoId: { accountCognitoId: any }): any => (
          <article
            className={`flex align-center ${classes.actions}`}
            style={{ textAlign: 'right' }}>
            <span>{accountCognitoId}</span>
            <Button
              id="copy-account-cognitoId"
              onClick={(e) => onCopy(e, accountCognitoId)}>
              <img
                src={`${copyIcon}`}
                alt="copy account cognito Id"
                id="copy-account-cognitoId"
                style={{ width: '1rem' }}
              />
            </Button>
          </article>
        ),
      },
      {
        title: t('accountsList.actions'),
        key: 'action',
        width: 120,
        render: (record: { id: any; accountCognitoId: any }) => (
          <article className={`flex align-center ${classes.actions}`}>
            <Button onClick={(e) => onEditAccount(e, record.accountCognitoId)}>
              <img src={`${editIcon}`} alt="edit account" />
            </Button>
            <div>
              <Button
                aria-describedby={id}
                onClick={(e) => onDeleteAccount(e, record.id)}>
                <img src={`${deleteIcon}`} alt="delete account" />
              </Button>
            </div>
          </article>
        ),
      },
    ];
  }, [
    onDeleteAccount,
    classes.actions,
    classes.email,
    classes.actionsText,
    onEditAccount,
    onCopy,
    id,
    t,
  ]);

  const smallScreensColumns = useMemo(() => {
    return [
      {
        title: t('accountsList.title'),
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        sorter: (a: any, b: any) =>
          a.title > b.title ? 1 : a.title === b.title ? 0 : -1,
      },
      {
        title: t('accountsList.email'),
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
        render: (email: string): any => (
          <a
            href={`mailto:${email}`}
            className={`${classes.actionsText} ${classes.email}`}>
            {email}
          </a>
        ),
      },
      {
        title: t('accountsList.accountId'),
        dataIndex: 'accountCognitoId',
        key: 'accountCognitoId',
        render: (accountCognitoId: { accountCognitoId: any }): any => (
          <article
            className={`flex align-center ${classes.actions}`}
            style={{ textAlign: 'right' }}>
            <span>{accountCognitoId}</span>
            <Button
              id="copy-account-cognitoId"
              onClick={(e) => onCopy(e, accountCognitoId)}>
              <img
                src={`${copyIcon}`}
                alt="copy account cognito Id"
                id="copy-account-cognitoId"
                style={{ width: '1rem' }}
              />
            </Button>
          </article>
        ),
      },
      {
        title: t('accountsList.actions'),
        key: 'action',
        render: (record: { accountCognitoId: any }) => (
          <article className={classes.actions}>
            <Button onClick={(e) => onEditAccount(e, record.accountCognitoId)}>
              <img src={`${editIcon}`} alt="edit account" />
            </Button>
            <Button
              onClick={(e) => onDeleteAccount(e, record.accountCognitoId)}>
              <img src={`${deleteIcon}`} alt="delete account" />
            </Button>
          </article>
        ),
      },
    ];
  }, [
    onDeleteAccount,
    classes.actions,
    classes.email,
    classes.actionsText,
    onEditAccount,
    onCopy,
    t,
  ]);

  const accountsData = useCallback(() => {
    const data = accounts.map((account: any) => ({
      id: account.id,
      title: account.title,
      description: account.description,
      accountCognitoId: account.accountCognitoId,
      ownerCognitoId: account.ownerCognitoId,
      country: account.country,
      state: account.state,
      email: account.email,
      name: `${account.firstName}`,
      lastName: account.lastName,
      phone: account.phone,
    }));
    setRows(data);
  }, [accounts]);

  useEffect(() => {
    accountsData();
  }, [accounts, accountsData]);

  const filteredRows = useMemo(
    () =>
      rows.filter(
        (row) =>
          row.title.toLowerCase().includes(searchText.toLowerCase()) ||
          row.country.toLowerCase().includes(searchText.toLowerCase()) ||
          row.email.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [rows, searchText],
  );

  const MemoizedTable = useMemo(
    () => (
      <Table
        columns={width > smallMaxWidth ? columns : smallScreensColumns}
        dataSource={filteredRows}
        pagination={false}
        rowKey={(record) => `${record.id}`}
        scroll={{ y: 400 }}
        size="large"
        sticky
        rowClassName={(record) =>
          record.title === '1' ? classes.tableRowLight : classes.tableRowDark
        }
      />
    ),
    [
      filteredRows,
      columns,
      smallScreensColumns,
      width,
      classes.tableRowLight,
      classes.tableRowDark,
    ],
  );

  return (
    <section className={`flex-column ${classes.root}`}>{MemoizedTable}</section>
  );
}

export default AccountsList;
