import { notification } from 'antd';
import messageTypes from '../../consts/messages';

const getNotificationStyle = (type: string): any =>
  ({
    success: {
      color: '#0f5132',
      border: '1px solid #badbcc',
      backgroundColor: '#d1e7dd',
    },
    warning: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #ffe58f',
      backgroundColor: '#fffbe6',
    },
    error: {
      color: '#842029',
      border: '1px solid #f5c2c7',
      backgroundColor: '#f8d7da',
    },
    info: {
      color: '#055160',
      border: '1px solid #cff4fc',
      backgroundColor: '#b6effb',
    },
  }[type]);

const notifyService = (message: string, type?: string): void => {
  switch (type) {
    case messageTypes.success: {
      notification.success({
        message: message,
        style: getNotificationStyle(type),
        duration: 2,
      });
      break;
    }
    case messageTypes.error: {
      notification.error({
        message: message,
        style: getNotificationStyle(type),
      });
      break;
    }
    default: {
      notification.info({
        message: `${message}`,
        duration: 2,
      });
      break;
    }
  }
};
export default notifyService;
