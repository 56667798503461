import { useState } from 'react';
import { Select as AntSelect } from 'antd';
import { SelectProps } from 'src/shared/data/types/formsTypes';
import greenUpArrow from 'src/assets/Icons/mainThemeIcons/greenUpArrow.svg';
import greenDownArrow from 'src/assets/Icons/mainThemeIcons/greenDownArrow.svg';
import greenSelectedCheckMark from 'src/assets/Icons/mainThemeIcons/greenSelectedCheckMark.svg';
import greenXIcon from 'src/assets/Icons/mainThemeIcons/greenXIcon.svg';
import toroUpArrow from 'src/assets/Icons/toroThemeIcons/toroUpArrow.svg';
import toroDownArrow from 'src/assets/Icons/toroThemeIcons/toroDownArrow.svg';
import toroSelectedCheckMark from 'src/assets/Icons/toroThemeIcons/toroSelectedCheckMark.svg';
import toroXIcon from 'src/assets/Icons/toroThemeIcons/toroXIcon.svg';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { useStyles } from './useStyles';

const { Option } = AntSelect;

function Select(props: SelectProps): JSX.Element {
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const classes = useStyles();
  const {
    options,
    value,
    placeholder,
    isMultiSelect = false,
    handleChangeSelection,
    handleBlur = () => {},
    defaultValue = undefined,
    filterOption,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (valueChange: string): void => {
    handleChangeSelection(valueChange);
  };
  return (
    <AntSelect
      defaultValue={defaultValue ?? undefined}
      placeholder={placeholder}
      onChange={handleChange}
      className={classes.root}
      mode={isMultiSelect ? 'multiple' : undefined}
      removeIcon={
        isToroAccount ? (
          <img src={toroXIcon} alt="x-icon" style={{ width: '10px' }} />
        ) : (
          <img src={greenXIcon} alt="x-icon" style={{ width: '10px' }} />
        )
      }
      menuItemSelectedIcon={
        isMultiSelect &&
        (isToroAccount ? (
          <img src={toroSelectedCheckMark} alt="checkmark" />
        ) : (
          <img src={greenSelectedCheckMark} alt="checkmark" />
        ))
      }
      suffixIcon={
        isOpen ? (
          isToroAccount ? (
            <img src={toroUpArrow} alt="up-arrow" />
          ) : (
            <img src={greenUpArrow} alt="up-arrow" />
          )
        ) : isToroAccount ? (
          <img src={toroDownArrow} alt="Down-arrow" />
        ) : (
          <img src={greenDownArrow} alt="Down-arrow" />
        )
      }
      showArrow
      showSearch
      data-hj-allow
      size="middle"
      filterOption={filterOption}
      onDropdownVisibleChange={() => setIsOpen(!isOpen)}
      popupClassName={classes.menu}
      maxTagCount="responsive"
      onBlur={handleBlur}
      value={value}>
      {options.map((opt) => (
        <Option value={opt.value} key={opt.value}>
          {opt.content}
        </Option>
      ))}
    </AntSelect>
  );
}

export default Select;
