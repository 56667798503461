import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import { Button, Dropdown, MenuProps } from 'antd';
import { ItemType } from 'src/shared/data/types/languageOptionsTypes';
import { supportedLanguages } from '../NavbarHelper';
import { useStyles } from './useStyles';

function SelectLanguage(props: any): JSX.Element {
  const { onChangeLanguage } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLElement>): any => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (): any => {
    setAnchorEl(null);
  };

  const items: ItemType[] = supportedLanguages.map((language: any) => ({
    key: language.code,
    label: (
      <Button
        className={`flex align-center ${classes.selectItem}`}
        onClick={() => {
          onChangeLanguage(language.code);
          handleClose();
        }}
        value={language.code}>
        {language.flag}
        {language.name}
      </Button>
    ),
  }));

  const menu: MenuProps = { items };

  return (
    <div className={`flex align-center justify-between ${classes.root}`}>
      <Dropdown
        menu={menu}
        placement="bottomRight"
        arrow
        className={classes.selectContainerItem}
        trigger={['click']}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'multi-lng-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={classes.iconBtn}>
          <LanguageIcon sx={{ width: 22, height: 22 }} />
        </IconButton>
      </Dropdown>
    </div>
  );
}

export default SelectLanguage;
