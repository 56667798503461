import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import t2sLogo from 'src/assets/Icons/mainThemeIcons/t2s-logo.svg';
import Typography from '@mui/material/Typography';
import { Button } from 'antd';
import countries from 'src/consts/countries';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Regex } from 'src/shared/constants/consts';
import {
  handleCountryValidation,
  isValidTLD,
} from 'src/shared/helpers/FormValidationHelper';
import EditTextForm from './EditTextForm';
import { useAppDispatch } from '../../../../reduxToolkit/store/hooks';
import { setAccounts } from '../../../../reduxToolkit/slices/AccountSlice/account.slice';
import GetAccountFields from '../ModalHelper';
import SelectState from '../AddAccountModal/SelectState';
import SelectCountry from '../AddAccountModal/SelectCountry';
import useStyles from '../useStyles';
import countryMap from '../../../../shared/data/CountryMap';

function EditAccountModal({
  accounts,
  record,
  onClose,
  loading,
  onEdit,
}: any): ReactElement | null {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [title, setTitle] = useState<string>(record.title);
  const [titleValidation, setTitleValidation] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(record.description);
  const [country, setCountry] = useState<string>(record.country);
  const [countryValidation, setCountryValidation] = useState<boolean>(false);
  const [stateName, setStateName] = useState<string>(record.state);
  const [stateNameValidation, setStateNameValidation] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(record.firstName);
  const [firstNameValidation, setFirstNameValidation] =
    useState<boolean>(false);
  const [lastName, setLastName] = useState<string>(record.lastName);
  const [lastNameValidation, setLastNameValidation] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(record.email);
  const [emailValidation, setEmailValidation] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(record.phone);
  const [phoneValidation, setPhoneValidation] = useState<boolean>(false);
  const [hasStates, setHasStates] = useState<boolean>(false);
  const { t } = useTranslation();
  const props = {
    title,
    setTitle,
    titleValidation,
    setTitleValidation,
    description,
    setDescription,
    country,
    setCountry,
    countryValidation,
    setCountryValidation,
    stateName,
    setStateName,
    stateNameValidation,
    setStateNameValidation,
    firstName,
    setFirstName,
    firstNameValidation,
    setFirstNameValidation,
    lastName,
    setLastName,
    lastNameValidation,
    setLastNameValidation,
    email,
    setEmail,
    emailValidation,
    setEmailValidation,
    phone,
    setPhone,
    phoneValidation,
    setPhoneValidation,
  };
  const accountFields = GetAccountFields(props);

  const handleEditAccountDataForm = useCallback(
    (updatedAccount: any): any => {
      const idx = accounts.findIndex(
        (account: any) => account.accountCognitoId === record.accountCognitoId,
      );
      const fullUpdatedAccount: any = { ...accounts[idx], ...updatedAccount };
      const cloneAccounts = [...accounts];
      cloneAccounts.splice(idx, 1, fullUpdatedAccount);
      dispatch(
        setAccounts({
          accounts: cloneAccounts,
          total_accounts: cloneAccounts.length,
        }),
      );
    },
    [accounts, dispatch, record.accountCognitoId],
  );

  const handleEdit = useCallback(
    (e: any): any => {
      const updatedAccount: any = {
        title: title,
        description: description,
        country: country,
        state: stateName,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      };
      handleEditAccountDataForm(updatedAccount);
      onEdit(e, updatedAccount);
    },
    [
      handleEditAccountDataForm,
      onEdit,
      title,
      description,
      country,
      stateName,
      firstName,
      lastName,
      email,
      phone,
    ],
  );

  return (
    <div className={`flex-column align-center ${classes.wrapper}`}>
      <div className="logo-center">
        <img src={t2sLogo} alt="t2s logo" />
      </div>
      <Typography
        textAlign="center"
        margin="unset"
        padding="0.5rem 0 0 0"
        color="#676879"
        fontSize="1.5rem">
        {t('accountFieldModal.updateAccountModalTitle')}
      </Typography>
      <div className={`flex-column align-center ${classes.formContainer}`}>
        <div className={`${classes.fieldsContainer} custom`}>
          <EditTextForm data={accountFields.titleField} />
          <EditTextForm data={accountFields.firstNameField} />
          <EditTextForm data={accountFields.lastNameField} />
          <SelectCountry
            data={accountFields.countryField}
            onState={setHasStates}
          />
          <SelectState
            data={accountFields.stateNameField}
            disabled={
              !hasStates &&
              country !== countries.USA &&
              country !== countries.USAShort
            }
          />
          <EditTextForm
            data={accountFields.phoneField}
            type="phone"
            code={countryMap.get(country)}
          />
          <EditTextForm data={accountFields.emailField} type="email" />
          <EditTextForm data={accountFields.descriptionField} />
        </div>
      </div>
      <div
        className={`btn-action-container common-button-container flex justify-between ${classes.modalBtnContainer}`}>
        <Button
          disabled={
            loading ||
            !title ||
            !firstName ||
            !lastName ||
            !email ||
            !phone ||
            !country ||
            !isValidPhoneNumber(phone) || // Checks if a phone number value is a "valid" phone number. A phone number is "valid" when it has valid length, and the actual phone number digits match the regular expressions for that country.
            !isPossiblePhoneNumber(phone) || // Checks if a phone number value is a "possible" phone number. A phone number is "possible" when it has valid length. The actual phone number digits aren't validated.
            // TLD = top-level domain extension - i.e .com .il etc.
            !Regex.EMAIL_REGEX.test(email) ||
            (Regex.EMAIL_REGEX.test(email) && !isValidTLD(email)) ||
            !handleCountryValidation(country, stateName)
          }
          id="save-edited-account"
          onClick={(e) => handleEdit(e)}
          className={`ant-btn-custom ${classes.modalSaveBtn}`}>
          {t('accountFieldModal.updateAccountBtn')}
        </Button>
        <Button onClick={() => onClose()} className={classes.modalCancelBtn}>
          {t('accountFieldModal.cancel')}
        </Button>
      </div>
    </div>
  );
}

export default EditAccountModal;
