const TREETOSCOPE_ACCOUNT_ID = 'cfd802a2-3593-4061-b2d2-f10e4bbe79c7';
const TORO_ACCOUNT_ID = 'ce10771e-0494-4853-b4dd-c353c6995d49';
const NETAFIM_ACCOUNT_ID = '2ba6de88-d42f-4262-8ded-93005a9aab42';

const accountNames = {
  [TREETOSCOPE_ACCOUNT_ID]: 'Treetoscope LTD',
  [TORO_ACCOUNT_ID]: 'Toro Ag',
  [NETAFIM_ACCOUNT_ID]: 'Netafim LTD',
};
export function accountNameById(accountId: string | undefined): string {
  return (
    accountNames[accountId as keyof typeof accountNames] ?? 'Unknown Account'
  );
}
