import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Typography } from '@mui/material';
import useStyles from './useStyles';

function DeleteModal(props: any): JSX.Element {
  const { handleDelete, onClose, loading, record } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const onSaveForm = (): void => {
    handleDelete(record);
  };

  return (
    <div className={`flex-column align-center justify-center ${classes.root}`}>
      <Typography>
        {`${t('deleteModal.title')}`} {record.title}?
      </Typography>
      <Typography variant="titleMedium">{`${t(
        'deleteModal.warning',
      )}`}</Typography>
      <div className={classes.btnContainer}>
        <Button
          id="confirm-delete-agent"
          type="primary"
          className={classes.confirmationBtn}
          disabled={loading}
          onClick={() => {
            void (async () => {
              await onSaveForm();
            })();
          }}>
          {`${t('deleteModal.delete')}`} &amp; {`${t('deleteModal.exit')}`}
        </Button>
        <Button
          type="primary"
          className={classes.cancelBtn}
          onClick={() => {
            onClose();
          }}>
          {`${t('deleteModal.cancel')}`}
        </Button>
      </div>
    </div>
  );
}

export default DeleteModal;
