export enum Measurement {
  LENGTH = 'length',
  VOLUME = 'volume',
  AREA = 'area',
  TEMPERATURE = 'temperature',
  PRESSURE = 'pressure',
  WIND_SPEED = 'speed',
  HUMIDITY = 'humidity',
  PRECIPITATION = 'precipitation',
  EVAPOTRANSPIRATION = 'evapotranspiration',
  FLOW_RATE = 'flowRate',
  ETA = 'eta',
}

export type MeasurementUnit =
  | LengthUnit
  | VolumeUnit
  | AreaUnit
  | TemperatureUnit
  | PressureUnit
  | WindSpeedUnit
  | HumidityUnit
  | PrecipitationUnit
  | EvapotranspirationUnit
  | FlowRateUnit
  | ETAUnit;

export enum LengthUnit {
  METER,
  MILLIMETER,
  FEET,
  INCH,
}

export enum VolumeUnit {
  CUBIC_METER,
  CUBIC_CENTIMETER,
  LITER,
  GALLON,
  CUBIC_INCH,
}

export enum AreaUnit {
  SQUARE_METER,
  ACRE,
  HECTARE,
  DUNAM,
  SQUARE_FEET,
}

export enum TemperatureUnit {
  CELSIUS,
  FAHRENHEIT,
}

export enum PressureUnit {
  KILOPASCAL,
}

export enum WindSpeedUnit {
  METER_PER_SECOND,
  MILE_PER_HOUR,
}

export enum HumidityUnit {
  PERCENT,
}

export enum PrecipitationUnit {
  MILLIMETER,
  INCH,
}

export enum EvapotranspirationUnit {
  MILLIMETER,
  INCH,
}

export enum FlowRateUnit {
  CUBIC_METER_PER_HOUR_PER_HECTARE,
  CUBIC_METER_PER_HOUR_PER_DUNAM,
  GALLON_PER_MINUTE_PER_ACRE,
  GALLON_PER_ACRE,
  CUBIC_CENTIMETER_PER_HOUR,
  SQUARE_CENTIMETER_PER_HOUR,
  LITER_PER_HOUR,
  LITER_PER_DAY,
  METER_PER_SECOND,
  HOUR,
  CUBIC_INCH_PER_HOUR,
  SQUARE_INCH_PER_HOUR,
  GALLON_PER_DAY,
}

export enum ETAUnit {
  MILLIMETER,
  INCH,
}

type DefaultUnits = {
  [Key in Measurement]: MeasurementUnit;
};

export const DefaultMeasurementUnit: DefaultUnits = {
  [Measurement.LENGTH]: LengthUnit.METER,
  [Measurement.VOLUME]: VolumeUnit.CUBIC_METER,
  [Measurement.AREA]: AreaUnit.SQUARE_METER,
  [Measurement.TEMPERATURE]: TemperatureUnit.CELSIUS,
  [Measurement.PRESSURE]: PressureUnit.KILOPASCAL,
  [Measurement.WIND_SPEED]: WindSpeedUnit.METER_PER_SECOND,
  [Measurement.HUMIDITY]: HumidityUnit.PERCENT,
  [Measurement.PRECIPITATION]: PrecipitationUnit.MILLIMETER,
  [Measurement.EVAPOTRANSPIRATION]: EvapotranspirationUnit.MILLIMETER,
  [Measurement.FLOW_RATE]: FlowRateUnit.CUBIC_METER_PER_HOUR_PER_DUNAM,
  [Measurement.ETA]: ETAUnit.MILLIMETER,
};

export const measurementLabel = {
  [Measurement.LENGTH]: {
    [LengthUnit.METER]: 'm',
    [LengthUnit.MILLIMETER]: 'mm',
    [LengthUnit.FEET]: 'ft',
    [LengthUnit.INCH]: 'in',
  },
  [Measurement.VOLUME]: {
    [VolumeUnit.CUBIC_METER]: 'm³',
    [VolumeUnit.CUBIC_CENTIMETER]: 'cm³',
    [VolumeUnit.LITER]: 'l',
    [VolumeUnit.GALLON]: 'gal',
    [VolumeUnit.CUBIC_INCH]: 'in³',
  },
  [Measurement.AREA]: {
    [AreaUnit.SQUARE_METER]: 'm^2',
    [AreaUnit.ACRE]: 'ac',
    [AreaUnit.HECTARE]: 'ha',
    [AreaUnit.DUNAM]: 'dunam',
    [AreaUnit.SQUARE_FEET]: 'ft^2',
  },
  [Measurement.TEMPERATURE]: {
    [TemperatureUnit.CELSIUS]: '°C',
    [TemperatureUnit.FAHRENHEIT]: '°F',
  },
  [Measurement.PRESSURE]: {
    [PressureUnit.KILOPASCAL]: 'kPa',
  }, // vpd
  [Measurement.WIND_SPEED]: {
    [WindSpeedUnit.METER_PER_SECOND]: 'm/s',
    [WindSpeedUnit.MILE_PER_HOUR]: 'mph',
  },
  [Measurement.HUMIDITY]: {
    [HumidityUnit.PERCENT]: '%',
  },
  [Measurement.PRECIPITATION]: {
    [PrecipitationUnit.MILLIMETER]: 'mm',
    [PrecipitationUnit.INCH]: 'in',
  },
  [Measurement.EVAPOTRANSPIRATION]: {
    [EvapotranspirationUnit.MILLIMETER]: 'mm',
    [EvapotranspirationUnit.INCH]: 'in',
  },

  [Measurement.FLOW_RATE]: {
    [FlowRateUnit.CUBIC_METER_PER_HOUR_PER_HECTARE]: 'm³/h/ha', // square meters per hour per hectare';
    [FlowRateUnit.CUBIC_METER_PER_HOUR_PER_DUNAM]: 'm³/h/dunam', // square meters per hour per hectare';
    [FlowRateUnit.GALLON_PER_MINUTE_PER_ACRE]: 'gpm/acre', // change from gal/m/ac' gal per minutes per acre
    [FlowRateUnit.GALLON_PER_ACRE]: 'gpm/acre', // change from gal/m/ac' gal per minutes per acre
    [FlowRateUnit.CUBIC_CENTIMETER_PER_HOUR]: 'cm^3/h', // Cubic centimeters per hour
    [FlowRateUnit.SQUARE_CENTIMETER_PER_HOUR]: 'cm^2/h', // Square centimeters per hour
    [FlowRateUnit.LITER_PER_HOUR]: 'l/hour', // Liters per hour
    [FlowRateUnit.LITER_PER_DAY]: 'l/Day', // Liters per day
    [FlowRateUnit.METER_PER_SECOND]: 'm/s', // Meters per second
    [FlowRateUnit.HOUR]: 'Hour', // Hour, if it's a measure of time duration
    [FlowRateUnit.CUBIC_INCH_PER_HOUR]: 'cubic in/h', // Cubic inches per hour
    [FlowRateUnit.SQUARE_INCH_PER_HOUR]: 'squared in/h', // Square inches per hour
    [FlowRateUnit.GALLON_PER_DAY]: 'gal/day', // Gallons per day
  },
  [Measurement.ETA]: {
    [ETAUnit.MILLIMETER]: 'mm',
    [ETAUnit.INCH]: 'in',
  },
};

export enum MeasurementSys {
  IMPERIAL,
  METRIC,
}
