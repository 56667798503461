import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import WeeklyEtaAvg from './GrowersReportsTableWeeklyEtaAvg';
import WeeklyEtaSum from './GrowersReportsTableWeeklyEtaSum';
import '../../../GrowersReportsManagement.scss';

export default function WeeklyEtaCalc(props: any): JSX.Element {
  const { farmIdx, row, blockIdx } = props;
  const etaCalcs: any = [];
  const { t } = useTranslation();

  etaCalcs.push(
    <TableCell style={{ borderBottom: 'none !important' }}>
      <Table>
        <TableRow style={{ borderBottom: 'none !important' }}>
          <TableCell style={{ width: 10 }}>{t('growerReports.sum')}</TableCell>
          <TableCell style={{ width: 10 }}>{t('growerReports.avg')}</TableCell>
        </TableRow>
        <TableRow style={{ borderBottom: 'none !important' }}>
          <TableCell style={{ width: 10 }}>
            <WeeklyEtaSum row={row} farmIdx={farmIdx} blockIdx={blockIdx} />
          </TableCell>
          <TableCell style={{ width: 10 }}>
            <WeeklyEtaAvg row={row} farmIdx={farmIdx} blockIdx={blockIdx} />
          </TableCell>
        </TableRow>
      </Table>
    </TableCell>,
  );
  return etaCalcs;
}
