import React from 'react';
import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FieldProps } from "./ModalHelper";
import useStyles from './useStyles';

interface SelectItemProps {
  data: FieldProps;
  items?: Array<{ label: string; value: any }>;
  onChange?: (value: string) => void;
}

const hasError = (data: FieldProps): boolean => {
  return !!data.fieldStateTouch && !data.fieldState;
};

const SelectItem = ({
  data,
  items = [],
  onChange,
}: SelectItemProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const helper = (): string => {
    return data.fieldName === 'role'
      ? t('agentModal.roleHelper')
      : 'Please select an item';
  };

  const handleChangeItem = (e: any): void => {
    if (data.setFieldStateString) {
      data.setFieldStateString(e);
    } else if (data.setFieldStateNumber) {
      data.setFieldStateNumber(e);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (): void => {
    if (data.setFieldStateTouch) {
      data.setFieldStateTouch(true);
    }
  };

  const getOptions = () => {
    if ('options' in data) {
      return data.options;
    }
    return items;
  };

  return (
    <div
      className={`flex-column ${data.fieldName}`}
      style={{ width: '100%', marginBottom: '-0.5rem' }}>
      <span id="select-item">{`${data.title}${
        data.isMandatory ? '*' : ''
      }`}</span>
      <Form.Item
        help={hasError(data) && helper()}
        validateStatus={hasError(data) ? 'error' : ''}>
        <Select
          id="select"
          className={classes.select}
          style={{ width: '100%' }}
          showSearch
          data-hj-allow
          defaultValue={data.fieldState}
          onChange={handleChangeItem}
          onBlur={handleBlur}
          options={getOptions()}
        />
      </Form.Item>
    </div>
  );
};

export default SelectItem;
