import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '13%',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#fff',
    gap: '10px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
    '& img': {
      height: '40%',
    },
    '& svg': {
      maxWidth: '2rem !important',
    },
  },
  InfoContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#fff',
  },
  TextInfoHeader: {
    color: theme.palette.oldPrimary.main,
    fontSize: 'calc(1rem + 0.45vw)',
    fontWeight: 700,
    margin: '0px',
  },
  TextInfo: {
    color: theme.palette.oldPrimary.main,
    fontWeight: 500,
    margin: '0px',
    fontSize: 'calc(0.6rem + 0.4vw)',
  },
}));

export default useStyles;
