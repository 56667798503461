import { useCallback, useMemo } from 'react';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import GrowersTableHelper from './GrowersTableHelper';
import GetColumnsProps from './ColumnsDetails';
import useStyles from './useStyles';
import { MiniGrower } from '../../../shared/data/types/growerFarmsTypes';
import { useAppSelector } from '../../../reduxToolkit/store/hooks';

function GrowersTable(props: GrowersTableProp): JSX.Element {
  const {
    growers,
    openEditGrowerModal,
    handleViewGrower,
    handleCopyGrowerId,
    onAddNewFarm,
    onActivateGrower,
    openDeleteModal,
    searchText,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAdmin } = useUserAccess();
  const blockIds = useAppSelector(
    (state) => state.rootReducer.growerState.blockIds,
  );

  const onEditGrower = useCallback(
    (e: any, record: any): void => {
      e.preventDefault();
      e.stopPropagation();
      openEditGrowerModal(e, record);
    },
    [openEditGrowerModal],
  );

  const onViewGrower = useCallback(
    (e: any, record: any): void => {
      e.preventDefault();
      e.stopPropagation();
      handleViewGrower(e, record);
    },
    [handleViewGrower],
  );

  const onDeleteGrower = useCallback(
    (e: any, record: any): void => {
      e.preventDefault();
      e.stopPropagation();
      const filteredRows = growers.filter(
        (grower) => grower.accountCognitoId !== record,
      );
      openDeleteModal(e, record);
    },
    [dispatch, openDeleteModal],
  );

  const columnsProps = GetColumnsProps(
    classes,
    handleCopyGrowerId,
    onAddNewFarm,
    onActivateGrower,
    onViewGrower,
    onEditGrower,
    onDeleteGrower,
  );

  const { columns } = GrowersTableHelper(columnsProps);

  const filteredRows = useMemo(() => {
    return growers.filter((grower) => {
      const lowerCaseSearchText = searchText.toLowerCase();
      let propArr = [grower.title, grower.country];
      if (isAdmin && grower.email) propArr.push(grower.email);
      const phraseToSearch = propArr.join('_').toLowerCase();
      return (
        phraseToSearch.includes(lowerCaseSearchText) ||
        blockIds[grower.id]?.includes(Number(lowerCaseSearchText))
      );
    });
  }, [growers, searchText, isAdmin, blockIds]);

  const MemorizedTable = useMemo(
    () => (
      <Table
        columns={columns}
        dataSource={filteredRows}
        pagination={false}
        rowKey={(record) => `${record.id}`}
        scroll={{ y: 300, scrollToFirstRowOnChange: true, x: 400 }}
        size="large"
        rowClassName={(record) =>
          record.title === '1' ? classes.tableRowLight : classes.tableRowDark
        }
      />
    ),
    [filteredRows, columns, classes.tableRowLight, classes.tableRowDark],
  );

  return (
    <section className={`flex-column ${classes.root}`}>
      {MemorizedTable}
    </section>
  );
}

export default GrowersTable;

export interface GrowersTableProp {
  growers: MiniGrower[];
  openEditGrowerModal: (e: any, record: any) => void;
  handleViewGrower: (e: any, record: any) => void;
  handleCopyGrowerId: (e: any, record: any) => void;
  onAddNewFarm: (e: any, record: any) => void;
  onActivateGrower: (e: any, record: any) => void;
  openDeleteModal: (e: any, record: any) => void;
  searchText: string;
}
