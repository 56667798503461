import { Chart, registerables } from 'chart.js';
import { useMemo } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import { SensorsChartProps } from 'src/shared/data/types/sensorTypes';
import {
  getChartData,
  getChartOptions,
  getMaxValue,
} from '../sensorsChartHelper';
import useStyles from './useStyles';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default function SensorChart(props: SensorsChartProps): JSX.Element {
  const classes = useStyles();
  const data = props;
  const maxValue = useMemo(() => getMaxValue(data), [data]);
  const graphData = useMemo(() => getChartData(data), [data]);
  const graphOptions = useMemo(
    () => getChartOptions(data.dates, maxValue),
    [data, maxValue],
  );

  return (
    <div className={classes.root}>
      <Line data={graphData} options={graphOptions} width="50%" height="20%" />
    </div>
  );
}
