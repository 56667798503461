import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FieldProps } from "./ModalHelper";

interface CheckboxProps {
    data: FieldProps;
}

export default function SelectedCheckbox({ data }: CheckboxProps): JSX.Element {
    const { t } = useTranslation();
    const handleChangeCheckbox = (e: CheckboxChangeEvent): void => {
        if (data.setFieldStateBoolean) {
            data.setFieldStateBoolean(e.target.checked);
        }
    };

    return (
        <div
        >
            <Form.Item >
                <Checkbox
                    checked={!!data.fieldState}
                    onChange={handleChangeCheckbox}
                >
                    {t(data.title)}
                </Checkbox>
            </Form.Item>
        </div>
    );
}
