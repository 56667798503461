import { useState } from 'react';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import {
  WeatherCellsFields,
  WeatherListCellProps,
} from 'src/shared/data/types/weatherTypes';
import {
  mediumMaxWidth,
  smallMaxWidth,
  xLargeMinWidth,
} from 'src/shared/constants/consts';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import useStyles from './useStyles';

export default function WeatherListCell({
  firstRowData,
  icon,
  iconSize = '',
  secondRowData,
  weatherFields,
}: WeatherListCellProps): JSX.Element {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { width } = useWindowDimensions();
  const classes = useStyles();

  // Function to extract the weather condition from the icon file name
  const getWeatherConditionKey = (
    iconPath: string,
  ): keyof WeatherCellsFields | null => {
    const matches = /\/static\/media\/(.+)\.[a-f0-9]+\.svg$/i.exec(iconPath);
    const key = matches?.[1]?.toUpperCase() as
      | keyof WeatherCellsFields
      | undefined;
    return key && weatherFields?.[key] ? key : null;
  };

  // Use the extracted key to get the correct tooltip text
  const getTooltipText = (iconPath: string): string => {
    const conditionKey = getWeatherConditionKey(iconPath);
    return conditionKey ? weatherFields?.[conditionKey] ?? '' : '';
  };

  const renderIconWithTooltip = (iconSrc: string): JSX.Element => (
    <div className={classes.iconContainer}>
      <IconWithToolTip
        text={getTooltipText(iconSrc)}
        svgIcon={
          <img
            style={{ maxWidth: `calc(0.6rem + ${iconSize}px)` }}
            src={iconSrc}
            alt=""
          />
        }
      />
    </div>
  );

  return width > xLargeMinWidth ? (
    <div className={`flex-column ${classes.root}`}>
      <div className={`flex align-center ${classes.firstRow}`}>
        <img
          style={{ maxWidth: '1.4rem', maxHeight: '1.3rem' }}
          src={icon}
          alt=""
        />
        <div className="flex">{firstRowData}</div>
      </div>
      <div className={classes.secondRow}>{secondRowData}</div>
    </div>
  ) : width > mediumMaxWidth && width <= xLargeMinWidth ? (
    <div className={`flex-column ${classes.root}`}>
      <div>{firstRowData}</div>
      <div className={classes.secondRow}>{secondRowData}</div>
    </div>
  ) : width > smallMaxWidth && width <= mediumMaxWidth ? (
    <div
      className={`flex-column ${classes.root}`}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}>
      {isTooltipVisible ? (
        renderIconWithTooltip(icon)
      ) : (
        <div className={classes.iconContainer}>
          <img
            style={{ maxWidth: `calc(0.6rem + ${iconSize}px)` }}
            src={icon}
            alt=""
          />
        </div>
      )}
      <div className={classes.secondRow}>{secondRowData}</div>
    </div>
  ) : (
      <div
          className={`flex-column ${classes.root}`}
          onMouseEnter={() => setIsTooltipVisible(true)}
          onMouseLeave={() => setIsTooltipVisible(false)}>
          {isTooltipVisible ? (
              renderIconWithTooltip(icon)
          ) : (
              <div className={classes.iconContainer}>
                  <img
                      style={{maxWidth: `calc(0.2rem + ${iconSize}px)`}}
                      src={icon}
                      alt=""
                  />
              </div>
          )}
          <div className={classes.secondRow}>{secondRowData}</div>
      </div>);
}
