import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GrowersReportTableRow from './GrowersReportsTableRow';
import '../../GrowersReportsManagement.scss';
import useStyles from '../../useStyles';

export default function GrowersReportCollapsibleTable(props: any): JSX.Element {
  const { rows } = props;
  const selectedGrower = useAppSelector(
    (state) => state.rootReducer.growerState.selectedGrower,
  );
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer className={classes.collapsibleTable} component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={6}>
              {selectedGrower?.title} - {t('growerReports.title')}{' '}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: any, index: number) => (
            <GrowersReportTableRow key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
