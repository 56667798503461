// ActualIrrigationModal.tsx

import React, { useState, useMemo } from 'react';
import {
    calculateAverage,
    calculateAverageTime,
    calculateTotal,
    calculateTotalTime,
    convertActualIrrigation,
    ConvertedActualIrrigation,
    formatHoursAndMinutesToString,
    formatNumberWithLabel,
} from './utils';
import { useAppDispatch, useAppSelector } from '../../../../../reduxToolkit/store/hooks';
import { ActualIrrigation } from '../../../../../shared/data/types/blockDataTypes';
import DesignModal from './design_modal';
import { dayQtyOptionsActualIrrigation } from "./constants";
import { useTranslation } from "react-i18next";
import {
    Measurement,
    MeasurementSys
} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import { ValueTime } from "../constants";

interface ActualIrrigationModalProps {
    flowData: ActualIrrigation[] | [] | undefined;
    userCountry: string;
    measurementSystem: MeasurementSys;
    measurement: Measurement;
}

const ActualIrrigationModal: React.FC<ActualIrrigationModalProps> = (
    {
        flowData,
        userCountry,
        measurementSystem,
        measurement
    }) => {
    const dispatch = useAppDispatch();
    const [day_qty, setDayQty] = useState<number>(7);
    const { t } = useTranslation();
    const isToroAccount = useAppSelector((state) => state.rootReducer.accountState.isToroAccount);

    // Early return if flowData is empty or undefined
    if (!flowData || flowData.length === 0) {
        return null;
    }

    // Convert the flowData to the desired format
    const convertedData: ConvertedActualIrrigation[] = convertActualIrrigation(flowData);

    // Use useMemo for performance optimization
    const filtered_data = useMemo(() => {
        const currentDate = new Date();
        // Calculate the threshold date
        const thresholdDate = new Date(currentDate);
        thresholdDate.setDate(currentDate.getDate() - day_qty);

        // Filter the data based on the Start date
        return convertedData
            .filter(item => {
                const itemDate = new Date(item.Start);
                return itemDate >= thresholdDate && itemDate <= currentDate;
            })
            .sort((a, b) => new Date(b.Start).getTime() - new Date(a.Start).getTime());
    }, [convertedData, day_qty]);

    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

    const total: string = formatNumberWithLabel(
        calculateTotal(filtered_data, 'Amount'),
        userCountry,
        measurementSystem,
        measurement,
        2,
        currentBlock?.area
    );
    const average: string = formatNumberWithLabel(
        calculateAverage(filtered_data, 'Amount', day_qty),
        userCountry,
        measurementSystem,
        measurement,
        2,
        currentBlock?.area
    );

    const value_time = useAppSelector(
        (state) => state.rootReducer.userState.value_time,
    );

    const display = value_time === ValueTime.time;

    const { hours: totalHours, minutes: totalMinutes } = calculateTotalTime(filtered_data, 'Time');
    const averageTime = calculateAverageTime(filtered_data, 'Time', day_qty);

    return (
        <DesignModal
            title={t('blockDataWidget.detailed_view_actual_irrigation')}
            iconName={isToroAccount ? 'sprinkler_red' : 'green_sprinkler'}
            dayQty={day_qty}
            setDayQty={setDayQty}
            total={display ? formatHoursAndMinutesToString(totalHours, totalMinutes) : total}
            average={display ? formatHoursAndMinutesToString(averageTime.hours, averageTime.minutes) : average}
            data={filtered_data}
            dayQtyOptions={dayQtyOptionsActualIrrigation(t)}
            prefix={t('blockDataWidget.last')}
        />
    );
};

export default ActualIrrigationModal;