export function cropTypeName(formOptions: any, cropTypeId?: number): string {
  if (!cropTypeId) return '';

  const cropObj = formOptions.cropOptions.find(
    (crop: any) => crop.id === cropTypeId,
  );

  if (!cropObj) return '';

  return cropObj.name;
}

export function varietyName(
  formOptions: any,
  cropTypeId?: number,
  varietyId?: number,
): string {
  if (!varietyId || !cropTypeId) return '';

  const varietyObj = formOptions?.varietyOptions[cropTypeId]?.find(
    (opt: any) => {
      return opt.id === varietyId;
    },
  );
  return varietyObj.name || '';
}

export function soilTextureName(
  formOptions: any,
  soilTextureId?: number,
): string {
  if (!soilTextureId) return '';

  const soilObj = formOptions.soilTextureOptions.find((soil: any) => {
    return soil.id === soilTextureId;
  });

  if (!soilObj) return '';

  return soilObj.name;
}

export function irrigationSystemName(
  formOptions: any,
  irrigationSystemId?: number,
): string {
  if (!irrigationSystemId) return '';

  const irrigationObj = formOptions.irrigationSystemOptions.find(
    (irrigation: any) => {
      return irrigation.id === irrigationSystemId;
    },
  );

  if (!irrigationObj) return '';

  return irrigationObj.name;
}
