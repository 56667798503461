import { createAsyncThunk } from '@reduxjs/toolkit';
import { GrowerDetails } from 'src/shared/data/types/growerOptionsTypes';
import growerAdapter from 'src/reduxToolkit/adapters/GrowersAdapter';
import {
  MiniGrower,
  NewGrower,
} from '../../shared/data/types/growerFarmsTypes';

export const getAllGrowers = (): any => {
  try {
    return growerAdapter.getAllGrowers();
  } catch (error: any) {
    return error;
  }
};

export const getBlockIds = (): any => {
  try {
    return growerAdapter.getBlockIds();
  } catch (error: any) {
    return error;
  }
};

export const editGrower = async (
  updatedGrower: MiniGrower,
  growerId: any,
): Promise<any> => {
  try {
    updatedGrower.id = growerId;
    const response = await growerAdapter.editGrower(updatedGrower);
    const { data } = response;
    return data;
  } catch (error: any) {
    console.error(error);
    return error?.response?.data;
  }
};

export const postNewGrower = createAsyncThunk<
  MiniGrower,
  { newGrower: NewGrower },
  { rejectValue: Error }
>(
  'post grower',
  async (
    { newGrower },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await growerAdapter.postNewGrower(newGrower);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSpecificGrowerById = async (
  growerId: string,
): Promise<any> => {
  try {
    const response = await growerAdapter.deleteSpecificGrowerById(growerId);
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};
