import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import downloadIcon from 'src/assets/Icons/sharedIcons/downloadIcon.svg';
import toroDownloadIcon from 'src/assets/Icons/toroThemeIcons/toroDownloadBox.svg';
import {
  getGrowerWeeksDownloadReport,
  getGrowerWeeksReport,
} from 'src/reduxToolkit/thunks/grower_reports_thunk';
import { saveAs } from 'file-saver';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import formatDate from 'src/shared/helpers/HelperFunctions';
import { Typography } from 'antd';
import GrowersReportsTableView from './GrowersReportsTableView/GrowersReportsTableView';
import useStyles from './useStyles';
import { setWeeklyReports } from '../../../../reduxToolkit/slices/GrowerReportsSlice/growerReports.slice';
import {MeasurementSystem} from "../../../../shared/data/types/UserTypes";

function GrowersReportsManagement(): JSX.Element {
  const [weeklyData, setWeeklyData] = useState<any>({});
  const [weeklyDataLoading, setWeeklyDataLoading] = useState<any>(false);
  const { data } = useAppSelector((state) => state.rootReducer.blockDataState);
  const blocksData = data;
  const [weekDataSet, setWeekDataSet] = useState<any>([]);
  const [exportBtnClicked, setExportBtnClicked] = useState<boolean>(false);
  const classes = useStyles();
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const selectedGrower = useAppSelector(
    (state) => state.rootReducer.growerState.selectedGrower,
  );
  const weekStartDay = selectedGrower?.country === 'Israel' ? 1 : 2;
  const { t } = useTranslation();
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );
  const dispatch = useAppDispatch();

  const grower = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState.currentGrower;
  });

  const measurement_system = useAppSelector(
      (state) => state.rootReducer.userState.measurementSystem,
  );
  const save = async (): Promise<void> => {
    try {
      setLoading(true);
      let { data } = await getGrowerWeeksDownloadReport(
        selectedGrower?.id,
        weekStartDay,
        selectedMeasurementSystem,
      );
      data = new Uint8Array(data);
      const d = new Date();
      const ts = formatDate(d);
      const blob = new Blob([data], { type: 'text/xlsx; charset=utf-8' });
      saveAs(
        blob,
        `${selectedGrower?.title}_${ts}_${t(
          'growerReports.downloadNameLabel',
        )}.xlsx`,
      );
      setLoading(false);
      window.gtag('event', 'grower_report_download', {
        file_name: `${selectedGrower?.title}_${ts}_${t(
          'growerReports.downloadNameLabel',
        )}.xlsx`,
      });
      setExportBtnClicked(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const onDownloadButtonClicked = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
    setExportBtnClicked(true);
    if (selectedGrower != null) {
      void save();
    }
  };

  useEffect(() => {
    setWeeklyDataLoading(true);
    getGrowerWeeksReport(grower?.id ?? 0, weekStartDay).then((res: any) => {
      if (res.data) {
        dispatch(setWeeklyReports({ weeklyReports: res.data }));
        setWeeklyData(res.data);
        setWeeklyDataLoading(false);
      }
    });
  }, []);

  const formatDateTable = (
      date: string | Date,
      measurementSystem: MeasurementSystem,
  ): string => {
    const d = new Date(date);

    if (isNaN(d.getTime())) { // Check for invalid dates
      return 'Invalid Date';
    }

    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();

    if (measurementSystem === MeasurementSystem.Imperial) {
      return `${month}/${day}/${year}`; // mm/dd/yyyy
    } else {
      return `${day}/${month}/${year}`; // dd/mm/yyyy
    }
  };

  useEffect(() => {
    if (!blocksData) return;
    try {
      setLoadingTableData(true);
      if (
        weeklyData &&
        Object.keys(weeklyData).length > 0 &&
        weeklyData?.weeks?.length > 0
      ) {
        const weekDataArr: any = [];
        weeklyData?.weeks.forEach((weekData: any, weekIdx: number) => {
          weekDataArr.push({
            startDate: formatDateTable(weekData.startDate, measurement_system), // Formatted start date
            endDate: formatDateTable(weekData.endDate, measurement_system),     // Formatted end date
            farms: weekData.farms,
            weekIdx: weekIdx,
          });
        });
        setWeekDataSet(weekDataArr);
      } else {
        setExportBtnClicked(false);
        setLoadingTableData(false);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingTableData(false);
    }
  }, [blocksData, weeklyData, loadingTableData]);

  const renderLoader = (): any => {
    return (
      <div className={classes.loaderContainer}>
        <div>
          <div className="lds-ellipsis" id="progress-loader">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  };

  const renderLoaderOnDownload = (): any => {
    return (
      <div className={classes.loaderContainerOnDownload}>
        <div>
          <div className="lds-ellipsis" id="progress-loader">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  };

  const renderReportTable = (): any => {
    if (weeklyDataLoading) {
      return <div className={classes.loaderWrapper}>{renderLoader()}</div>;
    }
    if (weeklyData?.weeks && weekDataSet) {
      return <GrowersReportsTableView weeklyData={weekDataSet} />;
    }
    return <div>{t('growerReports.noDataWarning')}</div>;
  };

  const renderDownloadReports = (): any => (
    <Button
      id="export-grower-report-btn"
      onClick={(e) => onDownloadButtonClicked(e)}
      disabled={exportBtnClicked || !(weeklyData?.weeks && weekDataSet)}
      className={`ant-btn-custom ${classes.exportAllBtn}`}>
      <span>{t('growerReports.export')}</span>
      <span style={{ width: '1rem', marginLeft: '0.5rem' }}>
        {isToroAccount ? (
          <img
            src={toroDownloadIcon}
            alt="Download Icon"
            style={{ maxWidth: '1rem', maxHeight: '1.2rem' }}
          />
        ) : (
          <img
            src={downloadIcon}
            alt="Download Icon"
            style={{ maxWidth: '1rem', maxHeight: '1.2rem' }}
          />
        )}
      </span>
    </Button>
  );

  return (
    <div
      className={`flex-column align-center justify-between ${classes.reportsModal}`}>
      <div className={classes.header}>
        {selectedGrower?.title} {t('growerReports.modalTitle')}
      </div>
      {renderReportTable()}
      <div className={`flex-column align-center ${classes.btnContainer}`}>
        {exportBtnClicked ? (
          <Typography className={classes.warning}>
            {t('growerReports.delayWarning')}
          </Typography>
        ) : null}
        {renderDownloadReports()}
        {loading && (
          <div className={classes.loaderWrapperOnDownload}>
            {renderLoaderOnDownload()}
          </div>
        )}
      </div>
    </div>
  );
}

export default GrowersReportsManagement;
