import { createSlice } from '@reduxjs/toolkit';
import { SensorsState } from 'src/shared/data/types/sensorTypes';

const initialState: SensorsState = {
  sensors: [],
};

export const SensorsSlice = createSlice({
  name: 'sensors',
  initialState,
  reducers: {
    updateSensors(state, action) {
      return {
        ...state,
        sensors: state.sensors.concat(action.payload.sensors),
      };
    },
  },
});

export const { updateSensors } = SensorsSlice.actions;

export const sensorsReducer = SensorsSlice.reducer;
