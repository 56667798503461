import ClearDayIcon from 'src/assets/Icons/weatherIcons/clear_day.svg';
import MostlyClearIcon from 'src/assets/Icons/weatherIcons/mostly_clear.svg';
import PartlyCloudyIcon from 'src/assets/Icons/weatherIcons/partly_cloudy.svg';
import MostlyCloudyIcon from 'src/assets/Icons/weatherIcons/mostly_cloudy.svg';
import CloudyIcon from 'src/assets/Icons/weatherIcons/cloudy.svg';
import FogIcon from 'src/assets/Icons/weatherIcons/fog.svg';
import FogLightIcon from 'src/assets/Icons/weatherIcons/fog_light.svg';
import drizzleIcon from 'src/assets/Icons/weatherIcons/drizzle.svg';
import rainIcon from 'src/assets/Icons/weatherIcons/rain.svg';
import rainLightIcon from 'src/assets/Icons/weatherIcons/rain_light.svg';
import rainHeavyIcon from 'src/assets/Icons/weatherIcons/rain_heavy.svg';
import snowIcon from 'src/assets/Icons/weatherIcons/snow.svg';
import flurriesIcon from 'src/assets/Icons/weatherIcons/flurries.svg';
import snowLightIcon from 'src/assets/Icons/weatherIcons/snow_light.svg';
import snowHeavyIcon from 'src/assets/Icons/weatherIcons/snow_heavy.svg';
import freezingDrizzleIcon from 'src/assets/Icons/weatherIcons/freezing_drizzle.svg';
import freezingRainIcon from 'src/assets/Icons/weatherIcons/freezing_rain.svg';
import freezingRainLightIcon from 'src/assets/Icons/weatherIcons/freezing_rain_light.svg';
import freezingRainHeavyIcon from 'src/assets/Icons/weatherIcons/freezing_rain_heavy.svg';
import icePelletsIcon from 'src/assets/Icons/weatherIcons/ice_pellets.svg';
import icePelletsHeavyIcon from 'src/assets/Icons/weatherIcons/ice_pellets_heavy.svg';
import icePelletsLightIcon from 'src/assets/Icons/weatherIcons/ice_pellets_light.svg';
import thunderstormIcon from 'src/assets/Icons/weatherIcons/thunderstorm.svg';

const getWeatherIcon = (tempCode: number): any => {
  switch (tempCode) {
    case 1000:
      return ClearDayIcon;
    case 1100:
      return MostlyClearIcon;
    case 1101:
      return PartlyCloudyIcon;
    case 1102:
      return MostlyCloudyIcon;
    case 1001:
      return CloudyIcon;
    case 2000:
      return FogIcon;
    case 2100:
      return FogLightIcon;
    case 4000:
      return drizzleIcon;
    case 4001:
      return rainIcon;
    case 4200:
      return rainLightIcon;
    case 4201:
      return rainHeavyIcon;
    case 5000:
      return snowIcon;
    case 5001:
      return flurriesIcon;
    case 5100:
      return snowLightIcon;
    case 5101:
      return snowHeavyIcon;
    case 6000:
      return freezingDrizzleIcon;
    case 6001:
      return freezingRainIcon;
    case 6200:
      return freezingRainLightIcon;
    case 6201:
      return freezingRainHeavyIcon;
    case 7000:
      return icePelletsIcon;
    case 7101:
      return icePelletsHeavyIcon;
    case 7102:
      return icePelletsLightIcon;
    case 8000:
      return thunderstormIcon;
    default:
      return ClearDayIcon;
  }
};

export default getWeatherIcon;
