import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import {
  AgentsDetails,
  OwnerResponse,
} from 'src/shared/data/types/agentOptionsTypes';

const adminToken = '';

class AgentAdapter {
  static readonly endpoint = `/accounts`;

  async getAllAgents(
    ownerAccountId: number,
  ): Promise<AxiosResponse<AgentsDetails>> {
    return HttpClientService.get<AgentsDetails>(
      `${AgentAdapter.endpoint}/${ownerAccountId}/all-agents/`,
    );
  }

  async getAgentsChildren(
    ownerAccountId: number,
    selectedAgentSub: string,
  ): Promise<AxiosResponse<AgentsDetails>> {
    return HttpClientService.get<AgentsDetails>(
      `${AgentAdapter.endpoint}/${ownerAccountId}/${selectedAgentSub}/children-agent/`,
    );
  }

  async getSpecificAgentById(
    agentId: string,
  ): Promise<AxiosResponse<AgentsDetails>> {
    return HttpClientService.get<AgentsDetails>(AgentAdapter.endpoint, {
      headers: { adminToken, agentId },
    });
  }

  async deleteSpecificAgentById(
    accountId: string,
    agentId: string,
  ): Promise<AxiosResponse<AgentsDetails>> {
    return HttpClientService.delete<AgentsDetails>(
      `${AgentAdapter.endpoint}/${accountId}/account-users/${agentId}`,
      {
        agentId,
        accountId,
      },
    );
  }

  async editSpecificAgentById(
    updatedAgent: AgentsDetails,
  ): Promise<AxiosResponse<AgentsDetails>> {
    return HttpClientService.patch<AgentsDetails>(
      `${AgentAdapter.endpoint}/${updatedAgent.id}`,
      { updatedAgent },
    );
  }

  async postNewAgent(
    newAgent: AgentsDetails,
  ): Promise<AxiosResponse<AgentsDetails>> {
    return HttpClientService.post<AgentsDetails>(
      `${AgentAdapter.endpoint}/create-account-user`,
      newAgent,
    );
  }

  async putNewAgent(
    id: string,
    editAgent: AgentsDetails,
  ): Promise<AxiosResponse<AgentsDetails>> {
    return HttpClientService.put<AgentsDetails>(
      `${AgentAdapter.endpoint}/update-account-user/${id}`,
      editAgent,
    );
  }

  async getOwners(): Promise<AxiosResponse<OwnerResponse>> {
    return HttpClientService.get<OwnerResponse>(
      `${AgentAdapter.endpoint}/user-agents`,
    );
  }
}

const agentsAdapter = new AgentAdapter();
export default agentsAdapter;
