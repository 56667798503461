import React, { useState, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { IrrigationRequirement } from "../../../../../shared/data/types/blockDataTypes";
import {
    Measurement,
    MeasurementSys
} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import { useAppSelector } from "../../../../../reduxToolkit/store/hooks";
import { convertDepthToTime } from "../../../../../shared/measurements-converter/converter";
import { ValueTime } from "../constants";
import {
    calculateAverage,
    calculateTotal,
    ConvertedIrrigationRequirement,
    convertIrrigationRequirement,
    formatNumberWithLabel
} from "../modals/utils";
import DesignModal from "../modals/design_modal";
import { dayQtyOptionsIrrigationRequirement } from "../modals/constants";

export interface IrrigationRequirementModalProps {
    data?: IrrigationRequirement[];
    userCountry: string;
    measurementSystem: MeasurementSys;
    measurement: Measurement;
}

const IrrigationRequirementModal: React.FC<IrrigationRequirementModalProps> = (
    {
        data,
        userCountry,
        measurementSystem,
        measurement
    }) => {
    const [day_qty, setDayQty] = useState<number>(7);
    const { t } = useTranslation();
    const isToroAccount = useAppSelector((state) => state.rootReducer.accountState.isToroAccount);
    const value_time = useAppSelector((state) => state.rootReducer.userState.value_time);

    const converted_data: ConvertedIrrigationRequirement[] = convertIrrigationRequirement(data || [], measurementSystem === MeasurementSys.METRIC);
    const filtered_data = useMemo(() => converted_data.reverse().slice(0, day_qty), [converted_data, day_qty]);
    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

    // Calculate total and average amounts for irrigation requirements
    const totalAmount = calculateTotal(filtered_data, 'Amount');
    const averageAmount = calculateAverage(filtered_data, 'Amount', day_qty);

    // Calculate flow rate and area for time conversion
    const system_flow_rate = 1; // Replace this with the actual flow rate source
    const block_area = currentBlock?.area || 0; // Get area from current block

    const totalTime = useMemo(() => {
        return convertAndFormatDepth(totalAmount, block_area, system_flow_rate);
    }, [totalAmount, block_area, system_flow_rate]);

    const averageTime = useMemo(() => {
        return convertAndFormatDepth(averageAmount, block_area, system_flow_rate);
    }, [averageAmount, block_area, system_flow_rate]);

    // Determine whether to display time values
    const display = value_time === ValueTime.time;

    // Transform filtered_data based on display state
    const transformed_data = useMemo(() => {
        if (!display) return filtered_data;

        return filtered_data.map(item => ({
            ...item,
            Amount: convertAndFormatDepth(item.Amount, block_area, system_flow_rate)
        }));
    }, [filtered_data, display, block_area, system_flow_rate]);

    return (
        <DesignModal
            title={t('blockDataWidget.detailed_view_irrigation_requirement')}
            iconName={isToroAccount ? 'water_full_red' : 'green_drop'}
            dayQty={day_qty}
            setDayQty={setDayQty}
            total={display ? totalTime.toString() : formatNumberWithLabel(totalAmount, userCountry, measurementSystem, measurement, 2, currentBlock?.area)}
            average={display ? averageTime.toString() : formatNumberWithLabel(averageAmount, userCountry, measurementSystem, measurement, 2, currentBlock?.area)}
            data={transformed_data}
            dayQtyOptions={dayQtyOptionsIrrigationRequirement(t)}
            prefix={t('blockDataWidget.next')}
        />
    );
};

export default IrrigationRequirementModal;

// Utility function to format irrigation time
export function formatIrrigationTime(time: number): string {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours}h ${minutes}min`;
}

// Utility function to convert irrigation depth to time
export function convertAndFormatDepth(irrigation_requirement: number, area: number, flowRate: number): string | number {
    const timeValue = convertDepthToTime(irrigation_requirement, area, flowRate);
    return timeValue ? formatIrrigationTime(timeValue) : 0;
}