 import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postNewAgent } from 'src/reduxToolkit/thunks/agent_thunks';
import countries from 'src/consts/countries';
import countryMap from 'src/shared/data/CountryMap';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Regex } from 'src/shared/constants/consts';
import {
  handleCountryValidation,
  isValidTLD,
} from 'src/shared/helpers/FormValidationHelper';
import {
  SelectCountry,
  SelectState,
  SelectOwner,
  SelectItem,
  TextForm,
  GenericModal,
  ModalHelper,
} from 'src/shared/components/GenericModal';
import { useAppDispatch } from 'src/reduxToolkit/store/hooks';
import notifyService from 'src/shared/notification/notification.service';
import messageTypes from 'src/consts/messages';
import agentsAdapter from 'src/reduxToolkit/adapters/AgentsAdapter';
import { UserRole } from 'src/shared/constants/enums';
import { Owner } from 'src/shared/data/types/agentOptionsTypes';

function AddAgentModal({ onSave, account, onClose }: any): ReactElement | null {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>('');
  const [titleValidation, setTitleValidation] = useState<boolean>(false);
  const [roleValidation, setRoleValidation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [countryValidation, setCountryValidation] = useState<string>('');
  const [stateName, setStateName] = useState<string>('');
  const [stateNameValidation, setStateNameValidation] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameValidation, setFirstNameValidation] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameValidation, setLastNameValidation] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailValidation, setEmailValidation] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const roleList =
    account?.role === UserRole.AGENT_ADMIN
      ? [UserRole.AGENT_ADMIN]
      : [UserRole.ACCOUNT_ADMIN, UserRole.AGENT_ADMIN];
  const [role, setRole] = useState<string>(roleList[0]);
  const [phoneValidation, setPhoneValidation] = useState<string>('');
  const [ownerId, setOwnerId] = useState<string>('');
  const [ownerEmail, setOwnerEmail] = useState<string>('');
  const [accountId, setAccountId] = useState<string | null>();
  const [owners, setOwners] = useState<Owner[]>([]);
  const { t } = useTranslation();
  const props = {
    title,
    setTitle,
    titleValidation,
    setTitleValidation,
    description,
    setDescription,
    country,
    setCountry,
    countryValidation,
    setCountryValidation,
    stateName,
    setStateName,
    stateNameValidation,
    setStateNameValidation,
    firstName,
    setFirstName,
    firstNameValidation,
    setFirstNameValidation,
    lastName,
    setLastName,
    lastNameValidation,
    setLastNameValidation,
    email,
    setEmail,
    emailValidation,
    setEmailValidation,
    phone,
    setPhone,
    phoneValidation,
    setPhoneValidation,
    role,
    setRole,
    roleValidation,
    setRoleValidation,
    ownerId,
    setOwnerId,
    ownerEmail,
    setOwnerEmail,
    accountId,
    setAccountId,
    city,
    setCity,
  };
  const agentFields = ModalHelper(props);

  useEffect(() => {
    getOwners().then();
  }, []);

  async function getOwners() {
    const owners = await agentsAdapter.getOwners();
    setOwners(owners.data.owners);
  }

  const newAgent = {
    country: country,
    state: stateName ?? null,
    city: city ?? null,
    email: email,
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    ownerId: ownerId,
    ownerEmail: ownerEmail,
    accountCognitoId: accountId,
    measurementSystem: account?.measurementSystem ?? null,
    role: role,
  };

  const handleSave = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    void dispatch(postNewAgent({ newAgent }))
      .then((res) => {
        if (res.payload != null) {
          onSave(res.payload);
          notifyService(
            `${newAgent.firstName} ${newAgent.lastName} ${t(
              'agentPage.notifySaveNewAgentSuccess',
            )}`,
            messageTypes.success,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderRoleLabel = (item: string): any => {
    if (item === 'account-admin') {
      return t('agentFieldModal.accountAdminRole');
    }
    if (item === 'agent-admin') {
      return t('agentFieldModal.agentAdminRole');
    }
    return item;
  };

  return (
    <GenericModal
      title={`${t('agentFieldModal.addAgentModalTitle')} ${account?.firstName} ${account?.lastName ? account?.lastName : ''}`}
      onClose={onClose}
      onSave={handleSave}
      isLoading={loading}
      isSaveDisabled={
        !ownerId ||
        !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !country ||
        !isValidPhoneNumber(phone) ||
        !isPossiblePhoneNumber(phone) ||
        !Regex.EMAIL_REGEX.test(email) ||
        (Regex.EMAIL_REGEX.test(email) && !isValidTLD(email)) ||
        !handleCountryValidation(country, stateName)
      }
      saveButtonText={t('agentFieldModal.saveAgentBtn')}
      cancelButtonText={t('agentFieldModal.cancel')}
      isAgent={true}>
      <div className="First">
        <TextForm data={agentFields.firstNameField} typeForm="agent" />
      </div>
      <div className="Last">
        <TextForm data={agentFields.lastNameField} typeForm="agent" />
      </div>
      <div className="Country">
        <SelectCountry data={agentFields.countryField} />
      </div>
      <div className="State">
        {country === countries.USA || country === countries.USAShort ? (
          <SelectState data={agentFields.stateNameField} />
        ) : (
          <TextForm data={agentFields.cityField} typeForm="grower" />
        )}
      </div>
      <div className="Phone">
        <TextForm
          data={agentFields.phoneField}
          type="phone"
          code={countryMap.get(country)}
          typeForm="agent"
        />
      </div>
      <div className="Email">
        <TextForm data={agentFields.emailField} type="email" typeForm="agent" />
      </div>
      <div className="Owner">
        <SelectOwner
          owners={owners}
          onChange={(id, email, accountId) => {
            setOwnerId(id);
            setOwnerEmail(email);
            setAccountId(accountId);
          }}
          selectedOwnerId={ownerId}
        />
      </div>
      <div className="Role">
        <SelectItem
          data={{
            ...agentFields.roleField,
            options: roleList.map((item: any) => ({
              label: renderRoleLabel(item),
              value: item,
            })),
          }}
        />
      </div>
    </GenericModal>
  );
}

export default AddAgentModal;
