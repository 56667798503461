// src/index.tsx
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './main.scss';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './reduxToolkit/store/store';
import { AuthProvider } from './shared/contexts/AuthContext';
import i18n from './shared/i18/i18n';

const root = document.getElementById('root') as HTMLElement;
ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <AuthProvider>
              <CookiesProvider>
                <Suspense fallback="...is loading">
                  <App />
                </Suspense>
              </CookiesProvider>
            </AuthProvider>
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>,
    root,
);
reportWebVitals();