import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import { useStyles } from './useStyles';

const WeatherButton = function WeatherButton(props: any): JSX.Element {
  const { button, weather, setOpenWeatherBar } = props;
  const [hoverOnWeatherButton, setHoverOnWeatherButton] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback(
    (
      e:
        | React.MouseEvent<HTMLAnchorElement>
        | React.MouseEvent<HTMLButtonElement>,
    ): void => {
      e.stopPropagation();
      setOpenWeatherBar(!weather);
    },
    [setOpenWeatherBar, weather],
  );

  const handleMouseEnter = useCallback((): void => {
    setHoverOnWeatherButton(true);
  }, []);

  return (
    <Button
      id="open-current-weather"
      className={`justify-between flex ${classes.mapSettingsTop}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}>
      {hoverOnWeatherButton ? (
        <IconWithToolTip
          color="#fff"
          svgIcon={button.name}
          text={`${t('map.weeklyWeatherTooltip')}`}
        />
      ) : (
        button.name
      )}
    </Button>
  );
};

export default WeatherButton;
