export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatDateForWeatherObj = (date: string): any => {
  const separatedDate = date.split('-');
  return {
    month: monthNames[Number(separatedDate[1]) - 1],
    day: separatedDate[2],
  };
};

// Function to get the current date in the provided timezone.
// It returns a string in the format 'YYYY-MM-DD'.
export const getTodaysDate = (timezone: string): string => {
  const now = new Date().toLocaleString('en-US', { timeZone: timezone });
  const currDate = new Date(now);
  const currYear = currDate.getFullYear();
  const currMonth = `${currDate.getMonth() + 1}`.padStart(2, '0');
  const currDay = `${currDate.getDate()}`.padStart(2, '0');
  return `${currYear}-${currMonth}-${currDay}`;
};

// Function to get the current date in the user.
// It returns a string in the format 'YYYY-MM-DD'.
export const formatDateToISO = (dateString: any): string => {
  // Parse the date string into a Date object
  const date = new Date(dateString);
  // Format the date components, ensuring two digits for month and day
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-indexed, add 1 to get correct month
  const day = date.getDate().toString().padStart(2, '0');
  const usersDate = `${year}-${month}-${day}`;
  // Combine the components in the desired format
  return usersDate;
};

export const OpenWeatherBar = {
  FIRST_WEEK_START_IDX: 0,
  FIRST_WEEK_END_IDX: 6, // Actual idx is 5, but this is used with slice() so we need the wanted idx + 1
  SECOND_WEEK_START_IDX: 6,
  SECOND_WEEK_END_IDX: 13, // Actual idx is 12, but this is used with slice() so we need the wanted idx + 1
  THIRD_WEEK_START_IDX: 13,
};
