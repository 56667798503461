import { InputFormFieldProps } from 'src/shared/data/types/formsTypes';
import useStyles from './useStyles';

function LabelFormField(props: InputFormFieldProps): JSX.Element {
  const { title, placeholder, value } = props;
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <span>{title}</span>
      <input
        id="common-input-label-form"
        value={value}
        placeholder={placeholder}
        className="common-input-label-form"
        type="text"
        readOnly
        disabled
        data-hj-allow
      />
    </span>
  );
}

export default LabelFormField;
