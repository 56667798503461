import { darken, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.backgroundGrey.main,
    padding: '1rem 1rem 0 1rem',
    gap: '0.3rem',
    textAlign: 'center',
    '& p': {
      color: theme.palette.darkGrey.main,
      fontWeight: '500',
    },
    '& span': {
      color: `${theme.palette.accent.main} !important`,
      fontWeight: 'unset',
    },
  },
  loader: {
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1rem',
    left: 0,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  confirmationBtn: {
    padding: ' 0.8rem',
    minWidth: 'fit-content',
    border: 0,
    borderRadius: '0.25rem',
    margin: '0.25rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'calc(0.8rem + 0.5vw)',
    backgroundColor: theme.palette.accent.main,
    '& span': {
      color: '#fff !important',
    },
    '&:hover:not(:disabled)': {
      backgroundColor: `${darken(theme.palette.accent.main, 0.2)}!important`,
    },
  },
  cancelBtn: {
    padding: ' 0.8rem',
    minWidth: 'fit-content',
    borderRadius: '0.25rem',
    margin: '0.25rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.darkGrey.main,
    border: '1px solid',
    fontSize: 'calc(0.8rem + 0.5vw)',
    borderColor: 'lightgrey',
    backgroundColor: '#fff',
    '& span': {
      color: `${theme.palette.darkGrey.main} !important`,
    },
    '&:hover': {
      backgroundColor: `${darken(theme.palette.grey.A100, 0.2)}!important`,
      borderColor: `${darken(theme.palette.grey.A100, 0.2)}!important`,
      color: `${theme.palette.accent.main}!important`,
    },
  },
}));

export default useStyles;
