import React, { useState} from 'react';
import {
    calculateAverage,
    calculateTotal, convertActualConsumption,
    ConvertedActualConsumption, formatNumberWithLabel,
} from './utils';
import { ActualConsumption } from '../../../../../shared/data/types/blockDataTypes';
import {useAppDispatch, useAppSelector} from '../../../../../reduxToolkit/store/hooks';
import DesignModal from './design_modal';
import {dayQtyOptionsActualConsumption} from "./constants";
import { useTranslation } from 'react-i18next';
import {
    Measurement,
    MeasurementSys
} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";

interface ActualConsumptionModalProps {
    data?: ActualConsumption[];
    userCountry: string;
    measurementSystem: MeasurementSys;
    measurement: Measurement;
}

const ActualConsumptionModal: React.FC<ActualConsumptionModalProps> = (
    {
        data,
        userCountry,
        measurementSystem,
        measurement
    }) => {
    const dispatch = useAppDispatch();
    const [day_qty, setDayQty] = useState<number>(7); // Number of days picker
    const { t } = useTranslation();
    // Convert server data to the desired format (only Date and Actual Consumption)
    const convertedData: ConvertedActualConsumption[] = convertActualConsumption(data || [], measurementSystem === MeasurementSys.METRIC);
    const isToroAccount = useAppSelector((state) => state.rootReducer.accountState.isToroAccount);

    const filtered_data = convertedData.slice(0, day_qty);
    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

    const total: string = formatNumberWithLabel(
        calculateTotal(filtered_data, 'Actual Consumption'),
        userCountry,
        measurementSystem,
        measurement,
        2,
        currentBlock?.area
    );
    const average: string = formatNumberWithLabel(
        calculateAverage(filtered_data, 'Actual Consumption',day_qty),
        userCountry,
        measurementSystem,
        measurement,
        2,
        currentBlock?.area
    );

    return (
        <DesignModal
            title= {t('blockDataWidget.detailed_view_actual_consumption')}
            iconName={isToroAccount ? 'tree_red': 'green_tree'}
            dayQty={day_qty}
            setDayQty={setDayQty}
            total={total}
            average={average}
            data={filtered_data}
            dayQtyOptions={dayQtyOptionsActualConsumption(t)}
            prefix={t('blockDataWidget.last')}
        />
    );
};

export default ActualConsumptionModal;