import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AddOrEditBlockState } from 'src/shared/data/types/blockTypes';
import { FormBlock } from 'src/shared/data/types/formsTypes';
import { getBlockInfoOptions } from 'src/reduxToolkit/thunks/block_thunks';
import { getUnconnectedSensors } from 'src/reduxToolkit/thunks/sensor_thunk';
import { getInstallers } from 'src/reduxToolkit/thunks/user_thunks';

const initialState: AddOrEditBlockState = {
  isNewBlockCreationInProgress: false,
  openModal: false,
  openConfirmationModal: false,
  newPolygonPathAfterEdit: undefined,
  newPolygonAreaAfterEdit: 0,
  newPolygonPathBeforeEdit: undefined,
  resetPolygon: false,
  savePolygon: false,
  block: {} as FormBlock,
  isInEditMode: false,
  numOfClicksOnMapDuringBlockCreation: 0,
  formOptions: {} as any,
  editedBlockId: '',
  deletedBlock: undefined,
  unconnectedSensors: [],
  installers: [],
  savedBlock: false,
  savedBlockId: undefined,
};

export const addOrEditBlockSlice = createSlice({
  name: 'modifyBlock',
  initialState,
  reducers: {
    setCreationInProgress(state, action: PayloadAction<boolean>) {
      state.isNewBlockCreationInProgress = action.payload;
    },
    setOpenModal(state, action: PayloadAction<boolean>) {
      state.openModal = action.payload;
    },
    setOpenConfirmationModal(state, action: PayloadAction<boolean>) {
      state.openConfirmationModal = action.payload;
    },
    setNewPolygonPathBeforeEdit(
      state,
      action: PayloadAction<google.maps.LatLngLiteral[]>,
    ) {
      state.newPolygonPathBeforeEdit = action.payload;
    },
    setResetPolygonPathAfterEdit(state, action: PayloadAction<boolean>) {
      state.resetPolygon = action.payload;
    },
    setSavePolygonPathAfterEdit(state, action: PayloadAction<boolean>) {
      state.savePolygon = action.payload;
    },
    setNewPolygonPathAfterEdit(
      state,
      action: PayloadAction<google.maps.LatLngLiteral[]>,
    ) {
      state.newPolygonPathAfterEdit = action.payload;
    },
    setNewPolygonAreaAfterEdit(state, action: PayloadAction<number>) {
      state.newPolygonAreaAfterEdit = action.payload;
    },
    initBlockFields(state, action) {
      state.block = action.payload;
    },
    updateBlockField(state, action) {
      if (state.block != null) {
        state.block = {
          ...state.block,
          [action.payload.field]: action.payload.value,
        };
      }
    },
    setIsInEditMode(state, action) {
      state.isInEditMode = action.payload;
    },
    setNumOfClicksOnMapDuringCreation(state, action: PayloadAction<number>) {
      state.numOfClicksOnMapDuringBlockCreation = action.payload;
    },
    setEditedBlockId(state, action) {
      state.editedBlockId = action.payload;
    },
    setSavedBlock(state, action) {
      state.savedBlock = action.payload;
    },
    setSavedBlockId(state, action) {
      state.savedBlockId = action.payload;
    },
    setDeletedBlock(state, action) {
      state.deletedBlock = action.payload;
    },
    clearBlock(state) {
      state.block = {} as FormBlock;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBlockInfoOptions.fulfilled, (state, action) => {
      const sortFunction = (a: any, b: any): number => {
        const nameA = a.name?.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      };
      const varietyOptions = action.payload.variety_options;
      Object.keys(varietyOptions).forEach((option: string) => {
        varietyOptions[option].sort(sortFunction);
      });
      state.formOptions = {
        cropOptions: action.payload.crop_options.sort(sortFunction),
        soilTextureOptions:
          action.payload.soil_texture_options.sort(sortFunction),
        irrigationSystemOptions:
          action.payload.irrigation_system_options.sort(sortFunction),
        varietyOptions: varietyOptions,
      };
    });
    builder.addCase(getUnconnectedSensors.fulfilled, (state, action) => {
      state.unconnectedSensors = action.payload;
    });
    builder.addCase(getInstallers.fulfilled, (state, action) => {
      state.installers = action.payload;
    });
  },
});
export const {
  setCreationInProgress,
  setOpenModal,
  setOpenConfirmationModal,
  setNewPolygonPathAfterEdit,
  setNewPolygonAreaAfterEdit,
  setNewPolygonPathBeforeEdit,
  setResetPolygonPathAfterEdit,
  setSavePolygonPathAfterEdit,
  initBlockFields,
  updateBlockField,
  setIsInEditMode,
  setNumOfClicksOnMapDuringCreation,
  setEditedBlockId,
  setSavedBlock,
  setSavedBlockId,
  setDeletedBlock,
  clearBlock,
} = addOrEditBlockSlice.actions;

export const addOrEditBlockReducer = addOrEditBlockSlice.reducer;
