import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    paddingBlock: '4px',
    lineHeight: '0.8rem',
    '& div': {
      fontSize: 'calc(0.6rem + 0.3vw) !important',
    },
    '@media (max-width: 767px)': {
      paddingBlock: '2px',

    },
  },
  firstRow: {
    height: 'fit-content',
    whiteSpace: 'nowrap',
    gap: '10%',
    fontSize: 'calc(0.6rem + 0.3vw) !important',
    '& div': {
      height: '100%',
      fontSize: 'calc(0.6rem + 0.3vw) !important',
      '@media (max-width: 850.9px)': {
        fontSize: 'calc(0.6rem + 0.3vw) !important',
      },
    },
  },
  iconContainer: {
    '& img': {
      '& svg': {
        height: '100%',
      },
    },
  },
  secondRow: {
    width: '100%',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    lineHeight: '19px',
    color: theme.palette.oldPrimary.main,
    fontSize: 'calc(0.6rem + 0.3vw) !important',
    '@media (max-width: 850.9px)': {
      fontSize: 'calc(0.6rem + 0.3vw) !important',
      fontWeight: '400 !important',
    },
  },
}));

export default useStyles;
