const calcNewPolygonTemporaryPath = (
  farmCenter: google.maps.LatLngLiteral,
): google.maps.LatLngLiteral[] => {
  const westCord: google.maps.LatLngLiteral = {
    lat: farmCenter.lat - 0.002,
    lng: farmCenter.lng,
  };
  const eastCord: google.maps.LatLngLiteral = {
    lat: farmCenter.lat + 0.000004,
    lng: farmCenter.lng + 0.003,
  };
  const northCord: google.maps.LatLngLiteral = {
    lat: farmCenter.lat,
    lng: farmCenter.lng + 0.004,
  };
  const southCord: google.maps.LatLngLiteral = {
    lat: farmCenter.lat,
    lng: farmCenter.lng - 0.0007,
  };
  return [westCord, southCord, eastCord, northCord];
};

export default calcNewPolygonTemporaryPath;
