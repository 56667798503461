import { PolygonType } from 'src/shared/data/types/polygonTypes';

export const polygonSettings: google.maps.PolygonOptions = {
  fillColor: 'rgba(255, 255, 255, 0.75)',
  fillOpacity: 0.85,
  strokeColor: '#FFFFFF',
  strokeWeight: 3,
};
// This function build the right **STYLE** settings for each polygon depend on its state
export const polygonSettingsBuilder = (
  polygon: PolygonType,
): google.maps.PolygonOptions => {
  if (polygon.isFlagged && polygon.isSelected) {
    return { ...polygonSettings, fillColor: '#72fff7', strokeColor: '#72FFF7' };
  }
  if (polygon.isSelected) {
    return { ...polygonSettings, fillColor: '#72fff7', strokeColor: '#72FFF7' };
  }
  if (polygon.isFlagged) {
    return {
      ...polygonSettings,
      fillColor: 'rgba(255, 255, 255, 0.75)',
      strokeColor: '#FFFFFF',
    };
  }
  return polygonSettings;
};
