import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MapState } from 'src/shared/data/types/mapTypes';
import { PolygonType } from 'src/shared/data/types/polygonTypes';
import getPolygonsByFarm from 'src/reduxToolkit/thunks/polygon_thunk';

const initialState: MapState = {
  polygons: [],
  polygonRefs: [],
  httpErr: undefined,
};

export const MapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setPolygons(state, action: PayloadAction<PolygonType[]>) {
      state.polygons = action.payload;
    },
    setPolygonsRefs(state, action: PayloadAction<any[]>) {
      state.polygonRefs = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getPolygonsByFarm.fulfilled, (state, action) => {
      state.polygons = [...state.polygons, ...action.payload];
    });
    builder.addCase(getPolygonsByFarm.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
  },
});

export const { setPolygons, setPolygonsRefs } = MapSlice.actions;

export const mapReducer = MapSlice.reducer;
