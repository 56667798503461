import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { DatePicker } from 'antd';
import { DaysOptions } from 'src/shared/data/types/sensorTypes';
import useStyles from './useStyles';

const { RangePicker } = DatePicker;

export default function DaysOptionsNavigation(props: DaysOptions): JSX.Element {
  const classes = useStyles();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dates, setDates] = useState({
    fromDate: undefined,
    toDate: undefined,
  });
  const { t } = useTranslation();

  const data = props;
  const tabs = [
    { value: 'Yesterday', label: `${t('sensorPage.yesterday')}` },
    { value: '3 Days', label: `3 ${t('sensorPage.days')}` },
    { value: '7 Days', label: `7 ${t('sensorPage.days')}` },
    { value: '30 Days', label: `30 ${t('sensorPage.days')}` },
    // { value: "custom", label: `${t('sensorPage.custom')}`},
  ];
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ): void => {
    data.onSelect(newValue);
  };

  const handleUpdateDates = (): void => {
    setIsDatePickerOpen(false);
    if (dates.fromDate !== undefined && dates.toDate !== undefined) {
      const range =
        Math.abs(
          new Date(dates.fromDate).getTime() - new Date(dates.toDate).getTime(),
        ) /
          (1000 * 60 * 60 * 24) +
        1; // millisecond to days calc
      data.onSelectDates(
        new Date(dates.fromDate),
        new Date(dates.toDate),
        range,
      );
    }
  };

  const handleDatesChange = (datesVals: any): void => {
    if (datesVals[0] !== null) {
      setDates((prevState) => ({
        ...prevState,
        fromDate: datesVals[0]?.format('MM/DD/YY').toString(),
      }));
    }
    if (datesVals[1] !== null) {
      setDates((prevState) => ({
        ...prevState,
        toDate: datesVals[1]?.format('MM/DD/YY').toString(),
      }));
    }
  };

  const renderTabs = tabs.map((tab) => (
    <Tab
      key={tab.value}
      className={classes.days}
      value={tab.value}
      iconPosition="start"
      label={tab.label}
      disableRipple
      onClick={
        tab.value === 'custom'
          ? () => {
              setIsDatePickerOpen(!isDatePickerOpen);
            }
          : () => {
              setIsDatePickerOpen(false);
            }
      }
    />
  ));

  return (
    <div className={classes.root}>
      <Box className={classes.root}>
        <div className={classes.daysContainer}>
          {!data.showTabs && (
            <Tabs
              className={classes.headerNavigation}
              sx={{ textTransform: 'none' }}
              value={data.selectedOption}
              onChange={handleChange}>
              {renderTabs}
            </Tabs>
          )}
          <div>
            {isDatePickerOpen && (
              <RangePicker
                onCalendarChange={handleDatesChange}
                suffixIcon=""
                bordered={false}
                open={isDatePickerOpen}
                renderExtraFooter={() => (
                  <button
                    type="button"
                    className={classes.datePickerBtn}
                    onClick={handleUpdateDates}>
                    {`${t('sensorPage.setDate')}`}
                  </button>
                )}
              />
            )}
          </div>
        </div>
      </Box>
    </div>
  );
}
