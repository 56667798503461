import axios from 'axios';
import React, { createContext, useContext, useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { setIsToroAccount } from 'src/reduxToolkit/slices/AccountSlice/account.slice';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { HttpClientService } from 'src/services/HttpClientService';

//  TODO: Refactor to use redux toolkit
export const AuthContext = createContext<{
  isAuthLoading: boolean;
  isAuthenticated: boolean;
  getSetAccessToken: (code: string) => void;
  autoLogin: (token: string) => void;
  signOut: () => void;
}>({
  isAuthenticated: false,
  isAuthLoading: true,
  getSetAccessToken: () => {},
  autoLogin: () => {},
  signOut: () => {},
});

const baseUrl: string = process.env.REACT_APP_SERVER_BASE_URL ?? '';

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const setCookie = useCookies(['token', 'cognito-code'])[1];
  const removeCookie = useCookies(['token', 'cognito-code'])[2];

  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.rootReducer.userState);

  const handleIsToro = useCallback(
    (): any => dispatch(setIsToroAccount(true)),
    [dispatch],
  );

  const handleToroAccountState = useCallback((): any => {
    if (
      userData.ownerAccountId === process.env.REACT_APP_TORO_OWNER_ACCOUNT_ID
    ) {
      handleIsToro();
    }
  }, [userData.ownerAccountId, handleIsToro]);

  const getSetAccessToken = async (authCode: string): Promise<void> => {
    try {
      const { data } = await HttpClientService.post<{ accessToken: string }>(
        `${baseUrl}/auth/login`,
        { authCode },
      );
      // Server always returns 200 so check manually for token
      if (data?.accessToken) {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${data.accessToken}`,
        };
        setCookie('token', data.accessToken, {
          path: '/',
          maxAge: 60 * 60 * 24, // Day
        });
        setIsAuthLoading(false);
        setIsAuthenticated(true);
        handleToroAccountState();
      } else {
        console.error('Authentication failed');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const autoLogin = (token: string): void => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    setIsAuthLoading(false);
    setIsAuthenticated(true);
    handleToroAccountState();
  };

  const signOut = (): void => {
    try {
      removeCookie('token', { path: '/' });
      removeCookie('cognito-code', { path: '/' });
      setIsAuthLoading(false);
      setIsAuthenticated(false);
    } catch (err) {
      console.error(err);
    }
  };

  const value = {
    isAuthLoading,
    isAuthenticated,
    getSetAccessToken,
    autoLogin,
    signOut,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
