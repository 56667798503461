import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { smallMaxWidth } from 'src/shared/constants/consts';
import {
  getAllGrowers,
  getBlockIds,
} from 'src/reduxToolkit/thunks/grower_thunk';
import {
  setBlockIds,
  setGrowers,
  setSelectedGrower,
} from 'src/reduxToolkit/slices/GrowerSlice/grower.slice';
import { setSelectedFarm } from 'src/reduxToolkit/slices/FarmSlice/farm.slice';
import greenPlus from 'src/assets/Icons/mainThemeIcons/greenPlus.svg';
import toroPlus from 'src/assets/Icons/toroThemeIcons/toroPlus.svg';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { NavbarState, UserRole } from 'src/shared/constants/enums';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import AddGrowerModal from './GrowerModal/AddGrowerModal/AddGrowerModal';
import AddFarmModal from '../FieldsPage/FarmManagement/FarmModal/AddFarmModal/AddFarmModal';
import EditGrowerModal from './GrowerModal/EditGrowerModal/EditGrowerModal';
import useStyles from './useStyles';
import Modal from '../../shared/components/Modal/Modal';
import ProgressBar from '../../shared/components/ProgressBar';
import notifyService from '../../shared/notification/notification.service';
import messageTypes from '../../consts/messages';
import growerAdapter from '../../reduxToolkit/adapters/GrowersAdapter';
import { DeleteModal } from 'src/shared/components/GenericModal';
import ConfirmationModal from '../../shared/components/Confirmation/ConfirmationModal';
import GrowersTable from './GrowersTable';

function GrowersManagement(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [loadingAction, setActionLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [toggleAddGrowerModal, setToggleAddGrowerModal] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [toggleActiveModal, setToggleActiveModal] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [toggleAddFarmModal, setToggleAddFarmModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const selectedGrower = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { farms } = useAppSelector((state) => state.rootReducer.farmState);
  const growers = useAppSelector(
    (state) => state.rootReducer.growerState.growers,
  );
  const userData = useAppSelector((state) => state.rootReducer.userState);
  const { isAdmin, isAccountAdminAccess, isAgentAdminAccess } = useUserAccess();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const { width } = useWindowDimensions();
  const classes = useStyles();
  const { t } = useTranslation();

  // Close all modals
  const handleCloseModal = (): any => {
    setToggleAddGrowerModal(false);
    setToggleEditModal(false);
    setToggleActiveModal(false);
    setToggleAddFarmModal(false);
    setToggleDeleteModal(false);
  };

  // View a specific grower
  const handleViewGrower = (e: any, recordId: any): any => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const growerIdx = growers.findIndex(
      (grower: any) => grower.id === recordId,
    );
    dispatch(setSelectedGrower(growers[growerIdx]));
    if (farms.length > 0) {
      dispatch(setSelectedFarm(farms[0]));
    }
    navigate(`${NavbarState.GROWER}/${recordId}`);
  };

  // Activate a new specific grower
  const handleOnActivate = async (record: any): Promise<any> => {
    if (record !== undefined) {
      setActivateLoading(true);
      try {
        const activeGrower = await growerAdapter.activateSpecificGrowerById(
          record.id,
        );
        setActivateLoading(!toggleActiveModal);
        const idx = growers.findIndex(
          (growerItem: any) => growerItem.id === record.id,
        );
        if (idx !== -1) {
          const fullUpdatedGrower = { ...growers[idx], ...activeGrower.data };
          const updatedGrowers = [
            ...growers.slice(0, idx),
            fullUpdatedGrower,
            ...growers.slice(idx + 1),
          ];
          dispatch(setGrowers(updatedGrowers));
        }
        handleCloseModal();
        notifyService(
          t('growersManagement.notifyActivateGrowerSuccess'),
          messageTypes.success,
        );
      } catch (error) {
        console.error(error);
      } finally {
        setActivateLoading(false);
      }
    }
  };

  // Delete a specific grower
  const handleDeleteGrower = async (record: any): Promise<any> => {
    if (record !== undefined) {
      setActionLoading(true);
      await growerAdapter
        .deleteSpecificGrowerById(record.id)
        .then(() => {
          const index = growers.findIndex((it) => it.id === record.id);
          const newItems = [
            ...growers.slice(0, index),
            ...growers.slice(index + 1),
          ];
          dispatch(setGrowers(newItems));
          setToggleDeleteModal(false);
          notifyService(
            t('growersManagement.notifyDeleteGrowerSuccess'),
            messageTypes.success,
          );
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  // Open confirmation activation modal for a specific grower
  const handleActivateGrower = async (e: any, recordId: any): Promise<any> => {
    e.preventDefault();
    e.stopPropagation();
    const idx = growers.findIndex((grower: any) => {
      return grower.id === recordId;
    });
    selectedGrower.current = growers[idx];
    setToggleActiveModal(true);
  };

  // Copy grower id number (cognito sub)
  const handleCopyGrowerId = async (
    e: any,
    growerCognitoId: any,
  ): Promise<any> => {
    const copiedText = await navigator.clipboard.writeText(growerCognitoId);
    notifyService(
      t('growersManagement.notifyCopyIdSuccess'),
      messageTypes.success,
    );
    return copiedText;
  };

  // Close add grower modal after save
  const handleOnSave = (): any => {
    setToggleAddGrowerModal(false);
  };

  // Edit specific grower
  const handleEditGrower = async (e: any, updatedGrower: any): Promise<any> => {
    e.preventDefault();
    e.stopPropagation();
    if (updatedGrower !== undefined) {
      setEditLoading(true);
      updatedGrower.id = selectedGrower.current.id;
      selectedGrower.current = updatedGrower;
      const idx = growers.findIndex(
        (grower: any) => grower.id === updatedGrower.id,
      );

      await growerAdapter
        .editGrower(updatedGrower)
        .then((data) => {
          if (data) {
            const updatedGrowers = JSON.parse(JSON.stringify(growers));
            updatedGrowers[idx] = {
              ...growers[idx],
              ...updatedGrower,
            };
            setToggleEditModal(!toggleEditModal);
            dispatch(setGrowers(updatedGrowers));
            notifyService(
              t('growersManagement.notifyUpdateGrowerSuccess'),
              messageTypes.success,
            );
          }
        })
        .finally(() => {
          setEditLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!userData.role || growers.length) return;

    setLoading(true);
    getAllGrowers()
      .then((mGrowers: any) => {
        dispatch(setGrowers(mGrowers.data.growers));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userData.role, dispatch]);

  useEffect(() => {
    if (
      growers.length === 1 &&
      [
        UserRole.GROWER,
        UserRole.RESEARCHER_GROWER,
        UserRole.SCIENTIST,
        UserRole.INSTALLER,
      ].includes(userData.role ?? UserRole.ADMIN) &&
      [userData.sub, userData.growerCognitoId].includes(
        growers[0].growerCognitoId,
      )
    ) {
      handleViewGrower(null, growers[0].id);
    }

    if (growers.length) {
      getBlockIds().then((res: { data: { blockIds: any } }) => {
        let obj = {} as { [index: number]: number[] };
        for (let grower of res.data.blockIds) {
          obj[grower.growerId] = grower.blockIds;
        }
        dispatch(setBlockIds(obj));
      });
    }
  }, [growers]);

  const openEditGrowerModal = (e: any, recordId: any): any => {
    e.preventDefault();
    e.stopPropagation();
    const idx = growers.findIndex((grower: any) => {
      return grower.id === recordId;
    });
    selectedGrower.current = growers[idx];
    setToggleEditModal(true);
  };

  const handleAddNewFarm = (e: any, recordId: any): any => {
    e.preventDefault();
    e.stopPropagation();
    const selectedGrowerIdx = growers.findIndex((grower: any) => {
      return grower.id === recordId;
    });
    if (selectedGrowerIdx !== -1) {
      selectedGrower.current = growers[selectedGrowerIdx];
      dispatch(setSelectedGrower(growers[selectedGrowerIdx]));
      setToggleAddFarmModal(true);
    }
  };

  const renderAddFarmModal = (): any => {
    if (toggleAddFarmModal) {
      return (
        <div className={classes.modalOverlay}>
          <Modal isOpen={toggleAddFarmModal} onCancel={handleCloseModal}>
            <AddFarmModal
              onClose={handleCloseModal}
              grower={selectedGrower.current}
            />
          </Modal>
        </div>
      );
    }
    return null;
  };

  const renderEditModal = (): any => {
    if (toggleEditModal) {
      return (
        <Modal isOpen={toggleEditModal} onCancel={handleCloseModal}>
          <EditGrowerModal
            growers={growers}
            record={selectedGrower.current}
            onClose={handleCloseModal}
            handleEditGrower={handleEditGrower}
            loading={editLoading}
          />
        </Modal>
      );
    }
    return null;
  };

  const renderActivateModal = (): any => {
    if (toggleActiveModal) {
      return (
        <Modal isOpen={toggleActiveModal} onCancel={handleCloseModal}>
          <ConfirmationModal
            loading={activateLoading}
            record={selectedGrower.current}
            onHandle={handleOnActivate}
            title={t('growersManagement.activationConfirmationTitle')}
            question={t('growersManagement.activationConfirmation')}
            onClose={handleCloseModal}
          />
        </Modal>
      );
    }
    return null;
  };

  const openDeleteModal = (e: any, recordId: any): void => {
    const idx = growers.findIndex((it) => it.id === recordId);
    if (idx >= 0) {
      selectedGrower.current = growers[idx];
      setToggleDeleteModal(true);
    }
  };

  const renderGrowersTable = (): any => {
    if (growers && growers.length > 0) {
      return (
        <GrowersTable
          growers={growers}
          openDeleteModal={openDeleteModal}
          handleViewGrower={handleViewGrower}
          openEditGrowerModal={openEditGrowerModal}
          handleCopyGrowerId={handleCopyGrowerId}
          onAddNewFarm={handleAddNewFarm}
          onActivateGrower={handleActivateGrower}
          searchText={searchText}
        />
      );
    }
    return null;
  };

  const renderDeleteModal = (): any => {
    if (toggleDeleteModal) {
      return (
        <Modal isOpen={toggleDeleteModal} onCancel={handleCloseModal}>
          <DeleteModal
            record={selectedGrower.current}
            onClose={handleCloseModal}
            loading={loadingAction}
            handleDelete={handleDeleteGrower}
          />
        </Modal>
      );
    }
    return null;
  };

  return (
    <section
      className={`flex-column container-inline ${classes.tableContainer}`}>
      <h2 className={`flex align-items justify-center ${classes.header}`}>
        {t('growersManagement.title')}
      </h2>
      <article
        className={
          width > smallMaxWidth
            ? `flex ${classes.btnContainer}`
            : `flex-column align-center justify-center ${classes.mobileBtnContainer}`
        }>
        <div
          className={`flex align-center justify-between ${classes.searchAndSort}`}>
          <article
            className={`flex align-center justify-between container ${classes.searchInput}`}>
            <SearchBar
              searchInputState={setSearchText}
              searchPlaceholder={t('growersManagement.searchPlaceholder')}
            />
          </article>
        </div>
        {(isAdmin || isAccountAdminAccess || isAgentAdminAccess) && (
          <Button
            onClick={() => setToggleAddGrowerModal(true)}
            className={`ant-btn-custom ${classes.userButton}`}>
            {isToroAccount ? (
              <img src={toroPlus} alt="plus" />
            ) : (
              <img src={greenPlus} alt="plus" />
            )}
            {t('growersManagement.addGrower')}
          </Button>
        )}
        {toggleAddGrowerModal && (
          <Modal isOpen={toggleAddGrowerModal} onCancel={handleCloseModal}>
            <AddGrowerModal onClose={handleCloseModal} onSave={handleOnSave} />
          </Modal>
        )}
      </article>
      {!loading && (
        <div className={classes.contentContainer}>{renderGrowersTable()}</div>
      )}
      {loading && <ProgressBar />}
      {renderEditModal()}
      {renderActivateModal()}
      {renderAddFarmModal()}
      {renderDeleteModal()}
    </section>
  );
}

export default GrowersManagement;
