import Button from '@mui/material/Button';
import { formatDateForWeatherObj } from 'src/shared/data/WeatherData';
import DropDown from 'src/shared/components/DropDown';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import greenBackArrow from 'src/assets/Icons/mainThemeIcons/green-back-arrow.svg';
import toroBackArrow from 'src/assets/Icons/toroThemeIcons/toro-back-arrow.svg';
import greenForwardArrow from 'src/assets/Icons/mainThemeIcons/green-forward-arrow.svg';
import toroForwardArrow from 'src/assets/Icons/toroThemeIcons/toro-forward-arrow.svg';
import { useTranslation } from 'react-i18next';
import { useStyles } from './useStyles';

function WeatherOpenState(props: any): JSX.Element {
  const {
    handleOnPrevDate,
    handleOnClick,
    handleOnNextDate,
    disableCounter,
    startDateRange,
    endDateRange,
    isDateRangeDefined,
  } = props;
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const classes = useStyles();
  const startDate = startDateRange && formatDateForWeatherObj(startDateRange);
  const endDate = endDateRange && formatDateForWeatherObj(endDateRange);
  const { t } = useTranslation();

  return (
    <div className={`flex align-center justify-between ${classes.openState}`}>
      <div className="flex align-center justify-center">
        <Button
          id="weekly-weather-previous-week"
          onClick={(e) => handleOnPrevDate(e)}
          disabled={disableCounter === -1}>
          {isToroAccount ? (
            <img
              src={toroBackArrow}
              alt="back"
              id="weekly-weather-previous-week"
              className={classes.backArrow}
            />
          ) : (
            <img
              src={greenBackArrow}
              alt="back"
              id="weekly-weather-previous-week"
              className={classes.backArrow}
            />
          )}
        </Button>
        {isDateRangeDefined && (
          <span className={classes.date}>
            <strong>
              {t(`${startDate.month}`)} {startDate.day}{' '}
            </strong>{' '}
            - {t(`${endDate.month}`)} {endDate.day}
          </span>
        )}
        <Button
          id="weekly-weather-next-week"
          onClick={(e) => {
            handleOnNextDate(e);
          }}
          disabled={disableCounter === 1}>
          {' '}
          {isToroAccount ? (
            <img
              id="weekly-weather-next-week"
              src={toroForwardArrow}
              alt="forward"
              className={classes.forwardArrow}
            />
          ) : (
            <img
              id="weekly-weather-next-week"
              src={greenForwardArrow}
              alt="forward"
              className={classes.forwardArrow}
            />
          )}
        </Button>
      </div>
      <div className={classes.rightSide}>
        <DropDown onClick={handleOnClick} arrowDirection="up" />
      </div>
    </div>
  );
}

export default WeatherOpenState;
