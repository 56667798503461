import { MeasurementSystem } from '../data/types/UserTypes';
import { measurementSystemLabels } from './converter.constants';
import { ConversionActions, ConversionResult } from './converter.types';
import toImperial from './toImperial';
import toMetric from './toMetric';
import {
  DefaultMeasurementUnit,
  Measurement,
  measurementLabel,
  MeasurementSys,
  MeasurementUnit,
} from './measurement-enums/measurement-enums';
import {
  userAreaMeasurementUnit,
  userEtaMeasurementUnit,
  userEvapotranspirationMeasurementUnit,
  userFlowRateMeasurementUnit,
  userHumidityMeasurementUnit,
  userLengthMeasurementUnit,
  userPrecipitationMeasurementUnit,
  userPressureMeasurementUnit,
  userTemperatureMeasurementUnit,
  userVolumeMeasurementUnit,
  userWindSpeedMeasurementUnit,
} from './converter-helper';
import measurementConversion from './measurement-calculations/measurement-calculations';
import { roundPrecision } from '../helpers/HelperFunctions';
import {ActualIrrigation} from "../data/types/blockDataTypes";

export function measurementSystemConvertValue(
  value: number | null | undefined,
  targetMeasurementSystem: MeasurementSystem,
  action: ConversionActions,
): ConversionResult {
  switch (targetMeasurementSystem) {
    case MeasurementSystem.Imperial:
      return toImperial(value, action);
    default:
      return toMetric(value, action);
  }
}

export const userMeasurementUnit = (
  userCountry: string,
  measureSys: MeasurementSys,
  measurement: Measurement,
): any => {
  // switch for each unit type to call on it's function
  switch (measurement) {
    case Measurement.LENGTH:
      return userLengthMeasurementUnit(measureSys);
    case Measurement.VOLUME:
      return userVolumeMeasurementUnit(measureSys);
    case Measurement.AREA:
      return userAreaMeasurementUnit(userCountry, measureSys);
    case Measurement.TEMPERATURE:
      return userTemperatureMeasurementUnit(measureSys);
    case Measurement.PRESSURE:
      return userPressureMeasurementUnit();
    case Measurement.WIND_SPEED:
      return userWindSpeedMeasurementUnit(measureSys);
    case Measurement.HUMIDITY:
      return userHumidityMeasurementUnit();
    case Measurement.PRECIPITATION:
      return userPrecipitationMeasurementUnit(measureSys);
    case Measurement.EVAPOTRANSPIRATION:
      return userEvapotranspirationMeasurementUnit(measureSys);
    case Measurement.ETA:
      return userEtaMeasurementUnit(measureSys);
    case Measurement.FLOW_RATE:
      return userFlowRateMeasurementUnit(userCountry, measureSys);
    default:
      return DefaultMeasurementUnit[measurement];
  }
};

export const convert = (
  originMeasurementUnit: MeasurementUnit,
  targetMeasurementUnit: MeasurementUnit,
  measurement: Measurement,
  value: any,
): any => {
  if (targetMeasurementUnit === originMeasurementUnit) {
    return value;
  }
  return measurementConversion(
    targetMeasurementUnit,
    originMeasurementUnit,
    measurement,
    value,
  );
};

export const convertValue = (
  userCountry: string,
  measureSys: MeasurementSys,
  measurement: Measurement,
  value: any,
): any => {
  const measurementUnit = userMeasurementUnit(
    userCountry,
    measureSys,
    measurement,
  );
  const defaultUnit = DefaultMeasurementUnit[measurement];
  return convert(defaultUnit, measurementUnit, measurement, value);
};

export const convertInvertValue = (
  userCountry: string,
  measureSys: MeasurementSys,
  measurement: Measurement,
  value: any,
): any => {
  const measurementUnit = userMeasurementUnit(
    userCountry,
    measureSys,
    measurement,
  );
  const defaultUnit = DefaultMeasurementUnit[measurement];
  return convert(measurementUnit, defaultUnit, measurement, value);
};

export const convertAndRound = (
  userCountry: string,
  measureSys: MeasurementSys,
  measurement: Measurement,
  value: number | null | undefined,
  precision: number,
): number | null | undefined => {
  const convertedValue = convertValue(
    userCountry,
    measureSys,
    measurement,
    value,
  );
  return roundPrecision(convertedValue, precision);
};

export const convertedLabel = (
  userCountry: string,
  measureSys: MeasurementSys,
  measurement: Measurement,
): string => {
  const measurementUnitIndex = userMeasurementUnit(
    userCountry,
    measureSys,
    measurement,
  );
  if (typeof measurementUnitIndex !== 'number') return 'unknown';

  const labelObject = measurementLabel[measurement];
  if (!labelObject) return 'unknown';

  // Convert the numeric index to a string
  const key = measurementUnitIndex.toString();

  // Access the label using the string key
  const label = labelObject[key as keyof typeof labelObject];
  return label || 'unknown';
};

export function convertLabel(
  userCountry: string,
  measureSys: MeasurementSys,
  measurement: Measurement,
): string {
  return convertedLabel(userCountry, measureSys, measurement);
}

export function systemMeasurement(
  measurementSystem: MeasurementSystem,
): MeasurementSys {
  return measurementSystem === MeasurementSystem.Metric
    ? MeasurementSys.METRIC
    : MeasurementSys.IMPERIAL;
}

export function isNumber(value: any): boolean {
  return (
    value === 0 ||
    Number(value) > 0 ||
    Array.isArray(String(value).match(/[\d.Kk,]/))
  );
}

export const convertDepthToTime = (irrigation_requirement: number, area: number, flowRate: number): number | undefined => {
  if (irrigation_requirement && flowRate) {
    const cubicMeterVal = (irrigation_requirement / 1000) * area; // block area is in m²
    const applicationRatePerBlock = flowRate * (area / 1000);
    return cubicMeterVal / applicationRatePerBlock;
  }
};

export function formatIrrigationTime(time: number, t: any): string {
  const hours = Math.floor(time);
  const minutes = Math.round((time - hours) * 60);

  // Create an array to hold the parts of the time string
  const timeParts: string[] = [];

  // Add hours to the string if greater than zero
  if (hours > 0) {
    timeParts.push(`${hours} ${t('blockDataWidget.hour')}`);
  }

  // Add minutes to the string if greater than zero
  if (minutes > 0) {
    timeParts.push(`${minutes} ${t('blockDataWidget.min')}`);
  }

  // Return the formatted string or a default message if both parts are empty
  return timeParts.length > 0 ? timeParts.join(' ') : '0 ' + t('blockDataWidget.min');
}

export function convertAndFormatDepth(irrigation_requirement: number, area: number, flowRate: number, t: any): string | number  {
  const timeValue = convertDepthToTime(irrigation_requirement, area, flowRate);
  return  timeValue ? formatIrrigationTime(timeValue, t) : 0;
}



export function calculateFlowDuration(start: Date | string, end: Date | string, t: any): string {
  // Ensure both start and end are valid Date objects
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Check if both dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.error('Invalid date format:', { start, end });
    return 'Invalid Date';
  }

  // Calculate the duration in milliseconds
  const durationInMilliseconds = endDate.getTime() - startDate.getTime();

  // Convert to total minutes
  const totalMinutes = Math.floor(durationInMilliseconds / (1000 * 60));

  // Calculate hours and remaining minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Create an array to hold the parts of the duration string
  const durationParts: string[] = [];

  // Add hours to the string if greater than zero
  if (hours > 0) {
    const hourTranslation = t('blockDataWidget.hour');
    durationParts.push(`${hours} ${hourTranslation}`);
  }

  // Add minutes to the string if greater than zero
  if (minutes > 0) {
    const minuteTranslation = t('blockDataWidget.min');
    durationParts.push(`${minutes} ${minuteTranslation}`);
  }

  // Return the formatted string or a default message if both parts are empty
  return durationParts.length > 0 ? durationParts.join(' ') : '0 ' + t('blockDataWidget.min');
}