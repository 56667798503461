export enum ConversionActions {
  CubicCentimetersAndCubicInches,
  SquareCentimetersAndSquareInches,
  litterAndGallonsPerHour,
  litterAndGallonsPerDay,
  CelsiusAndFahrenheit,
  MillimeterAndInches,
  HectaresAndAcres,
  DunamsAndAcres,
  SquareMetersAndAcres,
  SquareMetersToAcresOrHectares,
  SquareMetersAndSquareFeets,
  MetersAndFeet,
  cubicMetersAndGallons,
  MeterPerSecondAndMilePerHour,
  SquareMetersToHectare,
  SquareMetersToDunam,
  HectaresToSquareMeters,
  DunamsToSquareMeters,
  SquareMetersPerHourPerDunamsToGalPerMinPerAcre,
  SquareMetersPerHourPerDunamsToSquareMetersPerHourPerHectare,
}

export interface ConversionResult {
  value?: number | null;
  formattedValue?: string | undefined;
  label: string;
}
