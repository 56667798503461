import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    gap: '5px',
    alignItems: 'baseline',
  },
  etGraphTitleIcon: {},
  text: {
    ...theme.typography.title2,
    color: theme.palette.onSurface.main,
    paddingLeft: 16,
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    '@media (max-width: 767px)': {
      fontSize: 'calc(0.8rem + 0.4vw) !important',
    },
  },
}));

export default useStyles;
