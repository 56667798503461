function SmallProgressBar(): JSX.Element {
  return (
    <div>
      <div className="lds-ellipsis" id="progress-loader">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default SmallProgressBar;
