import React, { useState, useEffect } from 'react';
import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useStyles from './useStyles';
import { Owner } from 'src/shared/data/types/agentOptionsTypes';

interface SelectOwnerProps {
  owners: Owner[];
  onChange: (id: string, email: string, account_id: string) => void;
  selectedOwnerId?: string; // Optional prop for pre-selecting an owner
}

export default function SelectOwner({
  owners,
  onChange,
  selectedOwnerId,
}: SelectOwnerProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filteredOwners, setFilteredOwners] = useState<Owner[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<string | undefined>(
    selectedOwnerId,
  );
  useEffect(() => {
    setFilteredOwners(owners);
  }, [owners]);

  useEffect(() => {
    setSelectedOwner(selectedOwnerId);
  }, [selectedOwnerId]);

  const handleChangeOwner = (value: string): void => {
    const selectedOwner = owners.find((owner) => owner.id === value);
    setSelectedOwner(value);
    if (selectedOwner) {
      onChange(value, selectedOwner.email, selectedOwner.account_id);
    }
  };

  const handleSearch = (value: string): void => {
    const filtered = owners.filter((owner) => {
      const str = `${owner.first_name.toLowerCase()} ${owner.last_name.toLowerCase()} ${owner.email.toLowerCase()}`;
      return str.includes(value.toLowerCase());
    });
    setFilteredOwners(filtered);
  };

  const ownerLabel = (owner: Owner) => {
    return `${owner.first_name} ${owner.last_name} (${owner.email})`;
  };

  return (
    <div
      className="flex-column"
      style={{ width: '100%', marginBottom: '-0.5rem' }}>
      <span id="owner">{t('growerFieldModal.owner')} *</span>
      <Form.Item>
        <Select
          id="owner"
          className={classes.select}
          style={{ width: '100%' }}
          showSearch
          data-hj-allow
          onChange={handleChangeOwner}
          onSearch={handleSearch}
          filterOption={false}
          value={selectedOwner}
          options={filteredOwners.map((owner) => ({
            value: owner.id,
            label: ownerLabel(owner),
          }))}
        />
      </Form.Item>
    </div>
  );
}
