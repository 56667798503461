import { useTranslation } from 'react-i18next';
import { FarmField } from 'src/shared/data/types/farmTypes';
import { Form, Input } from 'antd';
import useStyles from '../useStyles';

const hasError = (data: FarmField): boolean => {
  return !!data.fieldStateTouch && !data.fieldState;
};

export default function TextForm(props: { data: FarmField }): JSX.Element {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const helper = (): string => {
    return `${t('addFarmModal.helper')}`;
  };

  const handleChangeInput = (e: any): any => {
    if (data.setFieldStateString !== undefined) {
      data.setFieldStateString(e.target.value);
    } else if (data.setFieldStateNumber !== undefined) {
      data.setFieldStateNumber(e.target.value);
    }
  };

  const handleBlur = (): void => {
    if (data.setFieldStateTouch) {
      data.setFieldStateTouch(true);
    }
  };

  return (
    <div className={classes.farmTitle}>
      <span>{`${data.title} ${data.isMandatory ? '*' : ''}`}</span>
      <Form.Item
        help={hasError(data) && helper()}
        validateStatus={hasError(data) ? 'error' : ''}>
        <Input
          className={data.title}
          id={data.fieldName}
          placeholder={`${data.title}${data.isMandatory ? '*' : ''}`}
          onChange={(e) => handleChangeInput(e)}
          required={data.isMandatory}
          onBlur={() => handleBlur()}
          type="text"
          data-hj-allow
        />
      </Form.Item>
    </div>
  );
}
