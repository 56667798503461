import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { NavbarState, UserRole } from 'src/shared/constants/enums';

function RoutesManager(): JSX.Element {
  const userData = useAppSelector((state) => state.rootReducer.userState);
  const navigate = useNavigate();

  useEffect(() => {
    const rootPath = '/';
    const pathname = window.location.pathname;

    if (pathname.includes('/fields') || pathname.includes('/sensors'))
      navigate(NavbarState.GROWER);

    if (pathname === rootPath) {
      if (userData.email && !userData.growerCognitoId) navigate('/connection');
      navigate(NavbarState.GROWER);
    }
  }, [userData.growerCognitoId, navigate, userData.email]);

  return <></>;
}

export default RoutesManager;
