import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: '500',
    fontSize: 'calc(1.2rem + 0.8vh)',
    color: theme.palette.darkGrey.main,
  },
  btnActionContainer: {
    display: 'flex',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },
  subtitle: {
    fontWeight: '500',
    fontSize: 'calc(1.5rem + 0.5vh)',
    color: theme.palette.oldPrimary.main,
  },
  subtitleBold: {
    fontWeight: '500',
    fontSize: 'calc(1rem + 0.5vh)',
    paddingBottom: '1rem',
    color: theme.palette.oldPrimary.main,
  },
  select: {
    width: '100%',
    border: '1px solid #76778673',
    borderRadius: '4px',
    '&:focus, &:hover': {
      boxShadow: '0 0 3px #76778673 !important',
      border: 'inherit',
      borderRadius: '4px',
    },
    '&:focus, &:active, &:hover': {
      border: '1px solid #76778673 !important',
      borderRadius: '4px',
    },
  },
  '&.ant-select-focused': {
    '&.ant-select-selector, &:focus, &:active, &:hover, &.ant-select-open .ant-select-selector':
      {
        border: '1px solid #76778673 !important',
        borderRadius: '4px',
      },
  },
  '& .ant-select-item': {
    '&:hover': {
      backgroundColor: '#76778673 !important',
    },
  },
  '& .ant-select-item-option-active': {
    '&:hover': {
      backgroundColor: '#76778673 !important',
    },
  },
  btnContainerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '1rem',
  },
  tableContainer: {
    maxWidth: '100vw',
    width: '100%',
    paddingBlock: '1.5%',
  },
  contentContainer: {
    width: '100%',
  },
  userButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.5rem',
    width: '18rem',
    marginLeft: 'auto',
    backgroundColor: 'transparent',
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    color: theme.palette.accent.main,
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    '& img': {
      marginRight: '4%',
      fill: theme.palette.accent.main,
    },
    '&:hover': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: theme.palette.accent.main,
    },
    '@media (min-width: 320px) and (max-width: 900px)': {
      margin: 'auto',
    },
  },
  header: {
    color: theme.palette.darkGrey.main,
    width: '100%',
    fontWeight: '500',
    fontSize: 'clamp(24px, 30px, 36px)',
    borderBottom: `solid 1px ${theme.palette.hoverGrey.main}`,
  },
  btnContainer: {
    width: '100%',
    marginBlock: '3% 0',
  },
  btnContainerSelect: {
    width: '30%',
    minWidth: '220px',
  },
  treeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  treeWrapper: {
    borderRight: '1px solid #bfbfbf',
    marginRight: '0.5rem',
    width: '50%',
    border: '1px solid rgb(220, 222, 226)',
    padding: '1rem',
    position: 'relative',
    boxShadow: 'rgba(160, 189, 163, 0.1) 0px 15px 50px',
    minHeight: 'fit-content',
    borderRadius: '20px',
    backgroundColor: 'rgb(255, 255, 255)',
    maxHeight: 'calc(100vh - 341px)',
    overflowY: 'auto',
  },
  treeItemPreview: {
    width: '50%',
  },
  item: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableBtn: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
    background: 'transparent',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  treeItemPreviewContainer: {
    width: '100%',
    border: '1px solid rgb(220, 222, 226)',
    padding: '1rem',
    boxShadow: 'rgba(160, 189, 163, 0.1) 0px 15px 50px',
    minHeight: 'fit-content',
    borderRadius: '20px',
    backgroundColor: 'rgb(255, 255, 255)',
    position: 'relative',
  },
}));

export default useStyles;
