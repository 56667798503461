import { useEffect, useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Protected from 'src/shared/components/Protected';
import { defaultLocale, smallMaxWidth } from 'src/shared/constants/consts';
import { ThemeProvider } from '@mui/material/styles';
import toroTheme from 'src/theme/toroTheme';
import mainTheme from 'src/theme/mainTheme';
import i18next from 'i18next';
import { setUserLanguage } from 'src/reduxToolkit/slices/LanguageSlice/language.slice';
import { NavbarState } from 'src/shared/constants/enums';
import { useWindowDimensions } from './shared/hooks/useWindowDimensions';
import Navbar from './shared/components/Navbar/Navbar';
import routes from './routes';
import { useAppDispatch, useAppSelector } from './reduxToolkit/store/hooks';
import {
  setCreationInProgress,
  setIsInEditMode,
  setNumOfClicksOnMapDuringCreation,
} from './reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';

function App(): any {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isNewBlockCreationInProgress = useAppSelector(
    (state) =>
      state.rootReducer.addOrEditBlockState.isNewBlockCreationInProgress,
  );
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const userData = useAppSelector((state) => state.rootReducer.userState);
  const { width } = useWindowDimensions();

  // this function flip isBlockCreationInProgress state with the add Block Button on the NavBar
  const toggleBlockCreationInProgress = useCallback(() => {
    if (location.pathname !== NavbarState.FIELDS) navigate(NavbarState.FIELDS);
    dispatch(setCreationInProgress(!isNewBlockCreationInProgress));
    dispatch(setNumOfClicksOnMapDuringCreation(0));
    dispatch(setIsInEditMode(false));
  }, [dispatch, isNewBlockCreationInProgress, location.pathname, navigate]);

  useEffect(() => {
    const i18nextLng = localStorage.getItem('i18nextLng');
    localStorage.clear();
    if (i18nextLng) {
      localStorage.setItem('i18nextLng', i18nextLng);
    }
  }, []);
//
  useEffect(() => {
    i18next.on('languageChanged', (lng) => {
      dispatch(setUserLanguage(lng));
    });
    return () => {
      i18next.off('languageChanged');
    };
  }, [dispatch]);

  useEffect(() => {
    if (userData?.role && userData?.measurementSystem) {
      gtag('event', 'userRole', { userRole: userData.role });
      gtag('event', 'userMeasurementSystem', {
        userMeasurementSystem: userData.measurementSystem,
      });
      // @ts-ignore
      if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
          country: userData.country,
          language: localStorage.getItem('i18nextLng') ?? defaultLocale,
          role: userData.role,
        });
      }
    }
  }, [userData.role, userData.measurementSystem]);

  const selectedTheme = isToroAccount ? toroTheme : mainTheme;

  return (
    <ThemeProvider theme={selectedTheme}>
      <div
        className={
          isToroAccount
            ? 'toro-app-container flex-column align-center'
            : 'app-container flex-column align-center'
        }>
        {!location.pathname.includes(NavbarState.CONNECTION) && (
          <Navbar
            navState={location.pathname}
            onButtonClicked={toggleBlockCreationInProgress}
            stateValue={isNewBlockCreationInProgress}
          />
        )}
        <main
          className={
            width <= smallMaxWidth
              ? isToroAccount === true
                ? 'mobile-routes-container flex-column'
                : 'mobile-routes-container flex-column'
              : isToroAccount
              ? 'toro-routes-container flex-column'
              : 'routes-container flex-column'
          }>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                element={<Protected>{route.component}</Protected>}
                path={route.path}
              />
            ))}
          </Routes>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
