import { useMemo } from 'react';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { UserRole } from '../constants/enums';
import { UserAccess } from '../data/types/UserTypes';

export const getUserRole = (role: UserRole | undefined): any => {
  let userRole: UserRole;
  switch (role) {
    case UserRole.ADMIN:
      userRole = UserRole.ADMIN;
      break;
    case UserRole.ACCOUNT_ADMIN:
      userRole = UserRole.ACCOUNT_ADMIN;
      break;
    case UserRole.ACCOUNT_VIEWER:
      userRole = UserRole.ACCOUNT_VIEWER;
      break;
    case UserRole.AGENT_ADMIN:
      userRole = UserRole.AGENT_ADMIN;
      break;
    case UserRole.RESEARCHER_GROWER:
      userRole = UserRole.RESEARCHER_GROWER;
      break;
    case UserRole.AGENT_VIEWER:
      userRole = UserRole.AGENT_VIEWER;
      break;
    case UserRole.GROWER:
      userRole = UserRole.GROWER;
      break;
    case UserRole.INSTALLER:
      userRole = UserRole.INSTALLER;
      break;
    default:
      userRole = UserRole.GROWER;
  }
  return userRole;
};

// Create a custom hook to get the user's access role and other derived information
export const useUserAccess = (): UserAccess => {
  const userData = useAppSelector((state) => state.rootReducer.userState);

  const user = useMemo(
    () => ({
      loggedIn: true,
      userRole: getUserRole(userData?.role),
    }),
    [userData?.role],
  );

  return {
    isViewerAccess:
      user.userRole === UserRole.AGENT_VIEWER ||
      user.userRole === UserRole.ACCOUNT_VIEWER,
    isResearcher: user.userRole === UserRole.RESEARCHER_GROWER,
    isGrowerAccess: user.userRole === UserRole.GROWER,
    isAdmin: user.userRole === UserRole.ADMIN,
    isAccountAdminAccess: user.userRole === UserRole.ACCOUNT_ADMIN,
    isAgentAdminAccess: user.userRole === UserRole.AGENT_ADMIN,
  };
};
