// React
import { useCallback, useEffect, useMemo, useState } from 'react';

// Components
import SensorHeadline from './SensorHeadline';
import SapMenu from './SapMenu';
import DaysOptionsNavigation from './DaysOptionsNavigation';
import SensorChart from './SensorChart';
import SensorCards from './SensorCards';
import { CircularProgress } from '@mui/material';

// Hooks
import { useAppDispatch, useAppSelector } from '../../reduxToolkit/store/hooks';
import useStyles from './useStyles';

// Types & Enums
import { Sensor } from '../../shared/data/types/sensorTypes';
import { MiniBlock, MiniFarm } from '../../shared/data/types/growerFarmsTypes';

// Helpers
import { getDatesByDays, getSensorsByBlockId } from './sensorsChartHelper';
import useWeatherHelper from '../Growers/Grower/FarmMap/useWeatherHelper';

// Redux
import Weather from '../../shared/components/Weather/Weather';

export default function SensorWidget(props: SensorWidgetProp): JSX.Element {
  // props
  const { block, farm } = props;

  // hooks
  const dispatch = useAppDispatch();
  const classes = useStyles();

  // state
  const [loadingData, setLoadingData] = useState(false);
  const [daysOption, setDays] = useState<string>('yesterday');
  const [switchFlipped, setSwitchFlipped] = useState<boolean>(false);
  const [sensorsToShow, setSensorsToShow] = useState<Sensor[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [isDoneSetAll, setIsDoneSetAll] = useState(false);
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);
  const [currentMapCenter, setCurrentMapCenter] = useState<any>(null);
  const [isWeatherBarOpen, setIsWeatherBarOpen] = useState<any>(false);
  // Redux
  const { sensors } = useAppSelector((state) => state.rootReducer.sensorsState);
  const measurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );

  // Helpers
  const {
    currentTemperatureIcon,
    currentTemperatureValue,
    currentTemperatureLabel,
    currentWeather,
  } = useWeatherHelper({
    currentFarm: farm,
    isWeatherBarOpen: true,
    setCurrentMapCenter,
    setIsWeatherBarOpen,
  });

  useEffect(() => {
    currentWeather();
  }, [farm?.id, currentWeather]);

  const fetchAndStoreSensors = useCallback((): void => {
    if (block != null) {
      void (async () => {
        setLoadingData(true);
        const { fromDate, toDate } = getDatesByDays(daysOption);
        const res = await getSensorsByBlockId(
          block.id,
          daysOption,
          fromDate,
          toDate,
          0,
          measurementSystem,
        );
        setLoadingData(false);
        setSensorsToShow(res.sensors);
        setDates(res.dates);
        setIsDoneSetAll(true);
      })();
    }
  }, [daysOption, block, measurementSystem]);

  useEffect(() => {
    if (block != null && daysOption !== 'custom') {
      const sensorsRes = sensors.find(
        (sensorsList) =>
          sensorsList.blockId === block?.id &&
          sensorsList.daysType === daysOption,
      );
      if (sensorsRes !== undefined) {
        setSensorsToShow(sensorsRes.sensors);
        setDates(sensorsRes.dates);
        setIsDoneSetAll(true);
      } else {
        fetchAndStoreSensors();
      }
    }
  }, [block, dispatch, daysOption, sensors, fetchAndStoreSensors]);

  const selectDayHandler = (option: string): void => {
    if (option !== 'custom') {
      setIsDoneSetAll(false);
    }
    setDays(option);
  };

  const switchedSensors = useMemo(() => {
    const res: { [key: string]: boolean } = {};
    sensorsToShow.forEach((sensor) => {
      res[sensor.sensorId] = true;
    });
    return res;
  }, [sensorsToShow]);

  const cardClickHandler = useCallback(
    (sensorIs: string) => {
      switchedSensors[sensorIs] = !switchedSensors[sensorIs];
      setSwitchFlipped(!switchFlipped);
    },
    [switchFlipped, switchedSensors],
  );

  const onWeatherClickHandler = (weatherState: boolean): void => {
    setIsWeatherOpen(weatherState);
  };

  const setCustomDatesSensorsToShow = (
    fromDate: Date,
    toDate: Date,
    range: number,
  ): void => {
    if (block != null) {
      setIsDoneSetAll(false);
      void (async () => {
        const res = await getSensorsByBlockId(
          block.id,
          daysOption,
          fromDate,
          toDate,
          range,
          measurementSystem,
        );
        setSensorsToShow(res.sensors);
        setDates(res.dates);
        setIsDoneSetAll(true);
      })();
    }
  };

  return (
    <div className={classes.graphAndBarContainer}>
      <Weather
        isOpenProp={isWeatherOpen}
        handleClickFunction={onWeatherClickHandler}
        currTempIcon={currentTemperatureIcon}
        currentTemperatureValue={currentTemperatureValue}
        currentTemperatureLabel={currentTemperatureLabel}
      />
      <div className={classes.chartWrapper}>
        {!block || loadingData ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <>
            <SensorHeadline title={block.title} cropName={block.cropType} />
            <SapMenu
              sensors={sensorsToShow}
              dates={dates}
              isWeatherOpen={isWeatherOpen}
            />
            <DaysOptionsNavigation
              onSelect={selectDayHandler}
              selectedOption={daysOption}
              onSelectDates={setCustomDatesSensorsToShow}
              showTabs={isWeatherOpen}
            />
            {isDoneSetAll ? (
              <div className={classes.innerContent}>
                <SensorChart
                  sensors={sensorsToShow}
                  sensorsState={switchedSensors}
                  dates={dates}
                />
                <SensorCards
                  days={daysOption}
                  sensors={sensorsToShow}
                  sensorsStates={switchedSensors}
                  clickHandler={cardClickHandler}
                />
              </div>
            ) : (
              <div className={classes.innerContent} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export interface SensorWidgetProp {
  block: MiniBlock;
  farm: MiniFarm;
}
