import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Button } from 'antd';
import downloadIcon from 'src/assets/Icons/sharedIcons/downloadIcon.svg';
import { SapMenuProps } from 'src/shared/data/types/sensorTypes';
import useStyles from './useStyles';

export default function SapMenu(props: SapMenuProps): JSX.Element {
  const { sensors, dates, isWeatherOpen } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback((): void => {
    const sensorsValues = sensors.map((sensor) =>
      [sensor.sensorId].concat(sensor.values.map((val) => val.toString())),
    );
    const datesData = [[' '].concat(dates)];
    const data = datesData.concat(sensorsValues);
    let CsvString = '';
    data.forEach((rowItem) => {
      rowItem.forEach((colItem) => {
        CsvString += `${colItem},`;
      });
      CsvString += '\r\n';
    });
    CsvString = `data:application/csv,${encodeURIComponent(CsvString)}`;
    const fileElement = document.createElement('A');
    fileElement.setAttribute('href', CsvString);
    fileElement.setAttribute('download', `${t('sensorPage.sensorsData')}.csv`);
    document.body.appendChild(fileElement);
    fileElement.click();
  }, [sensors, dates, t]);

  return (
    <div className={`flex align-center justify-between ${classes.root}`}>
      <p>{t('sensorPage.sapFlow')}</p>
      {!isWeatherOpen && (
        <Button onClick={handleClick} className={classes.iconBtn}>
          <img
            src={`${downloadIcon}`}
            className={classes.iconLarge}
            alt="download"
          />
        </Button>
      )}
    </div>
  );
}
