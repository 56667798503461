import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import '../../GrowersReportsManagement.scss';
import { ConversionActions } from 'src/shared/measurements-converter/converter.types';
import { MeasurementSystem } from 'src/shared/data/types/UserTypes';
import { measurementSystemConvertValue } from 'src/shared/measurements-converter/converter';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { roundPrecision } from 'src/shared/helpers/HelperFunctions';

export default function GrowersReportTableEtaEtc(props: any): JSX.Element {
  const { farmIdx, row, blockIdx } = props;
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );
  const etaEtc: any = [];
  const duplicatedDates: any = [];

  row?.farms[farmIdx].blocks[blockIdx].data?.forEach((data: any) => {
    if (duplicatedDates.includes(data.dateTitle)) {
      return;
    }
    duplicatedDates.push(data.dateTitle);

    let cETa =
      selectedMeasurementSystem === MeasurementSystem.Metric || data.ETa == null
        ? data.ETa
        : measurementSystemConvertValue(
            data.ETa ?? 0,
            selectedMeasurementSystem,
            ConversionActions.MillimeterAndInches,
          ).value;
    let cETc =
      selectedMeasurementSystem === MeasurementSystem.Metric || data.ETc == null
        ? data.ETc
        : measurementSystemConvertValue(
            data.ETc ?? 0,
            selectedMeasurementSystem,
            ConversionActions.MillimeterAndInches,
          ).value;
    const precision: number =
      selectedMeasurementSystem === MeasurementSystem.Metric ? 2 : 3;
    cETa = roundPrecision(cETa, precision);
    cETc = roundPrecision(cETc, precision);
    etaEtc.push(
      <TableCell style={{ borderBottom: 'none !important' }}>
        <TableRow style={{ borderBottom: 'none !important' }}>
          <TableCell style={{ width: 10 }}>
            {cETa !== null ? 'ETa' : ''}
          </TableCell>
        </TableRow>
        <TableRow style={{ borderBottom: 'none !important' }}>
          <TableCell style={{ width: 10 }}>
            {cETa !== null ? cETa : ''}
          </TableCell>
        </TableRow>
      </TableCell>,
    );
  });
  return etaEtc;
}
