import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    height: '100%',
    color: theme.palette.oldPrimary.main,
    gap: '3%',
    '& h1': {
      fontWeight: 400,
      fontSize: 'calc(0.6rem + 0.4vw)',
      color: theme.palette.oldPrimary.main,
      textAlign: 'center',
    },
    '& hr': {
      border: 0,
      height: '1px',
      display: 'block',
      borderTop: '1px solid',
      borderTopColor: theme.palette.oldPrimary.main,
      marginTop: '-0.2rem',
      width: '100%',
      opacity: '0.2',
      '@media (max-width: 1201px)': {
        width: '75%',
      },
    },
  },
  cellsContainer: {
    height: '100%',
    justifyContent: 'space-evenly',
    '@media (max-width: 850.9px)': {
      paddingInline: 'calc(0.4rem + 0.2vw)',
    },
    '@media (max-width: 767.9px)': {
      paddingInline: 'calc(0.1rem + 0.2vw)',
    },
  },
  separationLineWrapper: {
    height: '100%',
  },
  tempCell: {
    width: '100%',
    paddingInline: 'calc(0.4rem + 0.2vw) !important',
    gap: '0.8rem',
    '@media (max-width: 850.9px)': {
      flexDirection: 'column !important',
      paddingInline: '0 !important',
      gap: '0rem !important',
      marginBlock: '0 !important',
      alignItems: 'end',
    },
  },
  maxTemp: {
    margin: 0,
    fontWeight: '600 !important',
    fontSize: 'calc(0.6rem + 0.3vw) !important',
  },
  minTemp: {
    gap: '1rem',
    margin: 0,
    fontWeight: '400 !important',
    fontSize: 'calc(0.6rem + 0.3vw) !important',
  },
}));

export default useStyles;
