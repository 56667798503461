import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { CropTypeProps } from 'src/shared/data/types/formsTypes';
import CropsIcons from 'src/shared/data/CropsIcons';
import useStyles from './useStyles';

function CropType(props: CropTypeProps): JSX.Element {
  const { crop } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const Icon = useMemo(() => {
    if (crop?.name === 'Avocados' || crop?.name === 'Avocado')
      return CropsIcons.Avocado;
    return CropsIcons[crop?.name?.replace(' ', '')] ?? CropsIcons.Other;
  }, [crop]);

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Icon />
      </div>
      {crop?.name === 'TableGrape' ? (
        <div>Table Grape</div>
      ) : crop?.name === 'WineGrape' ? (
        <div>Wine Grape</div>
      ) : crop?.name === 'ForestTree' ? (
        <div>Forest Tree</div>
      ) : crop?.name === 'UrbanTree' ? (
        <div>Urban Tree</div>
      ) : crop?.name === 'oilOlive' ? (
        <div>Oil Olive</div>
      ) : (
        <div>{t(crop?.name)}</div>
      )}
    </div>
  );
}

export default CropType;
