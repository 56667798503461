import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'flex-start',
    width: '100%',
  },
  iconBtn: {
    cursor: 'pointer', // Add pointer cursor for hover effect
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.paddings.xs, // Updated to use theme.spacing
    '&:hover': {
      cursor: 'pointer', // Ensure pointer on hover
    },
  },
  actions: {
    marginLeft: 'auto',
    alignSelf: 'end',
    display: 'flex',
  },
  optionsContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'baseline',
  },
  // New styles added below
  container: {
    backgroundColor: theme.palette.surfaceWhite.main,
    borderRadius: theme.shape.borderRadius,
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.surface.main, // Adjust the color as needed
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.bodySmallRegular,
  },
  blockId: {
    textAlign: 'right',
  },
  infoGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.paddings.s,
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.paddings.m,
  },
  infoItem: {
    flex: 1,
  },
  infoTitle: {
    ...theme.typography.infoMedium,
    color: theme.palette.steel.main,
    lineHeight: '14px',
  },
  infoValue: {
    ...theme.typography.infoRegular,
    color: theme.palette.steel.main,
  },
}));

export default useStyles;