import { useTranslation } from 'react-i18next';
import { useEffect, useState, useCallback } from 'react';
import { Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { updateBlockField } from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import SearchBar from 'src/shared/components/SearchBar';
import mainThemeSensorImageLinkIcon from 'src/assets/Icons/mainThemeIcons/greenSensorImageLink.svg';
import mainThemeSensorLocationLinkIcon from 'src/assets/Icons/mainThemeIcons/greenSensorLocationLink.svg';
import toroThemeSensorImageLinkIcon from 'src/assets/Icons/toroThemeIcons/toroSensorImageLink.svg';
import toroThemeSensorLocationLinkIcon from 'src/assets/Icons/toroThemeIcons/toroSensorLocationLink.svg';
import { FormSensor } from 'src/shared/data/types/formsTypes';
import useStyles from './useStyles';

function AddSensorsForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const { block, unconnectedSensors } = useAppSelector(
    (state) => state.rootReducer.addOrEditBlockState,
  );
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const [rowsData, setRowsData] = useState<any[]>();
  const [searchInput, setSearchInput] = useState('');
  const [searchRowData, setSearchRowData] = useState<any>();
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      title: <span>Imei</span>,
      dataIndex: 'imei',
    },
    {
      title: <span>{t('BlockInfoForm.installer')}</span>,
      dataIndex: 'installer',
    },
    {
      title: <span>{t('BlockInfoForm.links')}</span>,
      dataIndex: 'links',
    },
  ];

  useEffect(() => {
    if (unconnectedSensors.length !== 0) {
      const rows = unconnectedSensors;
      setRowsData(rows);
    }
  }, [unconnectedSensors]);

  useEffect(() => {
    if (searchInput !== '') {
      const res = rowsData?.filter(
        (data) =>
          data.id
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          data.installerName?.toString().includes(searchInput.toLowerCase()) ||
          data.imei
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()),
      );
      setSearchRowData(res);
    }
  }, [searchInput, rowsData]);

  const rowSelectionConfig = {
    columnTitle: <div />,
    selectedRowKeys: block?.sensors,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (searchInput !== '')
        dispatch(
          updateBlockField({
            field: 'sensors',
            value: (block?.sensors ?? []).concat(selectedRowKeys),
          }),
        );
      else
        dispatch(
          updateBlockField({ field: 'sensors', value: selectedRowKeys }),
        );
    },
  };

  const handleClickOnImage = useCallback((url: string): void => {
    window.open(url, 'Image', 'width=resizable=1');
  }, []);

  const handleClickOnLocation = useCallback(
    (lat: number, lng: number): void => {
      const url = `https://maps.google.com/?q=${lat},${lng}`;
      window.open(url);
    },
    [],
  );

  return (
    <div className={classes.addSensorsContainer}>
      <div className={classes.inputTitle}>
        {t('BlockInfoForm.addUnitsToBlock')} {block?.title}
      </div>
      <div className={classes.inputContainer}>
        <SearchBar
          searchPlaceholder={t('BlockInfoForm.searchPlaceholder')}
          searchInputState={setSearchInput}
        />
      </div>
      <section className={classes.tableContainer}>
        {rowsData != null && (
          <Table
            rowSelection={rowSelectionConfig}
            size="middle"
            columns={columns}
            dataSource={(searchInput !== '' && searchRowData
              ? searchRowData
              : rowsData
            ).map((sensor: FormSensor) => ({
              key: sensor.id,
              imei: <span className={classes.cell}>{sensor.imei}</span>,
              installer: (
                <span className={classes.cell}>{sensor.installer}</span>
              ),
              links: (
                <div
                  className={`flex align-center justify-center ${classes.links}`}>
                  {isToroAccount ? (
                    <button
                      type="button"
                      onClick={() => handleClickOnImage(sensor.imgLink)}>
                      <img
                        src={toroThemeSensorImageLinkIcon}
                        alt="sensor Img"
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleClickOnImage(sensor.imgLink)}>
                      <img
                        src={mainThemeSensorImageLinkIcon}
                        alt="sensor Img"
                      />
                    </button>
                  )}

                  {isToroAccount ? (
                    <button
                      type="button"
                      onClick={() =>
                        handleClickOnLocation(sensor.lat, sensor.lng)
                      }>
                      <img
                        src={toroThemeSensorLocationLinkIcon}
                        alt="sensor Img"
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() =>
                        handleClickOnLocation(sensor.lat, sensor.lng)
                      }>
                      <img
                        src={mainThemeSensorLocationLinkIcon}
                        alt="sensor Img"
                      />
                    </button>
                  )}
                </div>
              ),
            }))}
            pagination={false}
          />
        )}
      </section>
    </div>
  );
}

export default AddSensorsForm;
