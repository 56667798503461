import AntModal from 'antd/es/modal';
import { ModalProps } from 'src/shared/data/types/formsTypes';
import greenXIcon from 'src/assets/Icons/mainThemeIcons/greenXIcon.svg';
import toroXIcon from 'src/assets/Icons/toroThemeIcons/toroXIcon.svg';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import useStyles from './useStyles';

function AddEditNewBlockModal(props: ModalProps): JSX.Element {
  const { isOpen, onCancel, children, bodyStyle } = props;
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const classes = useStyles();

  return (
    <AntModal
      className={classes.root}
      title=""
      open={isOpen}
      centered
      footer=""
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
      closeIcon={
        isToroAccount ? (
          <img src={toroXIcon} alt="x-icon" className={classes.closeIcon} />
        ) : (
          <img src={greenXIcon} alt="x-icon" className={classes.closeIcon} />
        )
      }
      bodyStyle={bodyStyle != null ? bodyStyle : {}}
      width="fit-content">
      {children}
    </AntModal>
  );
}

export default AddEditNewBlockModal;
