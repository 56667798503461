import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  GrowerFarmsState,
  MiniBlock,
  MiniFarm,
  MiniGrower,
} from 'src/shared/data/types/growerFarmsTypes';
import { GrowerModal } from '../../../pages/Growers/Grower/Grower';

const initialState: GrowerFarmsState = {
  currentGrower: null,
  modal: null,
};

export const growerFarmsSlice = createSlice({
  name: 'growerFarms',
  initialState,
  reducers: {
    setCurrentGrower(state, action: PayloadAction<MiniGrower | null>) {
      state.currentGrower = action.payload;
    },
    addFarmToGrower(state, action: PayloadAction<MiniFarm>) {
      state.currentGrower?.farms.push(action.payload);
    },
    addBlockToFarm(state, action: PayloadAction<MiniBlock>) {
      state.currentGrower?.farms
        .find((farm) => farm.id === action.payload.farmId)
        ?.blocks.push(action.payload);
    },
    replaceBlock(state, action: PayloadAction<MiniBlock>) {
      const farm = state.currentGrower?.farms.find(
        (farm) => farm.id === action.payload.farmId,
      );
      if (farm) {
        const blockIndex = farm.blocks.findIndex(
          (block) => block.id === action.payload.id,
        );
        farm.blocks[blockIndex] = action.payload;
      }
    },
    removeBlock(state, action: PayloadAction<number>) {
      for (let farm of state.currentGrower?.farms ?? []) {
        const blockIndex = farm.blocks.findIndex(
          (block) => block.id === action.payload,
        );
        if (blockIndex !== -1) {
          farm.blocks.splice(blockIndex, 1);
          break;
        }
      }
    },
    openModal(state, action: PayloadAction<GrowerModal | null>) {
      state.modal = action.payload;
    },
    closeModal(state) {
      state.modal = null;
    },
  },
});

export const {
  setCurrentGrower,
  addFarmToGrower,
  replaceBlock,
  openModal,
  closeModal,
  addBlockToFarm,
  removeBlock,
} = growerFarmsSlice.actions;

export default growerFarmsSlice.reducer;
