import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E4E6EA',
  },
  logoAndForm: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '470px',
    width: '450px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.backgroundGrey.main,
    position: 'relative',
  },
  midContainer: {
    height: '75px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fieldAndBtn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    color: '#CD3E3E',
    fontSize: '12px!important',
    textAlign: 'center',
  },
  textField: {
    width: '185px',
    height: '35px',
    borderRadius: '4px',
    border: 'none',
    textAlign: 'center',
  },
  connectBtn: {
    width: '90px',
    height: '40px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: 'lightgreen',
    color: theme.palette.backgroundGrey.main,
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: '30px!important',
    fontWeight: '700!important',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '35%',
    width: '80%',
    position: 'absolute',
    top: '64px',
  },
  disclaimer: {
    textAlign: 'center',
    fontSize: '16px!important',
    fontWeight: '400',
    position: 'absolute',
    bottom: '35px',
    width: '320px',
  },
}));

export default useStyles;
