import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  blockData: {
    paddingBlock: '0.5rem',
    gap: '0.4rem',
    flex: 1,
    boxSizing: 'border-box' /* Include padding in total width */,
  },
  blockInfoCard: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    transition: 'width 0.3s ease-in-out',
    padding: '1rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  closedBlockInfoCard: {
    backgroundColor: '#fff',
    height: '100%',
    flex: 1,
  },
  closedBlockInfoTitle: {
    height: '100%',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    '& .ant-typography': {
      transform: 'rotate(90deg)',
      fontSize: 'calc(1rem + 0.5vh)',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      margin: '0',
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  openBlockInfoCard: {
    backgroundColor: '#fff',
    width: '100%',
    gap: '1rem',
  },
  openBlockInfoTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.oldPrimary.main,
    fontSize: 'calc(0.5rem + 0.4vw)',
    fontWeight: 700,
  },
  EtCards: {
    flex: 1,
  },
  ETConsumptionGraph: {
    flex: 3,
    height: '100%',
  },
  noEtData: {
    color: theme.palette.oldPrimary.main,
    fontSize: 'calc(1vw + 1vh + 1vmin + 1.5rem) !important',
    margin: 'auto',
    fontFamily: 'Rubik, sans-serif !important',
  },
}));

export default useStyles;
