import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import logo from 'src/assets/Icons/mainThemeIcons/logoWithName.svg';
import { updateUserGrowerId } from 'src/reduxToolkit/thunks/user_thunks';
import { useAppDispatch, useAppSelector } from '../../reduxToolkit/store/hooks';
import useStyles from './useStyles';

function ConnectionPage(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [groupId, setGroupId] = useState('');
  const hasGrowerId = useAppSelector(
    (state) => state.rootReducer.farmState.hasGrowerId,
  );
  const token = useAppSelector((state) => state.rootReducer.tokenState.token);
  const [connectBtnClicked, setConnectBtnClicked] = useState(false);
  const { t } = useTranslation();

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setGroupId(event.target.value);
  };

  const onConnect = useCallback(async (): Promise<void> => {
    setConnectBtnClicked(true);
    await dispatch(updateUserGrowerId({ token, growerCognitoId: groupId }));
  }, [dispatch, token, groupId]);

  return (
    <div className={classes.root}>
      <div className={classes.logoAndForm}>
        <img src={logo} height="60px" alt="t2s logo" id="t2s-logo" />
        <div className={classes.innerContainer}>
          <form className={classes.form}>
            <Typography className={classes.title}>
              {t('connectionPage.title')}
            </Typography>
            <div className={classes.midContainer}>
              <Typography style={{ fontSize: '20px', fontWeight: '400' }}>
                {' '}
                {t('connectionPage.instruction')}
              </Typography>
              <div className={classes.fieldAndBtn}>
                <input
                  className={classes.textField}
                  id="login-input-field"
                  type="text"
                  value={groupId}
                  onChange={inputHandler}
                  placeholder={`${t('connectionPage.inputIdPlaceholder')}`}
                  required
                />
                <button
                  className={classes.connectBtn}
                  id="login-submit-button"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    void (async () => {
                      await onConnect();
                    })();
                  }}
                  disabled={groupId === ''}>
                  {t('connectionPage.connectBtn')}
                </button>
              </div>
              {connectBtnClicked && !hasGrowerId && (
                <Typography className={classes.error}>
                  {/* {t('connectionPage.idErrorHelper')} */}
                </Typography>
              )}
            </div>
          </form>
          <Typography className={classes.disclaimer}>
            {t('connectionPage.disclaimer')}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ConnectionPage;
