import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { smallMaxWidth } from 'src/shared/constants/consts';
import { getAllAccounts } from 'src/reduxToolkit/thunks/account_thunks';
import { setAccounts } from 'src/reduxToolkit/slices/AccountSlice/account.slice';
import greenPlus from 'src/assets/Icons/mainThemeIcons/greenPlus.svg';
import toroPlus from 'src/assets/Icons/toroThemeIcons/toroPlus.svg';
import { Button } from 'antd';
import { DeleteModal } from "src/shared/components/GenericModal";
import { useDispatch } from 'react-redux';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';
import AccountsList from './accountsList';
import AddAccountModal from './AccountModal/AddAccountModal/AddAccountModal';
import EditAccountModal from './AccountModal/EditAccountModal/EditAccountModal';
import useStyles from './useStyles';
import ProgressBar from '../../shared/components/ProgressBar';
import notifyService from '../../shared/notification/notification.service';
import messageTypes from '../../consts/messages';
import Modal from '../../shared/components/Modal/Modal';
import accountsAdapter from '../../reduxToolkit/adapters/AccountsAdapter';

function AccountsManagement(): JSX.Element {
  const [toggleModal, setToggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setActionLoading] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const selectedAccount = useRef<any>(null);
  const dispatch = useDispatch();
  const accounts = useAppSelector(
    (state) => state.rootReducer.accountState.accounts,
  );
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const { width } = useWindowDimensions();
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getAllAccounts().then((mAccounts: any) => {
      dispatch(
        setAccounts({
          accounts: mAccounts.data,
          total_accounts: mAccounts.data.length,
        }),
      );
      setLoading(false);
    });
  }, [dispatch]);

  const handleCloseModal = (): any => {
    setToggleModal(false);
    setToggleEditModal(false);
    setToggleDeleteModal(false);
  };

  const handleDelete = async (record: any): Promise<any> => {
    if (record !== undefined) {
      setActionLoading(true);
      await accountsAdapter
        .deleteSpecificAccountById(record.id)
        .then(() => {
          const index = accounts.accounts.findIndex(
            (it) => it.id === record.id,
          );
          const newItems = [
            ...accounts.accounts.slice(0, index),
            ...accounts.accounts.slice(index + 1),
          ];
          dispatch(
            setAccounts({
              accounts: newItems,
              total_accounts: accounts.total_accounts - 1,
            }),
          );
          setToggleDeleteModal(false);
          notifyService(
            `${selectedAccount.current.title} ${t(
              'accountsManagement.notifyDeleteAccountsSuccess',
            )}`,
            messageTypes.success,
          );
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  const handleCopy = async (e: any, accountCognitoId: any): Promise<any> => {
    const copiedText = await navigator.clipboard.writeText(accountCognitoId);
    notifyService(
      `${t('accountsManagement.notifyCopyIdSuccess')}`,
      messageTypes.success,
    );
    return copiedText;
  };

  const handleOnSave = (newAccount: any): any => {
    setToggleModal(false);
    const updatedAccounts = JSON.parse(JSON.stringify(accounts));
    updatedAccounts.accounts.push(newAccount);
    dispatch(setAccounts(updatedAccounts));
  };

  const handleOnEdit = async (e: any, updatedAccount: any): Promise<any> => {
    e.preventDefault();
    e.stopPropagation();
    if (updatedAccount !== undefined) {
      setActionLoading(true);
      updatedAccount.accountCognitoId =
        selectedAccount.current.accountCognitoId;
      selectedAccount.current = updatedAccount;
      const idx = accounts.accounts.findIndex(
        (account: any) =>
          account.accountCognitoId === updatedAccount.accountCognitoId,
      );
      const fullUpdatedAccount: any = {
        ...accounts.accounts[idx],
        ...updatedAccount,
      };
      const updatedAccounts = JSON.parse(JSON.stringify(accounts));
      updatedAccounts.accounts[idx] = fullUpdatedAccount;
      await accountsAdapter
        .editSpecificAccountById({
          id: fullUpdatedAccount.id,
          ...updatedAccount,
        })
        .then(() => {
          dispatch(setAccounts(updatedAccounts));
          notifyService(
            `${selectedAccount.current.title} ${t(
              'accountsManagement.notifyUpdateAccountsSuccess',
            )}`,
            messageTypes.success,
          );
          setToggleEditModal(!toggleEditModal);
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  const openEditModal = (e: any, recordId: any): any => {
    e.preventDefault();
    e.stopPropagation();
    const idx = accounts.accounts.findIndex((account: any) => {
      return account.accountCognitoId === recordId;
    });
    selectedAccount.current = accounts.accounts[idx];
    setToggleEditModal(true);
  };

  const handleDeleteHandler = (e: any, recordId: any): void => {
    e.preventDefault();
    e.stopPropagation();
    const idx = accounts.accounts.findIndex((it) => it.id === recordId);
    if (idx >= 0) {
      selectedAccount.current = accounts.accounts[idx];
      setToggleDeleteModal(true);
    }
  };

  const renderEditModal = (): any => {
    if (toggleEditModal) {
      return (
        <Modal isOpen={toggleEditModal} onCancel={handleCloseModal}>
          <EditAccountModal
            accounts={accounts.accounts}
            record={selectedAccount.current}
            loading={loadingAction}
            onEdit={handleOnEdit}
            onClose={handleCloseModal}
          />
        </Modal>
      );
    }
    return null;
  };

  const renderDeleteModal = (): any => {
    if (toggleDeleteModal) {
      return (
        <Modal isOpen={toggleDeleteModal} onCancel={handleCloseModal}>
          <DeleteModal
              accounts={accounts.accounts}
              record={selectedAccount.current}
              loading={loadingAction}
              handleDelete={handleDelete}
              onClose={handleCloseModal}
          />
        </Modal>
      );
    }
    return null;
  };

  const renderAccountsList = (): any => {
    if (accounts.accounts && accounts.accounts.length > 0) {
      return (
        <AccountsList
          accounts={accounts.accounts}
          handleDelete={handleDeleteHandler}
          onEdit={openEditModal}
          onClose={handleCloseModal}
          onCopy={handleCopy}
          searchText={searchText}
        />
      );
    }
    return null;
  };

  return (
    <section
      className={`flex-column container-inline ${classes.tableContainer}`}>
      <h2 className={`flex align-items justify-center ${classes.header}`}>
        {t('accountsManagement.title')}
      </h2>
      <article
        className={
          width > smallMaxWidth
            ? `flex ${classes.btnContainer}`
            : `flex-column align-center justify-center ${classes.mobileBtnContainer}`
        }>
        <div
          className={`flex align-center justify-between ${classes.searchAndSort}`}>
          <article
            className={`flex align-center justify-between container ${classes.searchInput}`}>
            <SearchBar
              searchInputState={setSearchText}
              searchPlaceholder={t('accountsManagement.searchPlaceholder')}
            />
          </article>
        </div>
        <Button
          onClick={() => setToggleModal(true)}
          className={`ant-btn-custom ${classes.userButton}`}>
          {isToroAccount ? (
            <img src={toroPlus} alt="plus" />
          ) : (
            <img src={greenPlus} alt="plus" />
          )}
          {t('accountsManagement.addAccount')}
        </Button>
        {toggleModal && (
          <Modal isOpen={toggleModal} onCancel={handleCloseModal}>
            <AddAccountModal
              toggleModal={toggleModal}
              onClose={handleCloseModal}
              onSave={handleOnSave}
              accounts={accounts.accounts}
            />
          </Modal>
        )}
      </article>
      {!loading && (
        <div className={classes.contentContainer}>{renderAccountsList()}</div>
      )}
      {loading && <ProgressBar />}
      {renderEditModal()}
      {renderDeleteModal()}
    </section>
  );
}

export default AccountsManagement;
