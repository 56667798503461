import { createAsyncThunk } from '@reduxjs/toolkit';
import { BlockData } from '../../shared/data/types/blockDataTypes';
import blockDataAdapter from '../adapters/BlockDataAdapter';

const getBlockData = createAsyncThunk<
  BlockData,
  { id: any },
  {
    rejectValue: Error;
  }
>(
  'block-data',
  async ({ id }, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await blockDataAdapter.getBlockData(id);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export default getBlockData;
