import { DrawingManager, Polygon } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import { BlockPolygonsProps } from 'src/shared/data/types/mapTypes';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { setCurrentBlock } from 'src/reduxToolkit/slices/BlockSlice/block.slice';
import {
  setCreationInProgress,
  setNewPolygonAreaAfterEdit,
  setNewPolygonPathAfterEdit,
  setNewPolygonPathBeforeEdit,
  setResetPolygonPathAfterEdit,
  setSavePolygonPathAfterEdit,
} from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { polygonSettingsBuilder } from './config';
import calcNewPolygonTemporaryPath from './blockPolygon-helper';
import { MiniBlock } from '../../../shared/data/types/growerFarmsTypes';
import { openModal } from '../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';
import { GrowerModal } from '../../Growers/Grower/Grower';
import { useSearchParams } from 'react-router-dom';

function BlockPolygons(props: BlockPolygonsProps): JSX.Element {
  const data = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const [drawingControl, setDrawingControl] = useState(true);
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [drawingType, setDrawingType] =
    useState<google.maps.drawing.OverlayType | null>(null);
  // States from slices
  const resetPolygonPathAfterEdit = useAppSelector(
    (state) => state.rootReducer.addOrEditBlockState.resetPolygon,
  );
  const savePolygonPathAfterEdit = useAppSelector(
    (state) => state.rootReducer.addOrEditBlockState.savePolygon,
  );
  const isNewBlockCreationInProgress = useAppSelector(
    (state) =>
      state.rootReducer.addOrEditBlockState.isNewBlockCreationInProgress,
  );
  const selectedFarm = useAppSelector(
    (state) => state.rootReducer.farmState.selectedFarm,
  );

  // This function changing the selected block state in click event
  function handleClickOnPolygon(blockId: number): void {
    navToBlock(blockId);
  }

  const navToBlock = (blockId: number): void => {
    setSearchParams({ blockId: blockId.toString() });
  };

  useEffect(() => {
    if (savePolygonPathAfterEdit) {
      dispatch(setCreationInProgress(false));
      dispatch(
        setNewPolygonPathBeforeEdit(
          calcNewPolygonTemporaryPath({
            lat: selectedFarm?.centerLat ?? 0,
            lng: selectedFarm?.centerLng ?? 0,
          }),
        ),
      );
      dispatch(openModal(GrowerModal.createBlock));
      dispatch(setSavePolygonPathAfterEdit(false));
      dispatch(setResetPolygonPathAfterEdit(false));
    }
  }, [
    dispatch,
    savePolygonPathAfterEdit,
    selectedFarm?.centerLat,
    selectedFarm?.centerLng,
    polygon,
  ]);

  useEffect(() => {
    if (resetPolygonPathAfterEdit) {
      polygon?.setPath([]);
      setDrawingType(null);
      setDrawingControl(true);
      dispatch(setResetPolygonPathAfterEdit(false));
      dispatch(setNewPolygonAreaAfterEdit(0));
    } else {
      setDrawingType(google.maps.drawing.OverlayType.POLYGON);
    }
  }, [dispatch, resetPolygonPathAfterEdit, polygon]);

  // set New Polygon Path to the calculated temporary path when polygon creation is initiated
  useEffect(() => {
    dispatch(
      setNewPolygonPathBeforeEdit(
        calcNewPolygonTemporaryPath({
          lat: selectedFarm?.centerLat ?? 0,
          lng: selectedFarm?.centerLng ?? 0,
        }),
      ),
    );
  }, [dispatch, selectedFarm?.centerLat, selectedFarm?.centerLng]);

  const onLoad = (): void => {
    setDrawingControl(true);
  };

  const onPolygonComplete = (polygonInternal: google.maps.Polygon): void => {
    const nextPath: google.maps.LatLngLiteral[] = polygonInternal
      .getPath()
      .getArray()
      .map((latLng: any) => ({ lat: latLng.lat(), lng: latLng.lng() }));
    setDrawingControl(false);
    setDrawingType(null);
    dispatch(setNewPolygonPathBeforeEdit(nextPath));
    dispatch(setNewPolygonPathAfterEdit(nextPath));
    setPolygon(polygonInternal);
    const zoneArea = google.maps.geometry.spherical.computeArea(nextPath);
    dispatch(setNewPolygonAreaAfterEdit(zoneArea));
  };

  useEffect(() => {
    setDrawingControl(isNewBlockCreationInProgress);
    if (!isNewBlockCreationInProgress) {
      dispatch(setResetPolygonPathAfterEdit(true));
    }
  }, [isNewBlockCreationInProgress, dispatch]);

  useEffect(() => {
    if (isNewBlockCreationInProgress && drawingControl) {
      setDrawingType(google.maps.drawing.OverlayType.POLYGON);
    } else {
      setDrawingType(null);
      polygon?.setPath([]);
      setDrawingControl(true);
      dispatch(setResetPolygonPathAfterEdit(false));
    }
  }, [isNewBlockCreationInProgress, drawingControl, polygon, dispatch]);

  return (
    <>
      <div>
        {data.blocksPolygons.map(
          (polygonItem): JSX.Element => (
            <Polygon
              path={polygonItem.polygon}
              key={
                polygonItem.polygon[0].lat.toString() +
                polygonItem.polygon[0].lng.toString()
              }
              options={polygonSettingsBuilder(polygonItem)}
              onClick={() => handleClickOnPolygon(polygonItem.id)}
            />
          ),
        )}
      </div>
      <DrawingManager
        drawingMode={drawingType}
        onLoad={onLoad}
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: data.drawModeIndicator && drawingControl,
          drawingControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
            drawingModes: drawingType
              ? [google.maps.drawing.OverlayType.POLYGON]
              : [],
          },
        }}
      />
    </>
  );
}

export default BlockPolygons;
