import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  modalContentContainer: {
    alignItems: 'center',
    minWidth: '70vw !important',
    zIndex: 1000,
  },
  reportsModal: {
    minWidth: '85vw',
    maxWidth: '90vw',
    minHeight: '60vh',
    maxHeight: '90vh',
    overflow: 'hidden',
    marginTop: '0.5rem',
    gap: '5%',
    paddingBlock: '3%',
    paddingInline: '2%',
    borderRadius: '4px',
    marginRight: '1.5rem',
    paddingBottom: '1.5rem',
    '& p': {
      margin: 'unset',
      fontSize: '1.8rem',
    },
    '@media (max-width: 767px)': {
      marginRight: 0,
    },
  },
  header: {
    fontFamily: `"Rubik", sans-serif !important`,
    fontWeight: 'bold',
    fontSize: '1.5rem',
    borderBottom: '1px solid',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.grey.A400,
  },
  titleDownloadContainer: {
    minWidth: '100%',
    height: 'fit-content',
    '& p': {
      margin: '2rem auto 0 auto !important',
    },
  },
  btnContainer: {
    justifyContent: 'space-between !important',
    gap: '0.5rem !important',
    textAlign: 'center',
    position: 'relative',
    width: '100%',
  },
  warning: {
    fontFamily: `"Rubik", sans-serif !important`,
    color: theme.palette.oldPrimary.main,
    margin: '0 !important',
    position: 'absolute',
    top: '0.3rem',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  exportAllBtn: {
    fontFamily: `"Rubik", sans-serif !important`,
    position: 'absolute',
    top: '1.3rem',
    backgroundColor: 'transparent',
    gap: '1rem',
    color: theme.palette.oldPrimary.main,
    margin: '0.5rem 0 0.8rem 0 !important',
    padding: '0.5rem 1rem !important',
    outline: 'none',
    border: `1px solid ${theme.palette.accent.main} !important`,
    borderRadius: '4px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontSize: 'calc(0.8rem + 0.4vw) !important',
    textTransform: 'none',
    '&:hover, &:active': {
      '&:not([disabled])': {
        border: `solid 1px ${theme.palette.accent.main} !important`,
        color: '#fff',
        fontWeight: '500',
        backgroundColor: `${theme.palette.accent.main} !important`,
        '& > span img': {
          filter: 'brightness(0) invert(1) opacity(0.85) !important',
        },
      },
    },
    '&:hover:enabled': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: `${theme.palette.accent.main} !important`,
      '& > span img': {
        filter: 'brightness(0) invert(1) opacity(0.85) !important',
      },
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: '#fff',
      pointerEvents: 'none',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(12px, 14px, 18px)',
    },
  },
  reportsTableContainer: {
    width: '100%',
    height: '100%',
    paddingBlock: '1rem 0.5rem',
    fontFamily: `"Rubik", sans-serif !important`,
    '& .MuiPaper-elevation1': {
      boxShadow: 'none !important',
    },
  },
  collapsibleTable: {
    fontFamily: `"Rubik", sans-serif !important`,
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    maxHeight: '24rem',
    width: '100%',
    overflow: 'auto',
  },
  tableHeader: {
    fontFamily: `"Rubik", sans-serif !important`,
    fontSize: 'calc(0.5rem + 0.4vw) !important',
    padding: '0.2rem 1.5rem !important',
    width: '3rem !important',
    // maxWidth: "3rem !important",
    whiteSpace: 'nowrap',
  },
  tableHeaderColumn: {
    fontFamily: `"Rubik", sans-serif !important`,
    padding: '0.2rem 1rem !important',
    lineHeight: '1.2rem',
    width: '3rem !important',
    maxWidth: '3rem !important',
    fontSize: 'calc(0.5rem + 0.4vw) !important',
  },
  weekRangeText: {
    fontFamily: `"Rubik", sans-serif !important`,
    whiteSpace: 'nowrap',
    fontSize: 'calc(0.5rem + 0.4vw) !important',
  },
  expendedTableTitle: {
    fontFamily: `"Rubik", sans-serif !important`,
    whiteSpace: 'nowrap',
    paddingInline: '0.2rem !important',
    width: '3rem !important',
    maxWidth: '3rem !important',
    fontSize: 'calc(0.5rem + 0.4vw) !important',
  },
  loaderWrapper: {
    position: 'relative',
  },
  loaderWrapperOnDownload: {
    position: 'relative',
    margin: '0 auto',
  },
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  loaderContainerOnDownload: {
    position: 'absolute',
    bottom: '-3.5rem',
    left: '-2rem',
  },
}));

export default useStyles;
