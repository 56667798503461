import Tooltip from 'antd/es/tooltip';
import { ReactComponent as Icon } from 'src/assets/Icons/sharedIcons/tooltipIcon.svg';
import { IconToolTipTypesProps } from 'src/shared/data/types/tooltipTypes';

function IconWithToolTip(props: IconToolTipTypesProps): JSX.Element {
  const { text, color = '#676879d9', svgIcon } = props;
  return (
    <Tooltip title={text} placement="top" color="#fff">
      {svgIcon !== undefined ? svgIcon : <Icon color={color} />}
    </Tooltip>
  );
}

export default IconWithToolTip;
