import React, { useCallback, useState } from 'react';
import { Select } from 'antd';
import { useAppDispatch } from 'src/reduxToolkit/store/hooks';
import { setSelectedFarm } from 'src/reduxToolkit/slices/FarmSlice/farm.slice';
import { MiniFarm } from '../../../../shared/data/types/growerFarmsTypes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useStyles from './useStyles';
import GenericIcon from "../../../Growers/Grower/block_data_widget/generic_icon"; // Import the shared styles

const { Option } = Select;

interface FarmDropdownListProps {
    farms: MiniFarm[];
    currentFarm: MiniFarm;
    onItemClicked: () => void;
}

function FarmDropdownList(props: FarmDropdownListProps): JSX.Element {
    const { farms, currentFarm, onItemClicked } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const classes = useStyles(); // Use the styles
    const [dropdownOpen, setDropdownOpen] = useState(false); // Track the open state

    const handleSelectedFarmChange = useCallback(
        (farmId: number) => {
            const selectedFarm = farms.find(farm => farm.id === farmId);
            if (selectedFarm) {
                dispatch(setSelectedFarm(selectedFarm));
                onItemClicked();
                setSearchParams({ farmId: farmId.toString() });
            }
        },
        [dispatch, onItemClicked, setSearchParams, farms]
    );

    const handleDropdownVisibleChange = (open: boolean) => {
        setDropdownOpen(open);
    };

    return (
        <Select
            className={classes.selectBorder} // Add the border style class here
            value={currentFarm?.id}
            onChange={handleSelectedFarmChange}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            dropdownClassName={classes.selectDropdown}
            placeholder="Select a farm"
            suffixIcon={
                dropdownOpen ? (
                    <GenericIcon name="chevron_up" />
                ) : (
                    <GenericIcon name="chevron_down" />
                )
            }
        >
            {farms.map(farm => (
                <Option key={farm.id} value={farm.id}>
                    {farm.title}
                </Option>
            ))}
        </Select>


    );
}

export default FarmDropdownList;
