// Assuming you have access to the translation function `t`

export const dayQtyOptionsActualConsumption = (t: (key: string) => string) => ({
    3: `3 ${t('blockDataWidget.days')}`,
    7: `1 ${t('blockDataWidget.week')}`,
    14: `2 ${t('blockDataWidget.weeks')}`,
    31: `1 ${t('blockDataWidget.month')}`,
    62: `2 ${t('blockDataWidget.months')}`,
});

export const dayQtyOptionsActualIrrigation = (t: (key: string) => string) => ({
    7: `7 ${t('blockDataWidget.days')}`,
    14: `2 ${t('blockDataWidget.weeks')}`,
    31: `1 ${t('blockDataWidget.month')}`,
    91: `3 ${t('blockDataWidget.months')}`,
    182: `6 ${t('blockDataWidget.months')}`,
    365: `1 ${t('blockDataWidget.year')}`,
});

export const dayQtyOptionsIrrigationRequirement = (t: (key: string) => string) => ({
    1: `1 ${t('blockDataWidget.day')}`,
    2: `2 ${t('blockDataWidget.days')}`,
    3: `3 ${t('blockDataWidget.days')}`,
    4: `4 ${t('blockDataWidget.days')}`,
    5: `5 ${t('blockDataWidget.days')}`,
    6: `6 ${t('blockDataWidget.days')}`,
    7: `7 ${t('blockDataWidget.days')}`,
});

