import { useTranslation } from 'react-i18next';
import TextWithTooltip from 'src/shared/components/TextWithTooltip';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import windIcon from 'src/assets/Icons/sharedIcons/wind.svg';
import precipitationIcon from 'src/assets/Icons/sharedIcons/daily_rain.svg';
import humidityIcon from 'src/assets/Icons/sharedIcons/humidity.svg';
import vpdIcon from 'src/assets/Icons/sharedIcons/vpd.svg';
import evapotranspirationIcon from 'src/assets/Icons/sharedIcons/evapotranspiration.svg';
import {
  calculateMaxVPD,
  roundPrecision,
} from 'src/shared/helpers/HelperFunctions';
import { Typography } from '@mui/material';
import {
  WeatherListCellProps,
  WeatherOpenState,
} from 'src/shared/data/types/weatherTypes';
import {
  formatDateForWeatherObj,
  formatDateToISO,
  getTodaysDate,
} from 'src/shared/data/WeatherData';
import getWeatherIcon from 'src/shared/data/WeatherIcons';
import {
  convertAndRound,
  convertLabel,
} from 'src/shared/measurements-converter/converter';
import { renderToString } from 'react-dom/server';
import { MeasurementSystem } from 'src/shared/data/types/UserTypes';
import WeatherListCellTemperature from '../WeatherListCellTemperature';
import WeatherListCell from '../WeatherListCell';
import useStyles from './useStyles';
import {
  Measurement,
  MeasurementSys,
} from '../../../../measurements-converter/measurement-enums/measurement-enums';

export default function WeatherListColumn(
  props: WeatherOpenState,
): JSX.Element {
  const { dateDetails, currentMiniFarm } = props;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );
  const selectedFarmTimezone = currentMiniFarm?.timezone;
  const timezone =
    selectedFarmTimezone ?? new Date().toISOString().split('T')[0];
  // Memoized value of today's date according to the farm's timezone
  const farmTodayDate = useMemo(() => getTodaysDate(timezone), [timezone]);
  // Memoized value of the user's current date
  const userTodayDate = new Date();
  const userTodayFormattedDate = formatDateToISO(userTodayDate);
  // Memoized value of the date from dateDetails converted to ISO string
  const dataDate = useMemo(
    () => new Date(dateDetails.date).toISOString().split('T')[0],
    [dateDetails.date],
  );
  // Formatted current date for display
  const currentDate = formatDateForWeatherObj(dateDetails.date);
  const isFarmUserUtcDateMatch = farmTodayDate === userTodayFormattedDate;
  const classes = useStyles();
  const { t } = useTranslation();
  const weeklyTempIconComponent = getWeatherIcon(dateDetails?.weatherCode);
  const weeklyTempIcon = useMemo(
    () => weeklyTempIconComponent && renderToString(weeklyTempIconComponent),
    [weeklyTempIconComponent],
  );
  const userCountry = useAppSelector(
    (state) => state.rootReducer.userState.country,
  );

  const measureSys =
    selectedMeasurementSystem === MeasurementSystem.Metric
      ? MeasurementSys.METRIC
      : MeasurementSys.IMPERIAL;

  // Daily Rain
  const convertedDailyRainValue = convertAndRound(
    userCountry,
    measureSys,
    Measurement.PRECIPITATION,
    dateDetails?.rainAccumulation,
    measureSys === MeasurementSys.METRIC ? 1 : 2,
  );
  const convertedDailyRainLabel = dateDetails?.rainAccumulation
    ? `${convertLabel(userCountry, measureSys, Measurement.PRECIPITATION)}`
    : '';

  // Wind Speed
  const convertedWindSpeedValue = convertAndRound(
    userCountry,
    measureSys,
    Measurement.WIND_SPEED,
    dateDetails?.wind,
    1,
  );
  const convertedWindSpeedLabel = dateDetails?.wind
    ? `${convertLabel(userCountry, measureSys, Measurement.WIND_SPEED)}`
    : '';

  // Daily ETO
  const convertedDailyEtoValue = convertAndRound(
    userCountry,
    measureSys,
    Measurement.EVAPOTRANSPIRATION,
    dateDetails?.evapotranspiration,
    measureSys === MeasurementSys.METRIC ? 1 : 2,
  );
  const convertedDailyEtoLabel = dateDetails?.evapotranspiration
    ? `${convertLabel(userCountry, measureSys, Measurement.EVAPOTRANSPIRATION)}`
    : '';

  // Max Temperature
  const convertedMaxTempValue = convertAndRound(
    userCountry,
    measureSys,
    Measurement.TEMPERATURE,
    dateDetails?.max_temperature,
    0,
  );
  const convertedMaxTempLabel = dateDetails?.max_temperature
    ? `${convertLabel(userCountry, measureSys, Measurement.TEMPERATURE)}`
    : '';

  // Min Temperature
  const convertedMinTempValue = convertAndRound(
    userCountry,
    measureSys,
    Measurement.TEMPERATURE,
    dateDetails?.min_temperature,
    0,
  );
  const convertedMinTempLabel = dateDetails?.min_temperature
    ? `${convertLabel(userCountry, measureSys, Measurement.TEMPERATURE)}`
    : '';

  const weatherFields = {
    VPD: `${t('weather.vpd')}`,
    HUMIDITY: `${t('weather.humidity')}`,
    DAILY_RAIN: `${t('weather.dailyRain')}`,
    WIND: `${t('weather.wind')}`,
    EVAPOTRANSPIRATION: `${t('weather.dailyEto')}`,
  };

  const vpdValue = calculateMaxVPD(
    dateDetails?.max_temperature,
    dateDetails?.humidity,
  );

  const generateWeatherListCells = (): WeatherListCellProps[] => {
    const cells: WeatherListCellProps[] = [];
    if (dateDetails?.wind !== null) {
      cells.push({
        firstRowData: weatherFields.WIND,
        icon: windIcon,
        iconSize: '12',
        secondRowData: `${convertedWindSpeedValue} ${convertedWindSpeedLabel}`,
      });
    }
    if (dateDetails?.rainAccumulation !== null) {
      cells.push({
        firstRowData: weatherFields.DAILY_RAIN,
        icon: precipitationIcon,
        iconSize: '10',
        secondRowData: `${convertedDailyRainValue} ${convertedDailyRainLabel}`,
      });
    }
    if (dateDetails?.vpd !== null) {
      cells.push({
        firstRowData: weatherFields.VPD,
        icon: vpdIcon,
        iconSize: '10',
        secondRowData: `${roundPrecision(vpdValue, 1)} kPa`,
      });
    }
    if (dateDetails?.humidity !== null) {
      cells.push({
        firstRowData: weatherFields.HUMIDITY,
        icon: humidityIcon,
        iconSize: '3',
        secondRowData: `${roundPrecision(dateDetails.humidity, 0)}%`,
      });
    }
    if (dateDetails?.evapotranspiration !== null) {
      cells.push({
        firstRowData: weatherFields.EVAPOTRANSPIRATION,
        icon: evapotranspirationIcon,
        iconSize: '8',
        secondRowData: `${convertedDailyEtoValue} ${convertedDailyEtoLabel}`,
      });
    }
    return cells;
  };

  const renderedCells = generateWeatherListCells().map((cell) => (
    <WeatherListCell
      key={cell.firstRowData}
      firstRowData={cell.firstRowData}
      icon={cell.icon}
      iconSize={cell.iconSize}
      secondRowData={cell.secondRowData}
      weatherFields={weatherFields}
    />
  ));

  return (
    <div className={`flex-column ${classes.root}`}>
      <h1>
        {/* Conditional rendering to display 'Today' if the farm's date matches user's date and UTC date, else display the date */}
        {isFarmUserUtcDateMatch ? (
          farmTodayDate === dataDate ? (
            <strong>{t('weather.today')}</strong>
          ) : (
            <span>{`${t(`${currentDate.month}`)} ${currentDate.day}`}</span>
          )
        ) : (
          <span>{`${t(`${currentDate.month}`)} ${currentDate.day}`}</span>
        )}
      </h1>
      <hr />
      <div className={`flex-column ${classes.cellsContainer}`}>
        {dateDetails?.temperature !== null ||
        dateDetails?.max_temperature !== null ||
        dateDetails?.min_temperature !== null ? (
          <WeatherListCellTemperature
            icon={weeklyTempIcon}
            iconSize={40}
            secondRowData={
              <div
                className={`flex align-center justify-center ${classes.tempCell}`}>
                <Typography
                  className={classes.maxTemp}
                  onMouseEnter={() => setIsTooltipVisible(true)}
                  onMouseLeave={() => setIsTooltipVisible(false)}>
                  {isTooltipVisible ? (
                    <TextWithTooltip
                      tooltipText={`${t('weather.dailyMaxTemp')}`}>
                      {convertedMaxTempValue}
                      {convertedMaxTempLabel}
                    </TextWithTooltip>
                  ) : (
                    `${convertedMaxTempValue}${convertedMaxTempLabel}`
                  )}
                </Typography>
                <Typography
                  fontSize={10}
                  className={`flex ${classes.minTemp}`}
                  onMouseEnter={() => setIsTooltipVisible(true)}
                  onMouseLeave={() => setIsTooltipVisible(false)}>
                  {isTooltipVisible ? (
                    <TextWithTooltip
                      tooltipText={`${t('weather.dailyMinTemp')}`}>
                      {convertedMinTempValue}
                      {convertedMinTempLabel}
                    </TextWithTooltip>
                  ) : (
                    `${convertedMinTempValue}${convertedMinTempLabel}`
                  )}
                </Typography>
              </div>
            }
          />
        ) : null}
        {renderedCells}
      </div>
    </div>
  );
}
