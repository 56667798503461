import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { AccountField } from 'src/shared/data/types/accountOptionsTypes';
import CountriesOptions from 'src/shared/data/CountryOptions';
import useStyles from '../useStyles';
import countries from '../../../../consts/countries';

const hasError = (data: AccountField): boolean => {
  return !!data.fieldStateTouch && !data.fieldState;
};

export default function SelectCountry(props: {
  data: AccountField;
  onState: any;
}): JSX.Element {
  const { data, onState } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const helper = (): string => {
    return `${t('accountFieldModal.countryHelper')}`;
  };

  const handleChangeCountry = (e: any): any => {
    if (data.setFieldStateString !== undefined) {
      data.setFieldStateString(e);
    } else if (data.setFieldStateNumber !== undefined) {
      data.setFieldStateNumber(e);
    }
    if (e === countries.USA || e === countries.USAShort) {
      onState(true);
    } else {
      onState(false);
    }
  };

  const handleBlur = (): void => {
    if (data.setFieldStateTouch) {
      data.setFieldStateTouch(true);
    }
  };

  return (
    <div
      className={`flex-column ${data.fieldName}`}
      style={{ width: '100%', marginBottom: '-0.5rem' }}>
      <span id="country">{`${data.title}${data.isMandatory ? '*' : ''}`}</span>
      <Form.Item
        help={hasError(data) && helper()}
        validateStatus={hasError(data) ? 'error' : ''}>
        <Select
          id="country"
          className={classes.select}
          style={{ width: '100%' }}
          defaultValue={data.fieldState}
          showSearch
          data-hj-allow
          onChange={(e) => handleChangeCountry(e)}
          onBlur={() => handleBlur()}
          options={CountriesOptions.map((singleCountry: any) => {
            return {
              value: singleCountry,
              label: t(`${singleCountry}`),
            };
          })}
        />
      </Form.Item>
    </div>
  );
}
