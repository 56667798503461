import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: 'max-content',
    gap: '6px',
    '@media (min-width: 769px) and (max-width: 900px)': {
      gap: 'unset',
    },
  },
  text: {
    color: '#676879',
    whiteSpace: 'nowrap',
    margin: '0',
    fontWeight: 700,
    fontSize: 'calc(0.6rem + 0.5vw)',
    '@media (min-width: 1240px) and (max-width: 1400px)': {
      fontSize: 'calc(0.6rem + 0.6vw) !important',
    },
  },
  iconImg: {
    height: '1rem',
    '@media (min-width: 1240px)': {
      marginInlineEnd: '-5px',
      maxHeight: 'unset',
      '@media (min-width: 769px) and (max-width: 900px)': {
        height: '1.4rem',
      },
      // "&:nth-child(1)": {
      //   marginInlineEnd: "unset",
      //   // display: "none !important",
      // },
    },
  },
}));

export default useStyles;
