export enum ETCardsNavState {
  IRRIGATION_REQUIREMENT = 'Irrigation Requirement',
  ACTUAL_CONSUMPTION = 'Actual Consumption',
}

export enum NavbarState {
  FIELDS = '/farms',
  ACCOUNTS = '/accounts',
  SENSORS = '/sensors',
  GROWER = '/growers',
  AGENTS = '/agents',
  CONNECTION = '/connection',
}

export enum UserRole {
  ADMIN = 'admin',
  ACCOUNT_ADMIN = 'account-admin',
  ACCOUNT_VIEWER = 'account-viewer',
  AGENT_ADMIN = 'agent-admin',
  AGENT_VIEWER = 'agent-viewer',
  GROWER = 'grower',
  SCIENTIST = 'scientist',
  INSTALLER = 'installer',
  RESEARCHER_GROWER = 'researcher-grower',
}

export const roleParser = (role: UserRole): string => {
  switch (role) {
    case UserRole.ACCOUNT_ADMIN: {
      return 'Account Admin';
    }
    case UserRole.AGENT_ADMIN: {
      return 'Agent Admin';
    }
    default: {
      return role;
    }
  }
};

export enum AdminModes {
  NONE = 'none',
  CREATE = 'add',
  EDIT = 'update',
  INACTIVE = 'set Inactive',
  DELETE = 'DELETE',
}

export enum BlockDaysBack {
  ET_DAYSBACK_IRRIGATION_CONSUMPTION_CARD = 7,
  ET__DAYSBACK_CONSUMPTION_GRAPH = 365,
}

export enum DefaultCountryCode {
  USA = 'us',
}

export enum MonthName {
  JAN = 'Jan',
  FEB = 'Feb',
  MAR = 'Mar',
  APR = 'Apr',
  MAY = 'May',
  JUN = 'Jun',
  JUL = 'Jul',
  AUG = 'Aug',
  SEP = 'Sep',
  OCT = 'Oct',
  NOV = 'Nov',
  DEC = 'Dec',
}

export enum DataLength {
  YEAR = 365,
  MONTH = 30,
}

export enum CropNames {
  AVOCADO = 'Avocado',
  APPLE = 'Apple',
  PECAN = 'Pecan',
  ORANGE = 'Orange',
  CLEMENTINE = 'Clementine',
  ALMOND = 'Almond',
}

export enum MapZoom {
  MAP_ZOOM = 15,
  MAP_ZOOM_EMPTY = 3,
}

export enum MapTypes {
  SATELLITE = 'satellite',
  ROADMAP = 'roadmap',
  HYBRID = 'hybrid',
}

export enum WineGrapeCropType {
  WINE_GRAPE = 'Wine Grape',
}
