import { createAsyncThunk } from '@reduxjs/toolkit';
import { BlockType } from 'src/shared/data/types/blockTypes';
import { BlockActualConsumptionGraphType } from 'src/shared/data/types/ETConsumptionGraphTypes';
import blocksAdapter from '../adapters/BlocksAdapter';
import { MiniBlock } from '../../shared/data/types/growerFarmsTypes';

export const getBlocksByFarm = createAsyncThunk<
  MiniBlock[],
  { farmId: number; daysBack: number },
  { rejectValue: Error }
>(
  'blocks',
  async (
    { farmId, daysBack },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await blocksAdapter.getBlocksByFarm(farmId, daysBack);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getFarmBlocksConsumptionGraphData = async (
  farmId: number,
  daysBack: number,
): Promise<BlockActualConsumptionGraphType[]> => {
  try {
    const response = await blocksAdapter.getFarmBlocksConsumptionGraphData(
      farmId,
      daysBack,
    );
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getNewBlockETcForecast = createAsyncThunk<
  any,
  number,
  { rejectValue: Error }
>(
  'newBlockEtcForecast',
  async (blockId, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await blocksAdapter.getNewBlockETcForecast(blockId);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getBlockETcForecasts = createAsyncThunk<
  any,
  number,
  { rejectValue: Error }
>(
  'blocketcforecast',
  async (blockId, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await blocksAdapter.getBlockETcForecasts(blockId);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getBlockInfoOptions = createAsyncThunk<
  any,
  number,
  { rejectValue: Error }
>(
  'blocks/formOptions',
  async (str, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await blocksAdapter.getBlockInfoOptions();
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
