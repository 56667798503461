// useDesignedCard.ts
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useDesignedCard = makeStyles((theme: Theme) => ({
    allContainer: {
        cursor: 'pointer', // Change the cursor on hover
        '&:hover $cardContainer': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '&:hover $title': {
            color: theme.palette.primary.main, // Change title color on hover
        },
        '&:hover $span': {
            color: theme.palette.primary.main, // Change span color on hover
        },
    },
    cardContainer: {
        borderRadius: theme.borderRadius.m,
        padding: theme.paddings.s,
        margin: '8px 0',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.surfaceVariant.main,
        width: '300px', // Default width
        height: '98px',  // Default height
        justifyContent: 'space-between',
        gap: theme.paddings.s,
        borderBottom: 'none', // No initial border
        transition: 'border-bottom-color 0.3s', // Smooth transition for border
        '@media (max-width: 1400.9px)': {
            width: '250px', // Adjusted width for tablet
            height: '70px', // Adjusted height for tablet
            padding: theme.paddings.xs, // Optional: Adjust padding if needed
            gap: theme.paddings.xs, // Optional: Adjust gap if needed
        },
        '@media (max-width: 1200.9px)': {
            width: '230px', // Adjusted width for smaller tablets
            height: '60px', // Adjusted height for smaller tablets
            padding: theme.paddings.xs,
            gap: theme.paddings.xs,
        },
        '@media (max-width: 1100.9px)': {
            width: '180px',
            height: '60px',
            padding: theme.paddings.xs,
            gap: theme.paddings.xs,
        },
        '@media (max-width: 900.9px)': {
            width: '150px',
            height: '60px',
            padding: theme.paddings.xs,
            gap: theme.paddings.xs,
        },
        '@media (max-width: 767.9px)': {
            width: '75vw', // Set width to 75% of the viewport
            height: '45px',
            padding: theme.paddings.xs,
            gap: theme.paddings.xs,
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 767.9px)': {
            width: '24px',
            height: '24px',
        },
    },
    leftIconCircle: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        backgroundColor: theme.palette.surface.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 1400.9px)': {
            width: '40px',
            height: '40px',
        },
        '@media (max-width: 1200.9px)': {
            width: '24px',
            height: '24px',
        },
        '@media (max-width: 767.9px)': {
            width: '24px',
            height: '24px',
        },
    },
    icon: {
        fontSize: '24px',
        color: theme.palette.onSurface.main,
        '@media (max-width: 767.9px)': {
            fontSize: '16px',
        },
    },
    rightIcon: {
        fontSize: '24px',
        color: theme.palette.onSurface.main,
        '@media (max-width: 767.9px)': {
            fontSize: '16px',
        },
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        // Tablet sizes: 768px to 1400.9px
        '@media (min-width: 768px) and (max-width: 1400.9px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '4px',
        },
        // Mobile sizes: below 768px
        '@media (max-width: 767.9px)': {
            marginBottom: '4px',
        },
    },
    title: {
        ...theme.typography.title3,
        color: theme.palette.onSurface.main,
        paddingRight: '5px',
        '@media (max-width: 1400.9px) and (min-width: 768px)': {
            ...theme.typography.bodySmallRegular,
        },
        '@media (max-width: 1300.9px)': {
            ...theme.typography.bodySmallRegular,
        },
        '@media (max-width: 1200.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 1100.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 900.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 850.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 767.9px)': {
            ...theme.typography.bodyMedium,
        },
    },
    span: {
        color: theme.palette.onSurface.main,
        '@media (min-width: 768px) and (max-width: 1400.9px)': {
            ...theme.typography.bodySmallRegular,
            marginTop: '4px', // Add some spacing when stacked
        },
        '@media (max-width: 1400.9px) and (min-width: 768px)': {
            ...theme.typography.bodySmallRegular,
            marginTop: '4px',
        },
        '@media (max-width: 1300.9px)': {
            ...theme.typography.bodySmallRegular,
            marginBottom: '0',
        },
        '@media (max-width: 1200.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 1100.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 900.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 850.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 767.9px)': {
            ...theme.typography.caption,
        },
    },
    contentContainer: {
        flex: 1,
        marginLeft: '16px',
        marginRight: '16px',
        display: 'flex',
        flexDirection: 'column', // Default flex direction for larger screens
        justifyContent: 'center',
        overflow: 'hidden',
        '@media (max-width: 767.9px)': {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            marginLeft: '8px',
            marginRight: '8px',
        },
    },
    mainTitle: {
        ...theme.typography.title1,
        color: theme.palette.onSurface.main,
        marginBottom: '8px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '@media (max-width: 1400.9px) and (min-width: 768px)': {
            ...theme.typography.bodyMedium,
            marginBottom: '4px',
        },
        '@media (max-width: 1200.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 1100.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 900.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 850.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 767.9px)': {
            ...theme.typography.title3,
            marginBottom: '0',
        },
    },
    subTitle: {
        ...theme.typography.bodyRegular,
        color: theme.palette.onSurface.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '@media (min-width: 768px) and (max-width: 1400.9px)': {
            ...theme.typography.bodySmallRegular,
            marginTop: '4px',
        },
        '@media (max-width: 1400.9px) and (min-width: 768px)': {
            ...theme.typography.bodySmallRegular,
            marginTop: '4px',
        },
        '@media (max-width: 1300.9px)': {
            ...theme.typography.bodySmallRegular,
            marginBottom: '0',
        },
        '@media (max-width: 1200.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 1100.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 900.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 850.9px)': {
            ...theme.typography.infoMedium,
            marginBottom: '0',
        },
        '@media (max-width: 767.9px)': {
            ...theme.typography.infoRegular,
            marginLeft: '8px',
        },
    },
}));

export default useDesignedCard;
