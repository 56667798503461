import { PolygonPoint } from '../data/types/growerFarmsTypes';

const toGeoJson = (polygon: PolygonPoint[]): any => {
  // In GEO JSON coordinates are described as long,lat !!
  const resArr = polygon.map((item: any) => {
    return [item.lng, item.lat];
  });
  resArr.push(resArr[0]);
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates: [resArr],
          type: 'Polygon',
        },
      },
    ],
  };
};

export default toGeoJson;
