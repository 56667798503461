import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { GrowerDetails } from 'src/shared/data/types/growerOptionsTypes';
import {
  GrowersResponse,
  MiniGrower,
  NewGrower,
} from '../../shared/data/types/growerFarmsTypes';

class GrowersAdapter {
  static readonly endpoint = `/growers`;

  async getAllGrowers(): Promise<AxiosResponse<GrowersResponse>> {
    return HttpClientService.get<GrowersResponse>(GrowersAdapter.endpoint);
  }

  async getBlockIds(): Promise<AxiosResponse<GrowersResponse>> {
    return HttpClientService.get<GrowersResponse>(
      `${GrowersAdapter.endpoint}/block-ids`,
    );
  }

  async activateSpecificGrowerById(
    growerId: string,
  ): Promise<AxiosResponse<GrowerDetails>> {
    return HttpClientService.put<GrowerDetails>(
      `${GrowersAdapter.endpoint}/${growerId}/change-status/active`,
      {
        growerId,
      },
    );
  }

  async deleteSpecificGrowerById(
    growerId: string,
  ): Promise<AxiosResponse<GrowerDetails>> {
    return HttpClientService.delete<GrowerDetails>(
      `${GrowersAdapter.endpoint}/${growerId}`,
      {
        growerId,
      },
    );
  }

  async editGrower(
    updatedGrower: MiniGrower,
  ): Promise<AxiosResponse<MiniGrower>> {
    return HttpClientService.patch<MiniGrower>(
      `${GrowersAdapter.endpoint}/${updatedGrower.id}`,
      updatedGrower,
    );
  }

  async postNewGrower(newGrower: NewGrower): Promise<AxiosResponse<NewGrower>> {
    return HttpClientService.post<NewGrower>(
      `${GrowersAdapter.endpoint}`,
      newGrower,
    );
  }
}

const growerAdapter = new GrowersAdapter();
export default growerAdapter;
