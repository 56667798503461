import {MeasurementSystem} from "../../../../../shared/data/types/UserTypes";
import {
    Measurement,
    MeasurementSys
} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import {
    ActualConsumption,
    ActualIrrigation,
    IrrigationRequirement
} from "../../../../../shared/data/types/blockDataTypes";
import {convertAndRound, convertedLabel} from "../../../../../shared/measurements-converter/converter";
import dayjs from "dayjs";

export const formatDate = (date: string, isMetric: boolean): string => {
    const [year, month, day] = date.split('-');
    return isMetric
        ? `${day}.${month}.${year.slice(-2)}` // Metric format: DD.MM.YY
        : `${month}.${day}.${year.slice(-2)}`; // Imperial format: MM.DD.YY
};

export const calculateTotal = (data: any, key: any): number => {
    if (!data || !Array.isArray(data)) {
        return 0; // Return 0 if data is undefined, null, or not an array
    }
    return data.reduce((acc: number, item: any) => acc + (item[key] || 0), 0);
};

export const calculateAverage = (data: any, key: any, day_qty: number): number => {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return 0; // Return 0 if data is undefined, null, not an array, or empty
    }
    return data.reduce((acc: number, item: any) => acc + (item[key] || 0), 0) / day_qty;
};

export function getMeasurementSys(measurement_system: MeasurementSystem): MeasurementSys {
    return measurement_system === MeasurementSystem.Imperial
        ? MeasurementSys.IMPERIAL
        : MeasurementSys.METRIC;
}

export function getMeasurementSystem(measurement_system: MeasurementSys): MeasurementSystem {
    return measurement_system === MeasurementSys.IMPERIAL
        ? MeasurementSystem.Imperial
        : MeasurementSystem.Metric

}
const formatHours = (start: any, finish: any): string => {
    const startDate = start instanceof Date ? start : new Date(start);
    const finishDate = finish instanceof Date ? finish : new Date(finish);

    if (isNaN(startDate.getTime()) || isNaN(finishDate.getTime())) {
        console.error("Invalid date format:", start, finish);
        return "Invalid time";
    }

    let diffInMilliseconds = finishDate.getTime() - startDate.getTime();

    // Handle negative durations by taking absolute value and optionally indicating negative time
    const isNegative = diffInMilliseconds < 0;
    diffInMilliseconds = Math.abs(diffInMilliseconds);

    const totalMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let result = "";
    if (hours > 0) {
        result += `${hours}h `;
    }
    if (minutes > 0 || hours === 0) { // Show minutes if there are any, or if hours is 0
        result += `${minutes}m`;
    }

    // Prefix with '-' if the duration was negative
    return isNegative ? `-${result.trim()}` : result.trim();
};



export interface ConvertedActualIrrigation {
    Start: Date;
    End: Date;
    Time: string;
    Amount: number;
}

export const convertActualIrrigation = (data: ActualIrrigation[]): ConvertedActualIrrigation[] => {
    return data.map(item => ({
        Start: item.start,
        End: item.finish,
        Time: formatHours(item.start, item.finish),
        Amount: Number(item.flow) // Converting flow to a number
    }));
};

export interface ConvertedActualConsumption {
    Date: string;
    'Actual Consumption': number;
}

export const convertActualConsumption = (data: ActualConsumption[] , isMetric: boolean): ConvertedActualConsumption[] => {
    return data.map(item => ({
        Date: formatDate(item.refDate, isMetric),
        'Actual Consumption': item.ETa,
        'ETo': item.ETo
    }));
};



export interface ConvertedIrrigationRequirement {
    Date: string;
    Amount: number;
}

export const convertIrrigationRequirement = (data: IrrigationRequirement[], isMetric: boolean): ConvertedIrrigationRequirement[] => {
    return data.map(item => ({
        Date: formatDate(item.refDate, isMetric),
        Amount: item.irrigForecast
    }));
};


export const formatValueWithLabel = (
    totalConverted: number | null | undefined,
    totalConvertedLabel: string
): string => {
    if (totalConverted === null || totalConverted === undefined) {
        return ''; // or return a placeholder like 'N/A'
    }

    return `${totalConverted}${totalConvertedLabel}`;
};

export const roundPrecision = (number: number, precision: number): number => {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
};

export const formatNumberWithLabel = (
    value: number,
    userCountry: string,
    measurementSystem: MeasurementSys,
    measurement: Measurement,
    precision: number = 2,
    blockArea?: number
): string => {
    const res =
        measurement === Measurement.VOLUME
            ? ((value ?? 0) / 1000) * (blockArea ?? 1)
            : value;

    const convertedValue = convertAndRound(
        userCountry,
        measurementSystem,
        measurement,
        res,
        precision
    );

    let finalValue: number;
    let finalLabel = convertedLabel(userCountry, measurementSystem, measurement);

    if (convertedValue == null) {
        finalValue = 0; // Default to 0 if null or undefined
    } else {
        finalValue = convertedValue;
    }

    let formattedValue: string;

    if (finalValue >= 1000) {
        // For values ≥ 1000, divide by 1000, round to 1 decimal place, and append 'K'
        const formattedLargeVal = finalValue / 1000;
        formattedValue = `${roundPrecision(formattedLargeVal, 1)}K `;
    } else {
        // For values < 1000, round to 2 decimal places
        formattedValue = roundPrecision(finalValue, 2).toString();
    }

    return `${formattedValue} ${finalLabel}`;
};

// Function to format datetime strings
export const formatDateTime = (dateString: string): string => {
    const date = dayjs(dateString);
    const time = date.format('HH:mm');
    const formattedDate = date.format('DD.MM.YY');
    return `${time} ${formattedDate}`;
};

// Function to generate CSV content from table data
export const generateCSV = (
    data: any[] | undefined,
    userCountry: string,
    measurementSys: MeasurementSys,
    measurement: Measurement,
    blockArea?: number
): string => {
    if (!data || data.length === 0) return '';

    const columns = Object.keys(data[0]);
    const csvRows: string[] = [];

    // Create CSV header from column names
    csvRows.push(columns.join(','));

    // Add data rows
    data.forEach(item => {
        const row = columns.map(column => {
            const value = item[column];
            if (typeof value === 'string') {
                // Apply date formatting for 'Start' and 'End' columns
                if (column === 'Start' || column === 'End') {
                    return formatDateTime(value);
                }
            } else if (typeof value === 'number') {
                // Apply number formatting with correct precision and blockArea
                return formatNumberWithLabel(value, userCountry, measurementSys, measurement, 2, blockArea);
            }
            // Return the unformatted value if no specific formatting is required
            return value;
        });
        csvRows.push(row.join(','));
    });

    return csvRows.join('\n');
};

// Function to sanitize the filename by removing invalid characters
export const sanitizeFilename = (name: string): string => {
    return name.replace(/[\/\\?%*:|"<>]/g, '_');
};

// Function to handle exporting CSV
export const handleExport = (
    data: any[] | undefined,
    userCountry: string,
    measurementSys: MeasurementSys,
    measurement: Measurement,
    title: string,
    currentBlockTitle: string,
    blockArea?: number
) => {
    const csvData = generateCSV(data, userCountry, measurementSys, measurement,blockArea);
    if (!csvData) return;

    // Sanitize the title to create a valid filename
    const sanitizedTitle = sanitizeFilename(title);
    const filename = `${currentBlockTitle} - ${sanitizedTitle}.csv`;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};




// Helper function to convert time string to total hours and minutes
export const parseTimeToHoursAndMinutes = (timeString: string): { hours: number; minutes: number } => {
    const timeParts = timeString.split(' ');
    let totalHours = 0;
    let totalMinutes = 0;

    timeParts.forEach(part => {
        const value = parseInt(part);
        if (part.endsWith('h')) {
            totalHours += value; // Add hours directly
        } else if (part.endsWith('m')) { // Changed from 'min' to 'm'
            totalMinutes += value; // Add minutes directly
        }
    });

    return { hours: totalHours, minutes: totalMinutes };
};

export const calculateTotalTime = (data: ConvertedActualIrrigation[], key: string): { hours: number; minutes: number } => {
    if (!data || !Array.isArray(data)) {
        return { hours: 0, minutes: 0 }; // Return 0 if data is undefined, null, or not an array
    }

    const total = data.reduce((acc: { hours: number; minutes: number }, item: any) => {
        const { hours, minutes } = parseTimeToHoursAndMinutes(item[key] || '');
        acc.hours += hours;
        acc.minutes += minutes;

        // Handle minutes overflow
        if (acc.minutes >= 60) {
            acc.hours += Math.floor(acc.minutes / 60); // Convert overflow to hours
            acc.minutes = acc.minutes % 60; // Keep the remaining minutes
        }


        return acc;
    }, { hours: 0, minutes: 0 });

    return total;
};

// Function to calculate average time in minutes
// utils.ts

export const calculateAverageTime = (
    data: ConvertedActualIrrigation[],
    key: string,
    day_qty: number
): { hours: number; minutes: number } => {
    if (!data || !Array.isArray(data) || data.length === 0 || day_qty <= 0) {
        return { hours: 0, minutes: 0 };
    }

    const { hours, minutes } = calculateTotalTime(data, key);
    const totalMinutes = hours * 60 + minutes;

    const averageTotalMinutes = totalMinutes / Math.max(day_qty, 1);

    const averageHours = Math.floor(averageTotalMinutes / 60);
    let averageMinutes = averageTotalMinutes % 60;

    averageMinutes = Math.round(averageMinutes);

    // Handle case where rounding minutes results in 60
    if (averageMinutes >= 60) {
        return {
            hours: averageHours + Math.floor(averageMinutes / 60),
            minutes: averageMinutes % 60,
        };
    }

    return { hours: averageHours, minutes: averageMinutes };
};

// utils.ts

export const formatHoursAndMinutesToString = (
    hours: number,
    minutes: number
): string => {
    let formattedHours = hours;
    let formattedMinutes = minutes;

    // If minutes are 60 or more, increment hours and reset minutes
    if (formattedMinutes >= 60) {
        formattedHours += Math.floor(formattedMinutes / 60);
        formattedMinutes = formattedMinutes % 60;
    }

    return `${formattedHours}h ${formattedMinutes}min`.trim();
};