import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { Weather } from 'src/shared/data/types/weatherTypes';

const baseUrl: string = process.env.REACT_APP_SERVER_BASE_URL ?? '';

class WeeklyWeatherAdapter {
  static readonly endpoint = `${baseUrl}/weather/farm-weather`;

  async getWeeklyWeatherData(
    farmId: number,
    lat: number,
    lng: number,
    timeZone: string,
  ): Promise<AxiosResponse<Weather[]>> {
    const response = await HttpClientService.get<Weather[]>(
      `${WeeklyWeatherAdapter.endpoint}?farmId=${farmId}&latitude=${lat}&longitude=${lng}&timeZone=${timeZone}`,
    );

    return response;
  }
}

const weeklyWeatherAdapter = new WeeklyWeatherAdapter();
export default weeklyWeatherAdapter;
