import { UserRole } from 'src/shared/constants/enums';
import {DepthVolume, ValueTime} from "../../../pages/Growers/Grower/block_data_widget/constants";

export interface User {
  id?: number;
  ownerId: string;
  sub: string;
  ownerAccountId: string;
  ownerAccountDatabaseId?: number;
  given_name: string;
  family_name: string;
  email: string;
  farmManagerId?: string;
  measurementSystem: MeasurementSystem;
  depth_volume: DepthVolume;
  value_time: ValueTime;
  growerCognitoId?: string;
  httpErr: any;
  role: UserRole | undefined;
  country: string;
}

export interface UserMeasurementSystem {
  measurementSystem: MeasurementSystem;
}
export interface UserDepthVolume {
  depth_volume: DepthVolume;
}

export interface UserValueTime {
  value_time: ValueTime;
}

export enum MeasurementSystem {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export interface UserAccess {
  isViewerAccess: boolean;
  isResearcher: boolean;
  isGrowerAccess: boolean;
  isAdmin: boolean;
  isAccountAdminAccess: boolean;
  isAgentAdminAccess: boolean;
}
