import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  BlockActualConsumptionGraph,
  BlockActualConsumptionGraphType,
} from 'src/shared/data/types/ETConsumptionGraphTypes';
import { DemoUserState } from 'src/shared/data/types/demoUserStateType';

const initialState: DemoUserState = {
  demoUserActualConsumptionData: [], // Initialize as an empty array
};

export const DemoUserSlice = createSlice({
  name: 'demoUser',
  initialState,
  reducers: {
    setDemoUserActualConsumptionData(
      state,
      action: PayloadAction<BlockActualConsumptionGraph[]>,
    ) {
      state.demoUserActualConsumptionData = action.payload;
    },
  },
});

export const { setDemoUserActualConsumptionData } = DemoUserSlice.actions;

export const demoUserReducer = DemoUserSlice.reducer;
