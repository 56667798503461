import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlockData, blockDataState } from '../../../shared/data/types/blockDataTypes';
import {RootState} from "../../store/root-state";

const initialState: blockDataState = { data: {} };

export const BlockDataSlice = createSlice({
  name: 'block-data',
  initialState,
  reducers: {
    setBlockData(state, action: PayloadAction<BlockData>) {
      const blockId = action.payload.blockId;
      state.data[blockId] = action.payload;
    },
  },
});

// Export the action
export const { setBlockData } = BlockDataSlice.actions;

// Export the reducer
export const blockDataReducer = BlockDataSlice.reducer;

// Selector to get all block IDs
export const selectAllBlockIds = (state: { rootReducer: RootState }): number[] => {
  return Object.keys(state.rootReducer.blockDataState.data).map(Number); // Access blockDataState inside rootReducer
};