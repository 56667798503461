import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > p': {
      margin: 0,
      width: '175px',
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2%',
  },
}));

export default useStyles;
