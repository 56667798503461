import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    '& input': {
      border: '1px solid',
      borderColor: '#76778673 !important',
      borderRadius: '4px',
      height: '32px',
      padding: '0 11px',
      '&::placeholder': {
        color: '#76778673 !important',
      },
      '&:-ms-input-placeholder': {
        color: '#76778673 !important',
      },
      '&::-ms-input-placeholder': {
        color: '#76778673 !important',
      },
    },
    '& input:focus-visible': {
      outline: 'none',
    },
    '& select': {
      borderColor: 'transparent',
    },
  },
}));

export default useStyles;
