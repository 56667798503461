import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SearchAndSortState } from 'src/shared/data/types/searchBarType';

const initialState: SearchAndSortState = {
  searchInput: '',
  options: ['Name', 'Variety'],
  selectedOption: 'Name',
};

export const SearchAndSortSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchInput(state, action: PayloadAction<string>) {
      state.searchInput = action.payload;
    },
    setSelectedOption(state, action: PayloadAction<string>) {
      state.selectedOption = action.payload;
    },
  },
});

export const { setSearchInput, setSelectedOption } = SearchAndSortSlice.actions;

export const searchAndSortReducer = SearchAndSortSlice.reducer;
