import { useTranslation } from 'react-i18next';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Regex } from 'src/shared/constants/consts';
import {
  handleCountryValidation,
  isValidTLD,
} from 'src/shared/helpers/FormValidationHelper';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { setGrowers } from 'src/reduxToolkit/slices/GrowerSlice/grower.slice';
import {
  SelectCountry,
  SelectState,
  SelectItem,
  SelectOwner,
  TextForm,
  GenericModal,
  ModalHelper,
  SelectedCheckbox
} from 'src/shared/components/GenericModal';
import countries from 'src/consts/countries';
import countryMap from 'src/shared/data/CountryMap';
import agentsAdapter from 'src/reduxToolkit/adapters/AgentsAdapter';
import { UserRole } from 'src/shared/constants/enums';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import { Owner } from 'src/shared/data/types/agentOptionsTypes';

function EditGrowerModal(funcProps: any): ReactElement | null {
  const { growers, record, onClose, handleEditGrower, loading } = funcProps;
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>(record.title);
  const [titleValidation, setTitleValidation] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(record.description);
  const [country, setCountry] = useState<string>(record.country);
  const [city, setCity] = useState<string>(record.city);
  const [countryValidation, setCountryValidation] = useState<boolean>(false);
  const [stateName, setStateName] = useState<string>(record.state);
  const [stateNameValidation, setStateNameValidation] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(record.firstName);
  const [firstNameValidation, setFirstNameValidation] =
    useState<boolean>(false);
  const [lastName, setLastName] = useState<string>(record.lastName);
  const [lastNameValidation, setLastNameValidation] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(record.email);
  const [emailValidation, setEmailValidation] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(record.phone);
  const [phoneValidation, setPhoneValidation] = useState<boolean>(false);
  const userData = useAppSelector((state) => state.rootReducer.userState);
  const [ownerId, setOwnerId] = useState<string>(record.ownerCognitoId);
  const [ownerAccountId, setOwnerAccountId] = useState<string>(record.accountCognitoId);
  const [owners, setOwners] = useState<Owner[]>([]);
  const [ownerEmail, setOwnerEmail] = useState<string>(record.ownerEmail);
  const [growerRoleList, setGrowerRoleList] = useState<any[]>([]);
  const { isAdmin } = useUserAccess();
  const [role, setRole] = useState<string>(record.role);
  const [roleValidation, setRoleValidation] = useState<boolean>(false);
  const [sendWeeklyReport, setSendWeeklyReport] = useState<boolean>(record.sendWeeklyReport);

  const { t } = useTranslation();
  const props = {
    title,
    setTitle,
    titleValidation,
    setTitleValidation,
    description,
    setDescription,
    country,
    setCountry,
    countryValidation,
    setCountryValidation,
    stateName,
    setStateName,
    stateNameValidation,
    setStateNameValidation,
    firstName,
    setFirstName,
    firstNameValidation,
    setFirstNameValidation,
    lastName,
    setLastName,
    lastNameValidation,
    setLastNameValidation,
    email,
    setEmail,
    emailValidation,
    setEmailValidation,
    phone,
    setPhone,
    phoneValidation,
    setPhoneValidation,
    role,
    setRole,
    roleValidation,
    setRoleValidation,
    ownerId,
    setOwnerId,
    ownerEmail,
    setOwnerEmail,
    city,
    setCity,
    sendWeeklyReport,
    setSendWeeklyReport
  };
  const growerFields = ModalHelper(props);

  const handleEditGrowerDataForm = useCallback(
    (updatedGrower: any): any => {
      const idx = growers.findIndex((grower: any) => grower.id === record.id);
      const fullUpdatedGrower: any = { ...growers[idx], ...updatedGrower };
      const cloneGrowers = [...growers];
      cloneGrowers.splice(idx, 1, fullUpdatedGrower);
      dispatch(setGrowers(cloneGrowers));
    },
    [growers, dispatch, record.id],
  );

  useEffect(() => {
    if (isAdmin) {
      setGrowerRoleList([
        {
          value: UserRole.GROWER,
          label: `${t('role.grower')}`,
        },
        {
          value: UserRole.RESEARCHER_GROWER,
          label: `${t('role.researcher-grower')}`,
        },
      ]);
    } else {
      setGrowerRoleList([
        {
          value: UserRole.GROWER,
          label: `${t('role.grower')}`,
        },
      ]);
    }
  }, [isAdmin, setGrowerRoleList, t]);

  useEffect(() => {
    if (country !== countries.USA && country !== countries.USAShort) {
      setStateName('');
    }
  }, [country]);

  useEffect(() => {
    getOwners().then();
  }, []);

  useEffect(() => {
    if (record.ownerEmail == null && owners.length) {
      setOwnerEmail(owners.find((owner) => owner.id === ownerId)?.email || '');
      setOwnerAccountId(owners.find((owner) => owner.id === ownerId)?.account_id || '');
    }
  }, [owners, ownerId, record.ownerEmail]);

  async function getOwners() {
    const owners = await agentsAdapter.getOwners();
    setOwners(owners.data.owners);
  }

  const handleEdit = useCallback(
    (e: any): any => {
      const updatedGrower: any = {
        title: title,
        description: description,
        country: country,
        state: stateName,
        city: city,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        accountCognitoId: ownerAccountId,
        ownerCognitoId: ownerId,
        ownerEmail: ownerEmail,
        accountId: userData.ownerAccountDatabaseId,
        role: role,
        sendWeeklyReport: sendWeeklyReport
      };
      handleEditGrowerDataForm(updatedGrower);
      handleEditGrower(e, updatedGrower);
    },
    [
      ownerAccountId,
      handleEditGrower,
      title,
      description,
      country,
      stateName,
      firstName,
      lastName,
      email,
      phone,
      userData.ownerAccountId,
      userData.ownerAccountDatabaseId,
      role,
      ownerId,
      ownerEmail,
      sendWeeklyReport
    ],
  );
  return (
    <GenericModal
      title={t('growerFieldModal.updateGrowerModalTitle')}
      onClose={onClose}
      onSave={handleEdit}
      isLoading={loading}
      isSaveDisabled={
        !ownerId ||
        !title ||
        !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !role ||
        !country ||
        !isValidPhoneNumber(phone) ||
        !isPossiblePhoneNumber(phone) ||
        !Regex.EMAIL_REGEX.test(email) ||
        (Regex.EMAIL_REGEX.test(email) && !isValidTLD(email)) ||
        !handleCountryValidation(country, stateName)
      }
      saveButtonText={t('growerFieldModal.updateGrowerBtn')}
      cancelButtonText={t('growerFieldModal.cancel')}>
      <div className="Business">
        <TextForm data={growerFields.titleField} typeForm="grower" />
      </div>
      <div className="Owner">
        <SelectOwner
          owners={owners}
          onChange={(id, email, account_id) => {
            setOwnerId(id);
            setOwnerEmail(email);
            setOwnerAccountId(account_id)
          }}
          selectedOwnerId={ownerId}
        />
      </div>
      <div className="First">
        <TextForm data={growerFields.firstNameField} typeForm="grower" />
      </div>
      <div className="Last">
        <TextForm data={growerFields.lastNameField} typeForm="grower" />
      </div>
      <div className="Country">
        <SelectCountry data={growerFields.countryField} />
      </div>
      <div className="State">
        {country === countries.USA || country === countries.USAShort ? (
          <SelectState data={growerFields.stateNameField} />
        ) : (
          <TextForm data={growerFields.cityField} typeForm="grower" />
        )}
      </div>
      <div className="Phone">
        <TextForm
          data={growerFields.phoneField}
          type="phone"
          code={countryMap.get(country)}
          typeForm="grower"
        />
      </div>
      <div className="Email">
        <TextForm
          data={growerFields.emailField}
          type="email"
          disabled={true}
          typeForm="grower"
        />
      </div>
      <div className="Description">
        <TextForm data={growerFields.descriptionField} typeForm="grower" />
      </div>
      <div className="Role">
        <SelectItem data={growerFields.roleField} items={growerRoleList} />
      </div>
      { isAdmin ?
          <div>
            <SelectedCheckbox data={growerFields.sendWeeklyReportField}/>
          </div>
          : null
      }
    </GenericModal>
  );
}

export default EditGrowerModal;
