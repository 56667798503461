import type { PayloadAction } from '@reduxjs/toolkit';
import type { TranslationState } from 'src/shared/data/types/languageStateType';
import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../../shared/i18/i18n';

const initialState: TranslationState = {
  language: i18n.language,
  loading: false,
};

export const LanguageSlice = createSlice({
  name: 'language-translation',
  initialState,
  reducers: {
    setUserLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
  },
});
export const { setUserLanguage } = LanguageSlice.actions;

export const languageReducer = LanguageSlice.reducer;
