import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  CurrWeather,
  WeatherState,
  Weather,
} from 'src/shared/data/types/weatherTypes';
import {
  getWeeklyWeatherData,
  getCurrWeatherData,
} from 'src/reduxToolkit/thunks/weather_thunks';

const initialState: WeatherState = {
  weeklyData: [],
  selectedWeek: [],
  currWeatherData: {
    windSpeed: 0,
    humidity: 0,
    temperature: undefined,
    weatherCode: 0,
  },
  httpErr: undefined,
};

export const WeatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setWeeklyData(state, action: PayloadAction<Weather[]>) {
      state.weeklyData = action.payload;
    },
    setCurrWeatherData(state, action: PayloadAction<CurrWeather>) {
      state.currWeatherData = action.payload;
    },
    setSelectedWeek(state, action: PayloadAction<Weather[]>) {
      state.selectedWeek = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getWeeklyWeatherData.fulfilled, (state, action) => {
      state.weeklyData = [...action.payload];
    });
    builder.addCase(getWeeklyWeatherData.rejected, (state) => {
      console.error('getWeeklyWeatherData.rejected: ', state.weeklyData);
    });
    builder.addCase(getCurrWeatherData.fulfilled, (state, action) => {
      state.currWeatherData = action.payload;
    });
    builder.addCase(getCurrWeatherData.rejected, (state) => {
      console.error('getCurrWeatherData.rejected: ', state.currWeatherData);
    });
  },
});

export const { setWeeklyData, setSelectedWeek, setCurrWeatherData } =
  WeatherSlice.actions;

export const weatherReducer = WeatherSlice.reducer;
