import { createAsyncThunk } from '@reduxjs/toolkit';
import { MeasurementSystem, User } from '../../shared/data/types/UserTypes';
import usersAdapter from '../adapters/UsersAdapter';

const getStandardizedMeasurementSystem = (
  country: string,
): MeasurementSystem => {
  const imperialCountries = [
    'US',
    'USA',
    'United States',
    'Myanmar',
    'Liberia',
  ];
  return imperialCountries.includes(country)
    ? MeasurementSystem.Imperial
    : MeasurementSystem.Metric;
};

// Utility function to standardize the measurement system
const standardizeMeasurementSystem = (userData: any): MeasurementSystem => {
  const lowerCaseInput = userData.measurementSystem?.toLowerCase();

  // Directly return "Metric" or "Imperial" if exact lowercase match found
  if (lowerCaseInput === 'metric') {
    return MeasurementSystem.Metric;
  }
  if (lowerCaseInput === 'imperial') {
    return MeasurementSystem.Imperial;
  }

  // For any non-standard inputs, utilize the getStandardizedMeasurementSystem function
  // which should return a MeasurementSystem value based on the country
  return getStandardizedMeasurementSystem(userData.country);
};

export const getUserDetails = createAsyncThunk<
  User,
  string,
  { rejectValue: Error }
>(
  'users',
  async (accessToken, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const { data } = await usersAdapter.getUserDetails(accessToken);

      if (data) {
        data.measurementSystem = standardizeMeasurementSystem(data);
      }
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateUserFarmId = createAsyncThunk<
  string,
  { token: string; farmId: string },
  { rejectValue: Error }
>(
  'users/update-employee-farm-manager-id',
  async (
    { token, farmId },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await usersAdapter.updateUserFarmId(token, farmId);
      const data: string | undefined = response ? farmId : undefined;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateUserGrowerId = createAsyncThunk<
  string,
  { token: string; growerCognitoId: string },
  { rejectValue: Error }
>(
  'users/update-employee-grower-cognito-id',
  async (
    { token, growerCognitoId },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await usersAdapter.updateUserGrowerId(
        token,
        growerCognitoId,
      );
      const data: string | undefined = response ? growerCognitoId : undefined;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getInstallers = createAsyncThunk<
  any,
  string,
  { rejectValue: Error }
>(
  'users/installers',
  async (accessToken, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await usersAdapter.getInstallers();
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
