import { Typography } from '@mui/material';
import { WeatherInfoBarElementProps } from 'src/shared/data/types/weatherTypes';
import { largeMaxWidth, mediumMaxWidth,smallMaxWidth } from 'src/shared/constants/consts';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { useStyles } from './useStyles';

function WeatherInfoBarElement({
  text,
  icon,
  measure,
  fontSize,
}: WeatherInfoBarElementProps): JSX.Element {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return width > largeMaxWidth ? (
    <div className={`justify-center align-center flex ${classes.root}`}>
      <img
        src={icon}
        alt="icon"
        style={{ minWidth: `calc(0.8rem + 12%)`, maxHeight: '1.3rem' }}
        className={classes.iconImg}
      />
      <Typography className={classes.text} variant="titleMedium">
        {text} {measure}{' '}
      </Typography>
    </div>
  ) : (
    <div
      className={`justify-center align-center ${
        width > mediumMaxWidth
          ? `flex ${classes.root}`
          : `flex-column ${classes.root}`
      }`}>
      <img src={icon} alt="icon" className={classes.iconImg} />
        {width > smallMaxWidth ? (
            <Typography
                className={classes.text}
                variant="titleMedium"
                fontSize={fontSize}>
                {measure}
            </Typography>
        ):
            <Typography
                className={classes.text}
                fontSize={fontSize}>
                {measure}
            </Typography>
        }
    </div>
  );
}
export default WeatherInfoBarElement;
