// src/shared/hooks/useTabs.tsx
import { NavbarState, UserRole } from 'src/shared/constants/enums';
import GrowersIcon from 'src/shared/components/Icon/GrowersIcon';
import FieldsIcon from 'src/shared/components/Icon/FieldsIcon';
import SensorsIcon from 'src/shared/components/Icon/SensorsIcon';
import AccountsIcon from 'src/shared/components/Icon/AccountsIcon';
import AgentsIcon from 'src/shared/components/Icon/AgentsIcon';
import { useTranslation } from 'react-i18next';
import { Tab } from 'src/shared/data/types/navbarTypes';
import {
  smallMaxWidth,
  mediumMinWidth,
  mediumExtraWidth,
  largeMediumWidth,
} from 'src/shared/constants/consts';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import UnitedStatesFlag from 'src/assets/Icons/sharedIcons/united-states.png';
import SpainFlag from 'src/assets/Icons/sharedIcons/spain.png';
import TurkeyFlag from 'src/assets/Icons/sharedIcons/turkey.png';
export const supportedLanguages = [
  {
    name: 'English (US)',
    code: 'en-US',
    flag: (
        <img
            src={UnitedStatesFlag}
            alt="united-states flag"
            style={{ width: '1.5rem', paddingInlineEnd: '0.5rem' }}
        />
    ),
  },
  {
    name: 'Spanish (Spain)',
    code: 'es-ES',
    flag: (
        <img
            src={SpainFlag}
            alt="SpainFlag"
            style={{ width: '1.5rem', paddingInlineEnd: '0.5rem' }}
        />
    ),
  },
  {
    name: 'Turkish',
    code: 'tr-TR',
    flag: (
        <img
            src={TurkeyFlag}
            alt="TurkeyFlag"
            style={{ width: '1.5rem', paddingInlineEnd: '0.5rem' }}
        />
    ),
  },
];

export const useTabs = (): Tab[] => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  // Mobile Devices: width <= smallMaxWidth
  if (width <= smallMaxWidth) {
    return [
      {
        value: NavbarState.GROWER,
        icon: <GrowersIcon />,
        label: t('navbar.growers') ?? NavbarState.GROWER,
        userRole: [
          UserRole.AGENT_VIEWER,
          UserRole.AGENT_ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.ACCOUNT_ADMIN,
          UserRole.ADMIN,
        ],
      },
      {
        value: NavbarState.FIELDS,
        icon: <FieldsIcon />,
        label: t('navbar.fields') ?? NavbarState.FIELDS,
        userRole: [
          UserRole.GROWER,
          UserRole.RESEARCHER_GROWER,
          UserRole.SCIENTIST,
          UserRole.AGENT_VIEWER,
          UserRole.AGENT_ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.ACCOUNT_ADMIN,
          UserRole.ADMIN,
        ],
      },
    ];
  }

  // Screens wider than 768px and narrower than 850px
  if (width > mediumMinWidth && width < mediumExtraWidth) {
    return [
      {
        value: NavbarState.GROWER,
        icon: <GrowersIcon />,
        label: t('navbar.growers') ?? NavbarState.GROWER,
        userRole: [
          UserRole.AGENT_VIEWER,
          UserRole.AGENT_ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.ACCOUNT_ADMIN,
          UserRole.ADMIN,
        ],
      },
      {
        value: NavbarState.FIELDS,
        icon: <FieldsIcon />,
        label: t('navbar.fields') ?? NavbarState.FIELDS,
        userRole: [
          UserRole.GROWER,
          UserRole.RESEARCHER_GROWER,
          UserRole.SCIENTIST,
          UserRole.AGENT_VIEWER,
          UserRole.AGENT_ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.ACCOUNT_ADMIN,
          UserRole.ADMIN,
        ],
      },
    ];
  }

  // Screens wider than 850px and narrower than 1199px
  if (width > mediumExtraWidth && width < largeMediumWidth) {
    return [
      {
        value: NavbarState.GROWER,
        icon: <GrowersIcon />,
        label: t('navbar.growers') ?? NavbarState.GROWER,
        userRole: [
          UserRole.AGENT_VIEWER,
          UserRole.AGENT_ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.ACCOUNT_ADMIN,
          UserRole.ADMIN,
        ],
      },
      {
        value: NavbarState.FIELDS,
        icon: <FieldsIcon />,
        label: t('navbar.fields') ?? NavbarState.FIELDS,
        userRole: [
          UserRole.GROWER,
          UserRole.RESEARCHER_GROWER,
          UserRole.SCIENTIST,
          UserRole.AGENT_VIEWER,
          UserRole.AGENT_ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.ACCOUNT_ADMIN,
          UserRole.ADMIN,
        ],
      },
      {
        value: NavbarState.SENSORS,
        icon: <SensorsIcon />,
        label: t('navbar.sensors') ?? NavbarState.SENSORS,
        userRole: [UserRole.SCIENTIST, UserRole.ADMIN],
      },
      {
        value: NavbarState.ACCOUNTS,
        icon: <AccountsIcon />,
        label: t('navbar.accounts') ?? NavbarState.ACCOUNTS,
        userRole: [UserRole.ADMIN],
      },
      {
        value: NavbarState.AGENTS,
        icon: <AgentsIcon />,
        label: t('navbar.agents') ?? NavbarState.AGENTS,
        userRole: [
          UserRole.ADMIN,
          UserRole.ACCOUNT_VIEWER,
          UserRole.ACCOUNT_ADMIN,
          UserRole.AGENT_ADMIN,
          UserRole.AGENT_VIEWER,
        ],
      },
    ];
  }

  // Screens wider than 1199px
  return [
    {
      value: NavbarState.GROWER,
      icon: <GrowersIcon />,
      label: t('navbar.growers') ?? NavbarState.GROWER,
      userRole: [
        UserRole.AGENT_VIEWER,
        UserRole.AGENT_ADMIN,
        UserRole.ACCOUNT_VIEWER,
        UserRole.ACCOUNT_ADMIN,
        UserRole.ADMIN,
      ],
    },
    {
      value: NavbarState.FIELDS,
      icon: <FieldsIcon />,
      label: t('navbar.fields') ?? NavbarState.FIELDS,
      userRole: [
        UserRole.GROWER,
        UserRole.RESEARCHER_GROWER,
        UserRole.SCIENTIST,
        UserRole.AGENT_VIEWER,
        UserRole.AGENT_ADMIN,
        UserRole.ACCOUNT_VIEWER,
        UserRole.ACCOUNT_ADMIN,
        UserRole.ADMIN,
      ],
    },
    {
      value: NavbarState.SENSORS,
      icon: <SensorsIcon />,
      label: t('navbar.sensors') ?? NavbarState.SENSORS,
      userRole: [UserRole.SCIENTIST, UserRole.ADMIN],
    },
    {
      value: NavbarState.ACCOUNTS,
      icon: <AccountsIcon />,
      label: t('navbar.accounts') ?? NavbarState.ACCOUNTS,
      userRole: [UserRole.ADMIN],
    },
    {
      value: NavbarState.AGENTS,
      icon: <AgentsIcon />,
      label: t('navbar.agents') ?? NavbarState.AGENTS,
      userRole: [
        UserRole.ADMIN,
        UserRole.ACCOUNT_VIEWER,
        UserRole.ACCOUNT_ADMIN,
        UserRole.AGENT_ADMIN,
        UserRole.AGENT_VIEWER,
      ],
    },
  ];
};