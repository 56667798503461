import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import { Button } from 'antd';
import { Button as MapButton } from '@mui/material';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import myLocation from 'src/assets/Icons/sharedIcons/myLocationIcon.svg';
import Map from '../../../../../shared/components/Map';
import useStyles from '../useStyles';
import { MarkerPointType } from '../../../../../shared/data/types/markerTypes';

function LocationForm(props: any): JSX.Element {
  const {
    onChange,
    handleSave,
    onClose,
    farmProps,
    farmFields,
    handleLoading,
    onPastedLocation,
  } = props;
  const classes = useStyles();
  const [currentCoordinates, setCurrentCoordinates] = useState<any>();
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(7);
  const [farmCenterMarkers, setFarmCenterMarkers] = useState<MarkerPointType[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [hoverOnMapButton, setHoverOnMapButton] = useState<boolean>(false);
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const { t } = useTranslation();

  const onPaste = (e: string): any => {
    if (typeof e !== 'string') {
      return;
    }
    const items: any[] = [];
    e.split(',')
      .map((item: any) => item.trim())
      .forEach((i: any) => {
        items.push(i);
        const coordinates = {
          lat: parseFloat(items[0]),
          lng: parseFloat(items[1]),
        };
        if (coordinates.lat && coordinates.lng) {
          onPastedLocation(coordinates);
          setCurrentCoordinates(coordinates);
          setFarmCenterMarkers([coordinates]);
        }
      });
    setZoom(15);
  };

  const currentLocation = (loadingDefaultValue: boolean): void => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          if (loadingDefaultValue) {
            setDefaultCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          } else {
            setCurrentCoordinates({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
          setLoading(false);
          setZoom(15);
        },
      );
    }
  };

  useEffect(() => {
    currentLocation(true);
  }, []);

  const mapButtons = [
    {
      name: `${t('map.myLocationTooltip')}`,
      icon: <img src={myLocation} alt="plus" style={{ width: '1.3rem' }} />,
      handler: (e: any): void => {
        e.stopPropagation();
        e.preventDefault();
        currentLocation(false);
      },
    },
  ];

  return (
    <div className={classes.locationContainer}>
      <div className={`${classes.mapContainer}`}>
        <Map
          mapCenter={currentCoordinates}
          defaultCenter={defaultCenter}
          blocksPolygons={[]}
          drawModeIndicator={false}
          isPointCoordinatesAvailable
          mapZoom={zoom}
          autocomplete
          isNewBlockCreationInProgress
          markersPoints={farmCenterMarkers}
          onPaste={onPaste}
          onPointClick={(e: any) => {
            onChange([e.latLng.lat(), e.latLng.lng()]);
            const coordinates = {
              lat: Math.round(e.latLng.lat() * 1000000) / 1000000,
              lng: Math.round(e.latLng.lng() * 1000000) / 1000000,
            };
            setCurrentCoordinates(coordinates);
            setFarmCenterMarkers([coordinates]);
          }}
          farmProps={farmProps}
          farmFields={farmFields}
        />
        <div className={classes.mapSettingsBtnContainer}>
          {mapButtons.map((button) => {
            return (
              <MapButton
                className={classes.farmMapSettingsBtn}
                type="button"
                key={button.name}
                onClick={(e) => button.handler(e)}
                onMouseEnter={() => setHoverOnMapButton(true)}>
                {hoverOnMapButton ? (
                  <IconWithToolTip
                    color="#fff"
                    svgIcon={button.icon ? button.icon : button.name}
                    text={button.name}
                  />
                ) : button.icon ? (
                  button.icon
                ) : (
                  button.name
                )}
              </MapButton>
            );
          })}
          {!loading && <div>{t('addFarmModal.loading')}</div>}
        </div>
      </div>
      <div
        className={`common-button-container flex justify-between ${classes.modalBtnContainer}`}>
        <Button
          id="save-new-farm"
          disabled={
            handleLoading ||
            !farmProps.title?.trim() ||
            !farmProps.farmCenterLat ||
            !farmProps.farmCenterLng
          }
          onClick={(e) => handleSave(e)}
          className={`ant-btn-custom ${
            isToroAccount
              ? classes.toroModalAddFarmBtn
              : classes.modalAddFarmBtn
          }`}>
          {`${t('addFarmModal.addFarmBtn')}`}
        </Button>
        <Button
          onClick={() => onClose()}
          className={
            isToroAccount ? classes.toroModalCancelBtn : classes.modalCancelBtn
          }>
          {t('addFarmModal.cancel')}
        </Button>
      </div>
    </div>
  );
}

export default LocationForm;
