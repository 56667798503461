import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import greenExpandArrow from 'src/assets/Icons/mainThemeIcons/greenExpandArrow.svg';
import toroExpandArrow from 'src/assets/Icons/toroThemeIcons/toroExpandArrow.svg';
import greenUpArrow from 'src/assets/Icons/mainThemeIcons/green-up-arrow.svg';
import toroUpArrow from 'src/assets/Icons/toroThemeIcons/toro-up-arrow.svg';
import { useStyles } from './useStyles';

function DropDown({
                    onClick,
                    arrowDirection,
                  }: {
  onClick: () => void;
  arrowDirection: string;
}): JSX.Element {
  const isToroAccount = useAppSelector(
      (state) => state.rootReducer.accountState.isToroAccount,
  );
  const classes = useStyles();
  const { t } = useTranslation();

  return (
      <div
          className={classes.dropdownBtn}
          onClick={() => onClick()}
          onKeyDown={() => onClick()}
          role="button"
          tabIndex={0}>
        <span className={classes.text}>{t('weather.weatherForecast')}</span>
        {arrowDirection === 'down' && (
            <img
                src={isToroAccount ? toroExpandArrow : greenExpandArrow}
                className={classes.downArrow}
                alt="down arrow"
            />
        )}
        {arrowDirection === 'up' && (
            <img
                src={isToroAccount ? toroUpArrow : greenUpArrow}
                className={classes.upArrow}
                alt="up arrow"
            />
        )}
      </div>
  );
}

export default DropDown;