import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { ActualIrrigation } from 'src/shared/data/types/blockDataTypes';

class FlowDataAdapter {
    static readonly endpoint = `flow-data`;

    async getFlowData(id: number): Promise<AxiosResponse<ActualIrrigation[]>> {
        return HttpClientService.get<ActualIrrigation[]>(FlowDataAdapter.endpoint, {
            params: { id },
        });
    }
}

const flowDataAdapter = new FlowDataAdapter();
export default flowDataAdapter;
