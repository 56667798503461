import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
    actualIrrigationState,
    ActualIrrigation,
} from '../../../shared/data/types/blockDataTypes';

const initialState = { flow_data: {} } as actualIrrigationState;

export const FlowDataSlice = createSlice({
    name: 'flow-data',
    initialState,
    reducers: {
        setFlowData(state, action: PayloadAction<ActualIrrigation[]>) {
            const blockId = action.payload[0]?.block_id;
            state.flow_data[blockId] = action.payload;
        },
    },
});

export const { setFlowData } = FlowDataSlice.actions;

export const flowDataReducer = FlowDataSlice.reducer;
