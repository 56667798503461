import { darken, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    maxWidth: '100%',
    minWidth: '50vw',
  },
  title: {
    color: theme.palette.oldPrimary.main,
    fontSize: '2rem',
    fontWeight: '700',
    marginBlock: 'unset',
  },
  subTitle: {
    color: theme.palette.oldPrimary.main,
    fontSize: '0.8rem',
    fontWeight: '400',
    marginBlock: 'unset',
  },
  form: {
    minWidth: '85%',
    '& .ant-steps': {
      paddingInline: '30%',
    },
    '& .ant-steps-item-icon': {
      border: '2px solid',
      borderColor: theme.palette.oldPrimary.main,
      display: 'inline-flex',
      justifyContent: 'center',
      width: '36px',
      height: '36px',
      backgroundColor: 'transparent',
    },

    '& .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon': {
      color: theme.palette.oldPrimary.main,
      fontWeight: 500,
      alignSelf: 'center',
      justifySelf: 'center',
    },
    '& .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon':
      {
        background: 'transparent',
        borderColor: theme.palette.accent.main,
        '& .ant-steps-icon': {
          color: theme.palette.accent.main,
          fontWeight: 500,
          alignSelf: 'center',
          justifySelf: 'center',
        },
      },
    '& .ant-steps-item-finish .ant-steps-item-icon': {
      backgroundColor: theme.palette.accent.main,
      borderColor: theme.palette.accent.main,
      '& .ant-steps-icon': {
        alignSelf: 'center',
        justifySelf: 'center',
      },
      '& .anticon svg': {
        '& path': {
          fill: '#fff',
        },
      },
    },
    '& .ant-steps-item-title:after': {
      border: '1px solid',
      borderColor: theme.palette.hoverGrey.main,
      backgroundColor: theme.palette.hoverGrey.main,
    },
    '& .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after':
      {
        border: '1px solid',
        borderColor: theme.palette.hoverGrey.main,
        backgroundColor: theme.palette.hoverGrey.main,
      },
  },
  stepsAction: {
    gap: '0.2rem',
  },
  formButtons: {
    display: 'flex',
    gap: '11px',
    marginTop: '1rem',
    '& button': {
      minWidth: '10vw',
      width: 'fit-content',
      height: '40px',
      border: `1px solid ${theme.palette.accent.main}`,
      borderRadius: '4px',
      backgroundColor: theme.palette.accent.main,
      color: '#fff',
      '&[disabled]': {
        backgroundColor: '#76778673 !important',
        userSelect: 'none',
        color: '#fff !important',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
      '&:hover:enabled': {
        color: '#fff',
        backgroundColor: darken(theme.palette.accent.main, 0.1),
      },
    },
    '& .ant-btn-ghost': {
      backgroundColor: '#fff',
      color: theme.palette.accent.main,
    },
    '& .ant-btn-ghost:focus, & .ant-btn-ghost:hover': {
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.accent.main}`,
    },
    '& .ant-btn-primary:focus, & .ant-btn-primary:hover': {
      backgroundColor: theme.palette.accent.main,
      border: `1px solid ${theme.palette.accent.main}`,
    },
  },
  backBtn: (props: { current: number }) => ({
    opacity: props.current > 0 ? 1 : 0,
    border: 'none',
    background: 'transparent',
    display: 'flex',
    gap: '5px',
    justifyContent: 'center',
    cursor: 'pointer',
    '& .icon': {
      '& svg': {
        width: '5px',
        height: '10px',
      },
    },
    '&:hover': {
      color: theme.palette.accent.main,
    },
    '&:active': {
      fontSize: 13,
      color: theme.palette.oldPrimary.main,
      '& .icon': {
        '& svg': {
          width: '4px',
          height: '8px',
        },
      },
    },
  }),
  errorText: {
    color: theme.palette.red.main,
    marginBottom: '5px',
  },
  stepContent: {
    height: '400px',
    paddingBlockEnd: '1rem',
    maxHeight: '450px',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
