import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { GrowerReportsState } from 'src/shared/data/types/GrowerReportsTypes';

class GrowerReportsAdapter {
  static readonly endpoint = `/growers`;

  async getGrowerWeeksReport(
    growerId: number,
    weekStartDay: number,
  ): Promise<AxiosResponse<GrowerReportsState>> {
    return HttpClientService.get<GrowerReportsState>(
      `${GrowerReportsAdapter.endpoint}/weeks-grower-report/${growerId}/${weekStartDay}`,
    );
  }

  async getGrowerWeeksDownloadReport(
    growerId: any,
    weekStartDay: number,
    mSys: string,
  ): Promise<AxiosResponse<GrowerReportsState>> {
    return HttpClientService.get<GrowerReportsState>(
      `${GrowerReportsAdapter.endpoint}/weeks-grower-report-download/${growerId}/${weekStartDay}/${mSys}`,
      { responseType: 'arraybuffer' },
    );
  }
}

const growerReportsAdapter = new GrowerReportsAdapter();
export default growerReportsAdapter;
