import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { GrowerFarmsState } from 'src/shared/data/types/growerFarmsTypes';

class GrowerFarmsAdapter {
  static readonly endpoint = `/growers`;

  async getGrowerFarms(id: number): Promise<AxiosResponse<GrowerFarmsState>> {
    return HttpClientService.get<GrowerFarmsState>(
      `${GrowerFarmsAdapter.endpoint}/grower-farms`,
      {
        params: { id },
      },
    );
  }
}

const growerFarmsAdapter = new GrowerFarmsAdapter();
export default growerFarmsAdapter;
