import React from 'react';
import useModalStyles from './useModalStyles';
import { useTranslation } from 'react-i18next';

interface CardSectionProps {
    total: string;
    average: string;
    dayQty: string;
    prefix:  string
}
const CardSection: React.FC<CardSectionProps> = ({ total, average, dayQty , prefix}) => {
    const classes = useModalStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.cardRow}>
            <div className={classes.card}>
                <div>
                    <div className={classes.cardTitle}>{t('blockDataWidget.total')}</div>
                    <div className={classes.cardSubtitle}>{prefix} {dayQty} </div> {/* Display number of days */}
                </div>
                <div className={classes.cardValue}>{total}</div>
            </div>

            <div className={classes.card}>
                <div>
                    <div className={classes.cardTitle}>{t('blockDataWidget.daily_average')}</div>
                    <div className={classes.cardSubtitle}>{prefix} {dayQty} </div> {/* Display number of days */}
                </div>
                <div className={classes.cardValue}>{average}</div>
            </div>
        </div>
    );
};

export default CardSection;
