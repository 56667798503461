// react
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

// redux
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import getGrowerFarms from 'src/reduxToolkit/thunks/grower_farm_thunks';
import { setCurrentGrower } from '../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';

// components
import Grower from './Grower/Grower';
import ProgressBar from '../../shared/components/ProgressBar';

// types
import { MiniGrower } from '../../shared/data/types/growerFarmsTypes';

// resources
import { asNumber } from '../../utils/numbering';

function Growers(): JSX.Element {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const routeParams = useParams<{ id?: string }>();

  const growerId = asNumber(routeParams.id);
  const farmId = asNumber(searchParams.get('farmId'));
  const blockId = asNumber(searchParams.get('blockId'));

  const grower: MiniGrower | null = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState.currentGrower;
  });

  async function fetchGrowerFarms(id: number) {
    // check if exists in reducer
    if (grower && grower.id === id) return;

    try {
      dispatch(setCurrentGrower(null));
      const response = await dispatch(getGrowerFarms(id)).unwrap();
      dispatch(setCurrentGrower(response.grower));
    } catch (error) {
      console.error('Failed to fetch grower farms:', error);
    }
  }

  useEffect(() => {
    if (growerId) fetchGrowerFarms(growerId).then((_) => _);
  }, [growerId]);

  return grower ? (
    <Grower grower={grower} farmId={farmId} blockId={blockId} />
  ) : (
    <ProgressBar />
  );
}

export default Growers;
//check