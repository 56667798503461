import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { PolygonType } from 'src/shared/data/types/polygonTypes';

class PolygonsAdapter {
  static readonly endpoint = `/blocks/polygonsByFarm`;

  async getPolygonsByFarm(
    farmId: number,
  ): Promise<AxiosResponse<PolygonType[]>> {
    return HttpClientService.get<PolygonType[]>(
      `${PolygonsAdapter.endpoint}/${farmId}`,
      {
        params: { farmId },
      },
    );
  }
}
const polygonsAdapter = new PolygonsAdapter();
export default polygonsAdapter;
