import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  calibrateBlockContainer: {
    width: '100%',
    flex: 1,
    gap: '0.4rem',
  },
  calibrateBlockIcon: {
    maxWidth: '1.5rem',
  },
  calibrateBlockText: {
    color: theme.palette.oldPrimary.main,
    fontSize: 'calc(0.6rem + 0.4vw  + 0.4vh) !important',
    lineHeight: 'unset !important',
    fontWeight: '700 !important',
    margin: '0 !important',
    '& small': {
      fontWeight: 500,
    },
  },
}));

export default useStyles;
