import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    background: '#fff',
    width: '100%',
    height: '30px',
  },
  daysContainer: {
    height: '100%',
    alignItems: 'center',
    zIndex: 0,
    display: 'flex',
  },
  headerNavigation: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.accent.main,
      top: '22px',
    },
    '& .MuiTabs-flexContainer': {
      gap: '5px',
      '& .MuiButtonBase-root': {
        zIndex: 0,
      },
    },
  },
  days: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: `${theme.palette.oldPrimary.main} !important`,
    fontSize: '1rem',
    opacity: 0.8,
    '&:hover': {
      color: theme.palette.accent.main,
      opacity: 1,
      fontWeight: '700',
    },
    '&.MuiButtonBase-root': {
      color: `${theme.palette.oldPrimary.main} !important`,
      padding: '0 0 0.3rem 0',
      minWidth: '70px',
      minHeight: '0',
    },
    '&.MuiButtonBase-root.Mui-selected': {
      opacity: 1,
      color: `${theme.palette.oldPrimary.main} !important`,
      fontSize: '1rem',
      fontWeight: '700',
    },
  },
  datePickerBtn: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.secondary.main,
    border: 'solid',
    borderColor: theme.palette.accent.main,
    borderRadius: '8px',
    margin: '10px 5px',
    '&:hover': {
      backgroundColor: '#59bf67',
      borderColor: '#59bf67',
    },
    '&:active': {
      backgroundColor: '#56b964',
      borderColor: '#56b964',
    },
  },
}));

export default useStyles;
