import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      position: 'absolute',
      top: 'calc(55px + 1vh)',
      left: '0.8rem',
      width: 'calc(100% - 1.6rem)',
      zIndex: 125,
      boxShadow: '0px 15px 50px rgba(160, 189, 163, 0.3)',
      borderRadius: '4px',
      display: (props: { isOpen: boolean }) =>
        props.isOpen ? 'block' : 'none',
    },
    sensorContainer: {
      position: 'absolute',
      top: 'calc(55px + 1vh)',
      left: '2.5%',
      width: '95%',
      zIndex: 125,
      boxShadow: '0px 15px 50px rgba(160, 189, 163, 0.3)',
      borderRadius: '4px',
      display: (props: { isOpen: boolean }) =>
        props.isOpen ? 'block' : 'none',
    },
    item: {
      cursor: 'pointer',
      background: theme.palette.backgroundGrey.main,
      height: '100%',
      color: theme.palette.oldPrimary.main,
      fontSize: '16px',
      padding: '16px 22px',
      borderBlockEnd: `1px solid ${theme.palette.oldPrimary.main}`,
      '&:last-of-type': {
        borderBlockEnd: 'none',
      },
    },
    Selected: {
      fontWeight: 'bold',
    },
    vSign: {
      float: 'left',
      marginInlineStart: '-18px',
      marginBlockStart: '7px',
    },
    title: {},
    '@media (max-width: 481px)': {},
  }),
  { name: 'FarmDropdownList' },
);
export default useStyles;
