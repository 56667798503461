import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { MeasurementSystem } from 'src/shared/data/types/UserTypes';
import { roundPrecision } from 'src/shared/helpers/HelperFunctions';
import { measurementSystemConvertValue } from 'src/shared/measurements-converter/converter';
import {
  ConversionActions,
  ConversionResult,
} from 'src/shared/measurements-converter/converter.types';

export default function WeeklyEtaSum(props: any): JSX.Element {
  const { farmIdx, row, blockIdx } = props;
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );
  const etaArr: any = [];
  const duplicatedDates: any = [];

  row?.farms[farmIdx].blocks[blockIdx].data?.forEach((data: any) => {
    if (duplicatedDates.includes(data.dateTitle)) return;
    duplicatedDates.push(data.dateTitle);
    etaArr.push(data.ETa);
  });

  const calcSum = (): any => {
    const sum = etaArr.reduce((a: number, b: number) => a + b, 0);
    const cSum: ConversionResult = measurementSystemConvertValue(
      sum,
      selectedMeasurementSystem,
      ConversionActions.MillimeterAndInches,
    );
    return selectedMeasurementSystem === MeasurementSystem.Metric
      ? `${roundPrecision(sum, 2)}`
      : `${roundPrecision(cSum?.value ?? null, 3)}`;
  };

  return <>{calcSum()}</>;
}
