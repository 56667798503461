import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { AccountField } from 'src/shared/data/types/accountOptionsTypes';
import StatesOptions from 'src/shared/data/StateOptions';
import useStyles from '../useStyles';

const hasError = (data: AccountField): boolean =>
  !!data.fieldStateTouch && !data.fieldState;

export default function SelectLocation(props: {
  data: AccountField;
  disabled?: boolean;
}): JSX.Element {
  const { data, disabled } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const helper = (): string => `${t('agentModal.stateHelper')}`;

  const handleChangeState = (e: any): any => {
    if (data.setFieldStateString !== undefined) {
      data.setFieldStateString(e);
    } else if (data.setFieldStateNumber !== undefined) {
      data.setFieldStateNumber(e);
    }
  };
  const handleBlur = (): void => {
    if (data.setFieldStateTouch != null) {
      data.setFieldStateTouch(true);
    }
  };
  return (
    <div
      className={`flex-column ${data.fieldName}`}
      style={{ width: '100%', marginBottom: '-0.5rem' }}>
      <span id="state">{t('accountFieldModal.state')}</span>
      <Form.Item
        help={!disabled && hasError(data) && helper()}
        validateStatus={!disabled && hasError(data) ? 'error' : ''}>
        <Select
          id="state"
          disabled={disabled}
          className={classes.select}
          style={{ width: '100%' }}
          defaultValue={data.fieldState}
          showSearch
          data-hj-allow
          onChange={(e) => handleChangeState(e)}
          onBlur={() => handleBlur()}
          options={StatesOptions.map((singleState: any) => ({
            value: singleState,
            label: singleState,
          }))}
        />
      </Form.Item>
    </div>
  );
}
