import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  icon: {
    '& svg': {
      width: '15px',
    },
    display: 'flex',
    alignItems: 'center',
  },
});

export default useStyles;
