import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  GrowerReportsState,
  ReportByWeek,
} from 'src/shared/data/types/GrowerReportsTypes';

const initialState: GrowerReportsState = {
  weeklyReports: { weeklyReports: [] },
  httpErr: undefined,
};

export const GrowerReportsSlice = createSlice({
  name: 'weeklyReports',
  initialState,
  reducers: {
    setWeeklyReports(state, action: PayloadAction<ReportByWeek>) {
      state.weeklyReports = action.payload;
    },
  },
});

export const { setWeeklyReports } = GrowerReportsSlice.actions;

export const GrowerReportsSliceReducer = GrowerReportsSlice.reducer;
