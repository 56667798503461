import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.backgroundGrey.main,
    justifyContent: 'space-around',
    width: '100%',
    height: 'max-content',
    padding: '1rem',
    borderRadius: '4px',
    '@media (max-width: 767px)': {
      padding: '0',
    },
  },
  separationLineWrapper: {
    width: '2%',
    '@media (max-width: 767px)': {
      width: '0%',
    },
  },
  separationLine: {
    height: '92%',
    width: 0,
    border: `solid 0.5px ${theme.palette.oldPrimary.main}`,
    opacity: 0.1,
    '@media (max-width: 767px)': {
      width: '1%',
    },
  },
}));

export default useStyles;
