import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { updateBlockField } from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { InputFormFieldProps } from 'src/shared/data/types/formsTypes';
import { convertInvertValue } from 'src/shared/measurements-converter/converter';
import useStyles from './useStyles';
import {
  Measurement,
  MeasurementSys,
} from '../../../../../shared/measurements-converter/measurement-enums/measurement-enums';
import { MeasurementSystem } from '../../../../../shared/data/types/UserTypes';

function InputFormField(props: InputFormFieldProps): JSX.Element {
  const {
    title,
    fieldName,
    placeholder,
    isNumber = false,
    minNumber,
    maxLength,
    errorMessage,
    errorValidNumMessage,
    readonly,
    value,
    isUniqueTitle,
    setIsUniqueTitle,
    farm,
  } = props;
  const { block } = useAppSelector(
    (state) => state.rootReducer.addOrEditBlockState,
  );
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isInBlur, setIsInBlur] = useState(false);
  const [blockTitleErrorMessage, setBlockTitleErrorMessage] = useState('');
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );
  const userCountry = useAppSelector(
    (state) => state.rootReducer.userState.country,
  );
  const measureSys =
    selectedMeasurementSystem === MeasurementSystem.Metric
      ? MeasurementSys.METRIC
      : MeasurementSys.IMPERIAL;

  const defaultValue = useMemo(() => {
    if (block && fieldName !== 'zonalDataJson') {
      if (isNumber) {
        const val = block?.[fieldName];
        const numVal = Number(val);
        if (Number.isNaN(numVal) || val === -1) {
          return '';
        }
        return numVal;
      }
      return block[fieldName]?.toString() ?? '';
    }
    return '';
  }, [block, fieldName, isNumber]);

  const handleChangeInputOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const val: string | number | undefined = isNumber
        ? e.target.value === ''
          ? -1
          : Number(e.target.value)
        : Number.isFinite(e.target.value)
        ? Number(e.target.value)
        : e.target.value;
      let convertedValue = val;

      switch (fieldName) {
        case 'treeSpacing':
        case 'rowSpacing':
          convertedValue = convertInvertValue(
            userCountry,
            measureSys,
            Measurement.LENGTH,
            Number(val),
          );
          break;
        case 'systemApplicationRate':
          convertedValue = convertInvertValue(
            userCountry,
            measureSys,
            Measurement.FLOW_RATE,
            Number(val),
          );
          break;
        default:
          // For fields that do not require conversion, use the original numeric value
          convertedValue = val;
          break;
      }
      dispatch(
        updateBlockField({
          field: fieldName,
          value: isNumber ? convertedValue : val,
        }),
      );

      setIsInBlur(true);
    },
    [isNumber, dispatch, fieldName, userCountry],
  );

  const handleUniqueBlockTitle = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const val: string | number | undefined = e.target.value;
      if (setIsUniqueTitle) {
        const blockTitles = farm.blocks
          .filter((b) => b.id !== block?.id)
          .map((block) => block.title);

        if (blockTitles.includes(val)) {
          setIsUniqueTitle(false);
          setBlockTitleErrorMessage(
            `${t('BlockInfoForm.blockTitleAlreadyExistErrorMessage')}`,
          ); // Set the specific error message
        } else {
          setIsUniqueTitle(true);
          setBlockTitleErrorMessage(''); // Clear the error message if the title is unique
        }
      }
      setIsInBlur(true);
    },
    [setIsUniqueTitle, t, farm.blocks, block?.title, block],
  );

  return (
    <span className={classes.root}>
      <span>{title}</span>
      <input
        defaultValue={value ?? defaultValue}
        placeholder={placeholder}
        className={`common-input ${
          isInBlur &&
          !defaultValue &&
          defaultValue !== 0 &&
          Number(defaultValue) < 1
            ? 'input-error'
            : ''
        }`}
        onBlur={(e) => {
          handleChangeInputOnBlur(e); // Always handle the change on blur
          if (fieldName === 'title') {
            // Only handle unique title check for the 'title' field
            handleUniqueBlockTitle(e);
          }
        }}
        type={isNumber ? 'number' : 'text'}
        min={minNumber}
        readOnly={readonly}
        maxLength={maxLength ?? maxLength}
        data-hj-allow=""
      />
      {isInBlur &&
        (defaultValue === '' ? (
          <span className="input-error-text">{errorMessage}</span>
        ) : !isUniqueTitle ? (
          <span className="input-error-text">{blockTitleErrorMessage}</span>
        ) : defaultValue === -1 ? (
          <span className="input-error-text">{errorMessage}</span>
        ) : Number(defaultValue) < 1 ? (
          <span className="input-error-text">{errorValidNumMessage}</span>
        ) : null)}
    </span>
  );
}

export default InputFormField;
