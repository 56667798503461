import React, { useMemo, useCallback } from 'react';
import { Typography } from '@mui/material';
import { renderToString } from 'react-dom/server';
import { useAppSelector, useAppDispatch } from '../../../../reduxToolkit/store/hooks';
import toroForwardArrow from '../../../../assets/Icons/toroThemeIcons/toro-forward-arrow.svg';
import greenForwardArrow from '../../../../assets/Icons/mainThemeIcons/green-forward-arrow.svg';
import { getCurrWeatherData } from '../../../../reduxToolkit/thunks/weather_thunks';
import getWeatherIcon from '../../../../shared/data/WeatherIcons';
import { convertAndRound, convertLabel } from '../../../../shared/measurements-converter/converter';
import { MeasurementSystem } from '../../../../shared/data/types/UserTypes';
import useStyles from './useStyles';
import { Measurement, MeasurementSys } from '../../../../shared/measurements-converter/measurement-enums/measurement-enums';
import { WeatherHelper, WeatherHelperProps } from '../../../../shared/data/types/weatherTypes';

const useWeatherHelper = (props: WeatherHelperProps): WeatherHelper => {
  const { currentFarm, isWeatherBarOpen, setIsWeatherBarOpen, setCurrentMapCenter } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const selectedMeasurementSystem = useAppSelector((state) => state.rootReducer.userState.measurementSystem);
  const currWeatherData = useAppSelector((state) => state.rootReducer.weatherState.currWeatherData);
  const isToroAccount = useAppSelector((state) => state.rootReducer.accountState.isToroAccount);
  const userCountry = useAppSelector((state) => state.rootReducer.userState.country);

  const measureSys = selectedMeasurementSystem === MeasurementSystem.Metric
      ? MeasurementSys.METRIC
      : MeasurementSys.IMPERIAL;

  const currentTemperatureValue = convertAndRound(
      userCountry,
      measureSys,
      Measurement.TEMPERATURE,
      currWeatherData?.temperature,
      0
  );

  const currentTemperatureLabel = `${convertLabel(
      userCountry,
      measureSys,
      Measurement.TEMPERATURE
  )}`;

  const currentTemperatureIconComponent = getWeatherIcon(currWeatherData?.weatherCode);

  const currentTemperatureIcon = useMemo(
      () => currentTemperatureIconComponent && renderToString(currentTemperatureIconComponent),
      [currentTemperatureIconComponent]
  );

  const currentWeather = useCallback(() => {
    if (currentFarm != null) {
      void dispatch(
          getCurrWeatherData({
            lat: JSON.stringify(currentFarm?.centerLat),
            lng: JSON.stringify(currentFarm?.centerLng),
          })
      );
    }
    if (setCurrentMapCenter) {
      setCurrentMapCenter({
        lat: currentFarm?.centerLat ?? 0,
        lng: currentFarm?.centerLng ?? 0,
      });
    }
  }, [dispatch, currentFarm, setCurrentMapCenter]);

  const openWeatherBarBtn = [
    {
      name: !isWeatherBarOpen ? (
          <div id="open-current-weather" className="flex align-center justify-between">
            <div id="open-current-weather" className="flex align-center">
              <img src={currentTemperatureIcon} alt="cloud and sun icon" id="open-current-weather" />
              {currWeatherData?.temperature !== undefined &&
                  currWeatherData?.temperature !== null && (
                      <Typography id="open-current-weather" fontSize={24}>
                        {`${currentTemperatureValue}${currentTemperatureLabel}`}
                      </Typography>
                  )}
            </div>
            <span
                role="button"
                id="open-current-weather"
                className={classes.forwardArrow}
                onClick={(e) => {
                  e.stopPropagation();
                  if (setIsWeatherBarOpen) {
                    setIsWeatherBarOpen(!isWeatherBarOpen);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.stopPropagation();
                    if (setIsWeatherBarOpen) {
                      setIsWeatherBarOpen(!isWeatherBarOpen);
                    }
                  }
                }}
                tabIndex={0}
            >
            {isToroAccount ? (
                <img src={toroForwardArrow} alt="forward" id="open-current-weather" />
            ) : (
                <img src={greenForwardArrow} alt="forward" id="open-current-weather" />
            )}
          </span>
          </div>
      ) : (
          ''
      ),
      handler: (e: any): void => {
        e.stopPropagation();
        if (setIsWeatherBarOpen) {
          setIsWeatherBarOpen(!isWeatherBarOpen);
        }
      },
    },
  ];

  return {
    currentTemperatureIcon,
    currentTemperatureValue,
    currentTemperatureLabel,
    currentWeather,
    openWeatherBarBtn,
  };
};

export default useWeatherHelper;
