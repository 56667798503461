import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'src/reduxToolkit/store/hooks';

// The page that the users lands after the login
function RedirectPage(): any {
  const baseUrl = process.env.REACT_APP_COGNITO_BASE_URL ?? '';
  const clientId = process.env.REACT_APP_CLIENT_ID ?? '';
  const responseType = process.env.REACT_APP_RESPONSE_TYPE ?? '';
  const scope = process.env.REACT_APP_SCOPE ?? '';
  const redirectUrl = process.env.REACT_APP_REDIRECT_URI ?? '';
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [, setCookie] = useCookies(['cognito-code']);

  useEffect(() => {
    // Cognito should return the user with a code property as param.
    if (searchParams.get('code') === null) {
      // Redirect to cognito if code doesn't exist
      window.location.replace(
        `${baseUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUrl}`,
      );
    } else {
      // Save the code and clear params
      // The code will later be used to fetch the access token from the backend
      const code = searchParams.get('code');
      setCookie('cognito-code', code, {
        path: '/',
        maxAge: 60 * 60 * 24, // Day
      });
      searchParams.delete('code');
      setSearchParams(searchParams);
    }
  }, [
    baseUrl,
    clientId,
    responseType,
    scope,
    redirectUrl,
    searchParams,
    setSearchParams,
    dispatch,
    setCookie,
  ]);

  return null;
}

export default RedirectPage;
