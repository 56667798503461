import React from 'react';
import { ValueTime } from '../constants';
import ToggleWidget from './toggle_widget';
import {ToggleWidgetOption} from './constants';
import { setValueTime } from '../../../../../reduxToolkit/slices/UserSlice/user.slice';
import { useAppDispatch } from '../../../../../reduxToolkit/store/hooks';
import {useTranslation} from "react-i18next";

interface ValueTimeToggleProps {
    value_time: ValueTime;
}

const ValueTimeToggle: React.FC<ValueTimeToggleProps> = ({ value_time }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const set_value_time = (selected: ValueTime): void => {
        // Dispatch the action with just the `selected` value
        dispatch(setValueTime({value_time: selected}));
    };

 const valueTimeOptions: ToggleWidgetOption[] = [
        { id: ValueTime.value, name: t('blockDataWidget.value') },
        { id: ValueTime.time, name: t('blockDataWidget.time') }
    ];

    return (
        <ToggleWidget
            options={valueTimeOptions}
            selected_option={value_time}
            set_selected_option={(optionId: ValueTime) => set_value_time(optionId)}
        />
    );
};

export default ValueTimeToggle;
