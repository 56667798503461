import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { FieldProps } from "./ModalHelper";
import StatesOptions from 'src/shared/data/StateOptions';
import useStyles from './useStyles';

interface SelectLocationProps {
  data: FieldProps;
}

const hasError = (data: FieldProps): boolean => {
  return !!data.fieldStateTouch && !data.fieldState;
};

const SelectLocation = ({ data }: SelectLocationProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const helper = (): string => {
    return data.fieldName === 'state'
      ? t('agentModal.stateHelper')
      : 'Please select a state';
  };

  const handleChangeState = (e: any): void => {
    if (data.setFieldStateString !== undefined) {
      data.setFieldStateString(e);
    } else if (data.setFieldStateNumber !== undefined) {
      data.setFieldStateNumber(e);
    }
  };

  const handleBlur = (): void => {
    if (data.setFieldStateTouch) {
      data.setFieldStateTouch(true);
    }
  };

  return (
    <div
      className={`flex-column ${data.fieldName}`}
      style={{ width: '100%', marginBottom: '-0.5rem' }}>
      <span id="state">{`${data.title}${data.isMandatory ? '*' : ''}`}</span>
      <Form.Item
        help={hasError(data) && helper()}
        validateStatus={hasError(data) ? 'error' : ''}>
        <Select
          id="state"
          className={classes.select}
          style={{ width: '100%' }}
          showSearch
          data-hj-allow
          defaultValue={data.fieldState}
          value={data.fieldState}
          onChange={handleChangeState}
          onBlur={handleBlur}
          options={StatesOptions.map((singleState: any) => ({
            value: singleState,
            label: singleState,
          }))}
        />
      </Form.Item>
    </div>
  );
};

export default SelectLocation;
