import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  Root: {
    fontSize: '0.6rem',
    height: '10%',
    flex: 1,
    '@media (max-width: 850.9px)': {
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  },
  FirstRow: {
    height: 'fit-content',
    fontSize: 'calc(0.6rem + 0.3vw)',
    '@media (max-width: 850.9px)': {
      width: '60%',
    },
    '& div': {
      height: '100%',
      fontSize: 'calc(0.6rem + 0.3vw) !important',
    },
    '& img': {
      display: 'flex',
      height: '100%',
      '@media (max-width: 850.9px)': {
        width: '100% !important',
      },
    },
  },
  SecondRow: {
    width: '100%',
    display: 'flex',
    '& div p': {
      marginBlock: '0.4rem !important',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      color: theme.palette.oldPrimary.main,
      fontSize: 'calc(0.6rem + 0.3vw) !important',
      '@media (max-width: 850.9px)': {
        fontSize: 'calc(0.6rem + 0.3vw) !important',
        marginBlock: '0 !important',
      },
    },
  },
}));

export default useStyles;
