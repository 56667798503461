//BlockDataGraph.tsx
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../../reduxToolkit/store/hooks';
import BlockCardsHelper from '../../../../FieldsPage/BlockInfo/BlockCardsHelper';
import ETGraphTitleReports from '../../../../FieldsPage/BlockInfo/ETConsumptionGraph/ETGraphTitleReports';
import useStyles from '../../../../FieldsPage/BlockInfo/ETConsumptionGraph/useStyles';
import { minBlockAgeToDisplayGraph } from '../../../../../shared/constants/consts';
import { MonthName } from '../../../../../shared/constants/enums';
import {
  getCurrentETValObj,
  processBlockData,
  extractGraphData,
  prepareGraphArgs,
} from './utils';
import { useWindowDimensions } from '../../../../../shared/hooks/useWindowDimensions';
import { smallMaxWidth } from '../../../../../shared/constants/consts';
import {useTranslation} from "react-i18next";

function BlockDataGraph(props: any): JSX.Element | null {
  const {
    flowData,
    startValueProp,
    endValueProp,
    handleZoomIn,
    handleZoomOut,
    blockData,
    block,
    farm,
    isToroAccount,
  } = props;

  const [datesArrInitialLength, setDatesArrInitialLength] = useState<number>(0);
  const [graphArgs, setGraphArgs] = useState<any>();

  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );

  const monthNames = useMemo(() => Object.values(MonthName), []);
  const today = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { renderProcessingData, renderNoData, renderCalibratingData } =
    BlockCardsHelper();
  const startValue = graphArgs?.dataZoom?.startValue;
  const endValue = graphArgs?.dataZoom?.endValue;
  const graphRef: React.MutableRefObject<ReactECharts | null> = useRef(null);

  const isDataNull = useMemo(
    () => blockData.actualConsumption?.length === 0,
    [blockData.actualConsumption?.length],
  );
  const isMinDaysToDisplay = useMemo(
    () => (blockData.blockDataAge ?? 0) >= minBlockAgeToDisplayGraph,
    [blockData.blockDataAge],
  );
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  useEffect(() => {
    setDatesArrInitialLength(blockData.actualConsumption.length);

    const uniqueDateDataMap = processBlockData(
      blockData,
      monthNames,
      selectedMeasurementSystem,
      getCurrentETValObj,
    );

    const graphData = extractGraphData(uniqueDateDataMap);
    const args = prepareGraphArgs(
      graphData,
      flowData,
      isToroAccount,
      startValueProp,
      endValueProp,
      selectedMeasurementSystem,
      t
    );
    setGraphArgs(args);
  }, [
    monthNames,
    startValueProp,
    endValueProp,
    isToroAccount,
    selectedMeasurementSystem,
    blockData,
    flowData,
    t
  ]);

  return (
    <div className={`flex-column ${classes.graphContainer}`}>
      {block && isMinDaysToDisplay && graphArgs && (
        <div className={`flex-column align-center ${classes.zoomControls}`}>
          <Button
            id="zoomIn"
            onClick={() => handleZoomIn()}
            disabled={Math.abs(startValue - endValue) < 14}>
            <PlusOutlined />
          </Button>
          <Button
            id="zoomOut"
            onClick={handleZoomOut}
            disabled={
              Math.abs(startValue - endValue) > graphArgs?.xAxis[0]?.data.length
            }>
            <MinusOutlined />
          </Button>
        </div>
      )}
      <div className={`flex ${classes.zoomBtnTitleContainer}`}>
        <ETGraphTitleReports blockId={block.id} />
      </div>
      {block && isMinDaysToDisplay ? (
        isDataNull ? (
          renderProcessingData()
        ) : !!graphArgs ? (
          <ReactECharts
            ref={graphRef}
            option={graphArgs}
            style={{
              height: smallMaxWidth < width ? '90%' : '260px',
              width: '100%',
            }}
          />
        ) : (
          renderNoData()
        )
      ) : (
        renderCalibratingData()
      )}
    </div>
  );
}

export default BlockDataGraph;
