import Tooltip from 'antd/es/tooltip';
import { TextToolTipTypesProps } from 'src/shared/data/types/tooltipTypes';

function TextWithTooltip(props: TextToolTipTypesProps): JSX.Element {
  const { tooltipText, children } = props;
  return (
    <Tooltip title={tooltipText} placement="top" color="#fff">
      {children}
    </Tooltip>
  );
}

export default TextWithTooltip;
