import React, { ReactElement } from 'react';
import { Button } from 'antd';
import Typography from '@mui/material/Typography';
import useStyles from './useStyles';
import t2sLogo from 'src/assets/Icons/mainThemeIcons/t2s-logo.svg';
import ToroLogo from '../../../assets/Icons/toroThemeIcons/toro-logo.svg';
import { useAppSelector } from '../../../reduxToolkit/store/hooks';

interface GenericModalProps {
  title: string;
  onClose: () => void;
  onSave: (e: any) => void;
  children: React.ReactNode;
  isLoading: boolean;
  isSaveDisabled: boolean;
  saveButtonText: string;
  cancelButtonText: string;
  isAgent?: boolean;
}

const GenericModal = ({
  title,
  onClose,
  onSave,
  children,
  isLoading,
  isSaveDisabled,
  saveButtonText,
  cancelButtonText,
  isAgent = false,
}: GenericModalProps): ReactElement => {
  const classes = useStyles();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );

  return (
    <div className={`flex-column align-center ${classes.wrapper}`}>
      <div className="logo-center">
        {isToroAccount ? (
          <img src={ToroLogo} alt="Toro logo" style={{ maxWidth: '5rem' }} />
        ) : (
          <img src={t2sLogo} alt="t2s logo" style={{ maxWidth: '5rem' }} />
        )}
      </div>
      <Typography
        textAlign="center"
        margin="unset"
        padding="0.5rem 0 0 0"
        color="#676879"
        fontSize="1.5rem">
        {title}
      </Typography>
      <div className={`flex-column align-center ${classes.formContainer}`}>
        <div className={`${classes.fieldsContainer} custom`}>{children}</div>
      </div>
      <div
        className={`btn-action-container common-button-container flex justify-between ${classes.modalBtnContainer}`}>
        <Button
          disabled={isLoading || isSaveDisabled}
          id="save-button"
          onClick={(e) => onSave(e)}
          className={`ant-btn-custom ${
            isAgent ? classes.modalSaveBtn : classes.toroModalSaveBtn
          }`}>
          {saveButtonText}
        </Button>
        <Button onClick={onClose} className={classes.modalCancelBtn}>
          {cancelButtonText}
        </Button>
      </div>
    </div>
  );
};

export default GenericModal;
