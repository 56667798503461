import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '1rem',
    gap: '1%',
    '@media (max-width: 567px)': {
      alignItems: 'center',
      gap: '5px',
    },
  },
  headerNavigation: {
    width: '100%',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.accent.main,
      bottom: '0',
      left: '0px',
    },
    '& .MuiTabs-flexContainer': {
      gap: '8%',
      display: 'flex',
      paddingBlock: '1%',
      width: '100%',
      '@media (min-width: 1050px) and (max-width: 1240px)': {
        gap: '5%',
      },
      '@media (min-width: 900px) and (max-width: 1049px)': {
        gap: '5%',
      },
      '@media (min-width: 768px) and (max-width: 899px)': {
        gap: '3%',
      },
    },
  },
  menuItems: {
    '& > div': {
      minHeight: 'unset !important',
    },
  },
  tabs: {
    '&:hover': {
      color: theme.palette.accent.main,
      cursor: 'pointer',
      opacity: 1,
    },
    '&.MuiButtonBase-root': {
      fontSize: '1rem',
      textTransform: 'none',
      padding: '0',
      margin: '0',
      minWidth: '5rem',
      minHeight: '0',
    },
    '&.MuiButtonBase-root.Mui-selected': {
      fontWeight: '700',
    },
    '@media (max-width: 767.9px)': {
      '&.MuiButtonBase-root': {
        fontSize: '12px',
      },
    }
    },
  toroAppLogo: {
    marginRight: '16px',
    '& a': {
      flex: '1',
      display: 'flex',
      margin: 'auto',
      gap: '20px',
      '& img': {
        width: '80px',
        '@media (max-width: 750px)': { // Adjusting media query to target mobile sizes
          width: '40px', // Setting the width to 30px for mobile
        },
      },
    },
    '@media (max-width: 750px)': { // Adjusting media query to target mobile sizes
      marginRight: '16px',
    },
  },
  growSphereLogo: {
    marginRight: '16px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    '& a': {
      flex: '1',
      display: 'flex',
      margin: 'auto',
      '& img': {
        width: '80px',
        '@media (min-width: 320px) and (max-width: 460px)': {
          height: '34px',
        },
      },
    },
  },
  mainAppLogo: {
    marginRight: '16px',
    gap: '20px',
    '& img': {
      marginTop: '3px',
      height: '42px',
    },
    '@media (max-width: 767.9px)': {
      gap: '10px',
      marginRight: '10px',
      '& img': {
        marginTop: '3px',
        height: '30px',
      },
    },
  },
  poweredlogo: {
    maxWidth: '6rem',
  },
  signOutContainer: {
    paddingInline: '0.4rem',
  },
  signOutBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.oldPrimary.main,
    fontSize: 'clamp(0.8rem, 1rem, 1.2rem)',
    fontWeight: '500',
    minWidth: 'fit-content',
    paddingBlock: '0',
    '&:hover': {
      color: `${theme.palette.accent.main}!important`,
      cursor: 'pointer',
      opacity: 1,
    },
    '@media (max-width: 767.9px)': {
      fontSize: '12px',
      paddingInline: '0',
    },
  },
  IFETELNumber: {
    cursor: 'pointer',
  },
  mainAccountInfo: {
    maxWidth: '100%',
    gap: '1%',
    flex: '1',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',

    '& h3': {
      fontSize: 'clamp(0.6rem, 1rem, 1.2rem)',
      color: '#676879',
      marginBlock: 'unset',
      paddingInlineEnd: '0.5rem',
      minWidth: 'fit-content',
    },
  },
  selectLang: {
    '@media (min-width: 370px) and (max-width: 767px)': {
      padding: '0 0.4rem',
    },
    '@media (min-width: 320px) and (max-width: 369px)': {
      padding: '0',
    },
  },
}));

export default useStyles;
