import { darken, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  farmTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '-1.5rem',
    '& input': {
      border: '1px solid',
      borderColor: '#76778673',
      borderRadius: '4px',
      '& input:focus-visible': {
        outline: 'none',
      },
      '&::placeholder': {
        color: '#76778673',
      },
      '&:-ms-input-placeholder': {
        color: '#76778673',
      },
      '&::-ms-input-placeholder': {
        color: '#76778673',
      },
      '&:focus-visible': {
        border: '1px solid !important',
        borderColor: '#76778673 !important',
        '&:hover': {
          outline: '#76778673 !important',
          border: '1px solid !important',
          borderColor: '#76778673 !important',
        },
      },
      '&:focus, &:hover': {
        boxShadow: '0 0 3px #76778673 !important',
        border: 'inherit',
      },
      '&:focus, &:active, &:hover': {
        border: '1px solid #76778673 !important',
      },
    },
  },
  '& .ant-input-affix-wrapper-focused, & .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, & .ant-input-affix-wrapper:hover':
    {
      borderColor: '#76778673 !important',
    },
  modal: {
    minWidth: '70vw',
    paddingInline: '1.5rem',
    gap: '1rem',
    paddingBlockEnd: '1rem',
    '& p': {
      margin: 'unset',
      fontSize: 'calc(1.4rem + 0.4vw)',
      '@media (max-width: 850px)': {
        fontSize: 'calc(1rem + 0.4vw)',
      },
    },
    '@media (max-width: 1000px)': {
      paddingInline: 'unset',
      paddingBlockEnd: 'unset',
      gap: 'unset',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      paddingInline: '2rem',
      paddingBlock: '2rem 3rem',
      scrollBehavior: 'smooth',
      overflowY: 'auto',
      scrollY: 'auto',
    },
  },
  locationContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 3.75rem',
    gridTemplateAreas: "'mapContainer' 'modalBtnContainer'",
    rowGap: '1rem',
    minWidth: '100%',
    minHeight: '60vh',
    height: '100%',
    '@media (min-width: 320px) and (max-width: 640px)': {
      display: 'flex',
      flex: '1',
      width: '100%',
      flexDirection: 'column',
      gap: '1rem',
      paddingBlock: '2rem',
    },
  },
  mapContainer: {
    minHeight: '100%',
    position: 'relative',
    gridArea: 'mapContainer',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    gap: '1rem',
    '@media (max-width: 850px)': {
      marginTop: '0',
    },
    '& div:nth-child(2) > div:nth-child(1) > div > div': {
      '&:nth-child(12)': {
        display: 'none !important',
      },
      '&:nth-child(16) div': {
        bottom: '7rem !important',
        right: '2rem !important',
        '&:nth-child(2)': {
          display: 'none !important',
        },
      },
    },
  },
  modalBtnContainer: {
    gridArea: 'modalBtnContainer',
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginTop: '1rem',
    flexDirection: 'row-reverse',
    '@media (min-width: 320px) and (max-width: 640px)': {
      gap: '1rem',
      alignItems: 'center',
    },
  },
  modalAddFarmBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.8rem',
    height: 'inherit',
    maxWidth: '18rem',
    marginLeft: 'auto',
    backgroundColor: theme.palette.accent.main,
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    color: '#fff',
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover, &:active': {
      '&:not([disabled])': {
        border: `solid 1px ${theme.palette.accent.main} !important`,
        color: '#fff',
        backgroundColor: darken(theme.palette.accent.main, 0.1),
      },
    },
    '&:hover:enabled': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: darken(theme.palette.accent.main, 0.1),
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: '#fff',
      pointerEvents: 'none',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(12px, 14px, 18px)',
    },
  },
  modalCancelBtn: {
    color: theme.palette.red.main,
    backgroundColor: 'transparent',
    border: 'solid 1px',
    lineHeight: '24px',
    borderColor: theme.palette.red.main,
    minWidth: '6rem',
    fontWeight: 400,
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    borderRadius: '4px',
    padding: '0.8rem',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in -out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    '&:hover': {
      border: 'solid 1px',
      borderRadius: '4px',
      borderColor: `${theme.palette.red.main}!important`,
      color: '#fff!important',
      backgroundColor: theme.palette.red.main,
    },
    '&:focus': {
      outline: 'none !important',
      borderColor: `${theme.palette.red.main}!important`,
      borderRadius: '4px',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(10px, 12px, 16px)',
    },
  },
  toroModalAddFarmBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.8rem',
    height: 'inherit',
    maxWidth: '18rem',
    marginLeft: 'auto',
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: theme.palette.oldPrimary.main,
    color: '#fff !important',
    minWidth: '6rem',
    lineHeight: '24px',
    transition: 'all 0.2s ease-in -out',
    '&:hover, &:active': {
      '&:not([disabled])': {
        border: `solid 1px ${theme.palette.accent.main} !important`,
        borderRadius: '4px',
        color: '#fff',
        backgroundColor: darken(theme.palette.accent.main, 0.1),
      },
    },
    '&:hover:enabled': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff !important',
      backgroundColor: darken(theme.palette.accent.main, 0.1),
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: '#fff',
      pointerEvents: 'none',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(12px, 14px, 18px)',
    },
    '&:hover': {
      border: 'solid 1px',
      borderColor: theme.palette.accent.main,
      color: '#fff !important',
      backgroundColor: theme.palette.accent.main,
    },
    '&:focus': {
      outline: 'none !important',
      borderColor: `${theme.palette.accent.main}!important`,
    },
  },
  toroModalCancelBtn: {
    color: theme.palette.oldPrimary.main,
    backgroundColor: 'transparent',
    border: 'solid 1px',
    borderColor: theme.palette.oldPrimary.main,
    minWidth: '6rem',
    fontWeight: 400,
    borderRadius: '4px',
    padding: '0.8rem',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in -out',
    height: 'inherit',
    maxWidth: '18rem',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff !important',
      backgroundColor: theme.palette.accent.main,
    },
    '&:focus': {
      outline: 'none !important',
      borderColor: `${theme.palette.accent.main}!important`,
      borderRadius: '4px',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(10px, 12px, 16px)',
    },
  },
  mapSettingsBtnContainer: {
    position: 'absolute',
    bottom: 0,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.25rem',
    '& > button': {
      background: `${darken(theme.palette.oldPrimary.main, 0.4)}!important`,
      borderRadius: '4px !important',
      border: '0.02em solid #ffffff54 !important',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px',
      '& .active': {
        border: '0.05em solid #ffffff9c!important',
      },
      '&:focus': {
        border: '0.05em solid #ffffff9c!important',
        outline: 'none!important',
      },
      '&:hover': {
        borderRadius: '4px !important',
        border: '0.05em solid #ffffff9c!important',
        background: `${darken(
          theme.palette.hoverDarkGrey.main,
          0.5,
        )}!important`,
      },
      '& > img': {
        filter: 'brightness(0) invert(1) opacity(0.85) !important',
      },
    },
  },
  mapCoordinatesDescription: {},
  mapCoordinatesDescriptionItem: {
    marginLeft: '0.5rem',
  },
  farmMapSettingsBtn: {
    padding: '0.5rem 1rem',
    marginLeft: '0.25rem!important',
    background: `${darken(theme.palette.oldPrimary.main, 0.4)}!important`,
    color: '#676879!important',
    fontWeight: 'bold',
    borderRadius: '0!important',
    height: '40px',
    width: '40px!important',
    fontSize: '0.75rem!important',
    minWidth: '40px!important',
    bottom: '2rem !important',
    border: '0.02em solid #ffffff54 !important',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px',
    '& .active': {
      border: '0.05em solid #ffffff9c!important',
    },
    '&:focus': {
      border: '0.05em solid #ffffff9c!important',
      outline: 'none!important',
    },
    '&:hover': {
      borderRadius: '4px !important',
      border: '0.05em solid #ffffff9c!important',
      background: `${darken(theme.palette.hoverDarkGrey.main, 0.5)}!important`,
    },
  },
  farmTitleSearch: {
    alignItems: 'end',
    minWidth: '100%',
    flex: '1',
    gap: '1.5rem',
    '@media (max-width: 850px)': {
      flexDirection: 'column',
      minWidth: '100%',
      gap: '1rem',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 'auto',
    },
  },
  autocomplete: {
    minWidth: '50%',
    marginLeft: 'auto',
    flex: '1',
    '@media (max-width: 850px)': {
      minWidth: '100%',
      paddingInline: 'unset',
    },
  },
  searchLocation: {
    alignItems: 'baseline',
    width: '100%',
    marginLeft: 'auto',
    '& span': {
      maxWidth: '100%',
    },
  },
  farmLocationInput: {
    width: '100%',
    paddingBlock: '0.5rem',
    height: '2rem',
    boxShadow: `0 1px 6px rgba(0, 0, 0, 0.1)`,
    fontSize: `1rem`,
    outline: `none`,
    margin: 0,
    textOverflow: `ellipses`,
    paddingInline: '1rem',
    border: '1px solid',
    borderColor: '#76778673',
    borderRadius: '4px',
    '@media (max-width: 850px)': {
      maxWidth: 'unset',
    },
    '& input:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: '#76778673',
    },
    '&:-ms-input-placeholder': {
      color: '#76778673',
    },
    '&::-ms-input-placeholder': {
      color: '#76778673',
    },
    '&:focus-visible': {
      border: '1px solid !important',
      borderColor: '#76778673 !important',
      borderRadius: '4px',
      '&:hover': {
        outline: '#76778673 !important',
        border: '1px solid !important',
        borderColor: '#76778673 !important',
        borderRadius: '4px',
      },
    },
    '&:focus, &:hover': {
      boxShadow: '0 0 3px #76778673 !important',
      border: 'inherit',
    },
    '&:focus, &:active, &:hover': {
      border: '1px solid #76778673 !important',
      borderRadius: '4px',
    },
  },
  farmNameInput: {
    minWidth: '50%',
    position: 'unset',
  },
}));

export default useStyles;
