import { createAsyncThunk } from '@reduxjs/toolkit';
import { AgentsDetails } from 'src/shared/data/types/agentOptionsTypes';
import agentsAdapter from '../adapters/AgentsAdapter';

// export const getAllAgents = createAsyncThunk<any, number, { rejectValue: Error }>(
//   "agents",
//   async (accountId, { rejectWithValue, fulfillWithValue }): Promise<any> => {
//     try {
//       const response = await agentsAdapter.getAllAgents(accountId);
//       const { data } = response;
//       return fulfillWithValue(data);
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const getSpecificAgentById = createAsyncThunk<
  AgentsDetails,
  string,
  { rejectValue: Error }
>(
  'get agent',
  async (agentId, { fulfillWithValue, rejectWithValue }): Promise<any> => {
    try {
      const response = await agentsAdapter.getSpecificAgentById(agentId);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editSpecificAgentById = async (
  updatedAgent: AgentsDetails,
  agentId: any,
): Promise<any> => {
  try {
    updatedAgent.agentCognitoId = agentId;
    const response = await agentsAdapter.editSpecificAgentById(updatedAgent);
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const postEditAgent = createAsyncThunk<
  AgentsDetails,
  { id: string; editAgent: AgentsDetails },
  { rejectValue: Error }
>(
  'post agent',
  async (
    { id, editAgent },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await agentsAdapter.putNewAgent(id, editAgent);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postNewAgent = createAsyncThunk<
  AgentsDetails,
  { newAgent: AgentsDetails },
  { rejectValue: Error }
>(
  'post agent',
  async ({ newAgent }, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await agentsAdapter.postNewAgent(newAgent);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSpecificAgentById = async (
  accountId: string,
  agentId: string,
): Promise<any> => {
  try {
    const response = await agentsAdapter.deleteSpecificAgentById(
      accountId,
      agentId,
    );
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};
