import React, {useMemo} from 'react';
import {
     calculateFlowDuration,
} from "../../../../../shared/measurements-converter/converter";
import {
    Measurement,
    MeasurementSys
} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import {ActualIrrigation} from "../../../../../shared/data/types/blockDataTypes";
import {openModal} from "../../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice";
import {GrowerModal} from "../../Grower";
import {useAppDispatch, useAppSelector} from "../../../../../reduxToolkit/store/hooks";
import DesignedCard from './designed_card';
import { useTranslation } from 'react-i18next';
import {formatDate} from "./utils";
import {formatNumberWithLabel} from "../modals/utils";

interface ActualIrrigationDataProps {
    measurement_system: MeasurementSys;
    measurement: Measurement;
    actual_irrigation: ActualIrrigation;
    userCountry: string;
}

const ActualIrrigationDataCard: React.FC<ActualIrrigationDataProps> = ({
       measurement_system,
       measurement,
       actual_irrigation,
       userCountry
   }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

    const value: string = formatNumberWithLabel(
        actual_irrigation.flow,
        userCountry,
        measurement_system,
        measurement,
        2,
        currentBlock?.area
    );


    const hourly_value = useMemo(() => {
        if (value) {
            return calculateFlowDuration(actual_irrigation.start, actual_irrigation.finish, t);
        }
    }, [actual_irrigation, value, t]);

    const last_event_text = `${t('blockDataWidget.last_event')}: ${formatDate(actual_irrigation.start,t)}`;

    return (
        <div onClick={() => dispatch(openModal(GrowerModal.actualIrrigation))}>
            <DesignedCard
                value={value}
                time={hourly_value}
                title={t('blockDataWidget.actual_irrigation')}
                span={last_event_text}
                leftIconName={"sprinkler"}
            />
        </div>
    );
};

export default ActualIrrigationDataCard;

