export default function formatDate(date: Date): string {
  const d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  const year = d.getFullYear().toString();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export const roundPrecision = (
  value: number | null | undefined,
  precision: number,
): number | null | undefined => {
  if (!value) return value;

  const multiplier = 10 ** precision || 0;
  return Math.round(value * multiplier) / multiplier;
};

export const calculateVPD = (temp: number, humidity: number): number => {
  const es = 0.6108 * Math.exp((17.27 * temp) / (temp + 237.3));
  return Number(es - (es * humidity) / 100);
};

export const calculateMaxVPD = (temp: number, humidity: number): number => {
  return calculateVPD(temp, humidity);
};

export const decimalHoursToTimeFormat = (
  decimalHours: number | null | undefined,
): number | null | undefined | string => {
  if (!decimalHours) return decimalHours;

  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);
  const formattedHours = `${hours}`;
  const formattedMinutes = `${minutes}`;
  return `${formattedHours}h ${formattedMinutes}m`;
};
