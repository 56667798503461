import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: '1px solid',
      borderColor: theme.palette.hoverGrey.main,
      borderRadius: '4px',
    },
    '&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector':
      {
        padding: '0 8px',
      },
    '&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector':
      {
        border: '1px solid',
        borderColor: theme.palette.hoverGrey.main,
        boxShadow: 'none',
      },
    '&.ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
      border: '1px solid',
      borderColor: theme.palette.hoverGrey.main,
      boxShadow: 'none',
    },
    '& .ant-select-selection-placeholder': {
      color: theme.palette.oldPrimary.main,
    },
  },
  menu: {
    padding: 0,
    '& .ant-select-item-option-active:not(.ant-select-item-option-disabled)': {
      background: 'theme.palette.backgroundGrey.main',
    },
    '& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)':
      {
        background: theme.palette.hoverGrey.main,
      },
  },
}));

export default useStyles;
