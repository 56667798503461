import { createAsyncThunk } from '@reduxjs/toolkit';
import growerFarmsAdapter from '../adapters/GrowerFarmsAdapter';
import { GrowerFarmsResp } from '../../api/types';

const getGrowerFarms = createAsyncThunk<
  GrowerFarmsResp,
  number,
  { rejectValue: Error }
>(
  'growerFarms',
  async (id, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await growerFarmsAdapter.getGrowerFarms(id);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export default getGrowerFarms;
