// SearchBar.tsx
import { Input } from 'antd';
import { SearchBarProps } from 'src/shared/data/types/searchBarType';
import useStyles from './useStyles';
import GenericIcon from "../../../pages/Growers/Grower/block_data_widget/generic_icon";

function SearchBar(props: SearchBarProps): JSX.Element {
    const data = props;
    const classes = useStyles();
    return (
        <div className={`flex justify-between ${classes.searchBar}`}>
            <Input
                className={`flex align-center justify-between ${classes.searchBarInput}`}
                placeholder={data.searchPlaceholder}
                onChange={(e) => data.searchInputState(e.target.value)}
                suffix={<GenericIcon name={'search'} />}
                data-hj-allow
                style={{ fontSize: '16px' }} // Inline style for testing
            />
        </div>
    );
}

export default SearchBar;