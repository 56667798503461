import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useToggleWidgetStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'black',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 30,
        width: 145,
        height: 26,
        overflow: 'hidden',
        '@media (max-width: 1200px)': {
            width: 120,
            height: 26,
        },
        '@media (max-width: 767.9px)': {
            width: '24vw', // Set width to 75% of the viewport
            height: 26,
        },
    },
    option: {
        ...theme.typography.infoMedium,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        lineHeight: '26px',
        flex: 1,
        textAlign: 'center',
        borderColor: theme.palette.onBorder.main,
        transition: 'background-color 0.3s ease, color 0.3s ease',
        '&:not(:last-child)': {
            borderRight: '1px solid black',
        },
        '@media (max-width: 767.9px)': {
            fontSize: '2vw', // Set font size to 3% of the viewport
        },
        '@media (max-width: 500.9px)': {
            fontSize: '3vw', // Set font size to 3% of the viewport
        },
    },
    selectedOption: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        cursor: 'default', // Remove pointer cursor for selected option
    },
    unselectedOption: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.surface.main,  // Change background on hover to "on surface" color
        },
    },
    firstOption: {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
    },
    lastOption: {
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
    },
}));

export default useToggleWidgetStyles;
