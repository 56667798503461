import React from 'react';
import { DepthVolume } from '../constants';
import ToggleWidget from "./toggle_widget";
import {ToggleWidgetOption} from "./constants";
import {setDepthVolume} from "../../../../../reduxToolkit/slices/UserSlice/user.slice";
import {useAppDispatch} from "../../../../../reduxToolkit/store/hooks";
import {useTranslation} from "react-i18next";

interface DepthVolumeToggleProps {
    depth_volume: DepthVolume;
}

const DepthVolumeToggle: React.FC<DepthVolumeToggleProps> = ({
         depth_volume,
     }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const set_depth_volume = (volume: DepthVolume): void => {
        dispatch(setDepthVolume({ depth_volume: volume }));
    };

     const depthVolumeOptions: ToggleWidgetOption[] = [
        { id: DepthVolume.depth, name: t('blockDataWidget.depth') },
        { id: DepthVolume.volume, name: t('blockDataWidget.volume')  }
    ];

    return (
        <ToggleWidget
          options={depthVolumeOptions}
          selected_option={depth_volume}
          set_selected_option={(optionId: DepthVolume) => set_depth_volume(optionId)}
        />
    );
};

export default DepthVolumeToggle;

