import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import { Button, Steps } from 'antd';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import {
  initBlockFields,
  setEditedBlockId,
  setSavedBlock,
  setOpenModal,
  setSavedBlockId,
  setIsInEditMode,
} from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { ReactComponent as LeftArrow } from 'src/assets/Icons/sharedIcons/leftArrow.svg';
import { getBlockInfoOptions } from 'src/reduxToolkit/thunks/block_thunks';
import { getUnconnectedSensors } from 'src/reduxToolkit/thunks/sensor_thunk';
import blocksAdapter from 'src/reduxToolkit/adapters/BlocksAdapter';
import BlockInfoForm from './BlockInfoForm';
import AddSensorsForm from './AddSensorsForm';
import useStyles from './useStyles';
import { getInstallers } from '../../../reduxToolkit/thunks/user_thunks';
import notifyService from '../../../shared/notification/notification.service';
import messageTypes from '../../../consts/messages';
import ToroLogo from '../../../assets/Icons/toroThemeIcons/toro-logo.svg';
import t2sLogo from '../../../assets/Icons/mainThemeIcons/t2s-logo.svg';
import {
  MiniBlock,
  MiniFarm,
} from '../../../shared/data/types/growerFarmsTypes';
import { BlockModal } from '../../Growers/Grower/Grower';
import { useSearchParams } from 'react-router-dom';
import {
  addBlockToFarm,
  closeModal,
  replaceBlock,
} from '../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';

const MIN_PLANTING_YEAR = 1900


function AddOrEditBlockForm(props: BlockFormProps): JSX.Element {
  const { blockModal, farm } = props;
  const {
    block,
    editedBlockId,
    newPolygonPathAfterEdit,
    newPolygonAreaAfterEdit,
  } = useAppSelector((state) => state.rootReducer.addOrEditBlockState);
  const dispatch = useAppDispatch();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isUniqueTitle, setIsUniqueTitle] = useState(true);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFillAllRequiredFields, setIsFillAllRequiredFields] = useState(true);

  const steps =
    blockModal == BlockModal.edit
      ? [
          {
            title: '',
            content: (
              <BlockInfoForm
                farm={farm}
                blockModal={blockModal}
                isUniqueTitle={isUniqueTitle}
                setIsUniqueTitle={setIsUniqueTitle}
              />
            ),
            visible: true,
          },
          {
            title: '',
            content: <AddSensorsForm />,
            visible: true,
          },
        ]
      : [
          {
            title: '',
            content: (
              <BlockInfoForm
                farm={farm}
                blockModal={blockModal}
                isUniqueTitle={isUniqueTitle}
                setIsUniqueTitle={setIsUniqueTitle}
              />
            ),
            visible: false,
          },
        ];

  const classes = useStyles({ current });
  const farmId = farm.id;

  const navToBlock = (blockId: number): void => {
    setSearchParams({ blockId: blockId.toString() });
  };

  useEffect(() => {
    if (blockModal === BlockModal.create) dispatch(initBlockFields({}));
    void dispatch(getUnconnectedSensors(''));
    void dispatch(getBlockInfoOptions(0));
    void dispatch(getInstallers(''));
  }, [blockModal, dispatch]);



  const checkIsFillAllRequiredFields = useCallback((): boolean => {
    if (block?.title === undefined || block?.title === '') return false;
    if (!block?.cropTypeId) return false;
    if (
      block?.treeSpacing === undefined ||
      block?.treeSpacing === -1 ||
      block?.treeSpacing < 1
    )
      return false;
    if (
        block?.plantingYear === undefined ||
        block?.plantingYear < MIN_PLANTING_YEAR ||
        block?.plantingYear > new Date().getFullYear())
      return false;
    return !(
      block?.rowSpacing === undefined ||
      block?.rowSpacing === -1 ||
      block?.rowSpacing < 1
    );
  }, [block]);

  const handleNextClick = useCallback((): void => {
    if (checkIsFillAllRequiredFields()) {
      setIsFillAllRequiredFields(true);
      setCurrent(current + 1);
    } else {
      setIsFillAllRequiredFields(false);
    }
  }, [current, checkIsFillAllRequiredFields]);

  const handlePrevClick = useCallback((): void => {
    if (current > 0) setCurrent(current - 1);
  }, [current]);

  const onSaveForm = useCallback(async (): Promise<void> => {
    if (loading) {
      return;
    }
    if (checkIsFillAllRequiredFields()) {
      let blockId = block?.id;
      if (block !== undefined && farmId !== undefined) {
        setLoading(true);
        if (blockModal === BlockModal.edit) {
          await blocksAdapter
            .updateBlockInfo(block)
            .then((res) => {
              if (res?.data) {
                dispatch(replaceBlock(res.data));
                dispatch(setSavedBlockId(res.data.id));
              }
            })
            .finally(() => {
              notifyService('Block updated successfully', messageTypes.success);
              setLoading(false);
            });
        } else {
          await blocksAdapter
            .createNewBlock(
              block,
              newPolygonPathAfterEdit,
              newPolygonAreaAfterEdit,
              farmId,
            )
            .then((data) => {
              blockId = data.data.id;
              dispatch(addBlockToFarm(data.data));
              dispatch(setSavedBlockId(data.data.id));
            })
            .finally(() => {
              notifyService(
                `${t('block.BlockCreatedSuccessfully')}`,
                messageTypes.success,
              );
              setLoading(false);
            });
        }
        setIsFillAllRequiredFields(true);

        dispatch(setIsInEditMode(false));
        dispatch(setEditedBlockId(''));
        dispatch(setSavedBlock(true));
        dispatch(closeModal());
        dispatch(initBlockFields({}));
        dispatch(setOpenModal(false));
        if (blockModal === BlockModal.create && blockId) {
          navToBlock(blockId);
        }
      }
    } else {
      setIsFillAllRequiredFields(false);
    }
  }, [
    dispatch,
    block,
    checkIsFillAllRequiredFields,
    newPolygonPathAfterEdit,
    newPolygonAreaAfterEdit,
    loading,
    farmId,
    t,
  ]);

  return (
    <>
      <button
        className={classes.backBtn}
        type="button"
        onClick={handlePrevClick}>
        <div className="icon">
          <LeftArrow />
        </div>
        <span>{t('addOrEditBlockModal.back')}</span>
      </button>
      <div className={`flex-column align-center ${classes.root}`}>
        {isToroAccount ? (
          <img src={ToroLogo} alt="Toro logo" style={{ maxWidth: '5rem' }} />
        ) : (
          <img src={t2sLogo} alt="t2s logo" style={{ maxWidth: '5rem' }} />
        )}
        <Typography
          className={
            blockModal === BlockModal.edit
              ? `edit-block-modal-title ${classes.title}`
              : `add-block-modal-title ${classes.title}`
          }
          fontSize="1.5rem">
          {blockModal === BlockModal.edit
            ? `${t('BlockInfoForm.edit')}`
            : `${t('BlockInfoForm.create')}`}{' '}
          {`${t('BlockInfoForm.block')}`}
        </Typography>
        <Typography className={classes.subTitle}>{farm?.title}</Typography>
        <div
          className={`flex-column justify-center align-center ${classes.form}`}>
          <Steps current={current}>
            {steps
              .filter((step) => step.visible)
              .map((item) => (
                <Steps.Step key={item.title} title={item.title} />
              ))}
          </Steps>
          <div className={classes.stepContent}>{steps[current].content}</div>
          <div
            className={`flex-column align-center justify-center ${classes.stepsAction}`}>
            {!isFillAllRequiredFields && (
              <Typography variant="title" className={classes.errorText}>
                {t('BlockInfoForm.isFillAllFieldsWarning')}
              </Typography>
            )}
            {current < steps.length - 1 && (
              <div className={classes.formButtons}>
                <Button
                  id="save-edited-block"
                  type="primary"
                  disabled={
                    loading || !checkIsFillAllRequiredFields() || !isUniqueTitle
                  }
                  onClick={() => {
                    void (async () => {
                      await onSaveForm();
                    })();
                  }}>
                  {t('addOrEditBlockModal.update')} &amp;{' '}
                  {t('addOrEditBlockModal.exit')}
                </Button>
                <Button
                  id="edited-block-modal-second-step"
                  type="default"
                  onClick={() => handleNextClick()}>
                  {`${t('addOrEditBlockModal.connectUnits')}`}
                </Button>
              </div>
            )}
            {current === steps.length - 1 && (
              <div className={classes.formButtons}>
                <Button
                  id="save-new-block"
                  type="primary"
                  disabled={
                    loading || !checkIsFillAllRequiredFields() || !isUniqueTitle
                  }
                  onClick={() => {
                    void (async () => {
                      await onSaveForm();
                    })();
                  }}>
                  {t('addOrEditBlockModal.save')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddOrEditBlockForm;

export interface BlockFormProps {
  blockModal: BlockModal;
  block?: MiniBlock;
  farm: MiniFarm;
}
