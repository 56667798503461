import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockCardProps } from 'src/shared/data/types/blockTypes';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import deleteIcon from '../../../../assets/Icons/sharedIcons/deleteIcon.svg';
import { useStyles } from './useStyles';
import { useAppDispatch, useAppSelector } from '../../../../reduxToolkit/store/hooks';
import {
    initBlockFields,
    setDeletedBlock,
    setEditedBlockId,
} from '../../../../reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { openModal } from '../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';
import { GrowerModal } from '../../../Growers/Grower/Grower';
import toGeoJson from '../../../../shared/mappers/toGeoJson';
import GenericIcon from '../../../Growers/Grower/block_data_widget/generic_icon';
import {
    convertAndRound,
    convertLabel,
    systemMeasurement,
} from 'src/shared/measurements-converter/converter';
import { Measurement } from 'src/shared/measurements-converter/measurement-enums/measurement-enums';

function BlockListCard(props: BlockCardProps): JSX.Element {
    const { block, isFromInfo, isSelected, isDeleteEnable } = props;
    const { isAdmin, isGrowerAccess } = useUserAccess();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const classes = useStyles({ isFromInfo: !!isFromInfo });

    const selectedMeasurementSystem = useAppSelector(
        (state) => state.rootReducer.userState.measurementSystem,
    );
    const measurementSys = useMemo(
        () => systemMeasurement(selectedMeasurementSystem),
        [selectedMeasurementSystem],
    );
    const userCountry = useAppSelector(
        (state) => state.rootReducer.userState.country,
    );

    const convertValue = (value: number, measurement: Measurement): string => {
        const convertedValue = convertAndRound(
            userCountry,
            measurementSys,
            measurement,
            value,
            2, // number of decimal places
        );
        const unitLabel = convertLabel(
            userCountry,
            measurementSys,
            measurement,
        );
        return `${convertedValue} ${unitLabel}`;
    };

    function onEdit() {
        dispatch(setEditedBlockId(block.id));
        dispatch(
            initBlockFields({
                id: block.id,
                title: block.title,
                cropTypeId: block.cropTypeId,
                cropVarietyId: block.cropVarietyId,
                treeSpacing: block.treeSpacing,
                rowSpacing: block.rowSpacing,
                area: block.area,
                soilTextureId: block.soilTextureId,
                plantingYear: block.plantingYear,
                irrigationSystemId: block.irrigationSystemId,
                systemApplicationRate: block.flowRate,
                farmId: block.farmId,
                zonalDataJson: undefined,
                sensors: [],
                installers: [],
            })
        );
        dispatch(openModal(GrowerModal.editBlock));
    }

    function onDelete() {
        dispatch(setDeletedBlock(block));
        dispatch(openModal(GrowerModal.deleteBlock));
    }

    function onDownload(): void {
        if (block.polygon) {
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(toGeoJson(JSON.parse(String(block.polygon))))
            )}`;
            const link = document.createElement('a');
            link.href = jsonString;
            link.download = `${block.title}.json`;
            link.click();
        }
    }

    return (
        <div className={classes.container}>
            {!isGrowerAccess && isSelected && (
                <div>
                    <div className={classes.separator}></div>
                    <div
                        className={`flex align-center ${classes.actions}`}
                        style={{ textAlign: 'right' }}
                    >
                        <div className={classes.iconBtn}>
                            <GenericIcon name={'edit'} size={24} onClick={() => onEdit()} />
                        </div>
                        {isDeleteEnable && (
                            <div className={classes.iconBtn} onClick={() => onDelete()}>
                                <img src={`${deleteIcon}`} alt="delete" />
                            </div>
                        )}
                        {isAdmin && (
                            <div id="download-block-json" className={classes.iconBtn}>
                                <GenericIcon name={'download'} size={24} onClick={() => onDownload()} />
                            </div>
                        )}
                    </div>
                    <div className={classes.separator}></div>
                </div>
            )}

            {/* Updated Header Section */}
            <div className={classes.header}>
                <h2 className={classes.title}>{t('blockDataWidget.block_information')}</h2>
                <div className={classes.blockId}>
                    <div className={classes.infoValue}>{t('blockDataWidget.block_id')} {block.id}</div>
                </div>
            </div>

            <div className={classes.infoGrid}>
                <div className={classes.infoRow}>
                    <div className={classes.infoItem}>
                        <div className={classes.infoTitle}> {t('blockDataWidget.variety')}</div>
                        <div className={classes.infoValue}>{block.cropVariety}</div>
                    </div>
                    <div className={classes.infoItem}>
                        <div className={classes.infoTitle}>{t('blockDataWidget.area')}</div>
                        <div className={classes.infoValue}>
                            {convertValue(block.area, Measurement.AREA)}
                        </div>
                    </div>
                    <div className={classes.infoItem}>
                        <div className={classes.infoTitle}>{t('blockDataWidget.planting_year')}</div>
                        <div className={classes.infoValue}>{block.plantingYear}</div>
                    </div>
                </div>
                <div className={classes.infoRow}>
                    <div className={classes.infoItem}>
                        <div className={classes.infoTitle}>{t('blockDataWidget.tree_spacing')}</div>
                        <div className={classes.infoValue}>
                            {convertValue(block.treeSpacing, Measurement.LENGTH)}
                        </div>
                    </div>
                    <div className={classes.infoItem}>
                        <div className={classes.infoTitle}>{t('blockDataWidget.row_spacing')}</div>
                        <div className={classes.infoValue}>
                            {convertValue(block.rowSpacing, Measurement.LENGTH)}
                        </div>
                    </div>
                    <div className={classes.infoItem}>
                        <div className={classes.infoTitle}>{t('blockDataWidget.irrigation_system')}</div>
                        <div className={classes.infoValue}>{t(`${block.irrigationSystem}`)}</div>
                    </div>
                </div>
                {block.flowRate ?
                (<div className={classes.infoRow}>
                    <div className={classes.infoItem}>
                        <div className={classes.infoTitle}>{t('fields.applicationRate')}</div>
                        <div className={classes.infoValue}>
                            <div className={classes.infoValue}>{block.flowRate ? block.flowRate : ''}</div>
                        </div>
                    </div>
                </div>
                ) : null
                }
            </div>
        </div>
    );
}

export default BlockListCard;