export const metricToImperialConstants = {
  default: 1,
  cubicMeterToCubicInch: 0.0610237,
  cm2ToIn2: 0.155,
  litToGal: 0.2641720524,
  cToF: (c: number) => {
    return (c * 9) / 5 + 32;
  },
  mmToIn: 0.0393701,
  hecToAc: 2.47105,
  dunamToAc: 0.247105,
  m2ToAc: 0.000247105,
  mToFt: 3.28084,
  m2ToFt2: 10.7639,
  m3ToGal: 264.172,
  meterSecToMileHour: 2.23694,
  m3HourDunamToGalMinAcre: 17.8177728,
};

export const imperialToMetricConstants = {
  default: 1,
  in3ToCm3: 16.3871,
  in2ToCm2: 6.4516,
  galTol: 3.78541,
  fToC: (f: number) => {
    return ((f - 32) * 5) / 9;
  },
  inToMm: 25.4,
  acToHectare: 0.404686,
  acToDunam: 4.04686,
  acToM2: 4046.86,
  ftToM: 0.3048,
  ft2ToM2: 0.092903,
  galToM3: 0.00378541,
  mileHourToMeterSec: 0.44704,
};

export const metricToMetricSubUnitConstants = {
  default: 1,
  m2ToHec: 0.0001,
  m2ToDun: 0.001,
  hecToM2: 10000,
  dunToM2: 1000,
  m3HourDunamToM3HourHectare: 10,
};

export const measurementSystemLabels = {
  none: 'none',
  in3H: 'cubic in/h',
  in2H: 'squared in/h',
  gal: 'gal',
  galH: 'gal/hour',
  galD: 'gal/day',
  galMac: 'gpm/acre', // change from gal/m/ac gal per minutes per acre
  far: '°F',
  cel: '°C',
  kpa: 'Kpa',
  mileH: 'mph', // Mile per hour
  percent: '%',
  in: 'in',
  WM2: 'w/m^2',
  ac: 'ac', // Acre
  m2: 'm^2',
  m3: 'm³',
  ft: 'ft',
  ft2: 'Ft^2',
  cm3H: 'cm^3/h',
  cm2H: 'cm^2/h',
  litH: 'l/hour',
  litD: 'l/Day',
  mpS: 'm/s', // Meter per second
  hec: 'hectare', // Hectare
  dunam: 'dunam',
  mm: 'mm',
  m: 'm',
  hour: 'Hour',
  m3Hha: 'm3/h/ha', // square meters per hour per hectare
  m3Hdun: 'm3/h/dunam', // square meters per hour per dunam
};
