import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';

class SensorsAdapter {
  static readonly endpoint = `/units`;

  static readonly dataEndpoint = `/units-processed-hourly-data`;

  async getSensorsProcessedDataByBlock(
    blockId: number,
    fromDate: string,
    toDate: string,
    measurementSystem?: string,
  ): Promise<any> {
    const res = HttpClientService.get<any>(
      `${SensorsAdapter.dataEndpoint}/find-by-block-and-date-range/${blockId}`,
      {
        params: { startRange: fromDate, endRange: toDate, measurementSystem },
      },
    );
    return res;
  }

  async getUnconnectedSensors(): Promise<AxiosResponse<any>> {
    return HttpClientService.post<any>(
      `${SensorsAdapter.endpoint}/find-non-referenced-units`,
      {},
    );
  }
}
const sensorsAdapter = new SensorsAdapter();
export default sensorsAdapter;
