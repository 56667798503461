import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Token } from '../../../shared/data/types/TokenTypes';

const initialState: Token = {
  token: '',
};

export const TokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const { setToken } = TokenSlice.actions;

export const tokenReducer = TokenSlice.reducer;
