import { useTranslation } from "react-i18next";
import { Select, Form } from "antd";
import { SelectField } from "src/shared/data/types/accountOptionsTypes";
import useStyles from "./useStyles";

const hasError = (data: SelectField): boolean =>
    !!data.fieldStateTouch && !data.fieldState;

export default function ItemSelect(props: { data: SelectField; onChange?: (value: string) => void }): JSX.Element {
    const { data, onChange } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const helper = (): string => `${t('agentModal.roleHelper')}`;

    const handleChangeItem = (e: any): any => {
        if (data.setFieldStateString !== undefined) {
            data.setFieldStateString(e);
        } else if (data.setFieldStateNumber !== undefined) {
            data.setFieldStateNumber(e);
        }
        if (onChange) {
            onChange(e);
        }
    };

    const handleBlur = (): void => {
        if (data.setFieldStateTouch != null) {
            data.setFieldStateTouch(true);
        }
    };

    return (
        <div className={`flex-column ${data.fieldName}`} style={{ width: "100%", marginBottom: "-0.5rem" }}>
            <span id="item">{`${data.title}${data.isMandatory ? "*" : ""}`}</span>
            <Form.Item help={hasError(data) && helper()} validateStatus={hasError(data) ? "error" : ""}>
                <Select
                    id="select"
                    className={classes.select}
                    style={{ width: "100%" }}
                    defaultValue={data.fieldState}
                    showSearch
                    data-hj-allow
                    onChange={handleChangeItem}
                    onBlur={handleBlur}
                    options={data.options}
                />
            </Form.Item>
        </div>
    );
}

