import {DepthVolume, ValueTime} from "../constants";
import {MeasurementSys} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";

export interface ToggleWidgetOption {
    id: number;
    name: string;
}

export const measurementSystemOptions: ToggleWidgetOption[] = [
    { id: MeasurementSys.METRIC, name: 'Metric' },
    { id: MeasurementSys.IMPERIAL, name: 'Imperial' }
];

// Options for Depth/Volume Toggle

