// DesignModal.tsx

import React from 'react';
import useModalStyles from './useModalStyles';
import CardSection from './card_section';
import TableSection from './table_section';
import GenericIcon from '../generic_icon';
import { IconFile } from '../../../../../assets/Icons/blockData';
import { Modal, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from "../../../../../reduxToolkit/store/hooks";
import { closeModal } from "../../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice";
import {generateCSV, getMeasurementSys, handleExport} from "./utils"; // Updated import
import { DepthVolume } from "../constants";
import { Measurement } from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import { smallMaxWidth } from 'src/shared/constants/consts';
import { useWindowDimensions } from "../../../../../shared/hooks/useWindowDimensions";

interface DesignModalProps {
    title: string;
    iconName: keyof typeof IconFile;
    dayQty: number;
    setDayQty: React.Dispatch<React.SetStateAction<number>>;
    total: string;
    average: string;
    data?: any[];
    dayQtyOptions: { [key: number]: string };
    prefix: string;
}

const DesignModal: React.FC<DesignModalProps> = ({
         title,
         iconName,
         dayQty,
         setDayQty,
         total,
         average,
         data,
         dayQtyOptions,
         prefix
     }) => {
    const dispatch = useAppDispatch();
    const classes = useModalStyles();
    const { t } = useTranslation();
    const onClose = () => dispatch(closeModal());

    const measurement_system = useAppSelector(
        (state) => state.rootReducer.userState.measurementSystem,
    );
    const userCountry = useAppSelector((state) => state.rootReducer.userState.country);
    const measurementSys = getMeasurementSys(measurement_system);
    const depth_volume = useAppSelector(
        (state) => state.rootReducer.userState.depth_volume,
    );
    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);
    const measurement = depth_volume === DepthVolume.volume ?
        Measurement.VOLUME :
        Measurement.ETA;
    const { width } = useWindowDimensions();
    const isMobile = width <= smallMaxWidth;
    const columns = data && data.length > 0 ? Object.keys(data[0]) : [];
    const isToroAccount = useAppSelector((state) => state.rootReducer.accountState.isToroAccount);

    // Remove the export-related functions from here

    const handleExportClick = () => {
        handleExport(
            data,
            userCountry,
            measurementSys,
            measurement,
            title,
            currentBlock?.title || 'Export',
            currentBlock?.area// Fallback in case currentBlock.title is undefined
        );
    };

    return (
        <Modal
            visible={true}
            centered={true}
            onCancel={onClose}
            footer={null}
            width={694}
            className={classes.modalContainer}
            wrapClassName={classes.modalWrapper}
        >
            <div className={classes.modalHeader}>
                <GenericIcon name={iconName} size={24} />
                <div className={classes.title}>{title}</div>
            </div>
            <div className={classes.separator}></div>
            <div className={classes.buttonRow}>
                <Select
                    size={'large'}
                    className={classes.selectBorder} // Add the border style class here
                    dropdownClassName={classes.selectDropdown}
                    value={dayQty}
                    onChange={setDayQty}
                    suffixIcon={<GenericIcon name={'calendar_today'} size={24} />}
                >
                    {Object.entries(dayQtyOptions).map(([key, value]) => (
                        <Select.Option key={key} value={Number(key)}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
                <Button
                    className={classes.exportButton}
                    icon={<GenericIcon name={isToroAccount ? 'download_red' : 'green_download'} size={24} />}
                    onClick={handleExportClick} // Use the exported handleExport function
                >
                    {!isMobile && t('blockDataWidget.export')}
                </Button>
            </div>
            <CardSection total={total} average={average} dayQty={dayQtyOptions[dayQty]} prefix={prefix} />
            <TableSection data={data} generateCSV={() => generateCSV(data, userCountry, measurementSys, measurement)} />
        </Modal>
    );
};

export default DesignModal;