import { useTranslation } from 'react-i18next';
import { FarmField } from 'src/shared/data/types/farmTypes';

const GetFarmFields = (farmProps: any): any => {
  const { t } = useTranslation();

  const titleField: FarmField = {
    title: `${t('addFarmModal.farmNameLabel')}`,
    fieldName: 'Farm Name',
    fieldState: farmProps.title,
    setFieldStateString: farmProps.setTitle,
    setFieldStateTouch: farmProps.setTitleValidation,
    fieldStateTouch: farmProps.titleValidation,
    isMandatory: true,
  };

  const farmCenterLatField: FarmField = {
    title: `${t('addFarmModal.farmLatLabel')}`,
    fieldName: 'Center Lat',
    fieldState: farmProps.farmCenterLat,
    setFieldStateString: farmProps.setFarmCenterLat,
    isMandatory: true,
  };

  const farmCenterLngField: FarmField = {
    title: `${t('addFarmModal.farmLngLabel')}`,
    fieldName: 'Center Lng',
    fieldState: farmProps.farmCenterLng,
    setFieldStateString: farmProps.setFarmCenterLng,
    isMandatory: true,
  };

  return { titleField, farmCenterLatField, farmCenterLngField };
};

export default GetFarmFields;
