import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, List } from '@mui/material';
import { BlockListProps } from 'src/shared/data/types/blockTypes';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { setCurrentBlock } from 'src/reduxToolkit/slices/BlockSlice/block.slice';
import BlockListCard from './BlockListCard';
import { useStyles } from './useStyles';
import { sortBlocks } from './blocklist-helper';
import { useSearchParams } from 'react-router-dom';
import SearchAndSort from '../SearchAndSort';
import { useTranslation } from 'react-i18next';
import {
  clearBlock,
  setCreationInProgress,
  setIsInEditMode,
  setNewPolygonAreaAfterEdit,
  setNumOfClicksOnMapDuringCreation,
} from '../../../reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';

// Icons
import GenericIcon from '../../Growers/Grower/block_data_widget/generic_icon';
import { useUserAccess } from "../../../shared/hooks/useUserAccess";
import { IconFile } from "../../../assets/Icons/blockData";

interface ExtendedBlockListProps extends BlockListProps {
  onBlockSelect?: () => void; // Optional callback
}

const cropTypeToIconMap: { [key: string]: keyof typeof IconFile } = {
  Kiwifruit: 'kiwi',
  Cherry: 'cherry',
  Mango: 'mango',
  'Urban Tree': 'urban_tree',
  Almond: 'almond',
  Walnut: 'walnut',
  Pistachio: 'pistachio',
  Pummelo: 'pummelo',
  Avocado: 'avocado',
  'Oil Olive': 'olive',
  Orange: 'orange',
  'Forest Tree': 'forest_tree',
  'Wine Grape': 'wine_grape',
  'Table Grape': 'table_grape',
  Pecan: 'pecan',
  Peach: 'peach',
  Lemon: 'lemon',
  Grapefruit: 'grapefruit',
  Hazelnut: 'hazelnut',
  Apple: 'apple',
  Clementine: 'clementine',
};

function getIconName(cropType: string): keyof typeof IconFile {
  // Provide a default icon if the crop type is not found
  return cropTypeToIconMap[cropType] || "general_crop"; // Ensure 'general_crop' exists in IconFile
}

function BlockList(props: ExtendedBlockListProps): JSX.Element {
  const { blocks, selectedOption, searchText, setSearchText, onBlockSelect } = props;
  const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

  const [searchParams, setSearchParams] = useSearchParams();
  const [blockList, setBlockList] = useState(sortBlocks(blocks, selectedOption));

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const listItems = useRef<any>(null);
  const { t } = useTranslation();

  const { isAdmin, isAccountAdminAccess, isAgentAdminAccess } = useUserAccess();
  const { isNewBlockCreationInProgress } = useAppSelector((state) => state.rootReducer.addOrEditBlockState);
  const measurement_system = useAppSelector(
      (state) => state.rootReducer.userState.measurementSystem,
  );

  const navToBlock = (blockId: number): void => {
    setSearchParams({ blockId: blockId.toString() });
  };

  const scrollToBlock = (blockId: number): void => {
    const itemIndex = blockList.findIndex((block) => block.id === blockId);
    const targetItem = listItems?.current?.children[itemIndex];

    if (targetItem) {
      // Scroll the selected block to the top of the list
      requestAnimationFrame(() => {
        targetItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    }
  };

  useEffect(() => {
    const items = sortBlocks(blocks, selectedOption);
    setBlockList(items);
  }, [dispatch, blocks, selectedOption]);

  const handleAccordionChange = (blockId: number) => {
    const selectedBlock = blockList.find((block) => block.id === blockId);
    if (selectedBlock) {
      dispatch(setCurrentBlock(selectedBlock));
      scrollToBlock(blockId);
      if (onBlockSelect) {
        onBlockSelect();
      }
    }
  };

  function openPolygonModal() {
    dispatch(clearBlock());
    dispatch(setNewPolygonAreaAfterEdit(0));
    dispatch(setCreationInProgress(true)); // Set to true
    dispatch(setNumOfClicksOnMapDuringCreation(0));
    dispatch(setIsInEditMode(false));
  }


  const renderItemsList = blockList.map((block) => (
      <div key={block.id} className={classes.accordionWrapper}>
        <Accordion
            expanded={currentBlock?.id === block.id}
            onChange={() => handleAccordionChange(block.id)}
            className={classes.accordion}
        >
          <AccordionSummary
              expandIcon={
                <GenericIcon name={currentBlock?.id === block.id ? 'chevron_down' : 'chevron_right'} />
              }
              aria-controls={`panel${block.id}-content`}
              id={`panel${block.id}-header`}
              className={`${classes.accordionSummary} ${
                  currentBlock?.id === block.id ? classes.selectedAccordion : ''
              }`}
              classes={{
                content: classes.summaryContent,
              }}
          >
            <div className={classes.summaryText}>
              <div
                  className={`${classes.heading} ${
                      currentBlock?.id === block.id ? classes.headingSelected : ''
                  }`}
              >
                {block.title}
              </div>
              <div className={classes.cropTypeContainer}>
                {/* Use the getIconName function to select the correct icon */}
                <GenericIcon name={getIconName(block.cropType)} />
                <div className={classes.cropType}>{ t(`${block.cropType}`)}</div>
              </div>
            </div>
          </AccordionSummary>
          {currentBlock?.id === block.id && (
              <AccordionDetails className={classes.accordionDetails}>
                <BlockListCard
                    block={block}
                    isSelected={(currentBlock != null && block.id === currentBlock.id) ?? false}
                    isDeleteEnable={!(block.units && block.units.length > 0)}
                    onSelect={() => {}}
                />
              </AccordionDetails>
          )}
        </Accordion>
      </div>
  ));

  return (
      <div className={classes.root}>
        <div className={`flex-column ${classes.searchBlockFarmContainer}`}>
          <div className={`flex align-center justify-between ${classes.addBlockContainer}`}>
            <div className={classes.searchBlockText}>{t('fields.blocks')}</div>
            {isAdmin || isAccountAdminAccess || isAgentAdminAccess ? (
                <div className={classes.addBlockBtn}>
                  <GenericIcon name={'add'} onClick={openPolygonModal}/>
                </div>
            ) : null}
          </div>
          <div className={classes.searchBlockContainer}>
            <SearchAndSort setSearchText={setSearchText} />
          </div>
        </div>
        <div className={classes.listContainer}>
          <List ref={listItems}>
            {renderItemsList}
          </List>
        </div>
      </div>
  );
}

export default BlockList;