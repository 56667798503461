import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { BlockData } from '../../shared/data/types/blockDataTypes';

class BlockDataAdapter {
  static readonly endpoint = `block-data`;

  async getBlockData(id: number): Promise<AxiosResponse<BlockData[]>> {
    return HttpClientService.get<BlockData[]>(BlockDataAdapter.endpoint, {
      params: { id },
    });
  }
}

const blockDataAdapter = new BlockDataAdapter();
export default blockDataAdapter;
