import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: (localStorage.getItem('i18nextLng') ?? 'en-US').split('-')[0],
    debug: false,
    backend: {
      allowMultiLoading: false,
      wait: true, // Set to true if you want to wait for the translations to load
    },
    // initImmediate: false,
    interpolation: {
      escapeValue: false, // Not required for React
    },
    ignoreJSONStructure: true,
    react: {
      useSuspense: true, // Set to true if you want to use Suspense for loading translations
    },
  })
  .catch((err: any) => console.error(err));

export default i18n;
