import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { FieldProps } from "./ModalHelper";
import CountriesOptions from 'src/shared/data/CountryOptions';
import useStyles from './useStyles';

interface SelectCountryProps {
  data: FieldProps;
}

const hasError = (data: FieldProps): boolean => {
  return !!data.fieldStateTouch && !data.fieldState;
};

export default function SelectCountry({
  data,
}: SelectCountryProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const helper = (): string => {
    return `${t('growerFieldModal.countryHelper')}`;
  };

  const handleChangeCountry = (e: any): void => {
    if (data.setFieldStateString !== undefined) {
      data.setFieldStateString(e);
    } else if (data.setFieldStateNumber !== undefined) {
      data.setFieldStateNumber(e);
    }
  };

  const handleBlur = (): void => {
    if (data.setFieldStateTouch) {
      data.setFieldStateTouch(true);
    }
  };

  return (
    <div
      className={`flex-column ${data.fieldName}`}
      style={{ width: '100%', marginBottom: '-0.5rem' }}>
      <span id="country">{`${data.title}${data.isMandatory ? '*' : ''}`}</span>
      <Form.Item
        help={hasError(data) && helper()}
        validateStatus={hasError(data) ? 'error' : ''}>
        <Select
          id="country"
          className={classes.select}
          style={{ width: '100%' }}
          showSearch
          defaultValue={data.fieldState}
          data-hj-allow
          onChange={handleChangeCountry}
          onBlur={handleBlur}
          options={CountriesOptions.map((singleCountry: any) => ({
            value: singleCountry,
            label: t(`${singleCountry}`),
          }))}
        />
      </Form.Item>
    </div>
  );
}
