import sensorsAdapter from 'src/reduxToolkit/adapters/SensorsAdapter';
import { SensorsChartProps, Sensor } from 'src/shared/data/types/sensorTypes';
import moment from 'moment';

export const lineColors: string[] = ['#61CF70', '#134046', '#3AE1D9'];
export const toroLineColors: string[] = ['#CC0000', '#580000', '#F23A1F'];
export const inactiveColor: string = '#EDF0EC';

export const getDatesByDays = (
  daysType: string,
): { fromDate: Date; toDate: Date } => {
  let numOfDays;
  switch (daysType) {
    case 'Yesterday':
      numOfDays = 1;
      break;
    case '3 Days':
      numOfDays = 3;
      break;
    case '7 Days':
      numOfDays = 7;
      break;
    case '30 Days':
      numOfDays = 30;
      break;
    default:
      numOfDays = 1;
      break;
  }

  // The toDate is set to yesterday because it will query on refDate in the data base, that is set to beginning of a day (for example 2022-11-20 00:00:00).
  // therefor, we want to query from today minos numOfDays until yesterday.
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const from = new Date(new Date().setDate(new Date().getDate() - numOfDays));
  return {
    fromDate: from,
    toDate: yesterday,
  };
};

const getModuloOfNumOfDatesToShow = (
  daysType: string,
  datesRange = 0,
): number => {
  switch (daysType) {
    case 'Yesterday':
      return 1;
    case '3 Days':
      return 2;
    case '7 Days':
      return 4;
    case '30 Days':
      return 16;
    default:
      return Math.floor(datesRange / 2) + 1;
  }
};

export const getSensorsByBlockId = async (
  blockId: number,
  numOfDays: string,
  fromDate: Date,
  toDate: Date,
  datesRange: number = 0,
  measurementSystem?: string,
): Promise<any> => {
  const newFromDate = moment(fromDate).format('YYYY-MM-DD');
  const newToDate = moment(toDate).format('YYYY-MM-DD');
  // const newFromDate = moment.utc(fromDate).format("YYYY-MM-DD");
  // const newToDate = moment.utc(toDate).format("YYYY-MM-DD") ;

  const res = await sensorsAdapter.getSensorsProcessedDataByBlock(
    blockId,
    newFromDate,
    newToDate,
    measurementSystem,
  );
  // const sensorsIds: number[] = [];
  const sensors: Sensor[] = [];
  const dates: string[] = [];
  const moduloByDaysType: number = getModuloOfNumOfDatesToShow(
    numOfDays,
    datesRange,
  );

  const units = res.data;
  units.forEach((unit: any) => {
    const values: number[] = [];
    const rawValues: number[] = [];
    let samples = [];
    if (unit.unitsProcessedHourlyDataItems)
      samples = unit.unitsProcessedHourlyDataItems;

    samples.forEach((dataItem: any, index: any) => {
      if (index % moduloByDaysType === 0) {
        values.push(dataItem.sapFlow);
      }
      rawValues.push(dataItem.sapFlow);
    });

    sensors.push({
      sensorId: unit.imei,
      values: values,
      rawValues: rawValues,
      blockId: blockId,
    });
  });

  if (units.length > 0 && units[0].unitsProcessedHourlyDataItems) {
    units[0].unitsProcessedHourlyDataItems.forEach(
      (dataItem: any, index: any) => {
        if (index % moduloByDaysType === 0) {
          dates.push(
            new Date(dataItem.sampleTimestamp)
              .toLocaleDateString('en-us', {
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
              })
              .replace(',', ''),
          );
        }
      },
      [],
    );
  }

  return Promise.resolve({ sensors, dates }).catch((err) => console.error(err));
};

export const getChartData = (data: SensorsChartProps): any => ({
  labels: data.dates,
  cubicInterpolationMode: 'monotone',
  datasets: data.sensors.map((sensor, index) => ({
    label: sensor.sensorId,
    data: sensor.values,
    borderWidth: 5,
    pointStyle: 'line',
    borderJoinStyle: 'round',
    borderColor: data.sensorsState[sensor.sensorId]
      ? lineColors[index]
      : inactiveColor,
    tension: 0.4,
  })),
});

export const getToroChartData = (data: SensorsChartProps): any => ({
  labels: data.dates,
  cubicInterpolationMode: 'monotone',
  datasets: data.sensors.map((sensor, index) => ({
    label: sensor.sensorId,
    data: sensor.values,
    borderWidth: 5,
    pointStyle: 'line',
    borderJoinStyle: 'round',
    borderColor: data.sensorsState[sensor.sensorId]
      ? toroLineColors[index]
      : inactiveColor,
    tension: 0.4,
  })),
});

export const getMaxValue = (data: SensorsChartProps): number => {
  let maxValue = -1;
  data.sensors.forEach((sensor) => {
    const max = Math.max(...sensor.values);
    if (max > maxValue) {
      maxValue = max;
    }
  });
  return maxValue;
};

export const getChartOptions = (
  datesArray: string[],
  maxValue: number,
): any => ({
  responsive: true,
  elements: {
    point: {
      radius: 2,
    },
  },
  plugins: {
    datalabels: {
      anchor: 'end' as 'end',
      align: 'start' as 'start',
      clamp: true,
      display: false,
    },
    tooltip: {
      backgroundColor: '#fff',
      bodyColor: '#4A5C73',
      titleColor: '#4A5C73',
      titleFont: { weight: '300', size: 14 },
      titleAlign: 'left',
      bodySpacing: 10,
      bodyFont: { weight: '400', size: 14 },
      boxWidth: 1,
      boxHeight: 1,
      boxPadding: 5,
      cornerRadius: 8,
      padding: 13,
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderWidth: 1,
      footerColor: 'rgba(0, 0, 0, 0)',
    },
    legend: {
      display: false,
    },
  },
  interaction: {
    mode: 'x',
    intersect: false,
    includeInvisible: true,
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
      },
      beginAtZero: false,
      grid: {
        borderColor: ' #C9CDD4',
        borderDash: [8, 4],
        color(ctx: any) {
          return ctx.index === datesArray.length - 1 ? '#fff' : '#EDF0EC';
        },
      },
    },
    y: {
      offset: true,
      display: true,
      title: {
        display: false,
      },
      ticks: {
        stepSize: 0.05,
        padding: 5,
      },
      grid: {
        borderColor: '#fff',
        color() {
          return '#EDF0EC';
        },
      },
      min: 0,
      max: maxValue,
      suggestedMin: 0,
      suggestedMax: maxValue,
      beginAtZero: true,
    },
  },
});
