import { createAsyncThunk } from '@reduxjs/toolkit';
import sensorsAdapter from '../adapters/SensorsAdapter';

export const getUnconnectedSensors = createAsyncThunk<
  any,
  string,
  { rejectValue: Error }
>(
  'blocks/unconnectedSensors',
  async (str, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await sensorsAdapter.getUnconnectedSensors();
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export default getUnconnectedSensors;
