import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxWidth: '100vw',
    width: '100%',
    paddingBlock: '1.5%',
  },
  iconBtn: {
    width: '1rem',
  },
  contentContainer: {
    width: '100%',
  },
  email: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  header: {
    color: theme.palette.darkGrey.main,
    width: '100%',
    fontWeight: '500',
    fontSize: 'clamp(24px, 30px, 36px)',
    borderBottom: `solid 1px ${theme.palette.hoverGrey.main}`,
  },
  btnContainer: {
    width: '100%',
    marginBlock: '3% 0',
    zIndex: 10,
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      height: '4rem',
      marginBottom: '-2rem',
    },
  },
  mobileBtnContainer: {
    width: '100%',
    marginBlockStart: '2rem',
    zIndex: 10,
    flexDirection: 'column-reverse',
    gap: '1.5rem',
  },
  userButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem',
    width: '18rem',
    marginLeft: 'auto',
    backgroundColor: 'transparent',
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    color: theme.palette.accent.main,
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    '& img': {
      marginRight: '4%',
      fill: theme.palette.accent.main,
    },
    '&:hover': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      color: '#fff',
      backgroundColor: theme.palette.accent.main,
    },
    '@media (min-width: 320px) and (max-width: 900px)': {
      margin: 'auto',
    },
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  root: {
    position: 'relative',
    minWidth: '100%',
    paddingBlock: 'calc(3rem + 1vh) 2rem',
    '& .ant-table-wrapper': {
      '& .ant-spin-nested-loading': {
        '& .ant-spin-container': {
          '& .ant-table': {
            '& .ant-table-container': {
              '& .ant-table-header': {
                alignItems: 'left',
                '& table': {
                  tableLayout: 'auto',
                  width: '100%',
                  textAlign: 'left',
                  '& .ant-table-thead > tr > th': {
                    textAlign: 'unset !important',
                    paddingInline: '0.5rem !important',
                    '@media (max-width: 767px)': {
                      '&:nth-child(1)': {
                        marginLeft: 'auto',
                        justifyContent: 'left',
                        whiteSpace: 'nowrap',
                        textAlign: 'left',
                        paddingInline: '0.2rem',
                      },
                    },
                  },
                },
              },
              '& .ant-table-body': {
                overflowX: 'hidden',
                '& table': {
                  tableLayout: 'auto',
                  width: '100%',
                  '& tbody > tr.ant-table-measure-row td': {
                    padding: '0 !important',
                  },
                  '& .ant-table-tbody > tr > td': {
                    padding: '10px 0.5rem !important',
                    textAlign: 'left',
                    '@media (max-width: 767px)': {
                      '&:nth-child(1)': {
                        marginLeft: 'auto',
                        justifyContent: 'left',
                        whiteSpace: 'nowrap',
                        textAlign: 'left',
                        paddingInline: '0.2rem',
                        maxWidth: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    },
                    '&:nth-child(4)': {
                      width: '120px',
                    },
                    '&:nth-child(6)': {
                      maxWidth: 'min-content',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    '@media (max-width: 767px)': {
      paddingBlockStart: '2rem',
    },
  },
  addNewFarmIcon: {
    margin: 'auto',
  },
  column: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 'clamp(0.6rem, 1.1rem, 1.3rem)',
  },
  title: {
    paddingInline: '20%',
    fontWeight: '700',
  },
  tableRowLight: {
    backgroundColor: '#ffffff',
  },
  tableRowDark: {
    backgroundColor: '#ededed',
    opacity: 0.6,
    pointerEvents: 'none',
  },
  actions: {
    margin: '0 auto',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '10%',
    '& span': {
      width: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '@media (max-width: 767px)': {
      marginLeft: 'unset',
      margin: '0 auto',
      justifyContent: 'left',
    },
  },
  tableBtn: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
    background: 'transparent',
    color: 'inherit',
    height: '24px !important',
    padding: 'unset !important',
    '&:hover': {
      color: '#000000e0 !important',
    },
    '&:has(.addNewFarmIcon)': {
      display: 'flex',
      justifyContent: 'center !important',
      margin: 'auto',
    },
  },
  tableCopyBtn: {
    margin: '0 auto',
    textAlign: 'left',
    '& span': {
      width: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  searchAndSort: {
    width: '100%',
    backgroundColor: '#fff',
    flex: '1',
  },
  searchInput: {
    minHeight: '-webkit-fill-available',
    maxWidth: '-webkit-fill-available',
    marginRight: 'auto',
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      minHeight: 'auto',
    },
    '@media (max-width: 767px)': {
      width: '60%',
      margin: '0 auto',
    },
  },
}));

export default useStyles;
