import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',

    '& input': {
      border: '1px solid',
      borderColor: '#76778673',
      borderRadius: '4px',
      height: '32px',
      padding: '0 11px',
      '&::placeholder': {
        color: '#76778673',
      },
      '&:-ms-input-placeholder': {
        color: '#76778673',
      },
      '&::-ms-input-placeholder': {
        color: '#76778673',
      },
      '&:focus-visible': {
        border: '1px solid !important',
        borderColor: '#76778673 !important',
        '&:hover': {
          outline: '#76778673 !important',
          border: '1px solid !important',
          borderColor: '#76778673 !important',
        },
      },
      '&:focus, &:hover': {
        boxShadow: '0 0 3px #76778673 !important',
        border: 'inherit',
      },
      '&:focus, &:active, &:hover': {
        border: '1px solid #76778673 !important',
      },
      '& .ant-form-item .ant-form-item-control-input': {
        border: '1px solid #76778673',
        '&:focus, &:hover': {
          boxShadow: '0 0 3px #76778673 !important',
          border: 'inherit',
        },
        '&:focus, &:active, &:hover': {
          border: '1px solid #76778673 !important',
        },
      },
      '&[disabled]': {
        backgroundColor: '#d7d7db !important',
        border: '1px solid #d7d7db !important',
        userSelect: 'none',
        cursor: 'not-allowed !important',
        pointerEvents: 'none',
        '&::selection': {
          background: 'none',
        },
      },
    },
    '& input:focus-visible': {
      outline: 'none',
    },
  },
}));

export default useStyles;
