import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Typography } from '@mui/material';
import useStyles from './useStyles';

function ConfirmationModal(props: any): JSX.Element {
  const { onHandle, onClose, loading, question, record, title } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={`flex-column align-center justify-center ${classes.root}`}>
      <Typography>{title}</Typography>
      <Typography variant="titleMedium">
        {question} {record.title}?
      </Typography>
      {loading && (
        <div>
          <div className={classes.loader}>
            <div>
              <div className="lds-ellipsis" id="progress-loader">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.btnContainer}>
        <Button
          id="confirm-activate"
          type="primary"
          className={classes.confirmationBtn}
          disabled={loading}
          onClick={() => {
            onHandle(record);
          }}>
          {`${t('activate')}`}
        </Button>
        <Button
          type="primary"
          className={classes.cancelBtn}
          onClick={() => {
            onClose();
          }}>
          {`${t('deleteModal.cancel')}`}
        </Button>
      </div>
    </div>
  );
}

export default ConfirmationModal;
