import { useTranslation } from 'react-i18next';
import DropDown from 'src/shared/components/DropDown';
import WeatherInfoBarElement from 'src/shared/components/WeatherInfoBarElement';
import { WeatherCloseState } from 'src/shared/data/types/weatherTypes';
import windIcon from 'src/assets/Icons/sharedIcons/wind.svg';
import humidityIcon from 'src/assets/Icons/sharedIcons/humidity.svg';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { Typography } from 'antd';
import { roundPrecision } from 'src/shared/helpers/HelperFunctions';
import { useStyles } from './useStyles';
import { MeasurementSystem } from '../../data/types/UserTypes';
import {
  Measurement,
  MeasurementSys,
} from '../../measurements-converter/measurement-enums/measurement-enums';
import {
  convertAndRound,
  convertLabel,
} from '../../measurements-converter/converter';
import { smallMaxWidth } from 'src/shared/constants/consts';
import {useWindowDimensions} from "../../hooks/useWindowDimensions";

function WeatherClosedState(props: WeatherCloseState): JSX.Element {
  const {
    handleOnClick,
    currTempIcon,
    currentTemperatureValue,
    currentTemperatureLabel,
  } = props;
  const currWeatherData = useAppSelector(
    (state) => state.rootReducer.weatherState.currWeatherData,
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );
  const userCountry = useAppSelector(
    (state) => state.rootReducer.userState.country,
  );
  const { width } = useWindowDimensions();

  const measureSys =
    selectedMeasurementSystem === MeasurementSystem.Metric
      ? MeasurementSys.METRIC
      : MeasurementSys.IMPERIAL;

  const currentWindSpeedValue = convertAndRound(
    userCountry,
    measureSys,
    Measurement.WIND_SPEED,
    currWeatherData.windSpeed,
    2,
  );
  const currentWindSpeedLabel = `${convertLabel(
    userCountry,
    measureSys,
    Measurement.WIND_SPEED,
  )}`;

  const weatherFields = {
    HUMIDITY: `${t('weather.humidity')}`,
    WIND: `${t('weather.wind')}`,
  };

  return (
    <ul className={`clean-list flex align-center ${classes.closedState}`}>
        {width > smallMaxWidth ? (
            <li>
                <Typography>{t('weather.currentWeather')}</Typography>
            </li>
        ) : null}
        {width > smallMaxWidth ? (<span style={{ color: '#676879', opacity: '0.8' }}>|</span> ) : null}
      <li
        className={classes.headerNavigation}
        style={{ textTransform: 'none' }}>
        {currWeatherData?.temperature !== null && (
          <WeatherInfoBarElement
            measure={
              currWeatherData?.temperature !== undefined
                ? `${currentTemperatureValue}${currentTemperatureLabel}`
                : ''
            }
            icon={currTempIcon}
            fontSize={16}
          />
        )}
        {currWeatherData?.windSpeed !== null && (
          <WeatherInfoBarElement
            text={weatherFields.WIND}
            measure={
              currWeatherData?.windSpeed !== undefined
                ? `${currentWindSpeedValue} ${currentWindSpeedLabel}`
                : ''
            }
            icon={windIcon}
            fontSize={16}
          />
        )}
        {currWeatherData?.humidity !== null && (
          <WeatherInfoBarElement
            text={weatherFields.HUMIDITY}
            measure={`${
              currWeatherData?.humidity !== undefined
                ? roundPrecision(currWeatherData.humidity, 0)
                : ''
            }%`}
            icon={humidityIcon}
            fontSize={16}
          />
        )}
      </li>
        {width > smallMaxWidth ? (  <span style={{ color: '#676879', opacity: '0.8' }}>|</span>) : null}
      <li className={classes.rightSide}>
        <DropDown onClick={handleOnClick} arrowDirection="down" />
      </li>
    </ul>
  );
}

export default WeatherClosedState;
