import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postEditAgent } from 'src/reduxToolkit/thunks/agent_thunks';
import countries from 'src/consts/countries';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Regex } from 'src/shared/constants/consts';
import {
  handleCountryValidation,
  isValidTLD,
} from 'src/shared/helpers/FormValidationHelper';
import { useAppDispatch } from 'src/reduxToolkit/store/hooks';
import {
  SelectCountry,
  SelectState,
  SelectOwner,
  SelectItem,
  TextForm,
  GenericModal,
  ModalHelper,
} from 'src/shared/components/GenericModal';
import countryMap from 'src/shared/data/CountryMap';
import { Owner } from 'src/shared/data/types/agentOptionsTypes';
import { UserRole } from 'src/shared/constants/enums';
import agentsAdapter from 'src/reduxToolkit/adapters/AgentsAdapter';

function EditAgentModal({
  record,
  onClose,
  onSave,
  account,
}: any): ReactElement | null {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<string>(record.country);
  const [city, setCity] = useState<string>(record.city);
  const [countryValidation, setCountryValidation] = useState<boolean>(false);
  const [stateName, setStateName] = useState<string>(record.state);
  const [stateNameValidation, setStateNameValidation] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(record.firstName);
  const [firstNameValidation, setFirstNameValidation] =
    useState<boolean>(false);
  const [lastName, setLastName] = useState<string>(record.lastName);
  const [lastNameValidation, setLastNameValidation] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(record.email);
  const [emailValidation, setEmailValidation] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(record.phoneNumber);
  const [phoneValidation, setPhoneValidation] = useState<boolean>(false);
  const [ownerId, setOwnerId] = useState<string>(record.ownerId);
  const [owners, setOwners] = useState<Owner[]>([]);
  const [ownerEmail, setOwnerEmail] = useState<string>(record.ownerEmail);
  const [role, setRole] = useState<string>(record.role);
  const [roleValidation, setRoleValidation] = useState<boolean>(false);
  const roleList =
    account?.role === UserRole.AGENT_ADMIN
      ? [UserRole.AGENT_ADMIN]
      : [UserRole.ACCOUNT_ADMIN, UserRole.AGENT_ADMIN];
  const { t } = useTranslation();
  const props = {
    country,
    setCountry,
    countryValidation,
    setCountryValidation,
    stateName,
    setStateName,
    stateNameValidation,
    setStateNameValidation,
    firstName,
    setFirstName,
    firstNameValidation,
    setFirstNameValidation,
    lastName,
    setLastName,
    lastNameValidation,
    setLastNameValidation,
    email,
    setEmail,
    emailValidation,
    setEmailValidation,
    phone,
    setPhone,
    phoneValidation,
    setPhoneValidation,
    role,
    setRole,
    roleValidation,
    setRoleValidation,
    ownerId,
    setOwnerId,
    ownerEmail,
    setOwnerEmail,
    city,
    setCity,
  };
  const agentFields = ModalHelper(props);
  const handleUpdate = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    void dispatch(
      postEditAgent({
        id: record.sub,
        editAgent: {
          firstName,
          lastName,
          email,
          phone,
          country,
          city,
          state: stateName,
          ownerId,
          ownerEmail,
          role,
        },
      }),
    )
      .then((res) => {
        if (res.payload != null) {
          onSave({
            firstName,
            lastName,
            email,
            phone,
            country,
            city,
            ownerId,
            ownerEmail,
            role,
            state: stateName,
            ...record,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderRoleLabel = (item: string): any => {
    if (item === 'account-admin') {
      return t('agentModal.accountAdminRole');
    }
    if (item === 'agent-admin') {
      return t('agentModal.agentAdminRole');
    }
    return item;
  };

  useEffect(() => {
    getOwners().then();
  }, []);

  useEffect(() => {
    if (record.ownerEmail == null && owners.length) {
      setOwnerEmail(owners.find((owner) => owner.id === ownerId)?.email || '');
    }
  }, [owners, ownerId, record.ownerEmail]);

  async function getOwners() {
    const owners = await agentsAdapter.getOwners();
    setOwners(owners.data.owners);
  }

  return (
    <GenericModal
      title={t('agentModal.updateAgentModalTitle')}
      onClose={onClose}
      onSave={handleUpdate}
      isLoading={loading}
      isSaveDisabled={
        !ownerId ||
        !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !country ||
        !isValidPhoneNumber(phone) ||
        !isPossiblePhoneNumber(phone) ||
        !Regex.EMAIL_REGEX.test(email) ||
        (Regex.EMAIL_REGEX.test(email) && !isValidTLD(email)) ||
        !handleCountryValidation(country, stateName)
      }
      saveButtonText={t('Modal.updateAgentBtn')}
      cancelButtonText={t('Modal.cancel')}
      isAgent={true}>
      <div className="First">
        <TextForm data={agentFields.firstNameField} typeForm="agent" />
      </div>
      <div className="Last">
        <TextForm data={agentFields.lastNameField} typeForm="agent" />
      </div>
      <div className="Country">
        <SelectCountry data={agentFields.countryField} />
      </div>
      <div className="State">
        {country === countries.USA || country === countries.USAShort ? (
          <SelectState data={agentFields.stateNameField} />
        ) : (
          <TextForm data={agentFields.cityField} typeForm="agent" />
        )}
      </div>

      <div className="Phone">
        <TextForm
          data={agentFields.phoneField}
          type="phone"
          code={countryMap.get(country)}
          typeForm="agent"
        />
      </div>
      <div className="Email">
        <TextForm data={agentFields.emailField} type="email" typeForm="agent" />
      </div>
      <div className="Owner">
        <SelectOwner
          owners={owners}
          onChange={(id, email) => {
            setOwnerId(id);
            setOwnerEmail(email);
          }}
          selectedOwnerId={ownerId}
        />
      </div>
      <div className="Role">
        <SelectItem
          data={{
            ...agentFields.roleField,
            options: roleList.map((item) => ({
              label: renderRoleLabel(item),
              value: item,
            })),
          }}
        />
      </div>
    </GenericModal>
  );
}

export default EditAgentModal;
