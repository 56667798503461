import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import useStyles from '../useStyles';
import { roleParser } from '../../../shared/constants/enums';
import copyIcon from '../../../assets/Icons/sharedIcons/copyIcon.svg';

function AgentPreview({ agent, onCopy }: any): ReactElement | null {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderAgentRole = (): any => {
    const agentRole = roleParser(agent?.role);
    if (agentRole === 'Account Admin') {
      return t('agentPage.accountAdminRole');
    }
    if (agentRole === 'Agent Admin') {
      return t('agentPage.agentAdminRole');
    }
    return agent?.role;
  };

  return (
    <div>
      <Typography className={classes.title}>
        {t('agentPage.profileInformation')}
      </Typography>
      <Typography className={classes.subtitleBold}>
        {agent?.firstName} {agent?.lastName}
      </Typography>
      <div className={classes.subtitleBold}>{renderAgentRole()}</div>
      {agent?.email && (
        <div>
          <a
            href={`mailto:${agent?.email}`}
            className={`${classes.tableBtn} ${classes.item}`}>
            {agent?.email}
          </a>
          <Button
            id="copy-agent-email"
            onClick={(e) => onCopy(e, agent?.email)}
            className={`${classes.tableBtn} ${classes.item}`}>
            <img
              src={`${copyIcon}`}
              alt="copy email"
              id="copy-agent-email"
              style={{ width: '1rem' }}
            />
          </Button>
        </div>
      )}
      {agent?.phoneNumber && (
        <div>
          <span>{agent?.phoneNumber}</span>
          <Button
            id="copy-agent-phone-number"
            onClick={(e) => onCopy(e, JSON.parse(agent?.phoneNumber))}
            className={`${classes.tableBtn} ${classes.item}`}>
            <img
              src={`${copyIcon}`}
              alt="copy phone number"
              id="copy-agent-phone-number"
              style={{ width: '1rem' }}
            />
          </Button>
        </div>
      )}
    </div>
  );
}

export default AgentPreview;
