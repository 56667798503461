import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { User } from '../../shared/data/types/UserTypes';

const baseUrl: string = process.env.REACT_APP_SERVER_BASE_URL ?? '';

class UsersAdapter {
  static readonly endpoint = `${baseUrl}/users`;

  async getUserDetails(accessToken: string): Promise<AxiosResponse<User>> {
    return HttpClientService.get<User>(`${UsersAdapter.endpoint}/user`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  async updateUserFarmId(
    accessToken: string,
    farm_manager_id: string,
  ): Promise<AxiosResponse<any>> {
    return HttpClientService.put<any>(
      `${UsersAdapter.endpoint}/update-employee-farm-manager-id`,
      {
        farm_manager_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
  }

  async getInstallers(): Promise<AxiosResponse<any>> {
    return HttpClientService.get<any>(
      `${UsersAdapter.endpoint}/installers-list`,
      {},
    );
  }

  async updateUserGrowerId(
    accessToken: string,
    grower_cognito_id: string,
  ): Promise<AxiosResponse<any>> {
    return HttpClientService.put<any>(
      `${UsersAdapter.endpoint}/update-employee-grower-cognito-id`,
      {
        grower_cognito_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
  }
}
const authAdapter = new UsersAdapter();
export default authAdapter;
