import { ReactComponent as almond } from 'src/assets/Icons/sharedIcons/almond.svg';
import { ReactComponent as apple } from 'src/assets/Icons/sharedIcons/apple.svg';
import { ReactComponent as avocado } from 'src/assets/Icons/sharedIcons/avocado.svg';
import { ReactComponent as clementine } from 'src/assets/Icons/sharedIcons/clementine.svg';
import { ReactComponent as forestTree } from 'src/assets/Icons/sharedIcons/forestTree.svg';
import { ReactComponent as grapefruit } from 'src/assets/Icons/sharedIcons/grapefruit.svg';
import { ReactComponent as hazelnut } from 'src/assets/Icons/sharedIcons/hazelnut.svg';
// import { ReactComponent as kiwifruit } from "src/assets/Icons/sharedIcons/kiwifruit.svg";
import { ReactComponent as lemon } from 'src/assets/Icons/sharedIcons/lemon.svg';
import { ReactComponent as oilOlive } from 'src/assets/Icons/sharedIcons/oilOlive.svg';
import { ReactComponent as orange } from 'src/assets/Icons/sharedIcons/orange.svg';
import { ReactComponent as peach } from 'src/assets/Icons/sharedIcons/peach.svg';
import { ReactComponent as pecan } from 'src/assets/Icons/sharedIcons/pecan.svg';
import { ReactComponent as pistachio } from 'src/assets/Icons/sharedIcons/pistachio.svg';
import { ReactComponent as pummelo } from 'src/assets/Icons/sharedIcons/pummelo.svg';
import { ReactComponent as tableGrape } from 'src/assets/Icons/sharedIcons/tableGrape.svg';
import { ReactComponent as urbanTree } from 'src/assets/Icons/sharedIcons/urbanTree.svg';
import { ReactComponent as walnut } from 'src/assets/Icons/sharedIcons/walnut.svg';
import { ReactComponent as wineGrape } from 'src/assets/Icons/sharedIcons/wineGrape.svg';

export const CropsIcons: any = {
  Almond: almond,
  Apple: apple,
  Avocado: avocado,
  Clementine: clementine,
  ForestTree: forestTree,
  Grapefruit: grapefruit,
  Hazelnut: hazelnut,
  // Kiwifruit: kiwifruit,
  Lemon: lemon,
  OilOlive: oilOlive,
  Orange: orange,
  Peach: peach,
  Pecan: pecan,
  Pistachio: pistachio,
  Pummelo: pummelo,
  TableGrape: tableGrape,
  UrbanTree: urbanTree,
  Walnut: walnut,
  WineGrape: wineGrape,
  Other: orange,
};

export default CropsIcons;
