import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActualIrrigation } from 'src/shared/data/types/blockDataTypes';
import flowDataAdapter from '../adapters/FlowAdapter';

const getFlowData = createAsyncThunk<
    ActualIrrigation[],
    { id: any },
    {
        rejectValue: Error;
    }
>(
    'flow-data',
    async ({ id }, { rejectWithValue, fulfillWithValue }): Promise<any> => {
        try {
            const response = await flowDataAdapter.getFlowData(id);
            const { data } = response;
            return fulfillWithValue(data);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export default getFlowData;
