import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  sortByContainer: {
    background: theme.palette.backgroundGrey.main,
    paddingInlineStart: '10px',
  },
  sortByBtn: {
    paddingInline: '15px',
    display: 'flex',
    width: '100%',
    height: '34px',
    background: '#fff',
    justifyContent: 'flex-end',
    alignItems: 'center',
    border: 'none',
  },

  '@media (max-width: 767px)': {
    sortByBtn: {
      width: '40px',
      height: '40px',
    },
  },
}));

export default useStyles;
