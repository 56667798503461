import GrowersReportCollapsibleTable from './GrowersReportTableComponents/GrowersReportsCollapsibleTable';
import useStyles from '../useStyles';

export default function GrowersReportsTableView(props: any): JSX.Element {
  const { weeklyData } = props;
  const classes = useStyles();

  return (
    <div
      className={`flex-column align-center ${classes.reportsTableContainer}`}
      id="growers-reports-table">
      <GrowersReportCollapsibleTable rows={weeklyData} />
    </div>
  );
}
