import { useTranslation } from 'react-i18next';
import SortByButton from 'src/shared/components/SortByButton';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';
import { SearchAndSortProps } from 'src/shared/data/types/searchBarType';
import useStyles from './useStyles';

function SearchAndSort(props: SearchAndSortProps): JSX.Element {
  const { onClick, setSearchText } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={`flex align-center justify-between ${classes.searchAndSort}`}>
      <article
        className={`flex align-center justify-between container ${classes.searchInput}`}>
        <SearchBar
          searchInputState={setSearchText}
          searchPlaceholder={t('fields.searchBlocks')}
        />
      </article>
        { onClick ?
            <article>
            <SortByButton onClick={onClick}/>
              </article>
            :
            null
        }

    </div>
  );
}

export default SearchAndSort;
