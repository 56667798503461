import { useTranslation } from 'react-i18next';
import { TextFormProps } from 'src/shared/data/types/accountOptionsTypes';
import { Input, Form } from 'antd';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { FieldConfigType } from 'src/shared/data/types/modalTypes';
import PhoneInput from 'react-phone-input-2';
import { Regex } from 'src/shared/constants/consts';
import { DefaultCountryCode } from 'src/shared/constants/enums';
import { isValidTLD } from 'src/shared/helpers/FormValidationHelper';
import useStyles from '../useStyles';
import 'react-phone-input-2/lib/style.css';

export default function EditTextForm(props: TextFormProps): JSX.Element {
  const { data, type, code } = props;
  const defaultErrorChecker = (): boolean =>
    !data.fieldStateTouch && !data.fieldState;
  const classes = useStyles();
  const { t } = useTranslation();
  const defaultHelper = (key: string) => () => t(`accountFieldModal.${key}`);

  const fieldConfig: Record<string, FieldConfigType> = {
    'Account Title': {
      helperKey: 'titleHelper',
    },
    'Account Email': {
      type: 'email',
      regex: Regex.EMAIL_REGEX,
      hasError: () => {
        if (!data.fieldStateTouch) return false;
        if (!data.fieldState) return true;
        if (!Regex.EMAIL_REGEX.test(`${data.fieldState}`)) return true;
        // TLD = top-level domain extension - i.e .com .il etc.
        if (
          Regex.EMAIL_REGEX.test(`${data.fieldState}`) &&
          !isValidTLD(`${data.fieldState}`)
        )
          return true;
        return false;
      },
      helperKey: 'emailHelper',
    },
    'First Name': {
      helperKey: 'firstNameHelper',
    },
    'Last Name': {
      helperKey: 'lastNameHelper',
    },
  };

  const helpForm: any = Object.fromEntries(
    Object.entries(fieldConfig).map(([key, config]) => [
      key,
      {
        type: config.type ?? 'text',
        hasError: config.hasError ? config.hasError : defaultErrorChecker,
        helper: defaultHelper(config.helperKey),
        regex: config.regex ?? undefined,
      },
    ]),
  );

  const handleChange = (e: any): any => {
    e.preventDefault();
    e.stopPropagation();
    if (data.setFieldStateString !== undefined) {
      data.setFieldStateString(e.target.value);
    } else if (data.setFieldStateNumber !== undefined) {
      data.setFieldStateNumber(e.target.value);
    }
  };
  const handleBlur = (): void => {
    if (data.setFieldStateTouch != null) {
      data.setFieldStateTouch(true);
    }
  };

  const value: any = data.fieldState;

  // This function checks if the phone number is valid or not.
  const isPhoneValid = (phone: string): boolean => {
    return isValidPhoneNumber(phone) && isPossiblePhoneNumber(phone);
  };

  // This function returns the appropriate error message or null.
  const getPhoneError = (phone: string): string | null => {
    if (!phone) return null;
    if (!isPhoneValid(phone)) return 'Invalid phone number';
    return null;
  };

  const handlePhoneChange = (ev: any): any => {
    if (ev != null) {
      const number: any = ev;
      if (data?.setFieldStateString != null) {
        data.setFieldStateString(`+${number}`);
      }
    }
  };

  return (
    <div
      className={`flex-column ${data.fieldName}`}
      style={{ width: '100%', marginBottom: '-0.5rem' }}>
      <span>{`${data.title} ${data.isMandatory ? '*' : ''}`}</span>
      {type === 'phone' && (
        <>
          <PhoneInput
            value={value}
            country={code?.toLowerCase() ?? DefaultCountryCode.USA}
            countryCodeEditable={false}
            onChange={handlePhoneChange}
            data-hj-allow
            containerClass={`common-input ${
              value && !isPhoneValid(value) ? classes.errorContainer : ''
            }`}
          />
          <span className={classes.error}>{getPhoneError(value)}</span>
        </>
      )}
      {type !== 'phone' && (
        <Form.Item
          help={
            helpForm[data.fieldName]?.hasError()
              ? helpForm[data.fieldName]?.helper()
              : undefined
          }
          validateStatus={helpForm[data.fieldName]?.hasError() ? 'error' : ''}>
          <Input
            className={classes.itemInput}
            placeholder={`${data.title}${data.isMandatory ? '*' : ''}`}
            onChange={(e) => handleChange(e)}
            defaultValue={data.fieldState}
            required={data.isMandatory}
            onBlur={() => handleBlur()}
            type={helpForm[data.fieldName]?.type || 'text'}
            data-hj-allow
          />
        </Form.Item>
      )}
    </div>
  );
}
