import SortTypeEnum from 'src/shared/constants/sortType.enum';
import { MiniBlock } from '../../../shared/data/types/growerFarmsTypes';

export const sortBlocks = (
  blocksArr: MiniBlock[],
  sortType: string,
): MiniBlock[] => {
  switch (sortType) {
    case SortTypeEnum.NAME:
      return [...blocksArr].sort((a, b) => a.title.localeCompare(b.title));
    case SortTypeEnum.VARIETY:
      return [...blocksArr].sort((a, b) =>
        a.cropType.localeCompare(b.cropType),
      );
    default:
      return [...blocksArr].sort((a, b) => a.title.localeCompare(b.title));
  }
};

export default sortBlocks;
