import {
  measurementSystemLabels,
  metricToImperialConstants,
} from './converter.constants';
import { ConversionActions, ConversionResult } from './converter.types';

export default function toImperial(
  value: number | null | undefined,
  action: ConversionActions,
): ConversionResult {
  const res: ConversionResult = { label: measurementSystemLabels.none };

  switch (action) {
    case ConversionActions.CubicCentimetersAndCubicInches:
      res.value = !value
        ? value
        : value * metricToImperialConstants.cubicMeterToCubicInch;
      res.label = measurementSystemLabels.in3H;
      break;

    case ConversionActions.SquareCentimetersAndSquareInches:
      res.value = !value ? value : value * metricToImperialConstants.cm2ToIn2;
      res.label = measurementSystemLabels.in2H;
      break;

    case ConversionActions.litterAndGallonsPerHour:
      res.value = !value ? value : value * metricToImperialConstants.litToGal;
      res.label = measurementSystemLabels.galH;
      break;

    case ConversionActions.litterAndGallonsPerDay:
      res.value = !value ? value : value * metricToImperialConstants.litToGal;
      res.label = measurementSystemLabels.galD;
      break;

    case ConversionActions.CelsiusAndFahrenheit:
      res.value = !value ? value : metricToImperialConstants.cToF(value);
      res.label = measurementSystemLabels.far;
      break;

    case ConversionActions.MillimeterAndInches:
      res.value = !value ? value : value * metricToImperialConstants.mmToIn;
      res.label = measurementSystemLabels.in;
      break;

    case ConversionActions.SquareMetersAndAcres:
      res.value = !value ? value : value * metricToImperialConstants.m2ToAc;
      res.label = measurementSystemLabels.ac;
      break;

    case ConversionActions.SquareMetersToAcresOrHectares:
      res.value = !value ? value : value * metricToImperialConstants.m2ToAc;
      res.label = measurementSystemLabels.ac;
      break;

    case ConversionActions.HectaresAndAcres:
      res.value = !value ? value : value * metricToImperialConstants.hecToAc;
      res.label = measurementSystemLabels.ac;
      break;

    case ConversionActions.DunamsAndAcres:
      res.value = !value ? value : value * metricToImperialConstants.dunamToAc;
      res.label = measurementSystemLabels.ac;
      break;

    case ConversionActions.SquareMetersAndSquareFeets:
      res.value = !value ? value : value * metricToImperialConstants.m2ToFt2;
      res.label = measurementSystemLabels.ft2;
      break;

    case ConversionActions.MetersAndFeet:
      res.value = !value ? value : value * metricToImperialConstants.mToFt;
      res.label = measurementSystemLabels.ft;
      break;

    case ConversionActions.cubicMetersAndGallons:
      res.value = !value ? value : value * metricToImperialConstants.m3ToGal;
      res.label = measurementSystemLabels.gal;
      break;

    case ConversionActions.MeterPerSecondAndMilePerHour:
      res.value = !value
        ? value
        : value * metricToImperialConstants.meterSecToMileHour;
      res.label = measurementSystemLabels.mileH;
      break;

    case ConversionActions.SquareMetersPerHourPerDunamsToGalPerMinPerAcre:
      res.value = !value
        ? value
        : value * metricToImperialConstants.m3HourDunamToGalMinAcre;
      res.label = measurementSystemLabels.galMac;
      break;

    default:
      res.value = value;
      break;
  }

  return res;
}
