import { CSSProperties } from 'react';

export const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '8px',
};
export const toolTipContainerStyle: CSSProperties = {
  position: 'absolute',
  bottom: 2,
  left: 72,
};
