import { darken, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: '100%',
    minWidth: '60vw',
    maxHeight: '100%',
    minHeight: '600px',
    height: '100%',
    paddingBlockStart: '1rem',
  },
  errorContainer: {
    '& input': {
      borderColor: '#ff4d4f',
    },
  },
  error: {
    color: '#ff4d4f',
    fontSize: '0.75rem',
  },
  formContainer: {
    gap: '2rem',
    width: '100%',
    paddingInline: '2rem',
    '@media(minWidth: 320px) and(maxWidth: 640px)': {
      rowGap: 'unset',
    },
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      maxHeight: '70vh',
      overflowY: 'auto',
    },
  },
  fieldsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 1fr)',
    gridTemplateRows: '100% repeat(2 50%) repeat(2 50%) repeat(2 50%) 100%',
    gridTemplateAreas: `"Account Account" "First Last" "Country State" "Phone Email" "Description Description"`,
    columnGap: '4%',
    rowGap: '2.5%',
    minWidth: '100%',
    height: '100%',
    paddingBlockEnd: '5%',
    '@media(minWidth: 320px) and (maxWidth: 640px)': {
      display: 'flex',
      flex: 1,
      width: '100%',
      flexDirection: 'column',
      gap: '1rem',
      paddingBlock: '2rem',
    },
    '& .Account': {
      gridArea: 'Account',
    },
    '& .First': {
      gridArea: 'First',
    },
    '& .Last': {
      gridArea: 'Last',
    },
    '& .Email': {
      gridArea: 'Email',
    },
    '& .Phone': {
      gridArea: 'Phone',
      '& > div': {
        position: 'relative !important',
        '& > div.flag-dropdown': {
          bottom: 0,
          padding: 0,
          position: 'absolute',
          top: 0,
          '& ul.country-list': {
            margin: '0.2rem 0 0 0 !important',
            maxHeight: '150px !important',
            padding: '0.2rem 0 0 0 !important',
            zIndex: '1000 !important',
            position: 'fixed !important',
          },
        },
      },
    },
    '& .Country': {
      gridArea: 'Country',
    },
    '& .State': {
      gridArea: 'State',
    },
    '& .Description': {
      gridArea: 'Description',
    },
  },
  '& .fields-container-grid.custom': {
    paddingBlockEnd: 0,
    rowGap: 0,
  },
  itemInput: {
    border: '1px solid',
    borderColor: '#76778673',
    borderRadius: '4px',
    '&::placeholder': {
      color: '#76778673',
    },
    '&:-ms-input-placeholder': {
      color: '#76778673',
    },
    '&::-ms-input-placeholder': {
      color: '#76778673',
    },
    '&:focus-visible': {
      border: '1px solid !important',
      borderColor: '#76778673 !important',
      borderRadius: '4px',
      '&:hover': {
        outline: '#76778673 !important',
        border: '1px solid !important',
        borderColor: '#76778673 !important',
        borderRadius: '4px',
      },
    },
    '&:focus, &:hover': {
      boxShadow: '0 0 3px #76778673 !important',
      border: 'inherit',
      borderRadius: '4px',
    },
    '&:focus, &:active, &:hover': {
      border: '1px solid #76778673 !important',
      borderRadius: '4px',
    },
  },
  '& .ant-input-affix-wrapper-focused, & .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, & .ant-input-affix-wrapper:hover':
    {
      borderColor: '#76778673 !important',
    },
  select: {
    width: '100%',
    border: '1px solid #76778673',
    borderRadius: '4px',
    '&:focus, &:hover': {
      boxShadow: '0 0 3px #76778673 !important',
      border: 'inherit',
      borderRadius: '4px',
    },
    '&:focus, &:active, &:hover': {
      border: '1px solid #76778673 !important',
      borderRadius: '4px',
    },
  },
  '&.ant-select-focused': {
    '&.ant-select-selector, &:focus, &:active, &:hover, &.ant-select-open .ant-select-selector':
      {
        border: '1px solid #76778673 !important',
        borderRadius: '4px',
      },
  },
  '& .ant-select-item': {
    '&:hover': {
      backgroundColor: '#76778673 !important',
    },
  },
  '& .ant-select-item-option-active': {
    '&:hover': {
      backgroundColor: '#76778673 !important',
    },
  },
  modalBtnContainer: {
    gap: '2rem',
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    flexDirection: 'row-reverse',
    '@media (min-width: 320px) and (max-width: 640px)': {
      gap: '1rem',
      alignItems: 'center',
    },
  },
  modalSaveBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.8rem',
    height: 'inherit',
    maxWidth: '18rem',
    marginLeft: 'auto',
    backgroundColor: theme.palette.accent.main,
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    color: '#fff',
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover, &:active': {
      '&:not([disabled])': {
        border: `solid 1px ${theme.palette.accent.main} !important`,
        borderRadius: '4px',
        color: '#fff',
        backgroundColor: darken(theme.palette.accent.main, 0.1),
      },
    },
    '&:hover:enabled': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: darken(theme.palette.accent.main, 0.1),
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: '#fff',
      pointerEvents: 'none',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(12px, 14px, 18px)',
    },
  },
  updateBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.8rem',
    height: 'inherit',
    maxWidth: '18rem',
    marginLeft: 'auto',
    backgroundColor: theme.palette.accent.main,
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    color: '#fff',
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover, &:active': {
      '&:not([disabled])': {
        border: `solid 1px ${theme.palette.accent.main} !important`,
        borderRadius: '4px',
        color: '#fff',
        backgroundColor: darken(theme.palette.accent.main, 0.1),
      },
    },
    '&:hover:enabled': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: darken(theme.palette.accent.main, 0.1),
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      color: '#fff',
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(12px, 14px, 18px)',
    },
  },
  modalCancelBtn: {
    color: theme.palette.red.main,
    backgroundColor: 'transparent',
    border: 'solid 1px',
    lineHeight: '24px',
    borderColor: theme.palette.red.main,
    minWidth: '6rem',
    fontWeight: 400,
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    borderRadius: '4px',
    padding: '0.8rem',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in -out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    '&:hover': {
      border: 'solid 1px',
      borderColor: `${theme.palette.red.main}!important`,
      color: '#fff!important',
      backgroundColor: theme.palette.red.main,
    },
    '&:focus': {
      outline: 'none !important',
      borderColor: `${theme.palette.red.main}!important`,
      borderRadius: '4px',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(10px, 12px, 16px)',
    },
  },
}));

export default useStyles;
