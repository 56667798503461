import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { AccountsDetails } from 'src/shared/data/types/accountOptionsTypes';

// This is temporary token until user management is deployed
const adminToken = '';

class AccountAdapter {
  static readonly endpoint = `/accounts`;

  async getAllAccounts(): Promise<AxiosResponse<AccountsDetails>> {
    return HttpClientService.get<AccountsDetails>(AccountAdapter.endpoint);
  }

  async getSpecificAccountById(
    accountId: string,
  ): Promise<AxiosResponse<AccountsDetails>> {
    return HttpClientService.get<AccountsDetails>(AccountAdapter.endpoint, {
      headers: { adminToken, accountId },
    });
  }

  async deleteSpecificAccountById(
    accountId: string,
  ): Promise<AxiosResponse<AccountsDetails>> {
    return HttpClientService.delete<AccountsDetails>(
      `${AccountAdapter.endpoint}/${accountId}`,
      {
        accountId,
      },
    );
  }

  async inactiveAccountById(
    accountId: string,
  ): Promise<AxiosResponse<AccountsDetails>> {
    return HttpClientService.put<AccountsDetails>(
      `${AccountAdapter.endpoint}/admin/unactive`,
      {
        id: accountId,
      },
    );
  }

  async editSpecificAccountById(
    updatedAccount: AccountsDetails,
  ): Promise<AxiosResponse<AccountsDetails>> {
    return HttpClientService.patch<AccountsDetails>(
      `${AccountAdapter.endpoint}/${updatedAccount.id}`,
      { updatedAccount },
    );
  }

  async postNewAccount(
    newAccount: AccountsDetails,
  ): Promise<AxiosResponse<AccountsDetails>> {
    return HttpClientService.post<AccountsDetails>(
      `${AccountAdapter.endpoint}`,
      newAccount,
    );
  }

  async getAccountsBySortingMethodAndSearchInput(
    pageNumber: number,
    accountsAmount: number,
    sortMethod: string,
    searchInput: string,
  ): Promise<AxiosResponse<AccountsDetails[]>> {
    return HttpClientService.get<AccountsDetails[]>(
      `${AccountAdapter.endpoint}/farm-managers?skip=${pageNumber}&limit=${accountsAmount}&sort_attribute=${sortMethod}&search_input=${searchInput}`,
    );
  }
}

const accountsAdapter = new AccountAdapter();
export default accountsAdapter;
