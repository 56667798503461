import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { BlockCardsHelperReturnType } from 'src/shared/data/types/EtForecastType';
import ETCalibrateBlockLabel from './IrrigationCardNav/ETCardsLayout/ETCard/ETCalibrateBlockLabel';
import { useStyles } from './useStyles';

const BlockCardsHelper = (): BlockCardsHelperReturnType => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderProcessingData = useCallback((): any => {
    return (
      <ETCalibrateBlockLabel
        label={`${t('fields.processing')}`}
        description={`${t('fields.fewDays')}`}
      />
    );
  }, [t]);

  const renderNoData = useCallback((): JSX.Element => {
    return (
      <Typography className={classes.noEtData}>{`${t(
        'fields.NA',
      )}`}</Typography>
    );
  }, [t, classes.noEtData]);

  const renderCalibratingData = useCallback((): any => {
    return (
      <ETCalibrateBlockLabel
        label={`${t('fields.plantSensorBinding')}`}
        description={`${t('fields.fewDays')}`}
      />
    );
  }, [t]);

  return {
    renderProcessingData,
    renderNoData,
    renderCalibratingData,
  };
};

export default BlockCardsHelper;
