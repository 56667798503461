import React from 'react';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { Weather } from 'src/shared/data/types/weatherTypes';
import WeatherListColumn from '../WeatherListColumn';
import useStyles from './useStyles';

export default function WeatherVerticalTable(props: any): JSX.Element {
  const { currentMiniFarm } = props;
  const classes = useStyles();
  const selectedWeek = useAppSelector(
    (state) => state.rootReducer.weatherState.selectedWeek,
  );

  return (
    <div className={`flex ${classes.root}`}>
      {selectedWeek?.map((item: Weather, index: number) => (
        <React.Fragment key={index}>
          {index !== 0 && (
            <div
              className={`flex align-center justify-center ${classes.separationLineWrapper}`}>
              <div className={`flex align-center ${classes.separationLine}`} />
            </div>
          )}
          <WeatherListColumn
            key={index}
            dateDetails={item}
            currentMiniFarm={currentMiniFarm}
          />
        </React.Fragment>
      ))}
    </div>
  );
}
