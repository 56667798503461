import growerReportsAdapter from '../adapters/GrowerReportsAdapter';

export const getGrowerWeeksReport = (
  growerId: number,
  weekStartDay: any,
): any => {
  try {
    const response = growerReportsAdapter.getGrowerWeeksReport(
      growerId,
      weekStartDay,
    );
    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getGrowerWeeksDownloadReport = (
  growerId: any,
  weekStartDay: any,
  mSys: string,
): any => {
  try {
    const response = growerReportsAdapter.getGrowerWeeksDownloadReport(
      growerId,
      weekStartDay,
      mSys,
    );
    return response;
  } catch (error: any) {
    return error.response.data;
  }
};
