import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WeeklyEtaCalc from './WeeklyEtaCalc/WeeklyEtaCalc';
import GrowersReportTableBlockDates from './GrowersReportsTableBlockDates';
import GrowersReportTableEtaEtc from './GrowersReportsTableEtaEtc';
import '../../GrowersReportsManagement.scss';
import useStyles from '../../useStyles';

export default function GrowersReportTableRow(props: any): any {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <TableCell style={{ width: 10 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.startDate} - {row.endDate}
        </TableCell>
      </TableRow>
      <TableRow style={{ paddingBottom: '0' }}>
        <TableCell style={{ padding: 'unset !important' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="farms" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      {t('growerReports.farmHeader')}
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      {t('growerReports.blockHeader')}
                    </TableCell>
                    <TableCell className={classes.tableHeaderColumn}>
                      {t('growerReports.weeklyEtaHeader')}
                    </TableCell>
                    <GrowersReportTableBlockDates
                      row={row}
                      weekIdx={row.weekIdx}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.farms?.map(
                    (farmRow: any, farmIdx: number) =>
                      farmRow.blocks?.length > 0 &&
                      farmRow.blocks?.map((blockRow: any, blockIdx: number) =>
                        blockRow.data ? (
                          <TableRow
                            key={blockIdx}
                            style={{ borderBottom: 'none' }}>
                            <TableCell style={{ width: 10 }}>
                              {farmRow.farmName}
                            </TableCell>
                            <TableCell style={{ width: 10 }}>
                              {farmRow.blocks[blockIdx].blockName}
                            </TableCell>
                            <TableCell style={{ width: 10 }}>
                              <WeeklyEtaCalc
                                row={row}
                                farmIdx={farmIdx}
                                blockIdx={blockIdx}
                              />
                            </TableCell>
                            <GrowersReportTableEtaEtc
                              row={row}
                              farmIdx={farmIdx}
                              blockIdx={blockIdx}
                            />
                          </TableRow>
                        ) : null,
                      ),
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
