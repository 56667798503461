import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FarmState } from 'src/shared/data/types/farmTypes';
import {
  getFarmsByUserToken,
  postNewFarm,
} from 'src/reduxToolkit/thunks/farm_thunks';
import { MiniFarm, NewFarm } from '../../../shared/data/types/growerFarmsTypes';

const initialState: FarmState = {
  farms: [],
  newFarm: undefined,
  selectedFarm: undefined,
  httpErr: undefined,
  managerId: 'netafim',
  hasGrowerId: false,
  isGrowerActivated: true,
};

export const FarmSlice = createSlice({
  name: 'farms',
  initialState,
  reducers: {
    setFarmsList(state, action: PayloadAction<MiniFarm[]>) {
      state.farms = action.payload;
    },
    setSelectedFarm(state, action: PayloadAction<MiniFarm>) {
      state.selectedFarm = action.payload;
    },
    setManagerId(state, action) {
      state.managerId = action.payload;
    },
    setHasFarmManagerId(state, action: PayloadAction<boolean>) {
      state.hasGrowerId = action.payload;
    },
    setGrowerActivated(state, action: PayloadAction<boolean>) {
      state.isGrowerActivated = action.payload;
    },
    setNewFarm(state, action: PayloadAction<NewFarm>) {
      state.newFarm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getFarmsByUserToken.fulfilled, (state, action) => {
      state.farms = [...action.payload];
      state.selectedFarm = { ...state.farms[0] };
      state.hasGrowerId = true;
    });
    builder.addCase(getFarmsByUserToken.rejected, (state) => {});
    builder.addCase(postNewFarm.fulfilled, (state, action) => {
      state.newFarm = action.payload;
    });
    builder.addCase(postNewFarm.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
  },
});

export const {
  setFarmsList,
  setSelectedFarm,
  setManagerId,
  setHasFarmManagerId,
  setNewFarm,
} = FarmSlice.actions;

export const farmReducer = FarmSlice.reducer;
