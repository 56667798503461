import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxWidth: '100vw',
    width: '100%',
    paddingBlock: '1.5%',
  },
  contentContainer: {
    width: '100%',
  },
  email: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  header: {
    color: theme.palette.darkGrey.main,
    width: '100%',
    fontWeight: '500',
    fontSize: 'clamp(24px, 30px, 36px)',
    borderBottom: `solid 1px ${theme.palette.hoverGrey.main}`,
  },
  btnContainer: {
    width: '100%',
    marginBlock: '3% 0',
    zIndex: 10,
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      height: '4rem',
      marginBottom: '-2rem',
    },
  },
  mobileBtnContainer: {
    width: '100%',
    marginBlockStart: '2rem',
    zIndex: 10,
    flexDirection: 'column-reverse',
    gap: '1.5rem',
    // "@media screen and (-webkit-min-device-pixel-ratio:0)": {
    //   height: '4rem',
    //   marginBottom: '-2rem'
    // },
  },
  userButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.5rem',
    width: '18rem',
    marginLeft: 'auto',
    backgroundColor: 'transparent',
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    color: theme.palette.accent.main,
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    '& img': {
      marginRight: '4%',
      fill: theme.palette.accent.main,
    },
    '&:hover': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: theme.palette.accent.main,
    },
    '@media (min-width: 320px) and (max-width: 900px)': {
      margin: 'auto',
    },
  },
  root: {
    position: 'relative',
    minWidth: '100%',
    paddingBlock: 'calc(3rem + 1vh) 2rem',
    '@media (max-width: 767px)': {
      paddingBlockStart: '2rem',
    },
  },
  title: {
    paddingInline: '20%',
    fontWeight: '700',
  },
  tableRowLight: {
    backgroundColor: '#ffffff',
  },
  tableRowDark: {
    backgroundColor: '#ededed',
    opacity: 0.6,
  },
  actionsText: {
    color: 'rgba(0, 0, 0, 0.88)!important',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.88)!important',
    },
  },
  actions: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      width: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .ant-btn': {
      border: 'none',
      outline: 'none',
      pointerEvents: 'auto',
      cursor: 'pointer',
      background: 'none',
    },
  },
  searchAndSort: {
    width: '100%',
    backgroundColor: '#fff',
    flex: '1',
  },
  searchInput: {
    minHeight: '-webkit-fill-available',
    maxWidth: '-webkit-fill-available',
    marginRight: 'auto',
    color: theme.palette.oldPrimary.main,
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      minHeight: 'auto',
    },
    '@media (max-width: 767px)': {
      width: '60%',
      margin: '0 auto',
    },
  },
}));

export default useStyles;
