import { Typography } from '@mui/material';
import bindingIcon from 'src/assets/Icons/sharedIcons/etIcons/bindingIcon.svg';
import { ETLabelPropsType } from 'src/shared/data/types/ETCardTypes';
import { useStyles } from './useStyles';

function ETCalibrateBlockLabel(props: ETLabelPropsType): JSX.Element {
  const { label, description } = props;
  const classes = useStyles();

  return (
    <div
      className={`flex align-center justify-center  ${classes.calibrateBlockContainer}`}>
      <img
        src={bindingIcon}
        alt="bindingIcon"
        className={classes.calibrateBlockIcon}
      />
      <Typography className={`flex-column  ${classes.calibrateBlockText}`}>
        {label}
        <small>{description}</small>
      </Typography>
    </div>
  );
}

export default ETCalibrateBlockLabel;
