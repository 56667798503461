import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  cell: {
    fontSize: '12px!important',
  },
  addSensorsContainer: {
    width: '100%',
  },
  links: {
    display: 'flex',
    gap: '5px',
    '& button': {
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      padding: '0',
    },
  },
  tableContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '70%',
    paddingBlockStart: '1rem',
  },
  inputContainer: {
    width: '250px',
    margin: 'auto',
  },
  inputTitle: {
    textAlign: 'center',
    paddingBlock: '1rem',
  },
}));

export default useStyles;
