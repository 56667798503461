import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginBlock: '10px',
    '& button': {
      border: 'none',
    },
    '& p': {
      fontSize: '34px',
      lineHeight: '38px',
      fontWeight: 300,
      color: theme.palette.oldPrimary.main,
      padding: 0,
      margin: 0,
    },
  },
  iconBtn: {
    width: '1rem',
    margin: '0.15rem',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingInlineEnd: '2rem',
  },
  iconLarge: {
    width: '1.2rem',
  },
}));

export default useStyles;
