import {
  AreaUnit,
  ETAUnit,
  EvapotranspirationUnit,
  FlowRateUnit,
  HumidityUnit,
  LengthUnit,
  MeasurementSys,
  PrecipitationUnit,
  PressureUnit,
  TemperatureUnit,
  VolumeUnit,
  WindSpeedUnit,
} from './measurement-enums/measurement-enums';

export const userLengthMeasurementUnit = (
  measureSys: MeasurementSys,
): LengthUnit => {
  return measureSys === MeasurementSys.METRIC
    ? LengthUnit.METER
    : LengthUnit.FEET;
};

export const userVolumeMeasurementUnit = (
  measureSys: MeasurementSys,
): VolumeUnit => {
  return measureSys === MeasurementSys.METRIC
    ? VolumeUnit.CUBIC_METER
    : VolumeUnit.GALLON;
};

export const userAreaMeasurementUnit = (
  userCountry: string,
  measureSys: MeasurementSys,
): AreaUnit => {
  return measureSys === MeasurementSys.METRIC
    ? userCountry === 'Turkey' || userCountry === 'Israel'
      ? AreaUnit.DUNAM
      : AreaUnit.HECTARE
    : AreaUnit.ACRE;
};

export const userTemperatureMeasurementUnit = (
  measureSys: MeasurementSys,
): TemperatureUnit => {
  return measureSys === MeasurementSys.METRIC
    ? TemperatureUnit.CELSIUS
    : TemperatureUnit.FAHRENHEIT;
};

export const userPressureMeasurementUnit = (): PressureUnit => {
  return PressureUnit.KILOPASCAL;
};

export const userWindSpeedMeasurementUnit = (
  measureSys: MeasurementSys,
): WindSpeedUnit => {
  return measureSys === MeasurementSys.METRIC
    ? WindSpeedUnit.METER_PER_SECOND
    : WindSpeedUnit.MILE_PER_HOUR;
};

export const userHumidityMeasurementUnit = (): HumidityUnit => {
  return HumidityUnit.PERCENT;
};

export const userPrecipitationMeasurementUnit = (
  measureSys: MeasurementSys,
): PrecipitationUnit => {
  return measureSys === MeasurementSys.METRIC
    ? PrecipitationUnit.MILLIMETER
    : PrecipitationUnit.INCH;
};

export const userEvapotranspirationMeasurementUnit = (
  measureSys: MeasurementSys,
): EvapotranspirationUnit => {
  return measureSys === MeasurementSys.METRIC
    ? EvapotranspirationUnit.MILLIMETER
    : EvapotranspirationUnit.INCH;
};

export const userFlowRateMeasurementUnit = (
  userCountry: string,
  measureSys: MeasurementSys,
): FlowRateUnit => {
  return measureSys === MeasurementSys.METRIC
    ? userCountry === 'Turkey' || userCountry === 'Israel'
      ? FlowRateUnit.CUBIC_METER_PER_HOUR_PER_DUNAM
      : FlowRateUnit.CUBIC_METER_PER_HOUR_PER_HECTARE
    : FlowRateUnit.GALLON_PER_MINUTE_PER_ACRE;
};

export const userEtaMeasurementUnit = (measureSys: MeasurementSys): ETAUnit => {
  return measureSys === MeasurementSys.METRIC
    ? ETAUnit.MILLIMETER
    : ETAUnit.INCH;
};
