import { conversionCalculations } from './measurement-calculations-enums';
import {
  AreaUnit,
  DefaultMeasurementUnit,
  ETAUnit,
  EvapotranspirationUnit,
  FlowRateUnit,
  HumidityUnit,
  LengthUnit,
  Measurement,
  MeasurementUnit,
  PrecipitationUnit,
  PressureUnit,
  TemperatureUnit,
  VolumeUnit,
  WindSpeedUnit,
} from '../measurement-enums/measurement-enums';

export default function measurementConversion(
  targetMeasurementUnit: MeasurementUnit,
  originMeasurementUnit: MeasurementUnit,
  measurement: Measurement,
  value: number,
): number | undefined {
  switch (measurement) {
    case Measurement.LENGTH:
      switch (originMeasurementUnit) {
        case LengthUnit.METER:
          switch (targetMeasurementUnit) {
            case LengthUnit.FEET:
              return conversionCalculations.metersToFeet(value);
            default:
              return DefaultMeasurementUnit[Measurement.LENGTH];
          }
        case LengthUnit.FEET:
          switch (targetMeasurementUnit) {
            case LengthUnit.METER:
              return conversionCalculations.feetToMeters(value);
            default:
              return DefaultMeasurementUnit[Measurement.LENGTH];
          }
        case LengthUnit.INCH:
          switch (targetMeasurementUnit) {
            case LengthUnit.MILLIMETER:
              return conversionCalculations.inchesToMillimeter(value);
            default:
              return DefaultMeasurementUnit[Measurement.LENGTH];
          }
        case LengthUnit.MILLIMETER:
          switch (targetMeasurementUnit) {
            case LengthUnit.INCH:
              return conversionCalculations.millimeterToInches(value);
            default:
              return DefaultMeasurementUnit[Measurement.LENGTH];
          }
        default:
          return DefaultMeasurementUnit[Measurement.LENGTH];
      }
    case Measurement.VOLUME:
      switch (originMeasurementUnit) {
        case VolumeUnit.CUBIC_METER:
          switch (targetMeasurementUnit) {
            case VolumeUnit.GALLON:
              return conversionCalculations.cubicMetersToGallons(value);
            default:
              return DefaultMeasurementUnit[Measurement.VOLUME];
          }
        case VolumeUnit.CUBIC_CENTIMETER:
          switch (targetMeasurementUnit) {
            case VolumeUnit.GALLON:
              return conversionCalculations.cubicCentimetersToGallons(value);
            default:
              return DefaultMeasurementUnit[Measurement.VOLUME];
          }
        case VolumeUnit.GALLON:
          switch (targetMeasurementUnit) {
            case VolumeUnit.CUBIC_METER:
              return conversionCalculations.gallonsToCubicMeters(value);
            default:
              return DefaultMeasurementUnit[Measurement.VOLUME];
          }
        case VolumeUnit.LITER:
          switch (targetMeasurementUnit) {
            case VolumeUnit.GALLON:
              return conversionCalculations.litersToGallons(value);
            default:
              return DefaultMeasurementUnit[Measurement.VOLUME];
          }
        case VolumeUnit.CUBIC_INCH:
          switch (targetMeasurementUnit) {
            case VolumeUnit.CUBIC_METER:
              return conversionCalculations.cubicInchesToCubicMeters(value);
            default:
              return DefaultMeasurementUnit[Measurement.VOLUME];
          }
        default:
          return DefaultMeasurementUnit[Measurement.VOLUME];
      }
    case Measurement.AREA:
      switch (originMeasurementUnit) {
        case AreaUnit.SQUARE_METER:
          switch (targetMeasurementUnit) {
            case AreaUnit.ACRE:
              return conversionCalculations.squareMetersToAcres(value);
            case AreaUnit.HECTARE:
              return conversionCalculations.squareMetersToHectares(value);
            case AreaUnit.DUNAM:
              return conversionCalculations.squareMetersToDunams(value);
            default:
              return DefaultMeasurementUnit[Measurement.AREA];
          }
        case AreaUnit.ACRE:
          switch (targetMeasurementUnit) {
            case AreaUnit.SQUARE_METER:
              return conversionCalculations.acresToSquareMeters(value);
            default:
              return DefaultMeasurementUnit[Measurement.AREA];
          }
        case AreaUnit.HECTARE:
          switch (targetMeasurementUnit) {
            case AreaUnit.SQUARE_METER:
              return conversionCalculations.hectaresToSquareMeters(value);
            default:
              return DefaultMeasurementUnit[Measurement.AREA];
          }
        case AreaUnit.DUNAM:
          switch (targetMeasurementUnit) {
            case AreaUnit.SQUARE_METER:
              return conversionCalculations.dunamsToSquareMeters(value);
            default:
              return DefaultMeasurementUnit[Measurement.AREA];
          }
        case AreaUnit.SQUARE_FEET:
          switch (targetMeasurementUnit) {
            case AreaUnit.SQUARE_METER:
              return value * 0.092903;
            default:
              return DefaultMeasurementUnit[Measurement.AREA];
          }
        default:
          return DefaultMeasurementUnit[Measurement.AREA];
      }
    case Measurement.TEMPERATURE:
      switch (originMeasurementUnit) {
        case TemperatureUnit.CELSIUS:
          switch (targetMeasurementUnit) {
            case TemperatureUnit.FAHRENHEIT:
              return conversionCalculations.celsiusToFahrenheit(value);
            default:
              return DefaultMeasurementUnit[Measurement.TEMPERATURE];
          }
        case TemperatureUnit.FAHRENHEIT:
          switch (targetMeasurementUnit) {
            case TemperatureUnit.CELSIUS:
              return conversionCalculations.fahrenheitToCelsius(value);
            default:
              return DefaultMeasurementUnit[Measurement.TEMPERATURE];
          }
        default:
          return DefaultMeasurementUnit[Measurement.TEMPERATURE];
      }
    case Measurement.PRESSURE:
      switch (originMeasurementUnit) {
        case PressureUnit.KILOPASCAL:
          return value;
        default:
          return DefaultMeasurementUnit[Measurement.PRESSURE];
      }
    case Measurement.WIND_SPEED:
      switch (originMeasurementUnit) {
        case WindSpeedUnit.METER_PER_SECOND:
          switch (targetMeasurementUnit) {
            case WindSpeedUnit.MILE_PER_HOUR:
              return conversionCalculations.meterPerSecondToMilePerHour(value);
            default:
              return DefaultMeasurementUnit[Measurement.WIND_SPEED];
          }
        case WindSpeedUnit.MILE_PER_HOUR:
          switch (targetMeasurementUnit) {
            case WindSpeedUnit.METER_PER_SECOND:
              return conversionCalculations.milePerHourToMeterPerSecond(value);
            default:
              return DefaultMeasurementUnit[Measurement.WIND_SPEED];
          }
        default:
          return DefaultMeasurementUnit[Measurement.WIND_SPEED];
      }
    case Measurement.HUMIDITY:
      switch (originMeasurementUnit) {
        case HumidityUnit.PERCENT:
          return value;
        default:
          return DefaultMeasurementUnit[Measurement.HUMIDITY];
      }
    case Measurement.PRECIPITATION:
      switch (originMeasurementUnit) {
        case PrecipitationUnit.MILLIMETER:
          switch (targetMeasurementUnit) {
            case PrecipitationUnit.INCH:
              return conversionCalculations.millimeterToInches(value);
            default:
              return DefaultMeasurementUnit[Measurement.PRECIPITATION];
          }
        case PrecipitationUnit.INCH:
          switch (targetMeasurementUnit) {
            case PrecipitationUnit.MILLIMETER:
              return conversionCalculations.inchesToMillimeter(value);
            default:
              return DefaultMeasurementUnit[Measurement.PRECIPITATION];
          }
        default:
          return DefaultMeasurementUnit[Measurement.PRECIPITATION];
      }
    case Measurement.EVAPOTRANSPIRATION:
      switch (originMeasurementUnit) {
        case EvapotranspirationUnit.MILLIMETER:
          switch (targetMeasurementUnit) {
            case EvapotranspirationUnit.INCH:
              return conversionCalculations.millimeterToInches(value);
            default:
              return DefaultMeasurementUnit[Measurement.EVAPOTRANSPIRATION];
          }
        case EvapotranspirationUnit.INCH:
          switch (targetMeasurementUnit) {
            case EvapotranspirationUnit.MILLIMETER:
              return conversionCalculations.inchesToMillimeter(value);
            default:
              return DefaultMeasurementUnit[Measurement.EVAPOTRANSPIRATION];
          }
        default:
          return DefaultMeasurementUnit[Measurement.EVAPOTRANSPIRATION];
      }
    case Measurement.FLOW_RATE:
      switch (originMeasurementUnit) {
        case FlowRateUnit.CUBIC_METER_PER_HOUR_PER_DUNAM:
          switch (targetMeasurementUnit) {
            case FlowRateUnit.CUBIC_METER_PER_HOUR_PER_HECTARE:
              return value * 10;
            case FlowRateUnit.GALLON_PER_MINUTE_PER_ACRE:
              return value * 17.8177728;
            default:
              return DefaultMeasurementUnit[Measurement.FLOW_RATE];
          }
        case FlowRateUnit.GALLON_PER_MINUTE_PER_ACRE:
          switch (targetMeasurementUnit) {
            case FlowRateUnit.CUBIC_METER_PER_HOUR_PER_DUNAM:
              return value / 17.8177728;
            case FlowRateUnit.CUBIC_METER_PER_HOUR_PER_HECTARE:
              return value / 1.78177728;
            default:
              return DefaultMeasurementUnit[Measurement.FLOW_RATE];
          }
        case FlowRateUnit.CUBIC_METER_PER_HOUR_PER_HECTARE:
          switch (targetMeasurementUnit) {
            case FlowRateUnit.CUBIC_METER_PER_HOUR_PER_DUNAM:
              return value / 10;
            case FlowRateUnit.GALLON_PER_MINUTE_PER_ACRE:
              return value * 1.78177728;
            default:
              return DefaultMeasurementUnit[Measurement.FLOW_RATE];
          }
        case FlowRateUnit.LITER_PER_HOUR:
          switch (targetMeasurementUnit) {
            case FlowRateUnit.GALLON_PER_MINUTE_PER_ACRE:
              return value * 0.264172;
            default:
              return DefaultMeasurementUnit[Measurement.FLOW_RATE];
          }
        default:
          return DefaultMeasurementUnit[Measurement.FLOW_RATE];
      }
    case Measurement.ETA:
      switch (originMeasurementUnit) {
        case ETAUnit.MILLIMETER:
          switch (targetMeasurementUnit) {
            case ETAUnit.INCH:
              return conversionCalculations.millimeterToInches(value);
            default:
              return DefaultMeasurementUnit[Measurement.ETA];
          }
        case ETAUnit.INCH:
          switch (targetMeasurementUnit) {
            case ETAUnit.MILLIMETER:
              return conversionCalculations.inchesToMillimeter(value);
            default:
              return DefaultMeasurementUnit[Measurement.ETA];
          }
        default:
          return DefaultMeasurementUnit[Measurement.ETA];
      }
    default:
      console.warn(
        `No conversion logic defined for ${targetMeasurementUnit} from ${originMeasurementUnit}`,
      );
      return value;
  }
}
