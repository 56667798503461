import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  InfoBox,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { MapProps } from 'src/shared/data/types/mapTypes';
import notifyService from 'src/shared/notification/notification.service';
import messageTypes from 'src/consts/messages';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import Container from '@mui/material/Container';
import { Button } from 'antd';
import { MapTypes } from 'src/shared/constants/enums';
import BlockPolygons from '../../../pages/FieldsPage/BlockPolygons';
import TextForm from '../../../pages/FieldsPage/FarmManagement/FarmModal/AddFarmModal/TextForm';
import { mapContainerStyle, toolTipContainerStyle } from './useStyles';
import './map.scss';
import useStyles from '../../../pages/FieldsPage/FarmManagement/FarmModal/useStyles';
import NavigateIcon from 'src/shared/components/Icon/NavigateIcon';
import CopyIcon from 'src/shared/components/Icon/CopyIcon';

function Map(mapProps: MapProps): JSX.Element {
  const {
    mapCenter,
    defaultCenter,
    blocksPolygons,
    mapZoom,
    isPointCoordinatesAvailable,
    onPointClick,
    onPaste,
    markersPoints,
    autocomplete,
    defaultIcon,
    farmFields,
    mapTypeId,
    drawModeIndicator,
  } = mapProps;
  const classes = useStyles();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
    preventGoogleFontsLoading: true,
    libraries: ['drawing', 'geometry', 'places'],
    language: 'en',
    region: 'us',
  });

  const mapRef = useRef<any>(null);
  const autocompleteRef = useRef<any>(null);
  const [position, setPosition] = useState(mapCenter);
  const [markers, setMarkers] = useState<any>(undefined);
  const [zoom, setZoom] = useState(1);
  const [openInfoBox, setOpenInfoBox] = useState<string | undefined>(undefined);
  const [pastedLocation, setPastedLocation] = useState('');
  const { t } = useTranslation();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): any => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (onPaste) onPaste(pastedLocation);
    }
  };

  const handleLoad = (map: any): void => {
    mapRef.current = map;
  };

  const handleCenter = (): void => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  };

  useEffect(() => {
    setPosition(mapCenter);
  }, [mapCenter]);

  useEffect(() => {
    if (markersPoints) {
      setMarkers(markersPoints);
    } else {
      setMarkers([]);
    }
  }, [markersPoints]);

  useEffect(() => {
    setZoom(mapZoom);
  }, [mapZoom]);

  const onLoad = (autocompleteR: any): void => {
    autocompleteRef.current = autocompleteR;
  };


  const handleOnClick = (
      details: any,
      event: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    if (event.type === 'click' || (event.type === 'keydown' && (event as React.KeyboardEvent<HTMLSpanElement>).key === 'Enter')) {
      onCopy(details);
    }
  };

  const onCopy = (details: any) => {
    let textToCopy = '';
    switch (details.type) {
      case 1: // IMEI number
        textToCopy = details.details; // Assuming details.details is a string.
        notifyService(t('map.notifyCopyImeiNumberSuccess'), messageTypes.success);
        break;
      case 2: // Coordinate
        textToCopy = `${details.details.lat}, ${details.details.lng}`;
        notifyService(t('map.notifyCopyCoordinateSuccess'), messageTypes.success);
        break;
      case 3: // Navigation
        textToCopy = `https://www.google.com/maps/dir/?api=1&destination=${details.details.lat},${details.details.lng}`;
        notifyService(t('map.notifyNavigateToLocationSuccess'), messageTypes.success);
        break;
      default:
        break;
    }

    navigator.clipboard.writeText(textToCopy).then(() => {
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };



  const onPlaceChanged = (): void => {
    if (autocompleteRef.current !== null) {
      const promiseData = autocompleteRef.current.getPlace();
      if (
        promiseData.name !== '' &&
        promiseData?.geometry?.location !== undefined
      ) {
        setPosition({
          lat: promiseData.geometry.location?.lat(),
          lng: promiseData.geometry.location?.lng(),
        });
        if (onPointClick) {
          onPointClick({
            latLng: promiseData?.geometry?.location,
          });
        }
        if (onPaste) onPaste({ latLng: promiseData?.geometry?.location });
      }
      setZoom(15);
    } else {
      console.warn(t('addFarmModal.loadedWarning'));
    }
  };

  const renderAutoComplete = (): any => {
    return (
      autocomplete && (
        <article className={`flex ${classes.farmTitleSearch}`}>
          <TextForm data={farmFields.titleField} />
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            className={`flex align-center justify-center ${classes.autocomplete}`}>
            <div className={`flex-column ${classes.searchLocation}`}>
              <span id="timezone">{t('addFarmModal.searchLocation')}</span>
              <input
                type="text"
                id="farm-location"
                placeholder={`${t(
                  'addFarmModal.searchFarmLocationPlaceholder',
                )}`}
                className={classes.farmLocationInput}
                onChange={
                  onPaste &&
                  ((e) => {
                    setPastedLocation(e.target.value);
                    onPaste(e.target.value);
                  })
                }
                onKeyDown={handleKeyDown}
                data-hj-allow=""
              />
            </div>
          </Autocomplete>
        </article>
      )
    );
  };
  return isLoaded ? (
    <>
      {renderAutoComplete()}
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        center={position || defaultCenter}
        zoom={zoom}
        options={{
          zoomControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_RIGHT,
          },
          streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          mapTypeControl: false,
          mapTypeId: mapTypeId || MapTypes.SATELLITE,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
              google.maps.MapTypeId.HYBRID,
            ],
            position: google.maps.ControlPosition.LEFT_CENTER,
          },
          rotateControl: false,
        }}
        onLoad={handleLoad}
        onDragEnd={handleCenter}
        onClick={(ev) => {
          if (isPointCoordinatesAvailable) {
            if (onPointClick) {
              onPointClick(ev);
            }
          }
        }}>
        {blocksPolygons && (
          <BlockPolygons
            blocksPolygons={blocksPolygons}
            drawModeIndicator={drawModeIndicator}
          />
        )}
        {markers?.length > 0 && (
          <MarkerClusterer
            options={{
              minimumClusterSize: 3,
              maxZoom: 10,
            }}>
            {(clusterer) => (
              <div>
                {markers?.map((marker: any) => (
                  <Marker
                    clusterer={clusterer}
                    key={`${marker.lat}_${marker.lng}_${marker.imei}`}
                    icon={
                      defaultIcon
                        ? {
                            url: `http://maps.google.com/mapfiles/ms/icons/${
                              marker.type ? 'blue' : 'red'
                            }-dot.png`,
                            scaledSize: new google.maps.Size(30, 32),
                          }
                        : {
                            url: `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`,
                            scaledSize: new google.maps.Size(30, 32),
                          }
                    }
                    onClick={() =>
                      setOpenInfoBox(`${marker.lat}_${marker.lng}`)
                    }
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}>
                    {openInfoBox === `${marker.lat}_${marker.lng}` &&
                      marker.details && (
                            <InfoBox
                                options={{
                                  closeBoxURL: '',
                                  enableEventPropagation: true,
                                }}>
                              <div className="map__info-box">
                                <Button
                                    className="map__info-box-btn"
                                    onClick={() => setOpenInfoBox(undefined)}
                                >
                                  X
                                </Button>
                                <div className="map__info-box-center-row">
                                  <CopyIcon
                                      onClick={(event) =>
                                          handleOnClick({details: marker.details, type: 1}, event)
                                      }
                                      onKeyDown={(event) =>
                                          handleOnClick({details: marker.details, type: 1}, event)
                                      }
                                      tabIndex={0}
                                  />
                                  <span
                                      role="button"
                                      id="copy-imei-number"
                                      onClick={(event) =>
                                          handleOnClick({details: marker.details, type: 1}, event)
                                      }
                                      onKeyDown={(event) =>
                                          handleOnClick({details: marker.details, type: 1}, event)
                                      }
                                      tabIndex={0}
                                  >
                                  {t('map.copyImei')}
                                  </span>
                                </div>
                                <div className={'map__info-box-imei-number'}>
                                    {marker.details}
                                  </div>
                                <div className="map__info-box-separator"></div>
                                <div className="map__info-box-center-row">
                                  <CopyIcon
                                      onClick={(event) =>
                                          handleOnClick(
                                              {details: {lat: marker.lat, lng: marker.lng}, type: 2},
                                              event
                                          )
                                      }
                                      onKeyDown={(event) =>
                                          handleOnClick(
                                              {details: {lat: marker.lat, lng: marker.lng}, type: 2},
                                              event
                                          )
                                      }
                                      tabIndex={0}
                                  />
                                  <span
                                      role="button"
                                      id="copy-coordinate"
                                      onClick={(event) =>
                                          handleOnClick(
                                              {details: {lat: marker.lat, lng: marker.lng}, type: 2},
                                              event
                                          )
                                      }
                                      onKeyDown={(event) =>
                                          handleOnClick(
                                              {details: {lat: marker.lat, lng: marker.lng}, type: 2},
                                              event
                                          )
                                      }
                                      tabIndex={0}
                                  >
                                  {t('map.copyCoordinate')}
                                  </span>
                                </div>
                                <div className="map__info-box-separator"></div>
                                <div className="map__info-box-center-row">
                                  <NavigateIcon
                                      onClick={(event) => {
                                        // Prevents the default anchor click behavior
                                        event.preventDefault();
                                        // Function to handle the logic or actions before navigating
                                        handleOnClick({ details: { lat: marker.lat, lng: marker.lng }, type: 3 }, event);
                                        // Programmatically navigate to the URL
                                        window.open(`https://www.google.com/maps/search/?api=1&query=${marker.lat},${marker.lng}&zoom=17&layer=satellite`, '_blank');
                                      }}
                                      onKeyDown={(event) => {
                                        handleOnClick({ details: { lat: marker.lat, lng: marker.lng }, type: 3 }, event);
                                      }}
                                      tabIndex={0}
                                  />
                                  <a
                                      role="button"
                                      id="navigate-to-location"
                                      href={`https://www.google.com/maps/dir/?api=1&destination=${marker.lat},${marker.lng}`}
                                      target="_blank"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        handleOnClick({ details: { lat: marker.lat, lng: marker.lng }, type: 3 }, event);
                                        window.open(`https://www.google.com/maps/search/?api=1&query=${marker.lat},${marker.lng}&zoom=17&layer=satellite`, '_blank');
                                      }}
                                      onKeyDown={(event) => {
                                        handleOnClick({ details: { lat: marker.lat, lng: marker.lng }, type: 3 }, event);
                                      }}
                                      tabIndex={0}
                                  >
                                    {t('map.navigateToLocation')}
                                  </a>
                                </div>
                              </div>

                            </InfoBox>
                        )}
                  </Marker>
                ))}
              </div>
            )}
          </MarkerClusterer>
        )}

        <Container style={toolTipContainerStyle}>
          <IconWithToolTip
              color="#fff"
              text={
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.google.com/intl/en-US_US/help/terms_maps/"
                    style={{textDecoration: 'none', color: '#676879d9'}}>
                  {t('addFarmModal.termsOfUse')}
                </a>
              }
          />
        </Container>
      </GoogleMap>
    </>
  ) : (
      <p>{t('addFarmModal.loadingMap')}</p>
  );
}

export default Map;
