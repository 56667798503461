import { useCallback, useEffect, useMemo } from 'react';
import { useAuth } from 'src/shared/contexts/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import {
  smallMaxWidth,
  xLargeMediumWidth,
  xSmallMaxWidth,

} from 'src/shared/constants/consts';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { getUserRole } from 'src/shared/hooks/useUserAccess';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, ThemeProvider } from '@mui/material';
import { Button } from 'antd';
import toroTheme from 'src/theme/toroTheme';
import mainTheme from 'src/theme/mainTheme';
import { NavbarPropsType } from 'src/shared/data/types/navbarTypes';
import t2sLogo from 'src/assets/Icons/mainThemeIcons/logoWithName.svg';
import t2sImgLogo from 'src/assets/Icons/mainThemeIcons/t2s-logo.svg';
import GrowSphereLogo from 'src/assets/Icons/GrowSphereLogo.svg';
import poweredlogo from 'src/assets/Icons/mainThemeIcons/poweredlogo.svg';
import ToroLogo from 'src/assets/Icons/toroThemeIcons/toro-logo.svg';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import { useTabs } from './NavbarHelper';
import SelectLanguage from './SelectLanguage/SelectLanguage';
import { getUserDetails } from '../../../reduxToolkit/thunks/user_thunks';
import { useStyles } from './useStyles';
import { NavbarState, UserRole } from '../../constants/enums';
import { AccountIds } from '../../constants/accounts';

const Navbar: React.FC<NavbarPropsType> = function Navbar(
    props: NavbarPropsType,
) {
  // props
  const { stateValue, navState } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.rootReducer.tokenState.token);
  const tabs = useTabs();
  const classes = useStyles({ stateValue: stateValue ?? false });
  const userData = useAppSelector((state) => state.rootReducer.userState);
  const mexicanUser = userData && userData.country === 'Mexico';
  const { signOut } = useAuth();
  const isToroAccount = useAppSelector(
      (state) => state.rootReducer.accountState.isToroAccount,
  );
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  // memos
  const user = useMemo(
      () => ({ loggedIn: true, userRole: getUserRole(userData?.role) }),
      [userData?.role],
  );
  const isGrowersPage = useMemo(() => navState === '/growers', [navState]);
  const isOnInnerPage = useMemo(
      () => navState.match(/growers\/\d+/),
      [navState],
  );
  const isOnSensorPage = useMemo(() => {
    return !!searchParams.get('sensors');
  }, [searchParams]);
  const navName = useMemo((): NavbarState => {
    if (isOnSensorPage) {
      return NavbarState.SENSORS;
    } else if (isOnInnerPage) {
      return NavbarState.FIELDS;
    } else return navState;
  }, [isOnInnerPage, isOnSensorPage, navState]);

  useEffect(() => {
    void dispatch(getUserDetails(token));
  }, [dispatch, token]);

  const onLogout = (): any => {
    signOut();
    window.location.replace('/');
  };

  const toggleChangeRoute = useCallback(
      (value: string) => {
        switch (value) {
          case '/sensors':
            setSearchParams({ sensors: '1' });
            return;
          case '/growers':
            navigate('/growers');
            return;
          case '/farms':
            setSearchParams();
            return;
          default:
            navigate(value);
        }
      },
      [navigate, setSearchParams],
  );

  const changeLanguageHandler = useCallback((lang: string): any => {
    localStorage.setItem('i18nextLng', lang);
    i18next.changeLanguage(lang).catch((err) => console.error(err));
  }, []);

  const renderTabs = tabs
      .filter((tab) => {
        return (
            tab.userRole.includes(user.userRole) &&
            (tab.value === NavbarState.GROWER ||
                (isGrowersPage
                    ? [NavbarState.AGENTS, NavbarState.ACCOUNTS].includes(tab.value)
                    : [NavbarState.AGENTS, NavbarState.ACCOUNTS].includes(tab.value) ||
                    isOnInnerPage))
        );
      })
      .map((tab: any, idx: number) => (
          <Tab
              onClick={() => toggleChangeRoute(tab.value)}
              key={idx}
              id={tab.label}
              className={`${classes.tabs} ${tab.label}`}
              value={tab.value}
              icon={tab.icon}
              iconPosition="start"
              label={tab.label}
              component={Link}
              disableRipple
              disabled={stateValue}
          />
      ));

  const renderAppLogo = () => {
    switch (userData.ownerAccountId) {
      case AccountIds.TORO:
        return (
            <li className={`flex ${classes.toroAppLogo}`}>
              <a href="/">
                <img src={ToroLogo} alt="Toro logo" />
                  {width > smallMaxWidth ? (
                      <img
                          src={poweredlogo}
                          className={classes.poweredlogo}
                          alt="t2s logo"
                      />
                  ) : null}
              </a>
            </li>
        );
      case AccountIds.GROWSPHERE:
        return (
            <li className={`flex ${classes.growSphereLogo}`}>
              <a
                  href="https://growsphere.netafim.com/"
                  target="_blank"
                  rel="noopener noreferrer">
                <img src={GrowSphereLogo} alt="GrowSphere logo" />
                  {width > smallMaxWidth ? (
                      <img
                          src={poweredlogo}
                          className={classes.poweredlogo}
                          alt="t2s logo"
                      />
                  ) : null}
              </a>
            </li>
        );
      case AccountIds.TREETOSCOPE:
        return (
            <li className={classes.mainAppLogo}>
              <a href="/">
                <img src={t2sLogo} alt="t2s logo" />
              </a>
            </li>
        );
      default:
        return (
            <li className={classes.mainAppLogo}>
              <a href="/">
                <img src={t2sImgLogo} alt="t2s logo" />
              </a>
            </li>
        );
    }
  };

  return (
      <ThemeProvider theme={isToroAccount ? toroTheme : mainTheme}>
        <header
            className={
              isToroAccount ? 'toro-app-header-container' : 'app-header-container'
            }>
          <ul
              className={`nav-list flex clean-list align-center container-inline justify-between ${classes.root}`}>
            {renderAppLogo()}
            <li
                className={`flex justify-between align-center ${classes.menuItems}`}>
              {/* Render Tabs for all screen sizes */}
              <Tabs
                  className={classes.headerNavigation}
                  sx={{ textTransform: 'none' }}
                  value={navName}>
                {renderTabs}
              </Tabs>
            </li>
            <li>
              <article className={`flex align-center ${classes.mainAccountInfo}`}>
                {userData.role &&
                    width >= xSmallMaxWidth &&
                    width <= smallMaxWidth && (
                        <h3 id="current-user-name">
                          {userData.given_name} {userData.family_name}
                        </h3>
                    )}
                {userData.role && width > smallMaxWidth && (
                    <h3 id="current-user-name">
                      {(userData.role != UserRole.ADMIN ||
                          width > xLargeMediumWidth) && (
                          <span>{t('navbar.welcome')} </span>
                      )}
                      {userData.given_name} {userData.family_name}
                    </h3>
                )}
                <span style={{ color: '#676879', opacity: '0.8' }}>|</span>
                <div className={`flex-column align-center ${classes.selectLang}`}>
                  <SelectLanguage onChangeLanguage={changeLanguageHandler} />
                </div>
                {mexicanUser ? (
                    <div
                        className={`flex align-center ${classes.signOutContainer}`}>
                      <Button
                          id="user-logout"
                          onClick={onLogout}
                          className={classes.signOutBtn}>
                        {t('navbar.signout')}
                      </Button>
                      <div className={`flex align-center ${classes.IFETELNumber}`}>
                        <IconWithToolTip
                            color="#676879"
                            text={`${t('navbar.IFETELNumber')}: BLTRT223-31194`}
                        />
                      </div>
                    </div>
                ) : (
                    <Button
                        id="user-logout"
                        onClick={onLogout}
                        className={classes.signOutBtn}>
                      {t('navbar.signout')}
                    </Button>
                )}
              </article>
            </li>
          </ul>
        </header>
      </ThemeProvider>
  );
};

export default Navbar;