import { SelectCropVariety } from 'src/shared/data/types/formsTypes';
import Select from 'src/shared/components/Select/Select';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import useStyles from './useStyles';

function SelectCropVarietyWidget(props: SelectCropVariety): JSX.Element {
  const {
    title,
    fieldName,
    placeholder,
    options,
    onChange,
    value = '',
    isMultiSelect = false,
    errorMessage = undefined,
    handleBlur,
    isInBlur,
    filterOption,
  } = props;
  const classes = useStyles();
  const { block } = useAppSelector(
    (state) => state.rootReducer.addOrEditBlockState,
  );

  const defaultValue =
    block !== undefined ? block[fieldName]?.toLocaleString() : '';

  return (
    <span className={classes.root}>
      <span>{title}</span>
      <div
        className={`input-container ${
          fieldName === 'cropTypeId' &&
          isInBlur &&
          !defaultValue &&
          errorMessage
            ? 'input-error'
            : ''
        }`}>
        <Select
          options={options}
          placeholder={placeholder}
          handleChangeSelection={onChange}
          isMultiSelect={isMultiSelect}
          handleBlur={handleBlur}
          value={value ?? defaultValue}
          filterOption={filterOption}
        />
      </div>
      {fieldName === 'cropTypeId' &&
        isInBlur &&
        value === '' &&
        errorMessage &&
        !defaultValue && (
          <span className="input-error-text">{errorMessage}</span>
        )}
    </span>
  );
}

export default SelectCropVarietyWidget;
