import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { CurrWeather } from 'src/shared/data/types/weatherTypes';

const baseUrl: string = process.env.REACT_APP_SERVER_BASE_URL ?? '';

class CurrentWeatherAdapter {
  static readonly endpoint = `${baseUrl}/weather/realtime`;

  async getCurrWeatherData(
    lat: string,
    lng: string,
  ): Promise<AxiosResponse<CurrWeather>> {
    const response = await HttpClientService.get<CurrWeather>(
      `${CurrentWeatherAdapter.endpoint}?latitude=${lat}&longitude=${lng}`,
    );

    return response;
  }
}

const currentWeatherAdapter = new CurrentWeatherAdapter();
export default currentWeatherAdapter;
