import { darken, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
};

const useStyles = makeStyles((theme: Theme) => ({
  graphContainer: {
    position: 'relative',
    padding: '1rem',
    marginInlineEnd: '0.2rem',
    borderRadius: '4px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    '@media (max-width: 767px)': {
      padding: '0.5rem',
    },
  },
  zoomBtnTitleContainer: {
    marginInlineStart: '2.2rem',
    '@media (max-width: 767px)': {
      marginInlineStart: '2.5rem',
      marginBlockEnd: '1rem',
    },
  },
  zoomControls: {
    position: 'absolute',
    zIndex: 50,
    backgroundColor: '#fff',
    top: '5px',
    '@media (max-width: 767px)': {
      color: theme.palette.accent.main,
    },
    '@media (min-width: 1450px)': {
      left: '1.8vw',
    },
    '@media (min-width: 1350px) and (max-width: 1449.9px)': {
      left: '1.9vw',
    },
    '@media (min-width: 1250px) and (max-width: 1349.9px)': {
      left: '2.1vw',
    },
    '@media (min-width: 1150px) and (max-width: 1249.9px)': {
      left: '2.3vw',
    },
    '@media (min-width: 1050px) and (max-width: 1149.9px)': {
      left: '2.5vw',
    },
    '@media (min-width: 950px) and (max-width: 1049.9px)': {
      left: '2.7vw',
    },
    '@media (min-width: 850px) and (max-width: 949.9px)': {
      left: '3.1vw',
    },
    '@media (min-width: 768px) and (max-width: 849.9px)': {
      left: '3.2vw',
    },
    '@media (min-width: 650px) and (max-width: 767.9px)': {
      left: '2.6vw',
    },
    '@media (min-width: 550px) and (max-width: 649.9px)': {
      left: '3vw',
    },
    '@media (min-width: 450px) and (max-width: 549.9px)': {
      left: '3.8vw',
    },
    '@media (min-width: 320px) and (max-width: 449.9px)': {
      left: '5.5vw',
    },
    '& button': {
      backgroundColor: 'linear-gradient(0deg, #DFDFDF, #DFDFDF)',
      color: theme.palette.oldPrimary.main,
      padding: '0 0.2rem',
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      outline: 'none !important',
      background: 'linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%)',
      border: '0.1rem solid #DFDFDF !important',
      transition: 'filter 0.3s ease',
      '&:nth-child(1)': {
        borderRadius: '50vmin 50vmin 0 0 !important',
      },
      '&:nth-child(2)': {
        borderRadius: '0 0 50vmin 50vmin !important',
      },
      '&:active': {
        outline: 'none!important',
      },
      '&:focus, &:hover': {
        outline: 'none!important',
        '& span': {
          color: `${darken(theme.palette.oldPrimary.main, 0.2)} !important`,
          fontWeight: '700',
        },
      },
      '&:hover': {
        filter: 'brightness(0.85)',
      },
      '& span': {
        '& svg': {
          width: '0.7rem',
        },
      },
      '@media (max-width: 767px)': {
        padding: '0 0.25rem',
      },
    },
    growerReportsModalOverlay: { ...overlayStyles },
    modalOverlay: { ...overlayStyles },
  },
  loaderContainer: {
    margin: 'auto',
    flex: 1,
  },
  loaderWrapper: {
    flex: 1,
  },
}));

export default useStyles;
