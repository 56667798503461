import { createAsyncThunk } from '@reduxjs/toolkit';
import { FarmDetails } from 'src/shared/data/types/farmTypes';
import farmAdapter from '../adapters/FarmsAdapter';
import { MiniFarm, NewFarm } from '../../shared/data/types/growerFarmsTypes';

export const getFarmsByManagerId = createAsyncThunk<
  FarmDetails[],
  string,
  { rejectValue: Error }
>(
  'farms',
  async (
    ownerAccountId,
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await farmAdapter.getFarmsByManagerId(ownerAccountId);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Oren's
// GrowerId === growerCognitoId taken from the current user token (He's a grower)
export const getFarmsByUserToken = createAsyncThunk<
  MiniFarm[],
  string,
  { rejectValue: Error }
>(
  'farms',
  async (token, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await farmAdapter.getFarmsByUserToken(token);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getFarmsByGrowerInternalId = createAsyncThunk<
  FarmDetails[],
  number,
  { rejectValue: Error }
>('farms', async (id, { rejectWithValue, fulfillWithValue }): Promise<any> => {
  try {
    const response = await farmAdapter.getFarmsByGrowerInternalId(id);
    const { data } = response;
    return fulfillWithValue(data);
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

// Specific GrowerCognitoId for non-grower users e.g. account-users, agent-users or admin
export const getFarmsByGrowerCognitoId = createAsyncThunk<
  FarmDetails[],
  string,
  { rejectValue: Error }
>(
  'farms',
  async (
    growerCognitoId,
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await farmAdapter.getFarmsByGrowerCognitoId(
        growerCognitoId,
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postNewFarm = createAsyncThunk<
  NewFarm,
  { newFarm: NewFarm },
  { rejectValue: Error }
>(
  'post farm',
  async ({ newFarm }, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await farmAdapter.postNewFarm(newFarm);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
