// useStylesFarmList.ts
import { makeStyles } from '@mui/styles';
import { alpha, darken, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 60px)', // Adjust height to account for the 60px header
    width: '100%',
    backgroundColor: theme.palette.surface.main,
    '@media (max-width: 767.9px)': {
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr', // Define three rows: Map, Menu, Mini Block Data
      gridTemplateAreas: `
        "map"
        "menu"
        "miniBlockData"
      `,
      height: 'auto',
      paddingBottom: '40px', // Adjust padding to account for the 60px footer
    },
  },
  menu: {
    width: '20%',
    minWidth: '200px', // Optional: Set a minimum width for better responsiveness
    paddingTop: theme.paddings.m,
    paddingLeft: theme.paddings.m,
    paddingBottom: theme.paddings.m,
    boxSizing: 'border-box',
    overflowY: 'auto', // Allow scrolling if content overflows
    order: 1, // Default order for desktop (appears first)
    '@media (max-width: 767.9px)': {
      width: '100%',
      height: 'auto',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.paddings.m,
      paddingRight: theme.paddings.m,
      order: 2, // On mobile, menu appears second
      gridArea: 'menu',
    },
  },
  content: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    order: 2, // Default order for desktop (appears second)
    '@media (max-width: 767.9px)': {
      width: '100%',
      order: 1, // On mobile, content appears first
      gridArea: 'map',
      display: 'contents', // Allow children to be placed in the grid areas
    },
  },
  mapContainer: {
    flex: '0 0 25%', // 25% height
    boxSizing: 'border-box',
    '@media (max-width: 767.9px)': {
      gridArea: 'map',
      paddingLeft: theme.paddings.xs,
      paddingRight: theme.paddings.xs,
      minHeight: '200px', // Ensure a minimum height for the map
    },
  },
  miniBlockData: {
    flex: '1', // Take the remaining 75% height
    boxSizing: 'border-box',
    paddingBottom: theme.paddings.m,
    overflowY: 'auto', // Allow scrolling if content overflows
    '@media (max-width: 767.9px)': {
      flex: 'none',
      height: '100%', // Ensure it takes full height
      display: 'flex', // Use Flexbox to center content
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.paddings.m,
      paddingLeft: theme.paddings.xs,
      paddingRight: theme.paddings.xs,
      gridArea: 'miniBlockData',
    },
  },
  noBlockFarmInfo: {
    marginBlockStart: '0.5rem',
    padding: '0.8rem',
    flex: '1',
    background: '#fff',
    borderRadius: '15px',
    maxWidth: '100%', // Ensure it doesn't exceed parent width
    wordWrap: 'break-word', // Handle long text
  },
  addBlockBtn: {
    position: 'relative',
    margin: '0 auto',
    padding: '0.4rem 0.8rem',
    backgroundColor: theme.palette.accent.main,
    fontWeight: '500',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: 'calc(1.2rem + 0.4vw)',
    borderRadius: '0.5rem',
    transition: 'background-color 0.2s',
    maxWidth: '100%',
    height: 'fit-content',
    '&:hover': {
      backgroundColor: darken(theme.palette.accent.main, 0.1),
      color: '#fff !important',
    },
    '@media (max-width: 767.9px)': {
      fontSize: '1rem',
      width: '100%', // Ensure button doesn't exceed container
    },
  },
  btnHidden: {
    display: 'none',
    visibility: 'hidden',
    backgroundColor: 'transparent',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  progressBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', // Adjust as needed
    width: '100%',  // Ensure it takes full width if necessary
    position: 'relative',
    zIndex: 1001,
    backgroundColor: 'transparent',
    '@media (max-width: 767.9px)': {
      height: '300px', // Adjust as needed
    },
  },
}));

export default useStyles;