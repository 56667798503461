import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { smallMaxWidth } from 'src/shared/constants/consts';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { NewBlockAddPolygonBackgroundProps } from 'src/shared/data/types/blockTypes';
import { setSavePolygonPathAfterEdit } from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import Button from '@mui/material/Button';
import { roundPrecision } from 'src/shared/helpers/HelperFunctions';
import { ConversionActions } from 'src/shared/measurements-converter/converter.types';
import { measurementSystemConvertValue } from 'src/shared/measurements-converter/converter';
import useStyles from './useStyles';

function NewBlockAddPolygonBackground(
  props: NewBlockAddPolygonBackgroundProps,
): JSX.Element {
  const { mapClickAddNewBlock, onButtonClicked, setShouldResetHighlight } =
    props;
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const { newPolygonAreaAfterEdit } = useAppSelector(
    (state) => state.rootReducer.addOrEditBlockState,
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );

  const isValidNumber = (value: number | undefined): boolean => {
    return (
      value !== undefined &&
      value !== null &&
      !Number.isNaN(value) &&
      value !== Infinity &&
      value !== -Infinity
    );
  };

  const conversionResult = measurementSystemConvertValue(
    newPolygonAreaAfterEdit,
    selectedMeasurementSystem,
    ConversionActions.SquareMetersToAcresOrHectares,
  );
  const convertedAreaObj = isValidNumber(newPolygonAreaAfterEdit)
    ? conversionResult
    : { value: 0, label: '' };
  const roundedAreaVal = roundPrecision(convertedAreaObj.value, 2);
  const newBlockAreaRef = useRef(0);
  newBlockAreaRef.current = roundedAreaVal ?? 0;
  const blockAreaLabelRef = useRef(t(`${convertedAreaObj.label}`));

  const text = useMemo(
    () =>
      mapClickAddNewBlock === 0
        ? `${t('addNewBlockPolygon.stepOne')}`
        : newPolygonAreaAfterEdit && newPolygonAreaAfterEdit < 0.1
        ? `${t('addNewBlockPolygon.polygonAreaSize')}`
        : `${t('addNewBlockPolygon.stepTwo')}`,
    [newPolygonAreaAfterEdit, mapClickAddNewBlock, t],
  );

  const clickOnSaveBlock = (e: any): void => {
    e.stopPropagation();
    if (newPolygonAreaAfterEdit) {
      dispatch(setSavePolygonPathAfterEdit(true));
    }
  };

  const textAreaDetails = useMemo(
    () =>
      newPolygonAreaAfterEdit === 0 ||
      (newPolygonAreaAfterEdit && newPolygonAreaAfterEdit < 0.1)
        ? ''
        : `${t('addNewBlockPolygon.area')} - ${newBlockAreaRef.current ?? 0} ${
            blockAreaLabelRef.current
          }`,
    [t, newPolygonAreaAfterEdit],
  );

  useEffect(() => {
    if (setShouldResetHighlight) {
      if (newPolygonAreaAfterEdit && newPolygonAreaAfterEdit < 0.1) {
        setShouldResetHighlight(true);
      } else setShouldResetHighlight(false);
    }
  }, [newPolygonAreaAfterEdit, setShouldResetHighlight]);

  return (
    <div className={classes.addPolygons}>
      <div
        className={`flex-column align-center justify-center ${classes.textContainer}`}>
        <div className={classes.text}>{text}</div>
        <div className={classes.subtext}>{textAreaDetails}</div>
        <section className={`flex align-center ${classes.addBlockActionBtns}`}>
          <div className={classes.cancelBlock}>
            {width > smallMaxWidth && isToroAccount ? (
              <Button
                className={classes.toroCancelBlockBtn}
                onClick={onButtonClicked}>
                {`${t('addNewBlockPolygon.cancel')}`}
              </Button>
            ) : (
              <Button
                className={classes.mainCancelBlockBtn}
                onClick={onButtonClicked}>
                {`${t('addNewBlockPolygon.cancel')}`}
              </Button>
            )}
          </div>
          <div className={classes.saveBlock}>
              <Button
                id="save-block-polygon"
                className={`ant-btn-custom ${classes.saveBlockBtn}`}
                onClick={(e) => clickOnSaveBlock(e)}
                disabled={
                  !newPolygonAreaAfterEdit ||
                  newPolygonAreaAfterEdit === 0 ||
                  newPolygonAreaAfterEdit < 0.1
                }>
                {`${t('addNewBlockPolygon.save')}`}
              </Button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default NewBlockAddPolygonBackground;
