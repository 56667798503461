import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import CropsIcons from 'src/shared/data/CropsIcons';
import useStyles from './useStyles';

export default function SensorHeadline(props: {
  title?: string;
  cropName?: string;
}): JSX.Element {
  const classes = useStyles();
  const { title, cropName } = props;
  const { t } = useTranslation();

  const Icon = useMemo(() => {
    if (cropName != null) {
      if (cropName === 'Avocados' || cropName === 'Avocado')
        return CropsIcons.Avocado;
      return CropsIcons[cropName?.replace(' ', '')] ?? CropsIcons.Other;
    }
    return CropsIcons.Other;
  }, [cropName]);

  return (
    <div className={classes.root}>
      <Icon />
      <div className={classes.InfoContainer}>
        <Typography className={classes.TextInfoHeader} variant="titleLarge">
          {title}
        </Typography>
        <Typography className={classes.TextInfo} variant="subtitle1">
          {t(`${cropName}`)}
        </Typography>
      </div>
    </div>
  );
}
