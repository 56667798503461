import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AccountsDetails,
  AccountsResponse,
} from 'src/shared/data/types/accountOptionsTypes';
import accountsAdapter from '../adapters/AccountsAdapter';

export const getAllAccounts = (): any => {
  try {
    return accountsAdapter.getAllAccounts();
  } catch (error: any) {
    return error;
  }
};

export const getSpecificAccountById = createAsyncThunk<
  AccountsDetails,
  string,
  { rejectValue: Error }
>(
  'get account',
  async (accountId, { fulfillWithValue, rejectWithValue }): Promise<any> => {
    try {
      const response = await accountsAdapter.getSpecificAccountById(accountId);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editSpecificAccountById = async (
  updatedAccount: AccountsDetails,
  accountId: any,
): Promise<any> => {
  try {
    updatedAccount.accountCognitoId = accountId;
    const response = await accountsAdapter.editSpecificAccountById(
      updatedAccount,
    );
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const postNewAccount = createAsyncThunk<
  AccountsDetails,
  {
    newAccount: AccountsDetails;
  },
  { rejectValue: Error }
>(
  'post account',
  async (
    { newAccount },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response = await accountsAdapter.postNewAccount(newAccount);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSpecificAccountById = async (
  accountId: string,
): Promise<any> => {
  try {
    const response = await accountsAdapter.deleteSpecificAccountById(accountId);
    const { data } = response;
    return data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const inactiveAccountById = createAsyncThunk<
  AccountsDetails,
  string,
  { rejectValue: Error }
>(
  ' inactive account',
  async (accountId, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      const response = await accountsAdapter.inactiveAccountById(accountId);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAccountsBySortingMethodAndSearchInput = createAsyncThunk<
  AccountsResponse,
  {
    pageNumber: number;
    accountsAmount: number;
    sortMethod: string;
    searchInput: string;
  },
  { rejectValue: Error }
>(
  'sorted_accounts',
  async (
    { pageNumber, accountsAmount, sortMethod, searchInput },
    { rejectWithValue, fulfillWithValue },
  ): Promise<any> => {
    try {
      const response =
        await accountsAdapter.getAccountsBySortingMethodAndSearchInput(
          pageNumber,
          accountsAmount,
          sortMethod,
          searchInput,
        );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
