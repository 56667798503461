import { useTranslation } from 'react-i18next';
import { ReactElement, useEffect, useState } from 'react';
import t2sLogo from 'src/assets/Icons/mainThemeIcons/t2s-logo.svg';
import ToroLogo from 'src/assets/Icons/toroThemeIcons/toro-logo.svg';
import { setSelectedFarm } from 'src/reduxToolkit/slices/FarmSlice/farm.slice';
import {
  addFarmToGrower,
  closeModal,
} from 'src/reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';
import Typography from '@mui/material/Typography';
import { postNewFarm } from '../../../../../reduxToolkit/thunks/farm_thunks';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../reduxToolkit/store/hooks';
import GetFarmFields from '../ModalHelper';
import LocationForm from './LocationForm';
import useStyles from '../useStyles';
import notifyService from '../../../../../shared/notification/notification.service';
import messageTypes from '../../../../../consts/messages';
import {
  MiniFarm,
  NewFarm,
} from '../../../../../shared/data/types/growerFarmsTypes';
import { useNavigate } from 'react-router-dom';

function AddFarmModal(props: any): ReactElement | null {
  const { grower } = props;
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const farms = useAppSelector((state) => state.rootReducer.farmState.farms);
  const [title, setTitle] = useState<string>('');
  const [titleValidation, setTitleValidation] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [farmCenterLat, setFarmCenterLat] = useState<string>('');
  const [farmCenterLng, setFarmCenterLng] = useState<string>('');
  const { t } = useTranslation();

  const farmProps = {
    title,
    setTitle,
    titleValidation,
    setTitleValidation,
    farmCenterLat,
    setFarmCenterLat,
    farmCenterLng,
    setFarmCenterLng,
  };
  const farmFields = GetFarmFields(farmProps);

  const newFarm: NewFarm = {
    title: title,
    farmCenterLat: farmCenterLat,
    farmCenterLng: farmCenterLng,
    growerCognitoId: grower.growerCognitoId,
    growerId: grower.id,
  };

  const handleOnSave = (farm: MiniFarm): any => {
    dispatch(addFarmToGrower(farm));
  };

  const handleSave = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    void dispatch(postNewFarm({ newFarm: newFarm }))
      .then((res: any) => {
        if (res?.payload) {
          handleOnSave(res?.payload.farm);
          notifyService(
            `${t('addFarmModal.notificationsSuccess')}`,
            messageTypes.success,
          );
          navToFarm(res?.payload.farm.id);
          dispatch(closeModal());
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (farms.length > 0) {
      dispatch(setSelectedFarm(farms[farms.length - 1]));
    }
  }, [dispatch, farms.length, farms]);

  const handleLocation = (value: string[]): void => {
    setFarmCenterLat(value[0] || '');
    setFarmCenterLng(value[1] || '');
  };

  const handlePastedLocation = (value: any): void => {
    if (!value[0] || !value[1]) {
      setFarmCenterLat(value?.lat || '');
      setFarmCenterLng(value?.lng || '');
    }
    handleLocation(value);
  };

  function navToFarm(farmId: number) {
    navigate(`/growers/${grower.id}?farmId=${farmId}`);
  }

  return (
    <div
      className={`align-center farm-form-container ${classes.modal}`}
      id="add-farm-modal">
      <div className="logo-center">
        {isToroAccount ? (
          <img src={ToroLogo} alt="Toro logo" style={{ maxWidth: '5rem' }} />
        ) : (
          <img src={t2sLogo} alt="t2s logo" style={{ maxWidth: '5rem' }} />
        )}
      </div>
      <Typography
        id="add-farm-title"
        textAlign="center"
        margin="unset"
        padding="0.5rem 0 0 0"
        color="#676879"
        fontSize="1.5rem">{`${t('addFarmModal.title')}`}</Typography>
      <LocationForm
        onChange={handleLocation}
        onPastedLocation={handlePastedLocation}
        onClose={() => dispatch(closeModal())}
        farmProps={farmProps}
        handleSave={handleSave}
        farmFields={farmFields}
        handleLoading={loading}
      />
    </div>
  );
}

export default AddFarmModal;
