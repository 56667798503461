import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { Typography } from '@mui/material';
import { useStyles } from './useStyles';

interface ETGraphTitleProps {
    blockId?: number;
}

function ETGraphTitle({blockId}: ETGraphTitleProps): JSX.Element {
  const isToroAccount = useAppSelector(
      (state) => state.rootReducer.accountState.isToroAccount,
  );
  // Access flowDataState through rootReducer
  const { flow_data } = useAppSelector((state) => state.rootReducer.flowDataState);
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const { t } = useTranslation();
  const titleSelect =  blockId ? flow_data[blockId]: null

  return (
      <div className={`flex ${classes.title}`}>
            <Typography className={classes.text} variant="titleMedium">
              {titleSelect
                  ? t('blockDataWidget.consumption_vs_irrigation')
                  : t('blockDataWidget.consumptionTrend')}
            </Typography>
      </div>
  );
}

export default ETGraphTitle;
