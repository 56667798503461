import { makeStyles } from '@mui/styles';
import { darken, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  reportsModal: {
    marginTop: '0.5rem',
    maxWidth: '90vw',
    minWidth: '65vw',
    maxHeight: '90vh',
    paddingBlock: '3%',
    paddingInline: '2%',
    borderRadius: '4px',
    '& p': {
      margin: 'unset',
      fontSize: '1.8rem',
    },
    '@media (max-width: 767px)': {
      marginRight: 0,
    },
  },
  header: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    borderBottom: '1px solid',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.grey.A400,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBlockStart: '2rem',
    paddingInline: '1rem',
    gap: '1.5rem',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  filterDatePicker: {
    width: '100%',
  },
  rangePicker: {
    width: '100%',
  },
  filterSelect: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  selectAllTypes: {
    paddingBlockEnd: '0.5rem',
  },
  checkbox: {
    paddingInlineStart: '1rem',
  },
  filterSelectFarms: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  multiSelect: {
    '&.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector':
      {
        minHeight: '2.5rem !important',
      },
  },
  btnContainer: {
    gap: '0.5rem !important',
    textAlign: 'center',
    position: 'relative',
    width: '100% !important',
  },
  warning: {
    color: theme.palette.oldPrimary.main,
    margin: '0 !important',
    position: 'absolute',
    top: '-1.5rem',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  modalExportBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    maxWidth: '18rem',
    color: theme.palette.accent.main,
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    minWidth: '150px',
    padding: '0.5rem',
    '&:hover, &:active': {
      '&:not([disabled])': {
        border: `solid 1px ${theme.palette.accent.main} !important`,
        color: '#fff',
        backgroundColor: darken(theme.palette.accent.main, 0.1),
      },
    },
    '&:hover:enabled': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: darken(theme.palette.accent.main, 0.1),
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: '#fff',
      pointerEvents: 'none',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(12px, 14px, 18px)',
    },
  },
  toroModalExportBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.8rem',
    height: 'inherit',
    maxWidth: '18rem',
    fontWeight: '400',
    fontSize: 'clamp(0.8rem, 1.2rem, 1.4rem)',
    border: `solid 1px ${theme.palette.accent.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: theme.palette.oldPrimary.main,
    minWidth: '6rem',
    lineHeight: '24px',
    transition: 'all 0.2s ease-in -out',
    '&:hover, &:active': {
      '&:not([disabled])': {
        border: `solid 1px ${theme.palette.accent.main} !important`,
        borderRadius: '4px',
        color: '#fff',
        backgroundColor: darken(theme.palette.accent.main, 0.1),
        '& span': {
          fill: '#fff !important',
          '& > svg': {
            fill: '#fff !important',
            '& > path': {
              fill: '#fff !important',
            },
          },
        },
      },
    },
    '&:hover:enabled': {
      border: `solid 1px ${theme.palette.accent.main} !important`,
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: darken(theme.palette.accent.main, 0.1),
    },
    '&[disabled]': {
      backgroundColor: '#76778673',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: '#fff',
      pointerEvents: 'none',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      fontSize: 'clamp(12px, 14px, 18px)',
    },
  },
  downloadIcon: {
    '& > svg': {
      fill: '#fff !important',
      '& > g > path': {
        fill: '#fff !important',
      },
    },
  },
  loaderContainer: {
    position: 'absolute',
    top: '-1rem',
    left: '-2rem',
  },
  loaderWrapper: {
    position: 'relative',
  },
}));

export default useStyles;
