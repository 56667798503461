import {
  imperialToMetricConstants,
  measurementSystemLabels,
  metricToMetricSubUnitConstants,
} from './converter.constants';
import { ConversionActions, ConversionResult } from './converter.types';

export default function toMetric(
  value: number | null | undefined,
  action: ConversionActions,
): ConversionResult {
  const res: ConversionResult = { label: measurementSystemLabels.none };

  switch (action) {
    case ConversionActions.CubicCentimetersAndCubicInches:
      res.value = !value ? value : value * imperialToMetricConstants.in3ToCm3;
      res.label = measurementSystemLabels.cm3H;
      break;

    case ConversionActions.SquareCentimetersAndSquareInches:
      res.value = !value ? value : value * imperialToMetricConstants.in2ToCm2;
      res.label = measurementSystemLabels.cm2H;
      break;

    case ConversionActions.litterAndGallonsPerHour:
      res.value = !value ? value : value * imperialToMetricConstants.galTol;
      res.label = measurementSystemLabels.litH;
      break;
    case ConversionActions.litterAndGallonsPerDay:
      res.value = !value ? value : value * imperialToMetricConstants.galTol;
      res.label = measurementSystemLabels.litD;
      break;

    case ConversionActions.CelsiusAndFahrenheit:
      res.value = !value ? value : imperialToMetricConstants.fToC(value);
      res.label = measurementSystemLabels.cel;
      break;

    case ConversionActions.MillimeterAndInches:
      res.value = !value ? value : value * imperialToMetricConstants.inToMm;
      res.label = measurementSystemLabels.mm;
      break;

    case ConversionActions.SquareMetersAndAcres:
      res.value = !value ? value : value * imperialToMetricConstants.acToM2;
      res.label = measurementSystemLabels.m2;
      break;

    case ConversionActions.SquareMetersToAcresOrHectares:
      res.value = !value
        ? value
        : value * metricToMetricSubUnitConstants.m2ToHec;
      res.label = measurementSystemLabels.hec;
      break;

    case ConversionActions.HectaresAndAcres:
      res.value = !value
        ? value
        : value * imperialToMetricConstants.acToHectare;
      res.label = measurementSystemLabels.hec;
      break;

    case ConversionActions.DunamsAndAcres:
      res.value = !value ? value : value * imperialToMetricConstants.acToDunam;
      res.label = measurementSystemLabels.dunam;
      break;

    case ConversionActions.SquareMetersAndSquareFeets:
      res.value = !value ? value : value * imperialToMetricConstants.ft2ToM2;
      res.label = measurementSystemLabels.m2;
      break;

    case ConversionActions.MetersAndFeet:
      res.value = !value ? value : value * imperialToMetricConstants.ftToM;
      res.label = measurementSystemLabels.m;
      break;

    case ConversionActions.cubicMetersAndGallons:
      res.value = !value ? value : value * imperialToMetricConstants.galToM3;
      res.label = measurementSystemLabels.m3;
      break;

    case ConversionActions.MeterPerSecondAndMilePerHour:
      res.value = !value
        ? value
        : value * imperialToMetricConstants.mileHourToMeterSec;
      res.label = measurementSystemLabels.mpS;
      break;

    // next 5 cases are metric to metric internal conversions
    case ConversionActions.SquareMetersToHectare:
      res.value = !value
        ? value
        : value * metricToMetricSubUnitConstants.m2ToHec;
      res.label = measurementSystemLabels.hec;
      break;

    case ConversionActions.SquareMetersToDunam:
      res.value = !value
        ? value
        : value * metricToMetricSubUnitConstants.m2ToDun;
      res.label = measurementSystemLabels.dunam;
      break;

    case ConversionActions.HectaresToSquareMeters:
      res.value = !value
        ? value
        : value * metricToMetricSubUnitConstants.hecToM2;
      res.label = measurementSystemLabels.m2;
      break;

    case ConversionActions.DunamsToSquareMeters:
      res.value = !value
        ? value
        : value * metricToMetricSubUnitConstants.dunToM2;
      res.label = measurementSystemLabels.m2;
      break;

    case ConversionActions.SquareMetersPerHourPerDunamsToSquareMetersPerHourPerHectare:
      res.value = !value
        ? value
        : value * metricToMetricSubUnitConstants.m3HourDunamToM3HourHectare;
      res.label = measurementSystemLabels.m3Hha;
      break;

    default:
      res.value = value;
      break;
  }

  return res;
}
