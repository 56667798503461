import React, { useCallback, useState } from 'react';
import useStyles from './useStyles';
import {useAppDispatch, useAppSelector} from '../../../../reduxToolkit/store/hooks';
import { GrowerModal } from '../Grower';
import { useUserAccess } from '../../../../shared/hooks/useUserAccess';
import { openModal } from '../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';
import FarmDropdownList from "../../../FieldsPage/FarmDropdown/List";
import GenericIcon from "../block_data_widget/generic_icon";
import {useTranslation} from "react-i18next";

function FarmList(props: any): JSX.Element | null {
  // props
  const { grower, currentFarm, options, selectedOption, setSearchText } = props;

  // hooks
  const classes = useStyles();
  const { isAdmin, isAccountAdminAccess, isAgentAdminAccess } = useUserAccess();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // state
  const [farmIsOpen, setFarmIsOpen] = useState(false);
  const [sortIsOpen, setSortIsOpen] = useState(false);
  const userLanguage = useAppSelector((state) => state.rootReducer.languageState.language);
console.log(userLanguage)

  const handleOnClickFarmList = useCallback(() => {
    if (sortIsOpen) {
      setSortIsOpen(!sortIsOpen);
    }
    setFarmIsOpen(!farmIsOpen);
  }, [setFarmIsOpen, farmIsOpen, setSortIsOpen, sortIsOpen]);

  const isNonEnLanguage = userLanguage !== 'en' && userLanguage !== 'en-US';

  return (
      <div className={`flex-column ${classes.titleContainer}`}>
        {
          <>
            <div className={`flex justify-between ${classes.farmTitleContainer}`}>
              <div className={classes.growerTitle}>
                {isNonEnLanguage ? (
                    <>
                      {t('blockDataWidget.farms')} {grower.title}
                    </>
                ) : (
                    <>
                      {grower.title} {t('blockDataWidget.farms')}
                    </>
                )}
              </div>
              {(isAdmin || isAccountAdminAccess || isAgentAdminAccess) && (
                  <div className={classes.addFarmButton}>
                    <GenericIcon name={'add'} onClick={() => dispatch(openModal(GrowerModal.createFarm))}/>
                  </div>
              )}
            </div>
            <FarmDropdownList
                farms={grower.farms} // New Ant Design dropdown
                currentFarm={currentFarm}
                onItemClicked={() => setFarmIsOpen(!farmIsOpen)}
            />
            </>
        }
      </div>
  );
}

export default FarmList;
