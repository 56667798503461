import React from 'react';
import useToggleWidgetStyles from './useToggleWidgetStyles';

interface ToggleWidgetProps<T> {
    options: ToggleWidgetOption[];
    selected_option: T;
    set_selected_option: (option_id: T) => void;
}

export interface ToggleWidgetOption {
    id: any;
    name: string;
}

const ToggleWidget = <T,>({
                              options,
                              selected_option,
                              set_selected_option,
                          }: ToggleWidgetProps<T>) => {
    const classes = useToggleWidgetStyles();

    const handleClick = (option_id: T) => {
        if (selected_option !== option_id) {
            set_selected_option(option_id);
        }
    };

    return (
        <div className={classes.container}>
            {options.map((option, index) => (
                <div
                    key={option.id}
                    className={`${classes.option} ${
                        selected_option === option.id ? classes.selectedOption : classes.unselectedOption
                    } ${index === 0 ? classes.firstOption : ''} ${
                        index === options.length - 1 ? classes.lastOption : ''
                    }`}
                    onClick={() => handleClick(option.id)}
                >
                    {option.name}
                </div>
            ))}
        </div>
    );
};

export default ToggleWidget;
