import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { postNewGrower } from 'src/reduxToolkit/thunks/grower_thunk';
import { GrowerState } from 'src/shared/data/types/growerOptionsTypes';
import {
  MiniGrower,
  NewGrower,
} from '../../../shared/data/types/growerFarmsTypes';

const initialState: GrowerState = {
  growers: [],
  newGrower: undefined,
  selectedGrower: undefined,
  httpErr: undefined,
  blockIds: {},
};

export const GrowerSlice = createSlice({
  name: 'growers',
  initialState,
  reducers: {
    setGrowers(state, action: PayloadAction<MiniGrower[]>) {
      state.growers = action.payload;
    },
    setSelectedGrower(state, action: PayloadAction<MiniGrower>) {
      state.selectedGrower = action.payload;
    },
    setNewGrower(state, action: PayloadAction<NewGrower>) {
      state.newGrower = action.payload;
    },
    setBlockIds(state, action: PayloadAction<any>) {
      state.blockIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postNewGrower.fulfilled, (state, action) => {
      state.growers.push(action.payload);
    });
    builder.addCase(postNewGrower.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
  },
});

export const { setSelectedGrower, setGrowers, setBlockIds } =
  GrowerSlice.actions;

export const growerReducer = GrowerSlice.reducer;
