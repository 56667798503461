import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '81vw',
    height: '100%',
    background: '#F1F2F7',
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    height: '100%',
    marginLeft: '1.5rem',
    marginRight: 'auto',
    marginTop: '6rem',
    padding: '2% 3%',
    boxShadow: '2px 4px 30px 2px rgba(25, 37, 26, 0.1)',
    borderRadius: '15px',
    background: '#F8F8F8',
  },
  graphAndBarContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 60px)',
  },
  blockList: {
    height: '100%',
    gridArea: 'menu',
    width: '20vw',
    '@media (max-width: 767px)': {
      width: '100%',
      minWidth: '700px',
    },
  },
  innerContent: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
