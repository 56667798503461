import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

export interface FieldProps {
  title: string;
  field?: string;
  fieldName: string;
  fieldState: string | number | boolean;
  setFieldStateString?: Dispatch<SetStateAction<string>>;
  setFieldStateNumber?: Dispatch<SetStateAction<number>>;
  setFieldStateBoolean?: Dispatch<SetStateAction<boolean>>;
  setFieldStateTouch?: Dispatch<SetStateAction<boolean>>;
  fieldStateTouch?: boolean;
  isMandatory: boolean;
  options?: Array<{ label: string; value: any }>;
}


const GetFields = (props: any): any => {
  const { t } = useTranslation();

  const ownerField: FieldProps = {
    title: t('growerFieldModal.owner'),
    fieldName: 'Owner',
    fieldState: props.ownerId,
    setFieldStateString: props.setOwnerId,
    setFieldStateTouch: props.setOwnerValidation,
    fieldStateTouch: props.ownerValidation,
    isMandatory: true,
  };

  const titleField: FieldProps = {
    title: t('growerFieldModal.businessName'),
    fieldName: 'Business Name',
    fieldState: props.title,
    setFieldStateString: props.setTitle,
    setFieldStateTouch: props.setTitleValidation,
    fieldStateTouch: props.titleValidation,
    isMandatory: true,
  };

  const descriptionField: FieldProps = {
    title: t('growerFieldModal.description'),
    fieldName: 'Description',
    fieldState: props.description,
    setFieldStateString: props.setDescription,
    isMandatory: false,
  };

  const countryField: FieldProps = {
    title: t('Modal.country'),
    fieldName: 'Country',
    fieldState: props.country,
    setFieldStateString: props.setCountry,
    setFieldStateTouch: props.setCountryValidation,
    fieldStateTouch: props.countryValidation,
    isMandatory: true,
  };

  const stateNameField: FieldProps = {
    title: t('Modal.state'),
    fieldName: 'State',
    fieldState: props.stateName,
    setFieldStateString: props.setStateName,
    setFieldStateTouch: props.setStateNameValidation,
    fieldStateTouch: props.stateNameValidation,
    isMandatory: false,
  };

  const firstNameField: FieldProps = {
    title: t('Modal.firstName'),
    fieldName: 'First Name',
    fieldState: props.firstName,
    setFieldStateString: props.setFirstName,
    setFieldStateTouch: props.setFirstNameValidation,
    fieldStateTouch: props.firstNameValidation,
    isMandatory: true,
  };

  const lastNameField: FieldProps = {
    title: t('Modal.lastName'),
    fieldName: 'Last Name',
    fieldState: props.lastName,
    setFieldStateString: props.setLastName,
    setFieldStateTouch: props.setLastNameValidation,
    fieldStateTouch: props.lastNameValidation,
    isMandatory: true,
  };

  const cityField: FieldProps = {
    title: t('Modal.city'),
    fieldName: 'City',
    fieldState: props.city,
    setFieldStateString: props.setCity,
    setFieldStateTouch: props.setCityValidation,
    fieldStateTouch: props.CityValidation,
    isMandatory: false,
  };

  const emailField: FieldProps = {
    title: t('Modal.email'),
    fieldName: 'Email',
    fieldState: props.email,
    setFieldStateString: props.setEmail,
    setFieldStateTouch: props.setEmailValidation,
    fieldStateTouch: props.emailValidation,
    isMandatory: true,
  };

  const phoneField: FieldProps = {
    title: t('Modal.phone'),
    fieldName: 'Phone',
    fieldState: props.phone,
    setFieldStateString: props.setPhone,
    setFieldStateTouch: props.setPhoneValidation,
    fieldStateTouch: props.phoneValidation,
    isMandatory: true,
  };

  const roleField: FieldProps = {
    title: t('Modal.role'),
    fieldName: 'Role',
    fieldState: props.role,
    setFieldStateString: props.setRole,
    setFieldStateTouch: props.setRoleValidation,
    fieldStateTouch: props.roleValidation,
    isMandatory: true,
  };

  const sendWeeklyReportField: FieldProps = {
    title: t('Modal.sendWeeklyReport'),
    fieldName: 'SendWeeklyReport',
    fieldState: props.sendWeeklyReport,  // Assume this is a boolean
    setFieldStateBoolean: props.setSendWeeklyReport, // Setter for boolean
    setFieldStateTouch: props.setSendWeeklyReportValidation,
    fieldStateTouch: props.sendWeeklyReportValidation,
    isMandatory: false, // Usually, checkbox fields are not mandatory
  };

  return {
    titleField,
    descriptionField,
    countryField,
    stateNameField,
    firstNameField,
    lastNameField,
    emailField,
    phoneField,
    roleField,
    cityField,
    ownerField,
    sendWeeklyReportField,
  };
};

export default GetFields;
