import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  MeasurementSystem,
  User, UserMeasurementSystem, UserDepthVolume, UserValueTime,
} from '../../../shared/data/types/UserTypes';
import { getUserDetails, updateUserGrowerId } from '../../thunks/user_thunks';
import {DepthVolume, ValueTime} from "../../../pages/Growers/Grower/block_data_widget/constants";

const initialState: User = {
  ownerId: '',
  sub: '',
  ownerAccountId: '',
  growerCognitoId: '',
  given_name: '',
  family_name: '',
  email: '',
  farmManagerId: '',
  httpErr: undefined,
  role: undefined,
  measurementSystem: MeasurementSystem.Metric,
  depth_volume: DepthVolume.depth,
  value_time: ValueTime.value,
  country: '',
};

export const UserSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // TODO: Do we need this one?
    setUserDetails(state, action: PayloadAction<User>) {
      state.id = action.payload.id;
      state.ownerId = action.payload.ownerId;
      state.sub = action.payload.sub;
      state.ownerAccountId = action.payload.ownerAccountId;
      state.growerCognitoId = action.payload.growerCognitoId;
      state.ownerAccountDatabaseId = action.payload.ownerAccountDatabaseId;
      state.family_name = action.payload.family_name;
      state.measurementSystem = action.payload.measurementSystem;
      state.depth_volume = DepthVolume.depth;
      state.value_time = ValueTime.value;
      state.given_name = action.payload.given_name;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.country = action.payload.country;
    },
    setMeasurementSys(state, action: PayloadAction<UserMeasurementSystem>) {
      state.measurementSystem = action.payload.measurementSystem;
    },
    setDepthVolume(state, action: PayloadAction<UserDepthVolume>) {
      state.depth_volume = action.payload.depth_volume;
    },
    setValueTime(state, action: PayloadAction<UserValueTime>) {
      state.value_time = action.payload.value_time;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.ownerId = action.payload.ownerId;
      state.sub = action.payload.sub;
      state.ownerAccountId = action.payload.ownerAccountId;
      state.growerCognitoId = action.payload.growerCognitoId;
      state.ownerAccountDatabaseId = action.payload.ownerAccountDatabaseId;
      state.family_name = action.payload.family_name;
      state.measurementSystem = action.payload.measurementSystem;
      state.depth_volume = action.payload.depth_volume;
      state.value_time = action.payload.value_time;
      state.given_name = action.payload.given_name;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.measurementSystem = state.measurementSystem ?? MeasurementSystem.Imperial;
      state.depth_volume = state.depth_volume ?? DepthVolume.depth;
      state.value_time = state.value_time ?? ValueTime.value;

      state.country = action.payload.country;
    });
    builder.addCase(getUserDetails.rejected, () => {});
    builder.addCase(
      updateUserGrowerId.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.growerCognitoId = action.payload;
        window.location.replace('/');
      },
    );
    builder.addCase(updateUserGrowerId.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
  },
});

export const { setUserDetails, setMeasurementSys, setDepthVolume, setValueTime } = UserSlice.actions;

export const userReducer = UserSlice.reducer;
