import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getAccountsBySortingMethodAndSearchInput,
  getSpecificAccountById,
  postNewAccount,
} from 'src/reduxToolkit/thunks/account_thunks';
import {
  AccountsDetails,
  AccountState,
  AccountsResponse,
} from 'src/shared/data/types/accountOptionsTypes';

const initialState: AccountState = {
  accounts: { accounts: [], total_accounts: 0 },
  newAccount: undefined,
  isToroAccount: false,
  selectedAccount: undefined,
  httpErr: undefined,
};

export const AccountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts(state, action: PayloadAction<AccountsResponse>) {
      state.accounts = action.payload;
    },
    setSelectedAccount(state, action: PayloadAction<AccountsDetails>) {
      state.selectedAccount = action.payload;
    },
    setNewAccount(state, action: PayloadAction<AccountsDetails>) {
      state.newAccount = action.payload;
    },
    setIsToroAccount(state, action: PayloadAction<boolean>) {
      state.isToroAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getSpecificAccountById.fulfilled, (state, action) => {
      state.selectedAccount = action.payload;
    });
    builder.addCase(getSpecificAccountById.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
    builder.addCase(
      getAccountsBySortingMethodAndSearchInput.fulfilled,
      (state, action) => {
        state.accounts = action.payload;
      },
    );
    builder.addCase(
      getAccountsBySortingMethodAndSearchInput.rejected,
      (state, action) => {
        state.httpErr = action.payload;
      },
    );
    builder.addCase(postNewAccount.fulfilled, (state, action) => {
      state.newAccount = action.payload;
    });
    builder.addCase(postNewAccount.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
  },
});

export const {
  setSelectedAccount,
  setAccounts,
  setNewAccount,
  setIsToroAccount,
} = AccountSlice.actions;

export const accountReducer = AccountSlice.reducer;
