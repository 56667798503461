import { Button } from '@mui/material';
import sensorIcon from 'src/assets/Icons/sharedIcons/activeSensorIcon.svg';
import disabledSensorIcon from 'src/assets/Icons/sharedIcons/inactiveSensorIcon.svg';
import { SensorSingleCard } from 'src/shared/data/types/sensorTypes';
import useStyles from './useStyles';

export default function SingleSensorCard(props: SensorSingleCard): JSX.Element {
  const data = props;
  const styleProps = { color: data.color, active: data.isOn[data.sensorId] };
  const classes = useStyles(styleProps);

  return (
    <Button className={classes.root} onClick={data.clickHandler}>
      <div className={classes.writtenData}>
        <img
          src={data.isOn[data.sensorId] ? sensorIcon : disabledSensorIcon}
          alt=""
        />
        <p>IMEI: {data.sensorId}</p>
      </div>
      <span className={classes.coloredLine} />
    </Button>
  );
}
