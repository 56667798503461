import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import t2sLogo from 'src/assets/Icons/mainThemeIcons/t2s-logo.svg';
import { postNewAccount } from 'src/reduxToolkit/thunks/account_thunks';
import { Button } from 'antd';
import Typography from '@mui/material/Typography';
import countries from 'src/consts/countries';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Regex } from 'src/shared/constants/consts';
import {
  handleCountryValidation,
  isValidTLD,
} from 'src/shared/helpers/FormValidationHelper';
import SelectCountry from './SelectCountry';
import SelectState from './SelectState';
import TextForm from './TextForm';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxToolkit/store/hooks';
import notifyService from '../../../../shared/notification/notification.service';
import messageTypes from '../../../../consts/messages';
import getAccountFields from '../ModalHelper';
import useStyles from '../useStyles';
import countryMap from '../../../../shared/data/CountryMap';

function AddAccountModal({ onClose, onSave }: any): ReactElement | null {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>('');
  const [titleValidation, setTitleValidation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [countryValidation, setCountryValidation] = useState<string>('');
  const [stateName, setStateName] = useState<string>('');
  const [stateNameValidation, setStateNameValidation] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameValidation, setFirstNameValidation] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameValidation, setLastNameValidation] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailValidation, setEmailValidation] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [phoneValidation, setPhoneValidation] = useState<string>('');
  const [hasStates, setHasStates] = useState<boolean>(false);
  const userData = useAppSelector((state) => state.rootReducer.userState);
  const classes = useStyles();
  const { t } = useTranslation();
  const props = {
    title,
    setTitle,
    titleValidation,
    setTitleValidation,
    description,
    setDescription,
    country,
    setCountry,
    countryValidation,
    setCountryValidation,
    stateName,
    setStateName,
    stateNameValidation,
    setStateNameValidation,
    firstName,
    setFirstName,
    firstNameValidation,
    setFirstNameValidation,
    lastName,
    setLastName,
    lastNameValidation,
    setLastNameValidation,
    email,
    setEmail,
    emailValidation,
    setEmailValidation,
    phone,
    setPhone,
    phoneValidation,
    setPhoneValidation,
  };
  const accountFields = getAccountFields(props);

  const newAccount = {
    title: title,
    description: description,
    country: country,
    state: stateName,
    email: email,
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    accountCognitoId: `${userData.sub}`,
    measurementSystem: `${userData.measurementSystem}`,
    ownerCognitoId: `${userData.sub}`,
  };

  const handleSave = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    void dispatch(postNewAccount({ newAccount: newAccount }))
      .then((res) => {
        if (res.payload != null) {
          onSave(newAccount);
          notifyService('Account created successfully', messageTypes.success);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderStates = (): ReactElement | null => {
    if (hasStates) {
      return <SelectState data={accountFields.stateNameField} />;
    }
    return null;
  };

  useEffect(() => {
    if (!hasStates) {
      setStateName('');
    }
  }, [hasStates]);

  return (
    <div className={`flex-column align-center ${classes.wrapper}`}>
      <div className="logo-center">
        <img src={t2sLogo} alt="t2s logo" />
      </div>
      <Typography
        textAlign="center"
        margin="unset"
        padding="0.5rem 0 0 0"
        color="#676879"
        fontSize="1.5rem">
        {t('accountFieldModal.addAccountModalTitle')}
      </Typography>
      <div className={`flex-column align-center ${classes.formContainer}`}>
        <div className={`${classes.fieldsContainer} custom`}>
          <TextForm data={accountFields.titleField} />
          <TextForm data={accountFields.firstNameField} />
          <TextForm data={accountFields.lastNameField} />
          <SelectCountry
            data={accountFields.countryField}
            onState={setHasStates}
          />
          <SelectState
            data={accountFields.stateNameField}
            disabled={
              !hasStates &&
              country !== countries.USA &&
              country !== countries.USAShort
            }
          />
          {renderStates()}
          <TextForm
            data={accountFields.phoneField}
            type="phone"
            code={countryMap.get(country)}
          />
          <TextForm data={accountFields.emailField} />
          <TextForm data={accountFields.descriptionField} />
        </div>
      </div>
      <div
        className={`btn-action-container common-button-container flex justify-between ${classes.modalBtnContainer}`}>
        <Button
          disabled={
            loading ||
            !title ||
            !firstName ||
            !lastName ||
            !email ||
            !phone ||
            !country ||
            !isValidPhoneNumber(phone) || // Checks if a phone number value is a "valid" phone number. A phone number is "valid" when it has valid length, and the actual phone number digits match the regular expressions for that country.
            !isPossiblePhoneNumber(phone) || // Checks if a phone number value is a "possible" phone number. A phone number is "possible" when it has valid length. The actual phone number digits aren't validated.
            // TLD = top-level domain extension - i.e .com .il etc.
            !Regex.EMAIL_REGEX.test(email) ||
            (Regex.EMAIL_REGEX.test(email) && !isValidTLD(email)) ||
            !handleCountryValidation(country, stateName)
          }
          id="save-new-account"
          onClick={(e) => handleSave(e)}
          className={`ant-btn-custom ${classes.modalSaveBtn}`}>
          {t('accountFieldModal.saveAccountBtn')}
        </Button>
        <Button onClick={() => onClose()} className={classes.modalCancelBtn}>
          {t('accountFieldModal.cancel')}
        </Button>
      </div>
    </div>
  );
}

export default AddAccountModal;
