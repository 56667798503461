import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getSpecificAgentById,
  postNewAgent,
} from 'src/reduxToolkit/thunks/agent_thunks';
import {
  AgentsDetails,
  AgentState,
  AgentsResponse,
} from 'src/shared/data/types/agentOptionsTypes';

const initialState: AgentState = {
  agents: { agents: [], total_agents: 0 },
  newAgent: undefined,
  isToroAccount: false,
  selectedAgent: undefined,
  httpErr: undefined,
};

export const AgentSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAgents(state, action: PayloadAction<AgentsResponse>) {
      state.agents = action.payload;
    },
    setSelectedAgent(state, action: PayloadAction<AgentsDetails>) {
      state.selectedAgent = action.payload;
    },
    setNewAgent(state, action: PayloadAction<AgentsDetails>) {
      state.newAgent = action.payload;
    },
    setIsToroAccount(state, action: PayloadAction<boolean>) {
      state.isToroAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getSpecificAgentById.fulfilled, (state, action) => {
      state.selectedAgent = action.payload;
    });
    builder.addCase(getSpecificAgentById.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
    builder.addCase(postNewAgent.fulfilled, (state, action) => {
      state.newAgent = action.payload;
    });
    builder.addCase(postNewAgent.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
  },
});

export const { setSelectedAgent, setAgents, setNewAgent, setIsToroAccount } =
  AgentSlice.actions;

export const agentReducer = AgentSlice.reducer;
