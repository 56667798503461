// react hooks
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { useAppSelector } from '../../../../reduxToolkit/store/hooks';
import useStyles from './useStyles';

// resources
import { useMapButtonsHelper } from './useMapButtonsHelper';
import useWeatherHelper from './useWeatherHelper';
import mapLayersIcon from 'src/assets/Icons/sharedIcons/mapLayersIcon.svg';

// types and enums
import { MapZoom } from 'src/shared/constants/enums';
import { MiniBlock } from '../../../../shared/data/types/growerFarmsTypes';
import { MapButton } from '../../../../shared/data/types/mapTypes';

// components
import IconWithToolTip from '../../../../shared/components/IconWithToolTip';
import WeatherButton from '../../../../shared/components/Weather/WeatherButton';
import Weather from '../../../../shared/components/Weather/Weather';
import Map from '../../../../shared/components/Map';

// redux
import { setNumOfClicksOnMapDuringCreation } from '../../../../reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';

function FarmMap(props: any): JSX.Element | null {
  // props
  const { farm, shouldResetHighlight } = props;
  const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

  // hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  // redux
  const { isNewBlockCreationInProgress, numOfClicksOnMapDuringBlockCreation } =
    useAppSelector((state) => state.rootReducer.addOrEditBlockState);

  // state
  const [currentMapCenter, setCurrentMapCenter] = useState<any>(null);
  const [mapLayerVisibility, setMapLayerVisibility] = useState<boolean>(false);
  const [isMapButtonHovered, setIsMapButtonHovered] = useState<boolean>(false);
  // UI related states
  const [isWeatherBarOpen, setIsWeatherBarOpen] = useState<any>(false);
  // Data fetching states
  const [isFetchingLocation, setIsFetchingLocation] = useState<any>(null);
  // units state
  const [usedUnits, setUsedUnits] = useState<any>([]);

  // resources
  const fetchCurrentLocation = (): void => {
    if (navigator.geolocation) {
      setIsFetchingLocation(true);
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setIsFetchingLocation(false);
      });
    }
  };

  // Effect to fetch blocks and polygons when a farm is selected
  useEffect(() => {
    if (farm?.id) {
      let itemUnits: any[] = [];
      if (farm?.blocks?.length > 0 && farm?.blocks?.[0]) {
        const modifiedBlocks = farm.blocks.map((block: MiniBlock) => ({
          ...block,
          polygon: block.polygon as any,
        }));
        for (let block of modifiedBlocks) {
          if (!block.units) continue;

          itemUnits = itemUnits.concat(
            block.units
              .filter((u: any) => !!u)
              .map((item: { imei: any }) => ({
                ...item,
                details: item.imei,
              })),
          );
        }
      } else if (farm) {
        itemUnits.push({
          lat: farm.centerLat,
          lng: farm.centerLng,
          type: 1,
        });
      }
      setUsedUnits(itemUnits);
    }
  }, [dispatch, farm]);

  const isBlockSelected = useCallback(
    (block: MiniBlock) => {
      return currentBlock !== null && block.id === currentBlock?.id;
    },
    [currentBlock],
  );

  const transformPolygonData = (polygon: string | any[]): any[] => {
    return typeof polygon === 'string' ? JSON.parse(polygon) : polygon;
  };

  const getMappedPolygons = useMemo(() => {
    if (!farm?.blocks?.[0]) return [];

    const polygonsWithFlags: any[] = [];
    farm?.blocks?.forEach((block: MiniBlock) => {
      const transformedPolygon = transformPolygonData(block.polygon);
      polygonsWithFlags.push({
        id: block.id,
        polygon: transformedPolygon,
        isSelected: isBlockSelected(block),
      });
    });
    return polygonsWithFlags;
  }, [farm.blocks, isBlockSelected]);

  useEffect(() => {
    if (farm) {
      setCurrentMapCenter({
        lat: farm.centerLat,
        lng: farm.centerLng,
      });
    } else {
      fetchCurrentLocation();
    }
  }, [farm]);

  const {
    centerFarmMapBtn,
    mapToolsBtn,
    showMapLayerOptions,
    mapLayersOptionBtn,
    mapType,
  } = useMapButtonsHelper({
    setMapLayerVisibility,
    mapLayerVisibility,
    isWeatherBarOpen,
    setIsWeatherBarOpen,
    farm,
    setCurrentMapCenter,
    fetchCurrentLocation,
  });

  // mapToolsBtn is used for map btns when block creation is in progress
  const {
    currentTemperatureIcon,
    currentTemperatureValue,
    currentTemperatureLabel,
    currentWeather,
    openWeatherBarBtn,
  } = useWeatherHelper({
    currentFarm: farm,
    isWeatherBarOpen,
    setIsWeatherBarOpen,
    setCurrentMapCenter,
  });

  useEffect(() => {
    currentWeather();
  }, [farm?.id, currentWeather]);

  // Handle map clicks during block creation
  const handleClickOnMapDivDuringCreation = useCallback(() => {
    if (isNewBlockCreationInProgress)
      dispatch(
        setNumOfClicksOnMapDuringCreation(
          numOfClicksOnMapDuringBlockCreation + 1,
        ),
      );
  }, [
    dispatch,
    isNewBlockCreationInProgress,
    numOfClicksOnMapDuringBlockCreation,
  ]);
//
  return (
      <div className={`grid ${classes.mapAndInfoContainer}`}>
        <div
            className={classes.map}
            onClick={handleClickOnMapDivDuringCreation}
            onKeyDown={handleClickOnMapDivDuringCreation}
        >
          <Map
              mapCenter={currentMapCenter}
              markersPoints={usedUnits}
              blocksPolygons={getMappedPolygons}
              defaultIcon
              drawModeIndicator
              mapZoom={farm ? MapZoom.MAP_ZOOM : MapZoom.MAP_ZOOM_EMPTY}
              isNewBlockCreationInProgress={isNewBlockCreationInProgress}
              mapTypeId={mapType}
          />
          <ul
              className={`flex justify-between clean-list ${classes.configMapSettingsContainer}`}>
            <li
                className={
                  !mapLayerVisibility
                      ? `map-icon-settings active ${classes.hoverOnLayers}`
                      : `${classes.hoverOnLayers}`
                }>
              <Button
                  onClick={(e) => showMapLayerOptions(e)}
                  onMouseEnter={() => setIsMapButtonHovered(true)}>
                {isMapButtonHovered ? (
                    <IconWithToolTip
                        color="#fff"
                        svgIcon={
                          <img
                              src={mapLayersIcon}
                              alt="map-Layers"
                              style={{width: '1.3rem'}}
                          />
                        }
                        text={`${t('map.layersTooltip')}`}
                    />
                ) : (
                    <img
                        src={mapLayersIcon}
                        alt="map-Layers"
                        style={{width: '1.3rem'}}
                    />
                )}
              </Button>
            </li>
            {mapLayerVisibility ? (
                mapLayersOptionBtn.map((button: MapButton) => {
                  return (
                      <li key={button.name}>
                        <Button
                            className={classes.mapSettingsBtn}
                            type="button"
                            onClick={button.handler}
                            onMouseEnter={() => setIsMapButtonHovered(true)}>
                          {isMapButtonHovered ? (
                              <IconWithToolTip
                                  color="#fff"
                                  svgIcon={button.icon ? button.icon : button.name}
                                  text={button.name}
                              />
                          ) : button.icon ? (
                              button.icon
                          ) : (
                              button.name
                          )}
                        </Button>
                      </li>
                  );
                })
            ) : (
                <div style={{display: 'none'}}/>
            )}
          </ul>
          {isNewBlockCreationInProgress ? (
              <div className={classes.mapSettings}>
                {mapToolsBtn.map((button) => {
                  return isMapButtonHovered ? (
                      <Button
                          className={
                            button.name === 'Reset' && shouldResetHighlight
                                ? classes.mapResetBtnHighlight
                                : classes.mapSettingsBtn
                          }
                          type="button"
                          key={button.name}
                          onClick={button.handler}
                          onMouseEnter={() => setIsMapButtonHovered(true)}>
                        <IconWithToolTip
                            color="#fff"
                            svgIcon={button.icon ? button.icon : button.name}
                            text={button.name}
                        />
                      </Button>
                  ) : (
                      <Button
                          className={classes.mapSettingsBtn}
                          type="button"
                          key={button.name}
                          onClick={button.handler}
                          onMouseEnter={() => setIsMapButtonHovered(true)}>
                        {button.icon ? button.icon : button.name}
                      </Button>
                  );
                })}
                {isFetchingLocation && <span>{`${t('field.loading')}`}</span>}
              </div>
          ) : (
              <div className={classes.mapSettings}>
                {centerFarmMapBtn.map((button: MapButton) => {
                  return (
                      <Button
                          className={classes.mapSettingsBtn}
                          type="button"
                          key={button.name}
                          onClick={button.handler}
                          onMouseEnter={() => setIsMapButtonHovered(true)}>
                        {isMapButtonHovered ? (
                            <IconWithToolTip
                                color="#fff"
                                svgIcon={button.icon ? button.icon : button.name}
                                text={button.name}
                            />
                        ) : button.icon ? (
                            button.icon
                        ) : (
                            button.name
                        )}
                      </Button>
                  );
                })}

                {isFetchingLocation && <span>{`${t('field.loading')}`}</span>}
              </div>
          )}
          {!isWeatherBarOpen && currentTemperatureValue != null ? (
              <div id="open-current-weather" className={classes.mapSettingsTop}>
                {openWeatherBarBtn.map((button: any, buttonIdx: number) => (
                    <WeatherButton
                        key={buttonIdx}
                        button={button}
                        openWeatherBar={isWeatherBarOpen}
                        setOpenWeatherBar={setIsWeatherBarOpen}
                    />
                ))}
              </div>
          ) : null}
          {isWeatherBarOpen && currentTemperatureValue != null && (
              <Weather
                  onClose={(ev: any) => {
                    setIsWeatherBarOpen(!ev);
                  }}
                  currTempIcon={currentTemperatureIcon}
                  currentTemperatureValue={currentTemperatureValue}
                  currentTemperatureLabel={currentTemperatureLabel}
                  currentFarm={farm}
              />
          )}
        </div>
      </div>
  );
}

export default FarmMap;
