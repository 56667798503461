import { useTranslation } from 'react-i18next';
import { AccountField } from 'src/shared/data/types/accountOptionsTypes';

const GetAccountFields = (props: any): any => {
  const { t } = useTranslation();
  const titleField: AccountField = {
    title: t('accountFieldModal.title'),
    fieldName: 'Account Title',
    fieldState: props.title,
    setFieldStateString: props.setTitle,
    isMandatory: true,
    setFieldStateTouch: props.setTitleValidation,
    fieldStateTouch: props.titleValidation,
  };

  const descriptionField: AccountField = {
    title: t('accountFieldModal.description'),
    fieldName: 'Description',
    fieldState: props.description,
    setFieldStateString: props.setDescription,
    isMandatory: false,
  };

  const countryField: AccountField = {
    title: t('accountFieldModal.country'),
    fieldName: 'Country',
    fieldState: props.country,
    setFieldStateString: props.setCountry,
    setFieldStateTouch: props.setCountryValidation,
    fieldStateTouch: props.countryValidation,
    isMandatory: true,
  };

  const stateNameField: AccountField = {
    title: t('accountFieldModal.stateName'),
    fieldName: 'State',
    fieldState: props.stateName,
    setFieldStateString: props.setStateName,
    setFieldStateTouch: props.setStateNameValidation,
    fieldStateTouch: props.stateNameValidation,
    isMandatory: false,
  };

  const firstNameField: AccountField = {
    title: t('accountFieldModal.firstName'),
    fieldName: 'First Name',
    fieldState: props.firstName,
    setFieldStateString: props.setFirstName,
    setFieldStateTouch: props.setFirstNameValidation,
    fieldStateTouch: props.firstNameValidation,
    isMandatory: true,
  };

  const lastNameField: AccountField = {
    title: t('accountFieldModal.lastName'),
    fieldName: 'Last Name',
    fieldState: props.lastName,
    setFieldStateString: props.setLastName,
    setFieldStateTouch: props.setLastNameValidation,
    fieldStateTouch: props.lastNameValidation,
    isMandatory: true,
  };

  const emailField: AccountField = {
    title: t('accountFieldModal.email'),
    fieldName: 'Account Email',
    fieldState: props.email,
    setFieldStateString: props.setEmail,
    setFieldStateTouch: props.setEmailValidation,
    fieldStateTouch: props.emailValidation,
    isMandatory: true,
  };

  const phoneField: AccountField = {
    title: t('accountFieldModal.phone'),
    fieldName: 'Phone',
    fieldState: props.phone,
    setFieldStateString: props.setPhone,
    setFieldStateTouch: props.setPhoneValidation,
    fieldStateTouch: props.phoneValidation,
    isMandatory: true,
  };

  return {
    titleField,
    descriptionField,
    countryField,
    stateNameField,
    firstNameField,
    lastNameField,
    emailField,
    phoneField,
  };
};

export default GetAccountFields;
