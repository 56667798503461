export function formatDate(date: Date | string, t: (key: string) => string): string {
    // If the input is a string, convert it to a Date object
    const validDate = new Date(date);

    // Check if the conversion resulted in a valid date
    if (isNaN(validDate.getTime())) {
        console.error('Invalid date:', date);
        return 'Invalid Date';
    }

    // Get the month and day
    const monthIndex = validDate.getMonth(); // Gets month index (0 for January, 1 for February, etc.)
    const day = validDate.getDate(); // Gets the day of the month as a number

    // Map month index to month names (keys for translation)
    const monthNames = [
        "January", "February", "March", "April", "May",
        "June", "July", "August", "September", "October",
        "November", "December"
    ];

    // Get the month name using the month index
    const monthNameKey = monthNames[monthIndex];

    // Translate the month name
    const translatedMonth = t(monthNameKey);

    // Return the formatted date as 'Translated Month Day' (e.g., June 8)
    return `${translatedMonth} ${day}`;
}


