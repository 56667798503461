import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .ant-modal-body': {
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: 'fit-content',
      '@media (min-width: 768px)': {},
      '@media (max-width: 1000px)': {
        minWidth: '80vw',
        maxWidth: '85vw',
      },
    },
    '& .ant-modal-content': {
      width: 'fit-content',
    },
  },
  closeIcon: {
    width: '18px',
    height: '18px',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.backgroundGrey.main,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
    borderRadius: '4px',
    minWidth: '70vw',
    minHeight: '80vh',
    maxHeight: '90vh',
    overflow: 'hidden',
    paddingInline: '5rem',
    paddingBlock: '3rem',
    zIndex: '999',
    justifyContent: 'space-around',
    '@media (min-width: 641px) and (max-width: 1100px)': {
      paddingInline: '3rem',
    },
    '@media (min-width: 320px) and (max-width: 640px)': {
      paddingInline: '2rem',
      paddingBlock: '2rem 3rem',
      scrollBehavior: 'smooth',
      overflowY: 'auto',
      scrollY: 'auto',
    },
    '& img': {
      paddingBlock: '0',
      '@media (min-width: 320px) and (max-width: 640px)': {
        paddingBlock: '3rem 1rem',
      },
    },
  },
}));

export default useStyles;
