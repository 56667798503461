import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Modal from 'src/shared/components/Modal/Modal';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { smallMaxWidth } from 'src/shared/constants/consts';
import { Button, Switch } from 'antd';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import reportsResearch from 'src/assets/Icons/sharedIcons/reportsIcon.svg';
import growerReportIcon from 'src/assets/Icons/sharedIcons/etIcons/growerReportIcon.svg';
import toroGrowerReportIcon from 'src/assets/Icons/toroThemeIcons/toroEtIcons/toroGrowerReportIcon.svg';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import ResearcherReportsManagement from 'src/pages/FieldsPage/Report/ResearcherReportsManagement';
import GrowersReportsManagement from 'src/pages/FieldsPage/Report/GrowersReportsManagement/GrowersReportsManagement';
import { MeasurementSystem } from 'src/shared/data/types/UserTypes';
import {setMeasurementSys} from 'src/reduxToolkit/slices/UserSlice/user.slice';
import ETGraphTitle from './ETGraphTitle/ETGraphTitle';
import { useStyles } from './useStyles';
import { GrowerModal } from '../../../../Growers/Grower/Grower';
import {
  closeModal,
  openModal,
} from '../../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';
import { getGrowerWeeksReport } from '../../../../../reduxToolkit/thunks/grower_reports_thunk';
import { setWeeklyReports } from '../../../../../reduxToolkit/slices/GrowerReportsSlice/growerReports.slice';
import { MiniGrower } from '../../../../../shared/data/types/growerFarmsTypes';

interface ETGraphTitleReportsProps {
    blockId?: number;
}

function ETGraphTitleReports({blockId}: ETGraphTitleReportsProps): JSX.Element {
  const {
    isViewerAccess,
    isResearcher,
    isGrowerAccess,
    isAdmin,
    isAccountAdminAccess,
    isAgentAdminAccess,
  } = useUserAccess();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const [weeklyData, setWeeklyData] = useState<any>({});
  const [weeklyDataLoading, setWeeklyDataLoading] = useState<any>(false);
  const [hoverOnResearchReportsButton, setHoverOnResearchReportsButton] =
    useState<boolean>(false);
  const selectedGrower = useAppSelector(
    (state) => state.rootReducer.growerState.selectedGrower,
  );
  const { t } = useTranslation();
  const classes = useStyles();
  const [hoverOnGrowerReportsButton, setHoverOnGrowerReportsButton] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { width } = useWindowDimensions();
  // let modal: GrowerModal | null = useAppSelector((state) => state.rootReducer.GrowerFarmsState.modal);
  const { modal } = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState;
  });
  const weekStartDay = selectedGrower?.country === 'Israel' ? 1 : 2;
  const grower: MiniGrower | null = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState.currentGrower;
  });

  useEffect(() => {
    if (grower?.id) {
      setWeeklyDataLoading(true);
      getGrowerWeeksReport(grower.id, weekStartDay).then((res: any) => {
        if (res.data) {
          dispatch(setWeeklyReports({ weeklyReports: res.data }));
          setWeeklyData(res.data);
          setWeeklyDataLoading(false);
        }
      });
    }
    setWeeklyDataLoading(false);
  }, [selectedGrower]);

  // function renderReportsModal(): JSX.Element {
  //   if (!modal) return <></>;
  //   switch (modal) {
  //     case GrowerModal.growerFarmsReport:
  //       return (<GrowersReportsManagement
  //         weeklyDataLoading={weeklyDataLoading}
  //         weeklyData={weeklyData} />);
  //     case GrowerModal.researcherFarmsReport:
  //       return (<ResearcherReportsManagement
  //         farms={farms}
  //         grower={selectedGrower}
  //       />);
  //     default:
  //       return <></>;
  //   }
  // }

  const renderViewGrowerReports = (): any =>
    hoverOnGrowerReportsButton ? (
      <Button
        id="grower-report-modal"
        className={classes.reportsButton}
        onClick={(_) => dispatch(openModal(GrowerModal.growerFarmsReport))}
        onMouseEnter={() => setHoverOnGrowerReportsButton(true)}>
        <IconWithToolTip
          color="#fff"
          svgIcon={
            isToroAccount ? (
              <img
                src={toroGrowerReportIcon}
                id="grower-report-modal"
                className={classes.iconLarge}
                alt="reports"
              />
            ) : (
              <img
                src={growerReportIcon}
                id="grower-report-modal"
                className={classes.iconLarge}
                alt="reports"
              />
            )
          }
          text={`${t('growerReports.growerReportsTooltip')}`}
        />
      </Button>
    ) : (
      <Button
        id="grower-report-modal"
        className={classes.reportsButton}
        onClick={(_) => dispatch(openModal(GrowerModal.growerFarmsReport))}
        onMouseEnter={() => setHoverOnGrowerReportsButton(true)}>
        {isToroAccount ? (
          <img
            src={toroGrowerReportIcon}
            id="grower-report-modal"
            className={classes.iconLarge}
            alt="reports"
          />
        ) : (
          <img
            src={growerReportIcon}
            id="grower-report-modal"
            className={classes.iconLarge}
            alt="reports"
          />
        )}
      </Button>
    );

  const renderViewResearcherReports = (): any =>
    hoverOnResearchReportsButton ? (
      <Button
        id="research-report-modal"
        className={classes.reportsButton}
        onClick={(_) => dispatch(openModal(GrowerModal.researcherFarmsReport))}
        onMouseEnter={() => setHoverOnResearchReportsButton(true)}>
        <IconWithToolTip
          color="#fff"
          svgIcon={
            <img
              src={reportsResearch}
              className={classes.iconLarge}
              alt="reports"
            />
          }
          text={`${t('researchReports.researchReportsTooltip')}`}
        />
      </Button>
    ) : (
      <Button
        id="research-report-modal"
        className={classes.reportsButton}
        onClick={(_) => dispatch(openModal(GrowerModal.researcherFarmsReport))}
        onMouseEnter={() => setHoverOnResearchReportsButton(true)}>
        <img
          src={reportsResearch}
          id="research-report-modal"
          className={classes.iconLarge}
          alt="reports"
        />
      </Button>
    );

  return (
    <div className={`flex-column ${classes.graphTitleContainer}`}>
      <div
        className={`flex align-center justify-between ${classes.graphTitleReports}`}>
        <ETGraphTitle blockId={blockId}/>
        <article
          className={`flex align-center justify-between ${classes.farmReportsIcons}`}>
          {(isAdmin || isAccountAdminAccess || isResearcher) &&
          width > smallMaxWidth
            ? renderViewResearcherReports()
            : null}
          {(isAdmin ||
            isAccountAdminAccess ||
            isAgentAdminAccess ||
            isViewerAccess ||
            isGrowerAccess) &&
          width > smallMaxWidth &&
          weeklyData
            ? renderViewGrowerReports()
            : null}
        </article>
      </div>
      {/*<Modal isOpen={[GrowerModal.growerFarmsReport, GrowerModal.researcherFarmsReport].includes(modal??GrowerModal.createBlock)} onCancel={() => dispatch(closeModal())}>*/}
      {/*  {renderReportsModal()}*/}
      {/*</Modal>*/}
    </div>
  );
}

export default ETGraphTitleReports;
