import ConnectionPage from './pages/ConnectionPage';
import AccountsManagement from './pages/AccountsManagement';
import AgentsManagement from './pages/AgentsManagement';
import RoutesManager from './pages/RoutesManager/RoutesManager';
import Growers from './pages/Growers';
import GrowersManagement from './pages/GrowersManagement';

const routes = [
  {
    path: '/connection',
    component: <ConnectionPage />,
  },
  {
    path: '/accounts',
    component: <AccountsManagement />,
  },
  {
    path: '/agents',
    component: <AgentsManagement />,
  },
  {
    path: '/growers/:id',
    name: 'grower_farms',
    props: true,
    component: <Growers />,
  },
  {
    path: '/growers',
    component: <GrowersManagement />,
  },
  {
    path: '/',
    component: <RoutesManager />,
  },
  {
    path: '/fields',
    component: <RoutesManager />,
  },
  {
    path: '/sensors',
    component: <RoutesManager />,
  },
];

export default routes;
