// src/styles/useMiniBlockDataWidgetStyles.ts
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useMiniBlockDataWidgetStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: theme.palette.surfaceWhite.main,
        paddingInline: theme.paddings.xxl,
        marginInline: theme.paddings.m,
        borderRadius: theme.borderRadius.m,
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // Optional: Adjust padding and margin for smaller screens if needed
        '@media (max-width: 767.9px)': {
            paddingInline: theme.paddings.l, // Adjust as per your theme
            marginInline: theme.paddings.s,  // Adjust as per your theme
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBlock: theme.paddings.m,
        // Adjust header layout for mobile devices
        '@media (max-width: 767.9px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingBlock: theme.paddings.s, // Adjust as per your theme
        },
    },
    blockTitle: {
        ...theme.typography.title2,
        // Optional: Add margin or other styles if needed
        '@media (max-width: 1200.9px)': {
            ...theme.typography.bodyMedium,        },
        '@media (max-width: 767.9px)': {
            marginBottom: theme.paddings.s, // Adds spacing below the title on mobile
        },
    },
    toggleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.paddings.l, // Increase gap size for more space between toggles
        '@media (max-width: 767.9px)': {
            flexDirection: 'row',
            gap: theme.paddings.m, // Adjust gap on mobile as well if needed
            marginTop: theme.paddings.xs,
        },
    },
    dataCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: theme.paddings.m, // Adjust spacing between cards
        flexWrap: 'wrap', // Allows wrapping of cards on smaller screens
        '@media (max-width: 767.9px)': {
            flexDirection: 'column',
            alignItems: 'center', // Center the cards horizontally
            justifyContent: 'flex-start',
            gap: theme.paddings.s, // Adjust gap for vertical layout
        },
    },
    graphContainer: {
        display: 'flex',
        height: '100%',
        marginTop: theme.paddings.m,
        '@media (max-width: 767.9px)': {
            marginTop: theme.paddings.s, // Adjust spacing on mobile
        },
    },
}));

export default useMiniBlockDataWidgetStyles;
