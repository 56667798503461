import { useTranslation } from 'react-i18next';
import { TableColumnDetails } from 'src/shared/data/types/tableOptionsType';
import { Button } from 'antd';
import { useAppSelector } from 'src/reduxToolkit/store/hooks';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import greenPlus from 'src/assets/Icons/mainThemeIcons/greenPlus.svg';
import toroPlus from 'src/assets/Icons/toroThemeIcons/toroPlus.svg';
import copyIcon from 'src/assets/Icons/sharedIcons/copyIcon.svg';
import viewIcon from 'src/assets/Icons/sharedIcons/view.svg';
import deleteIcon from 'src/assets/Icons/sharedIcons/deleteIcon.svg';
import activeIcon from 'src/assets/Icons/activate-grower.svg';
import editIcon from 'src/assets/Icons/sharedIcons/editIcon.svg';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import { MiniGrower } from '../../../shared/data/types/growerFarmsTypes';
import { accountNameById } from '../../../shared/data/GrowerUtils';

const GetColumnsProps = (
  classes: {
    tableBtn: any;
    email: any;
    actions: any;
    iconBtn: string | undefined;
    tableCopyBtn: any;
  },
  handleCopyGrowerId: any,
  onAddNewFarm: any,
  onActivateGrower: any,
  onViewGrower: (e: any, record: any) => void,
  onEditGrower: (e: any, record: any) => void,
  onDeleteGrower: (e: any, record: any) => void,
): any => {
  const { t } = useTranslation();
  const { isAdmin, isAccountAdminAccess, isAgentAdminAccess } = useUserAccess();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );

  const businessNameColumn: TableColumnDetails = {
    title: t('growersList.businessName') ?? 'Business Name',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.title > b.title ? 1 : a.title === b.title ? 0 : -1,
    render: (title: string, grower: { id: number }): any => (
      <Button
        className={classes.tableBtn}
        onClick={(e) => onViewGrower(e, grower.id)}>
        <span>{title}</span>
      </Button>
    ),
  };

  const accountNameColumn: TableColumnDetails = {
    title: t('growersList.accountName') ?? 'Account Name',
    dataIndex: 'account',
    key: 'account',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1,
    render: (_, record): any => (
      <article style={{ textAlign: 'left' }}>
        <span>{accountNameById(record.accountCognitoId)}</span>
      </article>
    ),
  };

  const growerNameColumn: TableColumnDetails = {
    title: t('growersList.name') ?? 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1,
    render: (_: any, grower: MiniGrower): any => (
      <span>{`${grower.firstName} ${grower.lastName}`}</span>
    ),
  };

  const countryColumn: TableColumnDetails = {
    title: t('growersList.country') ?? 'Country',
    dataIndex: 'country',
    key: 'country',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.country > b.country ? 1 : a.country === b.country ? 0 : -1,
  };

  const stateColumn: TableColumnDetails = {
    title: t('growersList.state') ?? 'State',
    dataIndex: 'state',
    key: 'state',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.state > b.state ? 1 : a.state === b.state ? 0 : -1,
  };

  const emailColumn: TableColumnDetails = {
    title: t('growersList.email') ?? 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    render: (email: string): any => (
      <a
        href={`mailto:${email}`}
        className={`${classes.tableBtn} ${classes.email}`}>
        {email}
      </a>
    ),
  };

  const phoneColumn: TableColumnDetails = {
    title: t('growersList.phone') ?? 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true,
  };

  const growerIdColumn: TableColumnDetails = {
    title: t('growersList.growerId') ?? 'Grower Id',
    dataIndex: 'growerCognitoId',
    key: 'growerCognitoId',
    render: (growerCognitoId: string): JSX.Element | null => (
      <article className={`flex align-center ${classes.tableCopyBtn}`}>
        <span>{growerCognitoId}</span>
        {growerCognitoId && (
          <Button
            id="copy-grower-cognitoId"
            onClick={(e) => {
              handleCopyGrowerId(e, growerCognitoId);
            }}
            className={`${classes.tableBtn}`}>
            <img
              src={copyIcon}
              alt="copy grower cognito Id"
              id="copy-grower-cognitoId"
              style={{ width: '1rem' }}
            />
          </Button>
        )}
      </article>
    ),
  };

  const addFarmColumn: TableColumnDetails = {
    title: t('growersList.farms') ?? 'Farms',
    key: 'farms',
    width: 60,
    ellipsis: true,
    render: (record: { id: number }): any =>
      (isAdmin || isAccountAdminAccess || isAgentAdminAccess) && (
        <Button
          onClick={(e) => onAddNewFarm(e, record.id)}
          className={classes.tableBtn}
          style={{ textAlign: 'left' }}>
          <IconWithToolTip
            color="#fff"
            svgIcon={
              isToroAccount ? (
                <img
                  src={toroPlus}
                  alt="add new farm"
                  className="addNewFarmIcon"
                />
              ) : (
                <img
                  src={greenPlus}
                  alt="add new farm"
                  className="addNewFarmIcon"
                />
              )
            }
            text={`${t('growersManagement.list.addFarm')}`}
          />
        </Button>
      ),
  };

  const actionsColumn: TableColumnDetails = {
    title: t('growersList.actions') ?? 'Actions',
    key: 'action',
    render: (record: { growerCognitoId: any; id: number }) => (
      <article className={`flex align-center ${classes.actions}`}>
        <Button
          onClick={(e) => onViewGrower(e, record.id)}
          className={classes.tableBtn}>
          <IconWithToolTip
            color="#fff"
            svgIcon={<img src={`${viewIcon}`} alt="view grower" />}
            text={`${t('growersManagement.list.view')}`}
          />
        </Button>
        {(isAdmin || isAccountAdminAccess || isAgentAdminAccess) && (
          <div>
            <Button
              onClick={(e) => onDeleteGrower(e, record.id)}
              className={classes.tableBtn}>
              <IconWithToolTip
                color="#fff"
                svgIcon={
                  <img src={`${deleteIcon}`} alt="delete-grower-popper" />
                }
                text={`${t('growersManagement.list.delete')}`}
              />
            </Button>
          </div>
        )}
        {(isAdmin || isAccountAdminAccess || isAgentAdminAccess) &&
          !record.growerCognitoId && (
            <Button
              onClick={(e) => onActivateGrower(e, record.id)}
              className={classes.tableBtn}>
              <IconWithToolTip
                color="#fff"
                svgIcon={
                  <img
                    src={`${activeIcon}`}
                    className={classes.iconBtn}
                    alt="Activate grower"
                  />
                }
                text={`${t('growersManagement.list.activate')}`}
              />
            </Button>
          )}
      </article>
    ),
  };

  const fullActionColumn: TableColumnDetails = {
    title: t('growersList.actions') ?? 'Actions',
    key: 'action',
    render: (record: { growerCognitoId: any; id: number }) => (
      <article className={`flex align-center ${classes.actions}`}>
        <Button
          onClick={(e) => onViewGrower(e, record.id)}
          className={classes.tableBtn}>
          <IconWithToolTip
            color="#fff"
            svgIcon={<img src={`${viewIcon}`} alt="view grower" />}
            text={`${t('growersManagement.list.view')}`}
          />
        </Button>
        {(isAdmin || isAccountAdminAccess || isAgentAdminAccess) && (
          <Button
            onClick={(e) => onEditGrower(e, record.id)}
            className={classes.tableBtn}>
            <IconWithToolTip
              color="#fff"
              svgIcon={<img src={`${editIcon}`} alt="edit grower" />}
              text={`${t('growersManagement.list.edit')}`}
            />
          </Button>
        )}
        {(isAdmin || isAccountAdminAccess || isAgentAdminAccess) && (
          <div>
            <Button
              onClick={(e) => onDeleteGrower(e, record.id)}
              className={classes.tableBtn}>
              <IconWithToolTip
                color="#fff"
                svgIcon={
                  <img src={`${deleteIcon}`} alt="delete-grower-popper" />
                }
                text={`${t('growersManagement.list.delete')}`}
              />
            </Button>
          </div>
        )}
        {(isAdmin || isAccountAdminAccess || isAgentAdminAccess) &&
          !record.growerCognitoId && (
            <Button
              onClick={(e) => onActivateGrower(e, record.id)}
              className={classes.tableBtn}>
              <IconWithToolTip
                color="#fff"
                svgIcon={
                  <img
                    src={`${activeIcon}`}
                    className={classes.iconBtn}
                    alt="Activate grower"
                  />
                }
                text={`${t('growersManagement.list.activate')}`}
              />
            </Button>
          )}
      </article>
    ),
  };

  return {
    businessNameColumn,
    accountNameColumn,
    growerNameColumn,
    countryColumn,
    stateColumn,
    emailColumn,
    phoneColumn,
    growerIdColumn,
    addFarmColumn,
    actionsColumn,
    fullActionColumn,
  };
};

export default GetColumnsProps;
