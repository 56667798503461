import growersIcon from 'src/assets/Icons/sharedIcons/growers-list.svg';

function FieldsIcon(): JSX.Element {
  return (
    <img
      src={growersIcon}
      alt=""
      style={{ paddingRight: '0.7rem' }}
      height="20px"
    />
  );
}

export default FieldsIcon;
