import { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { setToken } from 'src/reduxToolkit/slices/TokenSlice/token.slice';
import { useAppDispatch } from 'src/reduxToolkit/store/hooks';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import RedirectPage from './RedirectPage';
import notifyService from '../notification/notification.service';
import messageTypes from '../../consts/messages';

function Protected({ children, cookies }: any): JSX.Element {
  const dispatch = useAppDispatch();
  dispatch(setToken(cookies.get('token')));
  const { isAuthLoading, getSetAccessToken, isAuthenticated, autoLogin } =
    useAuth();
  const { t } = useTranslation();
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        if (config?.headers != null) {
          config.headers['x-custom-lang'] = `${
            (localStorage.getItem('i18nextLng') ?? 'en-US').split('-')[0]
          }`;
        }

        return config;
      },
      async (error: any) => Promise.reject(error),
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const codes = [500, 400];
        if (codes.includes(error?.response.status)) {
          switch (error?.response?.data?.type) {
            case 'VALIDATION': {
              notifyService(
                error?.response?.data?.message?.toString(),
                messageTypes.error,
              );
              break;
            }
            default: {
              notifyService(
                t('common.error.somethingWentWrong'),
                messageTypes.error,
              );
            }
          }
        }
      },
    );
  }, [t]);

  // Login handler
  useEffect(() => {
    const code = cookies.get('cognito-code');
    const token: string = cookies.get('token');

    // If token exists apply it to axios
    if (token !== undefined) {
      autoLogin(token);
      // If no token and there is code ask for a token from the server
    } else if (code !== undefined && !isAuthenticated) {
      getSetAccessToken(code);
    }
  }, [cookies, getSetAccessToken, isAuthenticated, autoLogin]);

  if (cookies.get('cognito-code') === undefined) {
    return <RedirectPage />;
  }
  if (isAuthLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '48px' }}>Loading...</div>
    );
  }
  return children;
}

export default withCookies(Protected);
