import ActualIrrigationModal from "./modals/actual_irrigation_modal";
import IrrigationRequirementModal from "./modals/irrigation_requirement_modal";

export enum DepthVolume {
    depth,
    volume
}

export enum ValueTime {
    value,
    time
}

export enum GrowerModalType {
    actualConsumption = 0,
    irrigationRequirement = 1,
    actualIrrigation ,
}


export const PRECISION = 2;



export const ActualConsumptionDaysQty = [
    {display: '3 days', value: 3},
    {display: '7 days', value: 7},
    {display: '2 weeks', value: 14},
    {display: '1 month', value: 31},
    {display: '2 months', value: 61},
]

export const ActualIrrigationDaysQty = [
    {display: '7 days', value: 1},
    {display: '2 weeks', value: 14},
    {display: '1 month', value: 31},
    {display: '3 months', value: 92},
    {display: '6 months', value: 183},
    {display: '1 year', value: 365}
]

export const IrrigationRequirementDaysQty = [
    {display: '1 day', value: 1},
    {display: '2 days', value: 2},
    {display: '3 days', value: 3},
    {display: '4 days', value: 4},
    {display: '5 days', value: 5},
    {display: '6 days', value: 6},
    {display: '7 days', value: 7},
]