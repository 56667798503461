import { useLocation } from 'react-router-dom';
import { WeatherDropdownListProps } from 'src/shared/data/types/weatherTypes';
import { NavbarState } from 'src/shared/constants/enums';
import WeatherVerticalTable from './WeatherVerticalTable';
import { useStyles } from './useStyles';

function WeatherDropdownList(props: WeatherDropdownListProps): JSX.Element {
  const { isOpen, currentFarm } = props;
  const location = useLocation();
  const classes = useStyles({ isOpen });

  return (
    <div
      className={
        location.pathname === NavbarState.FIELDS
          ? classes.container
          : classes.sensorContainer
      }>
      <WeatherVerticalTable currentMiniFarm={currentFarm} />
    </div>
  );
}

export default WeatherDropdownList;
